import { Grid, Typography } from '@mui/material'
import React from 'react'
import ReactionAvatar from '../../../components/assets/ReactionAvatar'
import { cameraIcon } from '../../../components/filters/assets/facade'
import { CalendarEvent } from '../Events'

interface Props {
  event: CalendarEvent
}

function VerticalCustomEvent(props: Props) {
  const { event } = props
  const { title, resource } = event
  const { coach, participants, url } = resource
  const { name } = coach

  return (
    <Grid direction={'row'} container alignItems={'center'} style={{ position: 'relative' }}>
      {url && (
        <img
          src={cameraIcon}
          alt="cameraIcon"
          style={{
            width: 10,
            top: 5,
            right: 5,
            position: 'absolute',
          }}
        />
      )}
      <ReactionAvatar asset={{ url: coach.avatar_url }} resizeMode={'contain'} style={{ width: 30, height: 30 }} />
      <Grid item style={{ marginLeft: 5, width: 'calc(100% - 45px)' }}>
        <Grid direction={'column'} container>
          <Typography style={{ fontWeight: 'bold', width: '70%' }}>{title}</Typography>
          <Typography style={{ fontSize: 10 }}>
            By {name} / {participants?.length || 0} participants
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default VerticalCustomEvent
