import { Grid, Typography } from '@mui/material'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { RouteComponentProps } from 'react-router-dom'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import ReactionMap from '../../components/Location/ReactionMap'
import PaperContainer from '../../components/PaperContainer'
import ReactionLoader from '../../components/ReactionLoader'
import UserContacts from '../../components/user/UserContacts'
import CoachesDispatcher from '../../reducers/coaches/dispatcher'
import CoachCompanies from './coach/companies/CoachCompanies'
import CoachEmployees from './coach/employees/CoachEmployees'
import CoachGroups from './coach/groups/CoachGroups'
import CoachSettings from './coach/settings/CoachSettings'
import CoachGeneral from './components/CoachGeneral'

interface Props extends RouteComponentProps<{ id: string }> {}

function CoachPage(props: Props) {
  const { match } = props
  const dispatch = useDispatch<ReduxDispatch>()

  const { id: coach_id } = match.params || {}

  const { coach } = useSelector((state: ReduxState) => ({
    coach: state.coaches[coach_id],
  }))

  const renderLocation = () => {
    const { latitude: lat, longitude: lng } = coach.location || {}
    const coachCoordinates = { lat, lng }
    return lat && lng ? (
      <ReactionMap initialCenter={coachCoordinates} markers={[coachCoordinates]} />
    ) : (
      'Coach does not select a location'
    )
  }

  const fetchAction = useAsyncFetch(async () => {
    await dispatch(CoachesDispatcher.getCoach(coach_id))
    await dispatch(CoachesDispatcher.getContacts(coach_id))
  }, [coach_id])

  return (
    <>
      <PaperContainer>
        <RendererStatusSplit
          statuses={fetchAction}
          isEmpty={_.isEmpty(coach)}
          renderError={(error) => <Typography>{error}</Typography>}
          renderLoading={() => <ReactionLoader />}
          renderEmpty={() => <Typography textAlign={'center'}>Coach is not found</Typography>}
          render={() => (
            <div>
              <Grid container direction={'row'} justifyContent={'space-between'}>
                <Grid item md={6} sm={9} xs={12}>
                  <CoachGeneral coach={coach} />
                </Grid>
              </Grid>

              <UserContacts user={coach} />
            </div>
          )}
        />
      </PaperContainer>

      {coach && (
        <Grid container justifyContent="center">
          <Grid xs={12} item sx={{ margin: '0 50px 50px 50px' }}>
            <CoachSettings coach={coach} />

            <CoachCompanies coach={coach} />

            <CoachEmployees coach={coach} />

            <CoachGroups coach={coach} />

            <Grid style={{ width: '100%', height: '200px', marginTop: '10px' }}>{renderLocation()}</Grid>
          </Grid>
        </Grid>
      )}
      {/* <SelectCompanyModal */}
      {/*  coach={coach} */}
      {/*  open={isSelectCompanyModalOpen} */}
      {/*  handleClose={() => this.setState({ isSelectCompanyModalOpen: false })} */}
      {/* /> */}
    </>
  )
}

export default CoachPage
