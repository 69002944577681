import Button from '@mui/material/Button/Button'
import Grid from '@mui/material/Grid/Grid'
import Paper from '@mui/material/Paper/Paper'
import TextField from '@mui/material/TextField/TextField'
import { withStyles } from '@mui/styles'
import { Asset } from '@reaction-club-types/core'
import React from 'react'
import { ExtractStyles } from '../../../../../typings/stylesExtractor'
import AssetEditable from '../../../PlayBooks/playbookCreate/AssetEditable'

const connectStyles = withStyles({
  buttonsContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  container: {
    padding: '1rem 2rem',
  },
})

interface Props {
  onSubmit: (form: { name: string; asset_id?: string }) => any
}
interface PropsInner extends Props, ExtractStyles<typeof connectStyles> {}

interface State {
  name: string
  asset: Asset | null
}

class CompanyForm extends React.PureComponent<PropsInner, State> {
  state: State = {
    asset: null,
    name: '',
  }

  render() {
    const { name, asset } = this.state
    const { classes } = this.props

    return (
      <Grid item xs={11} sm={8} lg={5}>
        <Paper>
          <div className={classes.container}>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6}>
                <AssetEditable asset={asset} onChange={this.handleAssetChange} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="standard-name"
                  label="Company name"
                  name="name"
                  required
                  variant="outlined"
                  value={name}
                  onChange={this.handleNameChange}
                  margin="dense"
                />
              </Grid>

              <Grid container justifyContent={'flex-end'} className={classes.buttonsContainer}>
                <Button variant="contained" color="primary" onClick={this.handleSubmit}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
    );
  }

  handleNameChange = (event) => {
    this.setState({ name: event.target.value })
  }

  handleAssetChange = (asset: Asset) => {
    this.setState({ asset })
  }

  handleSubmit = () => {
    const { name, asset } = this.state
    if (!name) return null

    const form = { name, asset_id: asset?.id }
    this.props.onSubmit(form)
  }
}

export default connectStyles(CompanyForm)
