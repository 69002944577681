import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles(
  {
    picture: {
      width: '2rem',
      height: '2rem',
      borderRadius: '50%',
      marginRight: '1rem',
      objectFit: 'cover',
      objectPosition: 'center',
    },
  },
  {
    classNamePrefix: 'UserAvatar',
  },
)

interface Props {
  name?: string
  avatar_url: string
}

function UserAvatar(props: Props) {
  const { avatar_url, name = 'User' } = props
  const classes = useStyles()

  return <img src={avatar_url} className={classes.picture} alt={name} />
}

/** @deprecated better use Avatar from @mui */
export default UserAvatar
