import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { facebookIcon, googleIcon } from '../assets/facade'
import LoginButton from './buttons/LoginButton'

const useStyles = makeStyles(
  {
    container: {
      paddingTop: 20,
    },
  },
  {
    classNamePrefix: 'SocialAuth',
  },
)

interface Props {
  onFacebookLogin(): Promise<any>
  onGoogleLogin(): Promise<any>
}

function SocialAuth(props: Props) {
  const { onFacebookLogin, onGoogleLogin } = props
  const classes = useStyles()

  return (
    <Grid container direction={'column'} justifyContent={'center'} className={classes.container}>
      <Grid container direction={'column'}>
        <LoginButton title={'Sign in with Facebook'} icon={facebookIcon} onLogin={onFacebookLogin} />

        <LoginButton title={'Sign in with Google'} icon={googleIcon} onLogin={onGoogleLogin} />
      </Grid>
    </Grid>
  );
}

export default SocialAuth
