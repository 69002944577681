import _ from 'lodash'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import types from './types'

type State = Record<string, ReduxStateChallengeTypeItem>

export interface ReduxStateChallengeTypeItem {
  challengeTypes: any[]
}

let initialState: State = {}

export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case types.SET: {
      let challengeTypes = action.payload
      if (!_.isArray(challengeTypes)) challengeTypes = [challengeTypes]

      _.forEach(challengeTypes, (type) => {
        let stateType = state[type.id]
        if (stateType) stateType = stateType.merge(type)
        else
          stateType = SeamlessImmutable({
            ...type,
          })

        state = state.merge({
          [type.id]: stateType,
        })
      })

      return state
    }

    case types.DELETE: {
      const { challenge_type_id } = action.payload as { challenge_type_id: string }

      return state.without(challenge_type_id)
    }

    default:
      return state
  }
}
