import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Chip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CompanyDispatcher from '@reducers/companies/dispatcher'
import { ReduxStateCompanyItem } from '@reducers/companies/reducer'
import { lookup } from 'country-data'
import _ from 'lodash'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import CountrySelectModal from '../../../../modals/CountrySelectModal'
import ConfigLabel from '../components/ConfigLabel'

const useStyles = makeStyles(
  {
    contentContainer: {
      padding: 20,
    },

    chipContainer: {
      marginLeft: 3,
      marginRight: 3,
    },
    chipAdd: {
      padding: '0.1rem 0.3rem',
      marginLeft: '5px',
      cursor: 'pointer',
    },
  },
  {
    classNamePrefix: 'CompanyConfigLocations',
  },
)

interface Props {
  company: ReduxStateCompanyItem
}

interface PropsInner extends Props {}

function CompanyConfigLocations(props: PropsInner) {
  const { company } = props
  const classes = useStyles()
  const { config } = company
  let { locations } = config || {}
  const [isModalVisible, setModalVisible] = useState(false)
  const dispatch = useDispatch()

  const handleClick = () => setModalVisible(true)
  const handleModalClose = () => setModalVisible(false)
  const handleAddCountry = async (country: string) => {
    await dispatch(CompanyDispatcher.addCountry(company.id, country))
  }
  const createDeleteHandler = (location: string) => async (ev) => {
    const isConfirmed = confirm(`Are you sure want to delete ${location} country?`)
    if (!isConfirmed) return null

    await dispatch(CompanyDispatcher.removeCountry(company.id, location))
  }

  return (
    <div>
      <ConfigLabel title={'Location:'} />
      {_.map(locations, (location) => {
        const countryInfo = lookup.countries({ alpha2: location })[0]
        const { name, emoji } = countryInfo || {}
        let label = name || location
        if (emoji) label = `${emoji} ${label}`

        return (
          <Chip
            label={label}
            variant="outlined"
            size={'small'}
            className={classes.chipContainer}
            key={`company-location-${location}`}
            onDelete={createDeleteHandler(location)}
          />
        )
      })}

      <span onClick={handleClick} className={classes.chipAdd}>
        <FontAwesomeIcon icon={faPlus} />
      </span>

      <CountrySelectModal isVisible={isModalVisible} onSelect={handleAddCountry} onClose={handleModalClose} />
    </div>
  )
}

export default CompanyConfigLocations
