/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { faPen, faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Link, Typography, Input } from '@mui/material'
import { UserContacts } from '@reaction-club-types/core'
import EmployeeDispatcher from '@reducers/employees/dispatcher'
import apiUsers from '@reducers/users/apiUsers'
import React, { useState } from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import validator from 'validator'
import Loader from '../Loader'

const cssLabel = css({
  marginRight: 5,
  color: '#4c4c4c',
  fontWeight: 'bold',
})

interface Props {
  user: {
    user_id: string
    contacts?: UserContacts
  }
}

function UserContactsView(props: Props) {
  const { user } = props
  const { contacts, user_id } = user || {}
  const { email, phone_number } = contacts || {}
  const [isEditMode, setEditMode] = useState(false)
  const [value, setValue] = useState<string>(email || '')
  const dispatch = useDispatch()

  const saveEmailAction = useAsyncHandler(async () => {
    await apiUsers.setContactEmail(user_id, value)
    await dispatch(EmployeeDispatcher.getContacts({ employee_id: user_id }))
    setEditMode(false)
  })

  const handleClickEdit = () => {
    setValue(email || '')
    setEditMode(true)
  }
  const handleEdit = (e) => {
    setValue(e.target.value)
  }

  const handlePressSave = () => {
    saveEmailAction.execute()
  }

  const isValid = validator.isEmail(value)

  return (
    <Grid
      css={css`
        margin-top: 10px;
      `}
    >
      <Typography css={css`font-size 16px;`} fontWeight={'bold'}>
        Contacts:
      </Typography>
      <Grid container alignItems={'center'}>
        <span css={cssLabel}>Email:</span>

        <div>
          {isEditMode ? (
            <Grid container alignItems={'center'}>
              <Input value={value} onChange={handleEdit} />
              <div
                css={css`
                  padding-left: 20px;
                `}
              >
                <RendererStatusSplit
                  statuses={saveEmailAction}
                  renderLoading={() => (
                    <div
                      css={css`
                        position: relative;
                      `}
                    >
                      <Loader scale={0.3} type={'absolute'} />
                    </div>
                  )}
                  render={() =>
                    isValid && (
                      <FontAwesomeIcon
                        onClick={handlePressSave}
                        icon={faFloppyDisk}
                        css={css`
                          cursor: pointer;
                          color: #797979;
                          //margin-left: 10px;
                        `}
                      />
                    )
                  }
                />
              </div>
            </Grid>
          ) : (
            <div>
              {email ? (
                <Link href={`mailto:${email}`} target={'_blank'}>
                  {email}
                </Link>
              ) : (
                <span>-</span>
              )}
            </div>
          )}
        </div>

        <div>
          {isEditMode ? (
            <div />
          ) : (
            <FontAwesomeIcon
              icon={faPen}
              onClick={handleClickEdit}
              css={css`
                font-size: 12px;
                color: #797979;
                margin-left: 10px;
              `}
            />
          )}
        </div>
      </Grid>

      <Grid container alignItems={'center'}>
        <span css={cssLabel}>Phone:</span>

        {phone_number ? <Link href={`tel:${phone_number}`}>{phone_number}</Link> : <span>-</span>}
      </Grid>
    </Grid>
  )
}

export default UserContactsView
