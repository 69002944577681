import { UserDevice } from '@reaction-club-types/core'
import _ from 'lodash'
import React from 'react'
import DevicesTable from './DevicesTable'
import UserDeviceItem from './UserDeviceItem'

interface Props {
  devices: UserDevice[]
}
export default function UserDevices(props: Props) {
  const { devices } = props

  const devicesOrdered = _.orderBy(devices, ['last_login_at', 'created_at'], 'desc')

  return (
    <DevicesTable>
      {devicesOrdered.map((device, index) => (
        <UserDeviceItem device={device} />
      ))}
    </DevicesTable>
  )
}
