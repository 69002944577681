import keyManager from 'constant-manager'

export default keyManager(
  {
    INIT_CREATE_PROGRAM: '[CREATE PROGRAM]: Set init state',
    CHANGE_NAME: '[CREATE PROGRAM]: Set program name',
    CHANGE_DESCR: '[CREATE PROGRAM]: Set program description',
    ADD_MILESTONE: '[CREATE PROGRAM]: Add milestone to program',
    ADD_TASK: '[CREATE PROGRAM]: Add task to milestone',
  },
  { prefix: 'CreateProgram__' },
)
