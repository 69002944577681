/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import EmployeeDispatcher from '@reducers/employees/dispatcher'
import { ReduxStateEmployeesItem } from '@reducers/employees/reducer'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../../components/Loader'
import UserDevices from './UserDevices/UserDevices'

interface Props {
  employee: ReduxStateEmployeesItem
}
function EmployeeDevices(props: Props) {
  const { employee } = props
  const { devices } = employee
  const dispatch = useDispatch()

  const devicesFetch = useAsyncFetch(async () => {
    await dispatch(EmployeeDispatcher.getDevices(employee.user_id))
  })

  return (
    <div
      css={css`
        min-height: 180px;
      `}
    >
      <RendererStatusSplit
        statuses={devicesFetch}
        isEmpty={_.isEmpty(devices)}
        renderError={(error) => <Typography color={'error'}>{error}</Typography>}
        renderLoading={() => (
          <Grid container justifyContent={'center'}>
            <Loader />
          </Grid>
        )}
        renderEmpty={() => (
          <div>
            <span style={{ fontSize: 30 }}>-</span>
          </div>
        )}
        render={() => <UserDevices devices={devices} />}
      />
    </div>
  )
}

export default EmployeeDevices
