import { Grid, Typography } from '@mui/material'
import AppDispatcher from '@reducers/app/dispatcher'
import AuthDispatcher from '@reducers/auth/dispatcher'
import { ReduxState } from '@reducers/index'
import React, { Suspense } from 'react'
import { connect } from 'react-redux'
import { WithRouterProps } from 'react-router'
import { ReduxDispatch } from '../typings/ReduxDispatch'
import { ExtractConnectType } from '../typings/ReduxExtractor'
// import ReactionLoader from './ReactionLoader'

const ua = window.navigator.userAgent
const isIE = /MSIE|Trident/.test(ua)

interface State {
  isReady: boolean
  error?: string
}
class AppLauncher extends React.PureComponent<WithRouterProps<{}> & ExtractConnectType<typeof connectStore>, State> {
  state = {
    isReady: false,
    error: '',
  }

  render() {
    const { isReady, error } = this.state
    if (error)
      return (
        <Grid container justifyContent={'center'}>
          <Typography variant={'error'} style={{ color: 'red', marginTop: 40 }}>
            {error}
          </Typography>
        </Grid>
      )

    if (!isReady) return null
    const { children } = this.props

    if (isIE) {
      return <div style={{ textAlign: 'center', padding: 20 }}>We don't support IE, try any other browser.</div>
    }

    return <Suspense fallback={null}>{children}</Suspense>
  }

  async handleInstall() {
    try {
      await this.props.useCredentials()
      await this.props.install()
      this.setState({ isReady: true })
    } catch (e) {
      this.setState({ error: e.message })
    }
  }

  componentDidMount(): void {
    this.handleInstall()
  }
}

const connectStore = connect(
  (state: ReduxState) => ({}),
  (dispatch: ReduxDispatch) => ({
    useCredentials: () => dispatch(AuthDispatcher.useCredentials()),
    install: () => dispatch(AppDispatcher.install()),
  }),
)
export default connectStore(AppLauncher)
