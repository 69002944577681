import React from 'react'
import { compose } from 'redux'
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react'
import { Grid } from '@mui/material'

const YOUR_GOOGLE_API_KEY_GOES_HERE = 'AIzaSyCaUJ8uieZIvOAlQGAcOOo99meQrbOa5MQ'

interface Coordinates {
  lat: number
  lng: number
}
interface Props {
  markers: Coordinates[]
  initialCenter: Coordinates
  center: Coordinates
}

class ReactionMap extends React.PureComponent<Props> {
  render() {
    const { markers, initialCenter, center } = this.props
    return (
      <Map
        initialCenter={initialCenter || TelAvivCoordinates}
        containerStyle={{ position: 'relative', width: '100%', height: '100%' }}
        google={this.props.google}
        zoom={10}
        center={center || TelAvivCoordinates}
      >
        {markers.map(marker => (
          <Marker position={{ lat: marker.lat, lng: marker.lng }} />
        ))}
      </Map>
    )
  }
}

const TelAvivCoordinates = { lat: 32.109333, lng: 34.855499 }

export default compose(
  GoogleApiWrapper({
    apiKey: YOUR_GOOGLE_API_KEY_GOES_HERE,
  }),
)(ReactionMap)
