import { Edit as EditIcon } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material'
import { ZoomHost } from '@reaction-club-types/core'
import ZoomHostDispatcher from '@reducers/zoomHosts/dispatcher'
import React, { useRef } from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import Loader from '../../../components/Loader'
import useModalState from '../../../hooks/useModalState'

interface Props {
  zoomHost: ZoomHost
}

function ZoomHostActions(props: Props) {
  const { zoomHost } = props
  const menuVisibility = useModalState()
  const anchorRef = useRef<any>()
  const dispatch = useDispatch<ReduxDispatch>()

  const { id, is_enabled } = zoomHost

  const actionSetEnabled = useAsyncHandler(async (is_enabled: boolean) => {
    await dispatch(ZoomHostDispatcher.setHostEnabled({ id, is_enabled }))
  })

  const handleClickEnable = () => {
    menuVisibility.onClose()
    return actionSetEnabled.execute(true)
  }

  const handleClickDisable = () => {
    menuVisibility.onClose()
    return actionSetEnabled.execute(false)
  }

  const { isLoading } = actionSetEnabled

  return (
    <div>
      {/* <EventUpdateModal event={event} isVisible={isEditModalVisible} onClose={handleUpdateModalClose} /> */}
      {/* <EventDeleteModal event={event} {...eventDeleteModal} onDelete={onDelete} /> */}
      {isLoading ? (
        <div>
          <Loader scale={0.3} />
        </div>
      ) : (
        <IconButton
          ref={anchorRef}
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={menuVisibility.onOpen}
          size="large"
        >
          <MoreVertIcon />
        </IconButton>
      )}
      <Menu
        id="long-menu"
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={menuVisibility.isVisible}
        onClose={menuVisibility.onClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {is_enabled ? (
          <MenuItem onClick={handleClickDisable}>
            <ListItemIcon>
              <EditIcon color={'primary'} fontSize={'small'} />
            </ListItemIcon>
            <Typography variant="inherit" color={'error'}>
              Disable host
            </Typography>
          </MenuItem>
        ) : (
          <MenuItem onClick={handleClickEnable}>
            <ListItemIcon>
              <EditIcon color={'primary'} fontSize={'small'} />
            </ListItemIcon>
            <Typography variant="inherit">Enable host</Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}

export default ZoomHostActions
