/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography, CardActionArea, Grid, Avatar } from '@mui/material'
import { Employee } from '@reaction-club-types/core'
import moment from 'moment'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

interface Props {
  user: Employee
}
function EmployeeCard(props: Props) {
  const { user } = props
  const { user_id, name, avatar_url, created_at, contacts, points } = user
  const { email, phone_number } = contacts || {}

  const history = useHistory()
  const href = history.createHref({ pathname: `/employee/${user_id}` })

  const createDate = useMemo(() => {
    return moment(created_at).format('LLL')
  }, [created_at])

  return (
    <Grid item xs={6} sm={6} md={4} lg={3}>
      <CardActionArea
        href={href}
        css={css`
          padding: 5px;
          border-radius: 8px;
          //overflow: hidden;
          position: relative;
        `}
      >
        <Grid
          css={css({
            position: 'relative',
            zIndex: 100,
            textAlign: 'center',
          })}
          container
          direction={'column'}
          alignItems={'center'}
        >
          <div
            css={css`
              position: relative;
            `}
          >
            <Avatar src={avatar_url} />
            {points ? (
              <div
                css={css`
                  background-color: #92d1ff;
                  padding: 1px 5px;
                  border-radius: 5px;
                  position: absolute;
                  bottom: -2px;
                  left: 70%;
                `}
              >
                <Typography
                  css={css`
                    font-size: 12px;
                    line-height: 14px;
                  `}
                >
                  {points}p
                </Typography>
              </div>
            ) : null}
          </div>

          <Typography
            css={css`
              font-size: 1rem;
              line-height: 1.2rem;
              margin: 4px 0;
              font-weight: bold;
            `}
          >
            {name || '-'}
          </Typography>

          <Typography variant={'caption'}>{phone_number}</Typography>
          <Typography variant={'caption'}>{email}</Typography>
          <Typography variant={'caption'}>{createDate}</Typography>
        </Grid>
      </CardActionArea>
    </Grid>
  )
}

export default EmployeeCard
