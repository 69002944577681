import { CircularProgress, Fab, Grid, Typography, Dialog } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Add as AddIcon } from '@mui/icons-material'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { Route, RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../../typings/ReduxExtractor'
import { ExtractStyles } from '../../../../typings/stylesExtractor'
import CompanyDispatcher from '../../../reducers/companies/dispatcher'
import CompanyInstancesSubPage from './CompanyInstancesSubPage'
import EditInstanceModal from './components/EditInstanceModal'
import InstanceListItem from './components/InstanceListItem'
import PageSection from '../../../layout/PageSection'

const connectStyles = withStyles({
  coachesListContainer: {
    width: 250,
    borderRight: '1px solid #e8d2d2',
    height: 500,
    overflowY: 'auto',
  },

  subPage: {
    height: 500,
    overflowY: 'auto',
  },

  emptyContainer: {
    padding: 10,
  },

  addBtn: {
    display: 'block',
    margin: '0 8px 0 auto',
  },
  modal: {
    minWidth: 300,
    maxWidth: 1200,
    width: '40%',
  },
})

interface Props extends RouteComponentProps<{ id: string }> {
  company_id: string
}

interface PropsInner extends Props, ExtractConnectType<typeof connectStore>, ExtractStyles<typeof connectStyles> {}

interface State {
  isLoading: boolean
  isModalOpened: boolean
}

class CompanyInstances extends React.PureComponent<PropsInner, State> {
  state = {
    isLoading: false,
    isModalOpened: false,
  }

  render() {
    const { classes, match, company } = this.props
    const { instance_count } = company

    const subPath = `${match.path}/instances/:instance_id`

    let title = 'Groups'
    if (instance_count) title += ` (${instance_count})`

    return (
      <PageSection title={title}>
        <Grid container direction={'row'}>
          <Grid item lg={3}>
            <Grid container direction={'row'} alignItems={'center'}>
              <div className={classes.coachesListContainer}>
                {this.renderList()}
                <Fab
                  color="primary"
                  aria-label="add"
                  size={'medium'}
                  className={classes.addBtn}
                  onClick={this.handlePressAdd}
                >
                  <AddIcon />
                </Fab>
                {this.renderModal()}
              </div>
            </Grid>
          </Grid>

          <Grid className={classes.subPage} item lg={9}>
            <Route sensitive exact path={subPath} render={props => <CompanyInstancesSubPage {...props} />} />
          </Grid>
        </Grid>
      </PageSection>
    )
  }

  renderList() {
    const { company, classes } = this.props
    const { isLoading } = this.state
    const { instances } = company

    let instancesOrdered = _.orderBy(instances, 'employee_count').reverse()

    if (isLoading)
      return (
        <Grid container justifyContent={'center'} className={classes.emptyContainer}>
          <CircularProgress color={'primary'} size={30} />
        </Grid>
      );

    if (_.isEmpty(instances))
      return (
        <Grid container justifyContent={'center'} className={classes.emptyContainer}>
          <Typography variant={'h4'}>No Instances</Typography>
        </Grid>
      );

    return _.map(instancesOrdered, instance => (
      <InstanceListItem instance={instance} key={`company-list-instance-${instance.id}`} />
    ))
  }

  handlePressAdd = () => {
    this.setState({
      isModalOpened: true,
    })
  }

  renderModal = () => {
    const { classes } = this.props
    const { isModalOpened } = this.state

    return (
      <Dialog
        classes={{
          paper: classes.modal,
        }}
        open={isModalOpened}
        onClose={() => this.setState({ isModalOpened: false })}
      >
        <EditInstanceModal finish={() => this.setState({ isModalOpened: false })} />
      </Dialog>
    )
  }

  async fetchData() {
    const { employees } = this.props.company || {}
    this.setState({ isLoading: _.isEmpty(employees) })
    try {
      const instances = await this.props.getInstances()
      const getCoaches = instances.map(instance => this.props.getInstanceCoaches(instance.id))
      await Promise.all(getCoaches)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  componentDidMount(): void {
    this.fetchData()
  }
}

const connectStore = connect(
  (state: ReduxState, { match }: Props) => ({
    company: state.companies[match.params.id],
  }),
  (dispatch: ReduxDispatch, { match }: Props) => ({
    getInstances: () => dispatch(CompanyDispatcher.getInstances(match.params.id)),
    getInstanceCoaches: (instance_id: string) =>
      dispatch(CompanyDispatcher.getInstanceCoaches(match.params.id, instance_id)),
  }),
)

export default compose<PropsInner, Props>(withRouter, connectStore, connectStyles)(CompanyInstances)
