/** @jsx jsx */
import { jsx } from '@emotion/react'
import { css } from '@emotion/react'
import { Avatar, CardActionArea, Grid, List, Typography } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Group } from '@reaction-club-types/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import ReactionAvatar from '../../../../components/asset/ReactionAvatar'

interface Props {
  group: Group
  url: string
  isSelected: boolean
}

function CoachGroupItem(props: Props) {
  const { group, url, isSelected } = props

  const history = useHistory()

  return (
    <Grid item xs={12} component={'div'} key={`group-item-${group.id}`} style={{ position: 'relative' }}>
      <CardActionArea
        css={[
          css({
            backgroundColor: '#EFEFEF',
            marginBottom: '0.7rem',
            borderRadius: '0.3rem',
            color: '#323232',

            '&:hover': {
              backgroundColor: '#dadada',
            },
          }),
          isSelected &&
            css`
              background-color: #323232;
              color: #fff;
            `,
        ]}
        href={history.createHref({ pathname: `${url}/g/${group.id}` })}
        disabled={isSelected}
      >
        <ListItem component="a">
          <ListItemIcon style={{ marginRight: 10 }}>
            <ReactionAvatar asset={group.asset ? group.asset : group.playbook ? group.playbook.asset : null} />
          </ListItemIcon>
          <ListItemText primary={group.name} />
        </ListItem>
      </CardActionArea>
    </Grid>
  )
}

export default CoachGroupItem
