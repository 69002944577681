// COACHES REQUESTS
import { prefix_web } from './apiOld'

export default {
  async getCoaches(query: ApiWebRequestCoachesQuery): Promise<ApiWebResponseCoaches> {
    const result = await this.apiClient.get(`${prefix_web}coaches`, {
      params: query,
    })
    return result.data
  },
  /**
   * Get detail about one Coach
   * @param coachId - coach number id
   * @returns Object - detail about coach inside
   */
  async getCoachById(coachId) {
    const result = await this.apiClient.get(`${prefix_web}coach/${coachId}`)
    return result.data
  },
  /**
   * Assigned roads to coach
   * @param coachId - coach number id
   * @param roads - array roads id [0,1]
   * @returns Object - status inside
   */
  async setCoachRoads(coachId, roads) {
    const result = await this.apiClient.post(`${prefix_web}coach/${coachId}/roads`, roads)
    return result.data
  },
  async getCoachCompanies(coachId) {
    const result = await this.apiClient.get(`${prefix_web}coach/${coachId}/companies`)
    return result.data
  },
  async getCoachRoads(coachId) {
    const result = await this.apiClient.get(`${prefix_web}coach/${coachId}/roads`)
    return result.data
  },
  async getCoachEmployees(coachId) {
    const result = await this.apiClient.get(`${prefix_web}coach/${coachId}/employees`)
    return result.data
  },
  async updateCoach(coachId, coachModel) {
    const result = await this.apiClient.post(`${prefix_web}coach/${coachId}`, coachModel)
    return result.data
  },
  async getCoachActiveDevices(coachId) {
    const result = await this.apiClient.get(`${prefix_web}coach/${coachId}/devices`)
    return result.data
  },
  async getCoachPlaybook(coachId) {
    const result = await this.apiClient.get(`${prefix_web}coach/${coachId}/paybooks`)
    return result.data
  },
  async assignCompaniesToCoach(coach_id: string, companiesId: string[]) {
    const result = await this.apiClient.post(`${prefix_web}coach/${coach_id}/companies`, { companies: companiesId })
    return result.data
  },
  async updateCoachContract(coach_id: string, is_contract_signed: boolean) {
    const result = await this.apiClient.post(`${prefix_web}coach/${coach_id}/contract`, { is_contract_signed })
    return result.data
  },
  async updateZoomEnabled(coach_id: string, is_zoom_enabled: boolean) {
    const result = await this.apiClient.post(`${prefix_web}coach/${coach_id}/zoom/available`, { is_zoom_enabled })
    return result.data
  },
  async getCoachGroups(coach_id: string): Promise<PlaybookInstance[]> {
    const result = await this.apiClient.get(`${prefix_web}coach/${coach_id}/groups`)
    return result.data
  },
  async getPersonalZoomHost(coach_id: string): Promise<ZoomHost | null> {
    const result = await this.apiClient.get(`${prefix_web}coach/${coach_id}/zoom/host`)
    return result.data
  },
}
