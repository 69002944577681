import TextField from '@mui/material/TextField'
import { TimePicker } from '@mui/x-date-pickers'
import moment, { Moment } from 'moment'
import React, { useEffect, useMemo, useState } from 'react'

interface Props {
  disabled?: boolean
  value: number
  onChange: (number: number) => void
  resultType: string
  challengeType: string
}
function ChallengeAppGoalInput(props: Props) {
  const { disabled = false, value, resultType, onChange, challengeType } = props
  const [time, setTime] = useState<Moment | null>(null)
  const isDisabled = useMemo(() => disabled || challengeType === 'steps', [disabled, challengeType])

  useEffect(() => {
    const duration = moment.duration(value, 'seconds')
    const momentObject = moment().startOf('day').add(duration)

    setTime(momentObject)
  }, [value])

  useEffect(() => {
    if (challengeType === 'steps') {
      onChange(6000)
    }
  }, [challengeType])

  const handleTime = (timeValue: Moment | null) => {
    if (!timeValue) return null
    const minutes = moment(timeValue, 'mm:ss').minutes()
    const seconds = moment(timeValue, 'mm:ss').seconds()
    return onChange(minutes * 60 + seconds)
  }

  if (resultType === 'time') {
    return (
      <TimePicker
        value={time}
        disabled={isDisabled}
        onChange={(timeValue) => setTime(timeValue)}
        views={['minutes', 'seconds']}
        inputFormat="mm:ss"
        disableOpenPicker
        mask="__:__"
        renderInput={(inputProps) => (
          <TextField
            {...inputProps}
            required
            value={value}
            label="Initial Goal: Time"
            variant="outlined"
            margin="dense"
            onBlur={() => handleTime(time)}
          />
        )}
      />
    )
  }

  return (
    <TextField
      required
      disabled={isDisabled}
      value={value}
      onChange={(e) => onChange(Math.abs(+(e.target.value || 0)))}
      label="Initial Goal: Repeats"
      inputMode="numeric"
      variant="outlined"
      margin="dense"
    />
  )
}

export default ChallengeAppGoalInput
