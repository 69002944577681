import keyManager from 'constant-manager'

const agentTypes = keyManager(
  {
    SET: '',
    SET_LIST: '',
    SET_LIST_NEXT: '',
    SET_COMPANY: '',
    REMOVE: '',
  },
  { prefix: 'AGENTS__' },
)

export default agentTypes
