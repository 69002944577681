import { Employee, Group, UserPointsLog, UserStepsDaily } from '@reaction-club-types/core'
import apiClient from '../../lib/apiClient'

export interface ApiEmployeesListParams {
  name?: string
  phone?: string
  email?: string
  offset: number
}

const apiEmployees = {
  getEmployees: async (params: ApiEmployeesListParams): Promise<{ employees: Employee[]; offset: number }> => {
    const result = await apiClient.get(`/employee/list`, {
      params,
    })
    return result.data
  },

  deleteEmployee: async (user_id: string): Promise<any> => {
    const result = await apiClient.delete(`/employee/${user_id}`)
    return result.data
  },

  getGroups: async (employee_id: string): Promise<Group[]> => {
    const result = await apiClient.get(`/employee/${employee_id}/groups`)
    return result.data
  },
  getPointsLog: async (employee_id: string, params: { offset: number }): Promise<UserPointsLog[]> => {
    const result = await apiClient.get(`/employee/${employee_id}/point-logs`, {
      params,
    })
    return result.data
  },
  getStepsHistory: async (employee_id: string): Promise<UserStepsDaily[]> => {
    const result = await apiClient.get(`/employee/${employee_id}/steps-history`, {
      // params,
    })
    return result.data
  },
  changePoints: async (employee_id: string, points: number): Promise<UserStepsDaily[]> => {
    const result = await apiClient.post(`/employee/${employee_id}/points`, {
      points,
    })
    return result.data
  },

  setCompany: async (employee_id: string, params: { company_id: string }): Promise<any> => {
    const result = await apiClient.post(`/employee/${employee_id}/company`, params)
    return result.data
  },
}

export default apiEmployees
