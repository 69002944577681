import { ThemeProvider } from '@mui/material/styles'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { SnackbarProvider } from 'notistack'
import React, { ReactElement } from 'react'
import { IFrameGuard } from 'react-iframe-guard'
import { JssProvider } from 'react-jss'
import { Provider } from 'react-redux'
import ErrorMessageProvider from './context/ErrorMessageProvider'
import store from './store'
import theme from './theme'

import './libs/firebase'

interface Props {
  children: ReactElement
}
export default function AppContext(props: Props) {
  const { children } = props

  return (
    <JssProvider>
      <IFrameGuard>
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <ErrorMessageProvider>
                <Provider store={store}>{children}</Provider>
              </ErrorMessageProvider>
            </LocalizationProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </IFrameGuard>
    </JssProvider>
  )
}
