import apiClient from '../../lib/apiClient'

const apiZoomHost = {
  async setZoomHostEnabled(id: string, is_enabled: boolean): Promise<{}> {
    const result = await apiClient.post(`/zoom-hosts/${id}/set-enabled`, { is_enabled })
    return result.data
  },
}

export default apiZoomHost
