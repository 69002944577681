import { Grid, Typography, CircularProgress } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { Company } from '@reaction-club-types/core'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { Route, RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../../typings/ReduxExtractor'
import { ExtractStyles } from '../../../../typings/stylesExtractor'
import PageSection from '../../../layout/PageSection'
import { ReduxState } from '../../../reducers'
import CompanyDispatcher from '../../../reducers/companies/dispatcher'
import CompanyAgentSubPage from './CompanyAgentSubPage'
import AgentListItem from './components/AgentListItem'

const connectStyles = withStyles({
  coachesListContainer: {
    // width: 250,
    borderRight: '1px solid #e8d2d2',
    height: 300,
    overflowY: 'auto',
  },

  emptyContainer: {
    padding: 10,
  },

  noAgentsText: {
    marginTop: 10,
    marginBottom: 10,
  },
})

interface State {
  isLoading: boolean
  isExists: boolean
}

interface Props extends RouteComponentProps<{ id: string }> {
  company: Company
}

interface PropsInner extends Props, ExtractStyles<typeof connectStyles>, ExtractConnectType<typeof connectStore> {}

class CompanyAgents extends React.PureComponent<PropsInner, State> {
  state: State = {
    isLoading: true,
    isExists: false,
  }

  render() {
    const { company } = this.props
    if (_.isEmpty(company)) return null

    return <PageSection title={'Agents'}>{this.renderBody()}</PageSection>
  }

  renderBody() {
    const { company, classes, match } = this.props
    const { isExists } = this.state

    const agentPath = `${match.url}/agent/:agent_id`

    if (!isExists) {
      return (
        <Typography variant={'body1'} className={classes.noAgentsText} align={'center'}>
          No agents
        </Typography>
      )
    }

    return (
      <Grid container direction={'row'}>
        <Grid item>
          <Grid container direction={'row'} alignItems={'center'}>
            <div className={classes.coachesListContainer}>{this.renderList({ routePath: agentPath })}</div>
          </Grid>
        </Grid>

        <Grid item>
          <Route
            sensitive
            exact
            path={agentPath}
            render={(props) => <CompanyAgentSubPage {...props} company={company} />}
          />
        </Grid>
      </Grid>
    )
  }

  renderList({ routePath }: { routePath: string }) {
    const { company, classes } = this.props
    const { isLoading } = this.state
    const { agents } = company

    if (isLoading)
      return (
        <Grid container justifyContent={'center'} className={classes.emptyContainer}>
          <CircularProgress color={'primary'} size={30} />
        </Grid>
      )

    if (_.isEmpty(agents))
      return (
        <Grid container justifyContent={'center'} className={classes.emptyContainer}>
          <Typography variant={'h4'}>No Agents</Typography>
        </Grid>
      )

    return _.map(agents, (agent) => (
      <AgentListItem agent={agent} key={`company-list-agent-${agent.user_id}`} agentRoutePath={routePath} />
    ))
  }

  async fetchData() {
    const { agents } = this.props.company || {}
    this.setState({ isLoading: _.isEmpty(agents) })
    try {
      const agents = await this.props.getCompanyAgents()
      this.setState({ isExists: !_.isEmpty(agents) })
    } finally {
      this.setState({ isLoading: false })
    }
  }

  componentDidMount(): void {
    this.fetchData()
  }
}

const connectStore = connect(
  (state: ReduxState, { match }: Props) => {
    return {}
  },
  (dispatch: ReduxDispatch, { match }: Props) => ({
    getCompanyAgents: () => dispatch(CompanyDispatcher.getAgents(match.params.id)),
  }),
)
export default compose<PropsInner, Props>(withRouter, connectStore, connectStyles)(CompanyAgents)
