import { ListItemText } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import { Company } from '@reaction-club-types/core'
import React from 'react'
import CompanyLogo from '../company/CompanyLogo'

interface Props {
  company: Company
  onPress(company: Company): any
}

function CompanyPreview(props: Props) {
  const { company, onPress } = props

  const handlePress = () => {
    onPress(company)
  }

  return (
    <ListItem button component="a" onClick={handlePress}>
      <ListItemIcon style={{ marginRight: 10 }}>
        <CompanyLogo company={company} width={34} height={34} />
      </ListItemIcon>
      <ListItemText primary={company.name} />
      {/* <ListItemSecondaryAction> */}
      {/*  <IconButton onClick={() => props.handleDelete(company.id)} edge="end" aria-label="delete"> */}
      {/*    <DeleteIcon /> */}
      {/*  </IconButton> */}
      {/* </ListItemSecondaryAction> */}
    </ListItem>
  )
}

export default CompanyPreview
