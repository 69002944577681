import { Typography, CardActionArea, Grid, Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(
  {
    card: {
      padding: 5,
      borderRadius: 8,
      overflow: 'hidden',
      position: 'relative',
    },

    container: {
      position: 'relative',
      zIndex: 100,
      textAlign: 'center',
      paddingTop: 5,
      paddingBottom: 5,
    },

    title: {
      fontSize: '1rem',
      lineHeight: '1.2rem',
      margin: '4px 0',
      fontWeight: 'bold',
    },

    icon: {
      color: '#8f918f',
    },

    employeeCount: {
      marginLeft: 5,
      fontSize: 12,
      color: '#282828',
    },
  },
  {
    classNamePrefix: 'CompanyCard',
  },
)

interface Props {
  type: any
  index: number
}
function ChallengeTypeCard(props: Props) {
  const { type, index } = props

  const classes = useStyles()
  const history = useHistory()
  const href = history.createHref({
    pathname: `/challenge-type/${type.id}`,
  })

  return (
    <Grid item gap={2}>
      <CardActionArea href={href} className={classes.card}>
        <Grid className={classes.container} container direction={'row'} alignItems={'center'}>
          <Grid xs={1}>
            <Typography className={classes.title}>{index}</Typography>
          </Grid>
          <Grid xs={6}>
            <Typography className={classes.title}>{type.label}</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography className={classes.title}>{type.name}</Typography>
          </Grid>
        </Grid>
      </CardActionArea>
      <Divider />
    </Grid>
  )
}

export default ChallengeTypeCard
