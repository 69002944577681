import _ from 'lodash'
import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import api from '../../libs/api/api'
import apiOld from '../../libs/api/apiOld'
import playbookInstanceActions from '../playbookInstances/actions'
import actions from './actions'

class Dispatcher {
  actions = actions

  getCoaches(): DispatcherResponse {
    return async dispatch => {
      const { coaches } = await apiOld.getCoaches({ offset: 0, name: null })
      return coaches.forEach(coach => dispatch(actions.setCoach(coach)))
    }
  }

  getCoach(coach_id: string): DispatcherResponse {
    return async dispatch => {
      const coach = await apiOld.getCoachById(coach_id)
      dispatch(actions.setCoach(coach))
      return coach
    }
  }

  getCoachCompanies(coach_id: string): DispatcherResponse {
    return async dispatch => {
      const companies = await apiOld.getCoachCompanies(coach_id)
      dispatch(actions.setCoachCompanies(coach_id, companies))
    }
  }

  getCoachEmployees(coach_id: string): DispatcherResponse {
    return async dispatch => {
      const employees = await apiOld.getCoachEmployees(coach_id)
      dispatch(actions.setCoachEmployees(coach_id, employees))
    }
  }

  getCoachGroups(coach_id: string): DispatcherResponse {
    return async dispatch => {
      const groups = await apiOld.getCoachGroups(coach_id)
      groups.map(group => dispatch(playbookInstanceActions.set(group)))
      dispatch(actions.setCoachGroups(coach_id, groups))
    }
  }

  assignCompaniesToCoach(coach_id: string, companyIds: string[]): DispatcherResponse {
    return async dispatch => {
      await apiOld.assignCompaniesToCoach(coach_id, companyIds)
      await dispatch(this.getCoachCompanies(coach_id))
    }
  }

  updateCoachContract(coach_id: string, is_contract_signed: boolean): DispatcherResponse {
    return async dispatch => {
      await apiOld.updateCoachContract(coach_id, is_contract_signed)
      await dispatch(this.getCoach(coach_id))
    }
  }

  updateCertified(coach_id: string, is_certified: boolean): DispatcherResponse {
    return async dispatch => {
      await apiOld.updateCertified(coach_id, is_certified)
      await dispatch(this.getCoach(coach_id))
    }
  }

  updateZoomEnabled(coach_id: string, is_zoom_enabled: boolean): DispatcherResponse {
    return async dispatch => {
      await apiOld.updateZoomEnabled(coach_id, is_zoom_enabled)
      await dispatch(this.getCoach(coach_id))
    }
  }

  unAssignCoachFromCompany(coach_id: string, company_id: string): DispatcherResponse {
    return async dispatch => {
      await apiOld.unassigneCoachWithCompany(company_id, coach_id)
      await dispatch(this.getCoachCompanies(coach_id))
    }
  }

  addCoachToGroup(coach_id: string, instance_id: string): DispatcherResponse {
    return async dispatch => {
      await apiOld.addCoachToGroup({ instance_id, coach_id })
      await dispatch(this.getCoachGroups(coach_id))
    }
  }

  getPersonalZoomHost(coach_id: string): DispatcherResponse {
    return async dispatch => {
      const host = await apiOld.getPersonalZoomHost(coach_id)
      await dispatch(actions.setPersonalHost(coach_id, host))
    }
  }

  getContacts(coach_id: string): DispatcherResponse {
    return async dispatch => {
      const contacts = await api.getUserContacts(coach_id)
      // dispatch(actions.setContacts({ coach_id, contacts }))
      // todo we need to migrate previous contacts first
    }
  }
  //
  // getCoachesNext(): DispatcherResponse {
  //   return async dispatch => {
  //     const coaches = await api.getCoaches()
  //     dispatch(actions.setCoaches(coaches))
  //   }
  // },
}

const CoachDispatcher = new Dispatcher()
export default CoachDispatcher
