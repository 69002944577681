import { FormControlLabel, Grid, Switch } from '@mui/material'
import CoachesDispatcher from '@reducers/coaches/dispatcher'
import { ReduxStateCoach } from '@reducers/coaches/reducer'
import React from 'react'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../../../typings/ReduxDispatch'
import PageSection from '../../../../layout/PageSection'
import CoachSettingsZoom from './CoachSettingsZoom'

interface Props {
  coach: ReduxStateCoach
}

function CoachSettings(props: Props) {
  const { coach } = props
  const { user_id, is_contract_signed, is_zoom_enabled } = coach

  const dispatch = useDispatch<ReduxDispatch>()

  const handleChangeContractSigned = async () => {
    await dispatch(CoachesDispatcher.updateCoachContract(user_id, !is_contract_signed))
  }

  // const handleChangeCertified = async () => {
  //   await dispatch(CoachesDispatcher.updateCertified(user_id, !is_certified))
  // }

  const handleChangeZoomEnabled = async () => {
    await dispatch(CoachesDispatcher.updateZoomEnabled(user_id, !is_zoom_enabled))
  }

  return (
    <PageSection title={'Coach Settings'}>
      <Grid container justifyContent={'space-between'} alignItems={'flex-start'}>
        <Grid direction={'column'}>
          <Grid>
            <FormControlLabel
              value="top"
              control={
                <Switch checked={is_contract_signed} onChange={handleChangeContractSigned} value="Contract signed" />
              }
              label="Contract signed"
            />
          </Grid>
          {/* <Grid> */}
          {/*  <FormControlLabel */}
          {/*    value="top" */}
          {/*    control={<Switch checked={is_certified} onChange={handleChangeCertified} value="Certified" />} */}
          {/*    label="Certified" */}
          {/*  /> */}
          {/* </Grid> */}
          <Grid>
            <FormControlLabel
              value="top"
              control={<Switch checked={is_zoom_enabled} onChange={handleChangeZoomEnabled} value="Zoom enabled" />}
              label="Zoom enabled"
            />
          </Grid>
          <Grid style={{ marginTop: 10 }}>
            <CoachSettingsZoom coach={coach} />
          </Grid>
        </Grid>
      </Grid>
    </PageSection>
  )
}

export default CoachSettings
