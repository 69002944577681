import { Divider, Grid, Link, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import { compose } from 'recompose'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../typings/ReduxExtractor'
import { ExtractStyles } from '../../../typings/stylesExtractor'
import { ReduxState } from '../../reducers'
import AuthDispatcher from '../../reducers/auth/dispatcher'
import LoginContainer from './LoginContainer'
import LocalAuth from './components/LocalAuth'
import SocialAuth from './components/SocialAuth'

const connectStyles = withStyles(
  {
    divider: {
      marginTop: 20,
    },
    logout: {
      cursor: 'pointer',
      marginTop: 50,
    },
  },
  {
    classNamePrefix: 'Login',
  },
)

interface Props extends RouteComponentProps<{}> {}
interface PropsInner extends Props, ExtractConnectType<typeof connectStore>, ExtractStyles<typeof connectStyles> {}

class Login extends React.PureComponent<PropsInner> {
  render() {
    const { classes, auth } = this.props

    if (auth.isAuthorized) {
      return this.renderUserIsAuthorized()
    }

    return (
      <LoginContainer>
        <LocalAuth onLoggedIn={this.handleUserLoggedIn} />

        <Divider className={classes.divider} />

        <SocialAuth onGoogleLogin={this.handleLoginWithGoogle} onFacebookLogin={this.handleLoginWithFacebook} />
      </LoginContainer>
    )
  }

  renderUserIsAuthorized() {
    const { auth, classes } = this.props
    const { role } = auth

    if (role === 'admin') return <Redirect to={'/'} />

    return (
      <LoginContainer>
        <div>
          <Typography variant={'h1'}>Access Denied</Typography>
          <Typography>Reaction Club must approve you, to access this service.</Typography>
        </div>

        <Grid container justifyContent={'center'}>
          <Link onClick={this.handleClickLogout} className={classes.logout}>
            logout
          </Link>
        </Grid>
      </LoginContainer>
    );
  }

  handleUserLoggedIn = () => {
    // ...
    console.log('handleUserLoggedIn is not implemented')
  }

  handleFirebaseLogin = async () => {
    const { loginWithFirebaseToken } = this.props

    try {
      const { currentUser } = getAuth()
      if (!currentUser) {
        return null
      }

      const token = await currentUser.getIdToken()
      if (!token) {
        return null
      }

      await loginWithFirebaseToken(token)

      this.handleUserLoggedIn()
    } catch (e) {
      console.log('ERR [handleFirebaseLogin]:', e)
    }
  }

  handleLoginWithFacebook = async () => {
    await this.logoutFirebaseSafe()

    const provider = new FacebookAuthProvider()

    try {
      const auth = getAuth()

      await signInWithPopup(auth, provider)
      console.log('Facebook handleFirebaseLogin')
      await this.handleFirebaseLogin()
    } catch (e) {
      console.log('ERR [loginWithFacebook]:', e)
    }
  }

  handleLoginWithGoogle = async () => {
    await this.logoutFirebaseSafe()

    const auth = getAuth()
    const provider = new GoogleAuthProvider()
    await signInWithPopup(auth, provider)

    console.log('Google handleFirebaseLogin')
    await this.handleFirebaseLogin()
  }

  handleClickLogout = () => {
    this.props.logout()
  }

  logoutFirebaseSafe = async () => {
    try {
      const auth = getAuth()
      await signOut(auth)
    } catch (e) {
      console.log('ERROR [logoutGoogle]', e)
    }
  }
}

const connectStore = connect(
  (state: ReduxState) => ({
    auth: state.auth,
  }),
  (dispatch: ReduxDispatch) => ({
    loginWithFirebaseToken: (token: string) => dispatch(AuthDispatcher.loginWithFirebaseToken(token)),
    logout: () => dispatch(AuthDispatcher.logout()),
  }),
)

export default compose<PropsInner, Props>(connectStore, connectStyles)(Login)
