import SeamlessImmutable from 'seamless-immutable'

import types from './actionTypes'

const initialState: ReduxStateCoachesPage = {
  name: '',
  list: [],
  offset: 0,
  total_count: 0,
}
export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction) {
  switch (action.type) {
    case types.SET: {
      const coaches: Coach[] = action.payload
      return state.merge({ list: coaches })
    }

    case types.SET_NEXT: {
      const coaches: Coach[] = action.payload

      return state.merge({
        list: state.list.asMutable({ deep: true }).concat(coaches),
      })
    }

    case types.SET_TOTAL_COUNT: {
      return state.merge({ total_count: action.payload })
    }

    case types.DELETE: {
      const coach_id = action.payload

      return state.updateIn(['list'], list => list.filter(coach => coach.user_id !== coach_id))
    }

    case types.SET_NAME: {
      const name: string = action.payload
      return state.merge({ name })
    }

    case types.SET_COORDINATES: {
      const coordinates = action.payload
      return state.merge({ coordinates })
    }

    default:
      return state
  }
}
