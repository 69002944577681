import { EventRecording } from '@reaction-club-types/core'
import types from './types'

const eventActions = {
  setEvent: (event: ReactionEvent) => ({ type: types.SET_EVENT, payload: { event } }),
  setEvents: (events: Event[]) => ({ type: types.SET_EVENTS, payload: { events } }),
  setParticipants: (payload: { event_id: string; participants: EventParticipant[] }) => ({
    type: types.SET_PARTICIPANTS,
    payload,
  }),
  setRecordings: (payload: { event_id: string; recordings: EventRecording[] }) => ({
    type: types.SET_RECORDINGS,
    payload,
  }),
  deleteEvent: (event_id: string) => ({ type: types.DELETE, payload: event_id }),
}

export default eventActions
