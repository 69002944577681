import apiClient from '../../lib/apiClient'

export type ChallengeType = {
  id: string
  name: string
  label: string
  is_active: boolean
  created_at: string
  result_type: string
}

export type ChallengeProvider = {
  id: string
  name: string
  label: string
  is_active: boolean
  created_at: string
}

export enum ChallengeApplicationMode {
  DEV = 'development',
  PROD = 'production',
}

export interface ChallengeItem {
  id: string
  application_id: string
  company_name: string
  company_id: string
  mode: ChallengeApplicationMode
  is_active: boolean
  is_enabled: boolean
}

const apiChallengeApplications = {
  async createChallengeApplication(body): Promise<{ challenge_app_id: string }> {
    const result = await apiClient.post<{ challenge_app_id: string }>(`/challenge-app/create`, body)
    return result.data
  },

  async updateChallengeApplication(id: string, body) {
    const result = await apiClient.put(`/challenge-app/${id}`, body)
    return result.data
  },

  async deleteChallengeApplication(id: string): Promise<void> {
    const result = await apiClient.delete(`/challenge-app/${id}`)
    return result.data
  },

  async setEnabledApplication(id: string, body: { is_enabled: boolean }): Promise<any> {
    const result = await apiClient.patch(`/challenge-app/${id}`, body)
    return result.data
  },

  async setPublishedApplication(id: string, body: { is_published: boolean }): Promise<any> {
    const result = await apiClient.patch(`/challenge-app/${id}`, body)
    return result.data
  },

  async getChallengeTypes(): Promise<ChallengeType[]> {
    const result = await apiClient.get<ChallengeType[]>(`/challenge-types/all`)
    return result.data
  },

  async getChallengeProviders(): Promise<ChallengeProvider[]> {
    const result = await apiClient.get<ChallengeProvider[]>(`/challenge-providers`)
    return result.data
  },

  async getChallengeCompaniesConfig(
    id: string,
    data: { offset: number; name: string },
  ): Promise<{ challenge_application_items: ChallengeItem[]; offset: number }> {
    const result = await apiClient.get<{ challenge_application_items: ChallengeItem[]; offset: number }>(
      `/challenge-app/${id}/companies`,
      { params: data },
    )
    return result.data
  },

  async updateChallengeCompanyConfig(app_id: string, company_id: string, data): Promise<ChallengeItem[]> {
    const result = await apiClient.patch<ChallengeItem[]>(`/challenge-app/${app_id}/companies/${company_id}`, data)
    return result.data
  },

  async updateChallengeCompanyBulk(
    app_id: string,
    data: { is_enabled?: boolean; mode?: ChallengeApplicationMode },
  ): Promise<ChallengeItem[]> {
    const result = await apiClient.put<ChallengeItem[]>(`/challenge-app/${app_id}/companies/bulk`, data)
    return result.data
  },
}

export default apiChallengeApplications
