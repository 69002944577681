/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { Company } from '@reaction-club-types/core'
import React from 'react'
import CompanyLogo from '../../components/company/CompanyLogo'

interface Props {
  company: Company
  onSelect(company: Company): any
}

function CompanyItem(props: Props) {
  const { company, onSelect } = props
  const { name } = company

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    onSelect(company)
  }

  return (
    <Grid
      container
      css={css`
        padding: 0.2rem 1rem;
        cursor: pointer;

        &:hover {
          background-color: #f5f1f1;
        },
      `}
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      onClick={handleClick}
    >
      <Grid container direction={'row'} alignItems={'center'}>
        <CompanyLogo company={company} width={50} height={50} />
        <Typography
          css={css`
            margin-left: 15px;
          `}
        >
          {name}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default CompanyItem
