/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Avatar, Grid, Typography } from '@mui/material'
import { Agent } from '@reaction-club-types/core'
import React from 'react'
import LinkOpenPage from '../../../components/LinkOpenPage'

interface Props {
  agent: Agent
}

function OnboardAgentPreview(props: Props) {
  const { agent } = props
  const { user_id, name, avatar_url } = agent

  return (
    <Grid container alignItems={'center'}>
      <Avatar
        src={avatar_url}
        css={css`
          width: 26px;
          height: 26px;
          margin-right: 5px;
        `}
      />
      <Typography
        textAlign={'left'}
        css={css`
          font-size: 12px;
          max-width: 150px;
          line-height: 16px;
        `}
      >
        {name}
      </Typography>
      <LinkOpenPage
        to={`/agent/${user_id}`}
        css={css`
          font-size: 12px;
          margin-left: 5px;
        `}
      />
    </Grid>
  )
}

export default OnboardAgentPreview
