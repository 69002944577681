/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography, Button } from '@mui/material'
import { ReduxState } from '@reducers/index'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import Loader from '../../components/Loader'
import useModalState from '../../hooks/useModalState'
import ZoomHostsDispatcher from '../../reducers/zoomHosts/dispatcher'
import ZoomHostCreateModal from './modals/ZoomHostCreateModal'
import ZoomHosts from './zoomHosts/ZoomHosts'

function ZoomSettings(props: {}) {
  const modalCreateState = useModalState()

  const dispatch = useDispatch<ReduxDispatch>()
  const actionFetch = useAsyncFetch(async () => {
    await dispatch(ZoomHostsDispatcher.updateHostsList())
  })

  const { hosts } = useSelector((state: ReduxState) => ({
    hosts: state.zoomHosts.list,
    availableHosts: state.zoomHosts.available,
  }))

  return (
    <div
      css={css`
        margin-top: 30px;
        padding: 0 20px;
      `}
    >
      <ZoomHostCreateModal {...modalCreateState} />

      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        css={css`
          margin-bottom: 20px;
        `}
      >
        <Typography variant={'h1'}>Zoom Settings</Typography>

        <Button onClick={modalCreateState.onOpen} variant={'contained'}>
          Create
        </Button>
      </Grid>

      <div>
        <RendererStatusSplit
          statuses={actionFetch}
          renderLoading={() => (
            <Grid container justifyContent={'center'}>
              <Loader />
            </Grid>
          )}
          render={() => (
            <div>
              <ZoomHosts zoomHosts={hosts} />
            </div>
          )}
        />
      </div>
    </div>
  )
}

export default ZoomSettings
