import './polyfill'
import React from 'react'
import { HashRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import AppContext from './AppContext'
import AppLauncher from './AppLauncher'
import AppRouter from './Router'
import { BugsnagErrorBoundary } from './libs/bugSnag'
import { persistor } from './store'

export default function App() {
  return (
    <BugsnagErrorBoundary>
      <AppContext>
        <PersistGate loading={null} persistor={persistor}>
          <HashRouter>
            <AppLauncher>
              <AppRouter />
            </AppLauncher>
          </HashRouter>
        </PersistGate>
      </AppContext>
    </BugsnagErrorBoundary>
  )
}
