/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

interface Props {
  to: string
  target?: LinkProps['target']
  className?: string
}

function LinkOpenPage(props: Props) {
  const { to, className, target } = props

  return (
    <Link
      to={to}
      target={target}
      css={css`
        color: #56579e;
        font-size: 14px;
        text-decoration: none;
        margin-left: 5px;
      `}
      className={className}
    >
      open <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
    </Link>
  )
}

export default LinkOpenPage
