import { useSelector } from 'react-redux'
import moment from 'moment'
import { ReduxState } from '@reducers/index'
import config from '../config'

function useExportCompanies() {
    const { token } = useSelector((state: ReduxState) => state.auth)

    if (!token) return () => null

    return async () => {
        const response = await fetch(`${config.api_url}/web/companies-report`, {
            headers: {
                Authorization: token,
            },
        })

        const blob = await response.blob()
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `companies-report-${moment().format('Do-MMMM-YYYY')}.csv`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }
}

export default useExportCompanies
