import { EventRecording } from '@reaction-club-types/core'
import appTypes from '@reducers/app/types'
import _ from 'lodash'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import types from './types'

interface State {
  [key: string]: ReduxStateReactionEventItem
}

export interface ReduxStateReactionEventItem extends ReactionEventFull {
  participants: EventParticipant[]
  recordings?: EventRecording[]
}

const initialState: State = {}

export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case appTypes.RESET: {
      return SeamlessImmutable(initialState)
    }

    case types.SET_EVENT: {
      const { event } = action.payload
      let stateEvent = state[event.id]
      if (stateEvent) stateEvent = stateEvent.merge(event)

      return state.merge({ [event.id]: stateEvent ? stateEvent.asMutable({ deep: true }) : event })
    }
    case types.SET_EVENTS: {
      const { events } = action.payload
      let newEventsStore = SeamlessImmutable({} as State)
      _.each(events, event => {
        let stateEvent = newEventsStore[event.id]
        if (stateEvent) stateEvent = stateEvent.merge(event)
        newEventsStore = newEventsStore.merge({ [event.id]: stateEvent ? stateEvent.asMutable({ deep: true }) : event })
      })

      return newEventsStore
    }

    case types.SET_PARTICIPANTS: {
      const { event_id, participants }: { event_id: string; participants: EventParticipant[] } = action.payload
      let stateEvent = state[event_id]
      if (!stateEvent) return state

      stateEvent = stateEvent.merge({ participants })

      return state.merge({ [event_id]: stateEvent })
    }

    case types.SET_RECORDINGS: {
      const { event_id, recordings }: { event_id: string; recordings: EventRecording[] } = action.payload
      let stateEvent = state[event_id]
      if (!stateEvent) return state

      stateEvent = stateEvent.merge({ recordings })

      return state.merge({ [event_id]: stateEvent })
    }

    case types.DELETE: {
      const event_id = action.payload

      return state.without(event_id)
    }

    default:
      return state
  }
}
