/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import { ReduxStateCompanyItem } from '@reducers/companies/reducer'
import React, { useState } from 'react'
import CompanyHead from './CompanyHead'
import CompanyPageDetails from './CompanyPageDetails'
import CompanyHRLoginButton from './components/CompanyHRLoginButton'
import CompanyPageActions from './components/CompanyPageActions'

interface Props {
  company: ReduxStateCompanyItem
}

function CompanyInfo(props: Props) {
  const [isEditMode, setEditMode] = useState(false)

  const { company } = props

  return (
    <Grid container justifyContent={'space-between'}>
      <Grid item>
        <CompanyHead company={company} isEditMode={isEditMode} />

        <CompanyPageDetails company={company} />
      </Grid>

      <Grid item>
        <CompanyHRLoginButton company={company} />

        <CompanyPageActions company={company} onEditModeChange={setEditMode} />
      </Grid>
    </Grid>
  )
}

export default CompanyInfo
