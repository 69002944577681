import { PlaybookInstance } from '@reaction-club-types/core'
import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import api from '../../libs/api/apiOld'
import companyDispatcher from '../companies/dispatcher'
import EmployeesDispatcher from '../employees/dispatcher'
import { actions as employeeActions } from '../employees/reducer'
import actions from './actions'
import apiGroup from './api'

class Dispatcher {
  actions = actions

  getEmployees(group_id: string): DispatcherResponse {
    return async (dispatch) => {
      const employees = await apiGroup.getGroupUsers(group_id)

      employees.map((employee) => dispatch(employeeActions.set(employee)))
    }
  }

  create({ name, description, asset, company_id }): DispatcherResponse {
    return async (dispatch) => {
      await api.createInstance({ name, description, ...(asset && { asset_id: asset.id }), company_id })
      await dispatch(companyDispatcher.getInstances(company_id))
    }
  }

  edit(instance: PlaybookInstance, { name, description, asset }): DispatcherResponse {
    return async (dispatch) => {
      await api.updatePlaybookInstance(instance.id, { name, description, ...(asset && { asset_id: asset.id }) })
      await dispatch(companyDispatcher.getInstances(instance.company_id))
    }
  }

  editPlaybook(instanceId, playbookId): DispatcherResponse {
    return async (dispatch) => {
      await api.updateInstancePlaybook(instanceId, playbookId)
    }
  }

  updateEmployeeInstance({
    instance_id,
    employee_id,
    company_id,
  }: {
    instance_id: string
    employee_id: string
    company_id: string
  }): DispatcherResponse {
    return async (dispatch) => {
      await api.updateEmployeeInstance({ instance_id, employee_id })
      dispatch(companyDispatcher.getEmployees(company_id))
      dispatch(EmployeesDispatcher.getGroups({ employee_id }))
    }
  }

  //
  // getCoachesNext(): DispatcherResponse {
  //   return async dispatch => {
  //     const coaches = await api.getCoaches()
  //     dispatch(actions.setCoaches(coaches))
  //   }
  // },
}

const GroupDispatcher = new Dispatcher()
export default GroupDispatcher
