import { faBook } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Group as CoachesIcon,
  Ballot as CompanyIcon,
  ChevronRight as ChevronRightIcon,
  Settings as SettingsIcon,
  ChevronLeft as ChevronLeftIcon,
  Event as EventIcon,
} from '@mui/icons-material'
import Divider from '@mui/material/Divider/Divider'
import Drawer from '@mui/material/Drawer/Drawer'
import IconButton from '@mui/material/IconButton/IconButton'
import List from '@mui/material/List/List'
import withStyles from '@mui/styles/withStyles'
import React from 'react'
import { compose } from 'recompose'
import { ExtractStyles } from '../../../typings/stylesExtractor'
import DrawerMenuItem from './DrawerMenuItem'

const drawerWidth = 240

const connectStyles = withStyles(
  (theme) => ({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
  }),
  {
    withTheme: true,
  },
)

interface Props {
  isOpened: boolean
  onClose(): any
}
interface PropsInner extends Props, ExtractStyles<typeof connectStyles> {}

function AppContainer(props: PropsInner) {
  const { classes, theme, isOpened, onClose } = props

  const handleDrawerClose = () => {
    window.dispatchEvent(new Event('resize'))
    onClose()
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={isOpened}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose} size="large">
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        <DrawerMenuItem to={'/companies'} title={'Companies'} icon={<CompanyIcon />} />
        {/* <DrawerMenuItem */}
        {/*  to={'/playbooks'} */}
        {/*  title={'Playbooks'} */}
        {/*  icon={<FontAwesomeIcon icon={faBook} style={{ fontSize: 17, marginLeft: 2 }} />} */}
        {/* /> */}
        <DrawerMenuItem to={'/employees'} title={'Employees'} icon={<CoachesIcon />} />
        <DrawerMenuItem to={'/coaches'} title={'Coaches'} icon={<CoachesIcon />} />
        <DrawerMenuItem to={'/agents'} title={'Agents'} icon={<CoachesIcon />} />
        <DrawerMenuItem to={'/events'} title={'Events'} icon={<EventIcon />} />
        <DrawerMenuItem to={'/playbook-types'} title={'Playbook types'} icon={<CoachesIcon />} />
        <DrawerMenuItem to={'/zoom-settings'} title={'Zoom settings'} icon={<SettingsIcon />} />
        <DrawerMenuItem to={'/stripe-settings'} title={'Stripe settings'} icon={<SettingsIcon />} />
      </List>
    </Drawer>
  )
}

export default compose<PropsInner, Props>(connectStyles)(AppContainer)
