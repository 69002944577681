/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Avatar, Grid, Typography } from '@mui/material'
import { Agent } from '@reaction-club-types/core'
import React from 'react'
import UserLastSeen from '../../../components/user/UserLastSeen'

interface Props {
  agent: Agent
}

const AgentGeneral = (props: Props) => {
  const { agent } = props
  const { name, avatar_url } = agent

  return (
    <Grid
      container
      alignItems={'center'}
      direction={'column'}
      justifyContent={'center'}
      css={css`
        margin-bottom: 20px;
      `}
    >
      <Grid>
        <Avatar
          css={css`
            width: 100px;
            height: 100px;
          `}
          src={avatar_url}
        />
      </Grid>
      <Typography
        css={css({
          margin: '15px 0px 10px',
        })}
        variant={'h1'}
      >
        {name}
      </Typography>

      <UserLastSeen user={agent} />
    </Grid>
  )
}

export default AgentGeneral
