import apiUsers from './apiUsers'
import { actions } from './reducer'

class Dispatcher {
  getSmsLogs(user_id: string) {
    return async (dispatch) => {
      const list = await apiUsers.getSmsLogs(user_id)
      dispatch(actions.setSmsLogs({ user_id, list }))
    }
  }

  getLocalAccount(user_id: string) {
    return async (dispatch) => {
      const localAccount = await apiUsers.getLocalAccount(user_id)
      dispatch(actions.setLocalAccount({ user_id, localAccount }))
    }
  }
}

const UserDispatcher = new Dispatcher()
export default UserDispatcher
