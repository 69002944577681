/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Box, Grid, Typography } from '@mui/material'
import { Agent } from '@reaction-club-types/core'
import AgentDispatcher from '@reducers/agent/dispatcher'
import { ReduxState } from '@reducers/index'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux'
import { RouteComponentProps, Link } from 'react-router-dom'
import { compose } from 'recompose'
import Button from '@mui/material/Button/Button'
import moment from 'moment/moment'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../typings/ReduxExtractor'
import ScrollView from '../../components/ScrollView'
import AgentListItem from './list/AgentListItem'
import config from '../../config'

interface Props extends RouteComponentProps {
  isChooseMode?: boolean
}

interface PropsInner extends Props, ExtractConnectType<typeof connectStore> {}

interface State {
  isLoading: boolean
  isLoadingNext: boolean
  error: string
}

class AgentsPage extends React.PureComponent<PropsInner, State> {
  scrollRef

  state = {
    isLoading: false,
    error: '',
    isLoadingNext: false,
  }

  exportCSV = async () => {
    const { token } = this.props;

    const response = await fetch(`${config.api_url}/web/agent/xlsx`, {
      headers: {
        Authorization: token,
      },
    })

    const blob = await response.blob()
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `agents-report-${moment().format('Do-MMMM-YYYY')}.csv`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  render() {
    const { classes, isNextBlocked } = this.props
    const { error, isLoading } = this.state

    if (isLoading)
      return (
        <Grid container justifyContent="center">
          <Loader type={'CradleLoader'} color={'#332323'} height={100} width={100} />
        </Grid>
      )

    if (error)
      return (
        <Grid container direction={'column'} alignItems={'center'}>
          <Typography color={'error'} align={'center'}>
            Some error happen
          </Typography>
          <Typography color={'error'}>{error}</Typography>
        </Grid>
      )

    return (
      <div>
        <ScrollView offset={35} ref={(ref) => (this.scrollRef = ref)}>
          <InfiniteScroll
            pageStart={0}
            loadMore={this.handleLoadMore}
            hasMore={!isNextBlocked}
            loader={
              <Grid container justifyContent="center">
                <Loader type={'ThreeDots'} color={'#332323'} height={50} width={50} />
              </Grid>
            }
            getScrollParent={() => this.scrollRef}
            useWindow={false}
          >
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent={'center'}
                  css={css`
                    padding-bottom: 30px;
                  `}
                >
                  <Grid item sm={10}>
                    <Box marginBottom={'10px'}>
                      <Button onClick={this.exportCSV} variant={'contained'} color={'primary'} css={css({ marginRight: 20 })}>
                        Export CSV
                      </Button>
                      <Grid container justifyContent={'flex-end'}>
                        <Link to={'/agents/onboard-list'}>Onboard List</Link>
                      </Grid>
                    </Box>
                    {this.renderList()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </InfiniteScroll>
        </ScrollView>
      </div>
    )
  }

  renderList = () => {
    const { agents } = this.props
    let listFiltered = agents

    // if (name) {
    //   coachesFiltered = _.filter(coaches, coach => (coach.name || '').toLowerCase().includes(name.toLowerCase()))
    // }

    return (
      <Grid container direction={'row'} justifyContent={'space-between'}>
        {listFiltered.map((agent) => (
          <AgentListItem agent={agent} onOpen={this.handleItemOpen} key={`agent-list-item-${agent.user_id}`} />
        ))}
        <Grid item xs={6} sm={4} md={3} />
        <Grid item xs={6} sm={4} md={3} />
        <Grid item xs={6} sm={4} md={3} />
      </Grid>
    )
  }

  handleItemOpen = ({ user_id }: Agent) => {
    const { history } = this.props

    history.push(`/agent/${user_id}`)
  }

  handleLoadMore = async () => {
    if (this.state.isLoading) return null
    if (this.state.isLoadingNext) return null

    this.setState({ isLoadingNext: true })

    try {
      await this.props.getAgentsNext()
    } finally {
      this.setState({ isLoadingNext: false })
    }
  }

  handleFetchCoaches = async () => {
    this.setState({ isLoading: false, error: '' })
    this.props
      .getAgents()
      .catch((error) => this.setState({ error: error.message }))
      .finally(() => this.setState({ isLoading: false }))
  }

  componentDidMount() {
    this.handleFetchCoaches()
  }
}

const connectStore = connect(
  (state: ReduxState) => ({
    token: state.auth.token,
    agents: state.agents.list,
    isNextBlocked: state.agents.listIsBlocked,
  }),
  (dispatch: ReduxDispatch) => ({
    getAgents: () => dispatch(AgentDispatcher.getAgents()),
    getAgentsNext: () => dispatch(AgentDispatcher.getAgentsNext()),
  }),
)
export default compose<PropsInner, Props>(connectStore)(AgentsPage)
