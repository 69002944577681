import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, List, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import CoachesDispatcher from '@reducers/coaches/dispatcher'
import { ReduxStateCoach } from '@reducers/coaches/reducer'
import invariant from 'invariant'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useAsyncFetch, useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useHistory } from 'react-router-dom'
import { ReduxDispatch } from '../../../../../typings/ReduxDispatch'
import Loader from '../../../../components/Loader'
import CompanyLogo from '../../../../components/company/CompanyLogo'

interface Props {
  coach: ReduxStateCoach
}

function CompaniesList(props: Props) {
  const { coach } = props
  const { user_id, companies } = coach
  const dispatch = useDispatch<ReduxDispatch>()

  const filteredCompanies = _.filter(companies, (company) => company.id !== coach.company_id)
  const club = _.find(companies, (company) => company.id === coach.company_id)

  const history = useHistory()
  const snackbar = useSnackbar()

  const fetchCompanies = useAsyncFetch(async () => {
    await dispatch(CoachesDispatcher.getCoachCompanies(user_id))
  }, [user_id])

  const actionDelete = useAsyncHandler(async (company_id: string) => {
    invariant(company_id, 'company_id is invalid')
    await dispatch(CoachesDispatcher.unAssignCoachFromCompany(user_id, company_id))
  })

  const handleDelete = async (company_id: string) => {
    if (!confirm('Are you sure want to remove coach from company?')) return null
    await actionDelete.execute(company_id)
    snackbar.enqueueSnackbar('Coach is deleted from company')
  }

  return (
    <RendererStatusSplit
      statuses={fetchCompanies}
      isEmpty={_.isEmpty(filteredCompanies)}
      renderLoading={() => <Loader />}
      renderEmpty={() => <Typography>Coach does not have a company</Typography>}
      render={() => (
        <List component="nav" aria-label="secondary mailbox folders" style={{ maxHeight: 400, overflow: 'auto' }}>
          <ListItem component="a" onClick={() => history.push(`/company/${coach.company_id}`)}>
            <ListItemIcon style={{ marginRight: 10 }}>
              {club && <CompanyLogo company={club} width={34} height={34} />}
            </ListItemIcon>
            <ListItemText primary={'Coach Club'} />
          </ListItem>

          {_.map(filteredCompanies, (company) => (
            <ListItem
              key={`coach-company-${company.id}`}
              component="a"
              onClick={() => history.push(`/company/${company.id}`)}
            >
              <ListItemIcon style={{ marginRight: 10 }}>
                <CompanyLogo company={company} width={34} height={34} />
              </ListItemIcon>
              <ListItemText primary={company.name} />
              <ListItemSecondaryAction>
                <IconButton onClick={() => handleDelete(company.id)} edge="end" aria-label="delete" size="large">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    />
  )
}

export default CompaniesList
