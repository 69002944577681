import { IPlan } from '@reducers/companies/types'
import types from './types'

export default {
  set: (companies: Company[] | Company): ReduxAction => ({ type: types.SET, payload: companies }),
  setEmployees: (company_id: string, employees: Employee[]): ReduxAction => ({
    type: types.SET_EMPLOYEES,
    payload: { company_id, employees },
  }),
  setCoaches: (company_id: string, coaches: Coach[]): ReduxAction => ({
    type: types.SET_COACHES,
    payload: { company_id, coaches },
  }),
  setStickers: (company_id: string, stickers: CompanyStickers[]): ReduxAction => ({
    type: types.SET_STICKERS,
    payload: { company_id, stickers },
  }),
  setInstances: (company_id: string, instances: PlaybookInstance[]): ReduxAction => ({
    type: types.SET_INSTANCES,
    payload: { company_id, instances },
  }),
  setAgents: (company_id: string, agents: Agent[]): ReduxAction => ({
    type: types.SET_AGENTS,
    payload: { company_id, agents },
  }),
  setPlan: (company_id: string, plan: IPlan): ReduxAction => ({
    type: types.SET_PLAN,
    payload: { company_id, plan },
  }),

  setInstanceCoaches: ({
    company_id,
    instance_id,
    coaches,
  }: {
    company_id: string
    instance_id: string
    coaches: Coach[]
  }): ReduxAction => ({
    type: types.SET_INSTANCE_COACHES,
    payload: { company_id, instance_id, coaches },
  }),

  resetPoints: (company_id: string): ReduxAction => ({
    type: types.RESET_POINTS,
    payload: { company_id },
  }),

  deleteCompany: (company_id: string): ReduxAction => ({
    type: types.DELETE,
    payload: { company_id },
  }),
}
