/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Avatar, Box, Grid, Typography } from '@mui/material'
import EmployeeDispatcher from '@reducers/employees/dispatcher'
import { ReduxStateEmployeesItem } from '@reducers/employees/reducer'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../../components/Loader'

interface Props {
  employee: ReduxStateEmployeesItem
}
function EmployeeGroups(props: Props) {
  const { employee } = props
  const { groups } = employee
  const dispatch = useDispatch()

  const groupsFetch = useAsyncFetch(async () => {
    await dispatch(EmployeeDispatcher.getGroups({ employee_id: employee.user_id }))
  })

  return (
    <div>
      <Box marginBottom={'10px'}>
        <Typography variant={'h2'}>Groups</Typography>
      </Box>
      <div>
        <RendererStatusSplit
          statuses={groupsFetch}
          isEmpty={_.isEmpty(groups)}
          renderLoading={() => <Loader />}
          renderEmpty={() => (
            <div>
              <span style={{ fontSize: 30 }}>-</span>
            </div>
          )}
          render={() => (
            <div>
              {_.map(groups, (item) => (
                <Grid
                  container
                  alignItems={'center'}
                  key={`employee-company-group-${item.id}`}
                  sx={{ marginBottom: '5px' }}
                >
                  <Avatar src={item.thumb} />
                  <span
                    css={css`
                      margin-left: 10px;
                      font-size: 16px;
                      font-weight: bold;
                    `}
                  >
                    {item.name}
                  </span>
                </Grid>
              ))}
            </div>
          )}
        />
      </div>
    </div>
  )
}

export default EmployeeGroups
