/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Paper, Typography } from '@mui/material'
import { ReduxState } from '@reducers/index'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useParams } from 'react-router-dom'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import Loader from '../../components/Loader'
import ScrollView from '../../components/ScrollView'
import CompanyDispatcher from '../../reducers/companies/dispatcher'
import CompanyAgents from './company/CompanyAgents'
import CompanyCoaches from './company/CompanyCoaches'
import CompanyConfig from './company/CompanyConfig'
import CompanyEmployees from './company/CompanyEmployees'
import CompanyInfo from './company/CompanyInfo'
import CompanyInstances from './company/CompanyInstances'
import CompanyPayment from './company/CompanyPayment'
import CompanyPreferences from './company/CompanyPreferences'
import CompanyStickers from './company/CompanyStickers'

interface Props {}

function CompanyPage(props: Props) {
  const params = useParams<{ id: string }>()
  const company_id = params.id

  const { company } = useSelector((state: ReduxState) => ({
    company: state.companies[company_id],
  }))

  const dispatch = useDispatch<ReduxDispatch>()
  const fetchData = useAsyncFetch(async () => {
    await dispatch(CompanyDispatcher.getCompany(company_id))
  }, [company_id])

  return (
    <ScrollView css={css({ paddingTop: 30, paddingBottom: 20 })}>
      <Grid container justifyContent={'center'}>
        <Grid lg={10} item container justifyContent={'center'}>
          <Paper css={css({ padding: '15px 60px 15px 20px', width: '100%', position: 'relative' })}>
            <RendererStatusSplit
              statuses={fetchData}
              isEmpty={!company}
              renderLoading={() => (
                <Grid
                  container
                  justifyContent={'center'}
                  css={css`
                    padding-top: 20px;
                  `}
                >
                  <Loader />
                </Grid>
              )}
              renderEmpty={() => (
                <Grid container justifyContent={'center'}>
                  <Typography>Company is not found</Typography>
                </Grid>
              )}
              render={() => <CompanyInfo company={company} />}
            />
          </Paper>

          {company && (
            <Grid container direction={'column'}>
              <CompanyPreferences company={company} />

              <CompanyConfig company={company} />

              <CompanyPayment company={company} />

              <CompanyAgents company={company} />

              <CompanyCoaches company_id={company.id} />

              <CompanyEmployees company_id={company.id} />

              <CompanyInstances company_id={company.id} />

              <CompanyStickers company_id={company.id} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </ScrollView>
  )
}

export default CompanyPage
