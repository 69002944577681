import { Grid, Switch, Typography } from '@mui/material'
import { ChallengeApplicationMode, ChallengeItem } from '@reducers/challengeAppplications/api'
import ChallengeApplicationDispatcher from '@reducers/challengeAppplications/dispatcher'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'

interface Props {
  challengeItem: ChallengeItem
}

function CompanyConfigItem(props: Props) {
  const { challengeItem } = props
  const dispatch = useDispatch<ReduxDispatch>()
  const [testMode, setTestMode] = useState(challengeItem.mode === ChallengeApplicationMode.DEV)
  const [isEnabled, setEnabled] = useState(challengeItem.is_enabled)

  useEffect(() => {
    setEnabled(challengeItem.is_enabled)
  }, [challengeItem.is_enabled])

  useEffect(() => {
    setTestMode(challengeItem.mode === ChallengeApplicationMode.DEV)
  }, [challengeItem.mode])

  const updateChallengeData = async (data) => {
    await dispatch(
      ChallengeApplicationDispatcher.updateChallengeItem(challengeItem.application_id, challengeItem.company_id, data),
    )
  }

  const handleSetMode = async (state: boolean) => {
    const prevState = testMode
    try {
      setTestMode(state)
      await updateChallengeData({ mode: state ? ChallengeApplicationMode.DEV : ChallengeApplicationMode.PROD })
    } catch (e) {
      setTestMode(prevState)
    }
  }

  const handleSetEnabled = async (state: boolean) => {
    const prevState = isEnabled
    try {
      setEnabled(state)
      await updateChallengeData({ is_enabled: state })
    } catch (e) {
      setEnabled(prevState)
    }
  }

  return (
    <Grid container alignItems={'center'}>
      <Grid item xs={6}>
        <Typography>{challengeItem.company_name}</Typography>
      </Grid>
      <Grid item xs={4} container flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'}>
        <Typography>Test Mode: </Typography>
        <Switch checked={testMode} onChange={() => handleSetMode(!testMode)} />
      </Grid>
      <Grid item xs={2} container flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'}>
        <Typography>Enabled: </Typography>
        <Switch checked={isEnabled} onChange={() => handleSetEnabled(!isEnabled)} />
      </Grid>
    </Grid>
  )
}

export default CompanyConfigItem
