import { Autocomplete } from '@mui/material'
import TextField from '@mui/material/TextField'
import React, { useMemo } from 'react'

interface Props {
  disabled?: boolean
  value: { label: string; id: string }
  onChange: ({ label, id }: { label: string; id: string }) => void
}

const RESULT_TYPES = {
  INT: 'integer',
  TIME: 'time',
}

function ChallengeResultTypeSelect(props: Props) {
  const { disabled = false, value, onChange } = props

  const resultTypes = useMemo(
    () => Object.values(RESULT_TYPES).map((i) => ({ label: i, id: i, name: i })),
    [RESULT_TYPES],
  )
  const currentValue = useMemo(() => resultTypes.find((i) => i.name === value.label) || null, [value])

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const label = e?.target.value
    const typeObject = resultTypes?.find((i) => i.name === label)
    if (typeObject) {
      onChange({ label, id: typeObject?.id })
    }
  }

  return (
    <Autocomplete
      disablePortal
      options={resultTypes}
      value={currentValue}
      fullWidth
      onSelect={handleSelect}
      getOptionLabel={(i) => i.name}
      disabled={disabled}
      sx={{ maxWidth: 300 }}
      renderInput={(params) => (
        <TextField {...params} required label="Challenge Result Type" variant="outlined" margin="dense" />
      )}
    />
  )
}

export default ChallengeResultTypeSelect
