import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

const useStyles = makeStyles(
  {
    text: {
      marginLeft: 5,
      marginRight: 5,
    },
    red: {
      color: '#aa373f',
    },
    green: {
      color: '#3eaa18',
    },
  },
  {
    classNamePrefix: 'StatusBoolean',
  },
)

interface Props {
  isTrue?: boolean
  onlyDefined?: boolean
  onlyTrue?: boolean
}

function StatusBoolean(props: Props) {
  const { isTrue, onlyDefined, onlyTrue } = props
  const classes = useStyles()

  let isEmpty = onlyDefined && !_.isBoolean(isTrue)
  if (onlyTrue) isEmpty = !isTrue

  if (isEmpty) {
    return <span className={classNames([classes.text])}>-</span>
  }

  if (isTrue) return <span className={classNames([classes.text, classes.green])}>yes</span>
  return <span className={classNames([classes.text, classes.red])}>no</span>
}

export default StatusBoolean
