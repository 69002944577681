import { Grid } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles({
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    marginRight: 10,
    marginBottom: 5,
  },
})

interface Props {
  value: ReactionEvent['workout_type']
  onChange(type: ReactionEvent['workout_type']): any
}

export default function EventTypeSwitch(props: Props) {
  const { value, onChange } = props
  const classes = useStyles()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type: any = (event.target as HTMLInputElement).value
    onChange(type)
  }

  return (
    <FormControl component="fieldset">
      <Grid container alignItems={'center'}>
        <span className={classes.title}>Type:</span>
        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
          <Grid container>
            <FormControlLabel value="on_site" control={<Radio />} label="On Site" />
            <FormControlLabel value="live_streaming" control={<Radio />} label="Online Event" />
          </Grid>
        </RadioGroup>
      </Grid>
    </FormControl>
  )
}
