import types from './actionTypes'

export default {
  // @deprecated
  set: (playbooks: Playbook[]) => ({ type: types.SET, payload: playbooks }),
  setPlaybook: (playbook: Playbook): ReduxAction => ({ type: types.SET_PLAYBOOK, payload: playbook }),
  // setPlaybook: (playbook_id: string, coaches): ReduxAction => ({ type: types.SET_PLAYBOOK, payload: playbook }),
  setPlaybookDetail: playbook => ({ type: types.SET_PLAYBOOK_DETAIL, payload: playbook }),
  setPlaybookPrograms: (playbook_id: string, programs: Program[]): ReduxAction => ({
    type: types.SET_PLAYBOOK_PROGRAMS,
    payload: { playbook_id, programs },
  }),
  setProgram: (program: Program): ReduxAction => ({
    type: types.SET_PROGRAM,
    payload: program,
  }),
  deleteProgram: (playbook_id: string, program_id: string): ReduxAction => ({
    type: types.DELETE_PROGRAM,
    payload: { program_id, playbook_id },
  }),
  deletePlaybook: (playbook_id: string): ReduxAction => ({
    type: types.DELETE_PLAYBOOK,
    payload: playbook_id,
  }),

  setPrivateCompanies: companies => ({ type: types.SET_PRIVATE_COMPANIES, payload: companies }),
  setPrivateCoaches: (payload: { playbook_id: string; coaches: string[] }) => ({
    type: types.SET_PRIVATE_COACHES,
    payload,
  }),
}
