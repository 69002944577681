/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Grid } from '@mui/material'
import CompanyDispatcher from '@reducers/companies/dispatcher'
import { ReduxStateEmployeesItem } from '@reducers/employees/reducer'
import { ReduxState } from '@reducers/index'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import LinkOpenPage from '../../../components/LinkOpenPage'
import Loader from '../../../components/Loader'
import CompanyLogo from '../../../components/company/CompanyLogo'
import PageSection from '../../../layout/PageSection'
import EmployeeGroups from './EmployeeGroups'

interface Props {
  employee: ReduxStateEmployeesItem
}
function EmployeeCompany(props: Props) {
  const { employee } = props
  const { company_id } = employee
  const dispatch = useDispatch()

  const { company } = useSelector((state: ReduxState) => ({ company: state.companies[company_id as string] }))

  const companyFetch = useAsyncFetch(async () => {
    if (!company_id) return null

    await dispatch(CompanyDispatcher.getCompany(company_id))
  }, [company_id])

  return (
    <PageSection title={'Company'}>
      <div
        css={css`
          padding: 10px;
          min-height: 240px;
        `}
      >
        <RendererStatusSplit
          statuses={companyFetch}
          isEmpty={!company}
          renderLoading={() => (
            <Grid container justifyContent={'center'}>
              <Loader />
            </Grid>
          )}
          renderEmpty={() => (
            <div>
              <span style={{ fontSize: 30 }}>-</span>
            </div>
          )}
          render={() => (
            <Grid container>
              <Grid
                item
                css={css`
                  border-right: 1px solid #dfdfdf;
                  padding-right: 20px;
                  margin-right: 20px;
                `}
              >
                <Grid container alignItems={'center'}>
                  <CompanyLogo company={company} width={50} height={50} />
                  <span
                    css={css`
                      margin-left: 10px;
                      font-size: 16px;
                      font-weight: bold;
                    `}
                  >
                    {company.name}
                  </span>
                  <LinkOpenPage to={`/company/${company.id}`} />
                </Grid>
              </Grid>

              <Grid item>
                <EmployeeGroups employee={employee} />
              </Grid>
            </Grid>
          )}
        />
      </div>
    </PageSection>
  )
}

export default EmployeeCompany
