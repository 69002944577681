import { ReduxStateCoach } from '@reducers/coaches/reducer'
import React from 'react'
import PageSection from '../../../../layout/PageSection'
import CompaniesList from './CompaniesList'

interface Props {
  coach: ReduxStateCoach
}

function CoachCompanies(props: Props) {
  const { coach } = props

  return (
    <PageSection title={'Coach companies'}>
      <CompaniesList coach={coach} />
    </PageSection>
  )
}

export default CoachCompanies
