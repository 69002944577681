import React, { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import useReferredProp from './useReferredProp'

interface Props {
  children: ReactElement | ReactElement[]
  threshold?: number
  className?: string
  isNextExists?: boolean
  isLoading?: boolean
  getNext(): any
  getContainerRef?(): HTMLDivElement | undefined
  renderLoading?(): ReactElement
}

function ScrollElement(props: Props) {
  const {
    children,
    getContainerRef,
    getNext,
    threshold = 250,
    className,
    isNextExists = true,
    isLoading,
    renderLoading,
  } = props

  const divRef = useRef<HTMLDivElement>()
  // const getNextRef = useReferredProp(getNext) // fix `Stale closure` issue
  const propsRef = useReferredProp(props) // fix `Stale closure` issue
  const containerEl = useMemo((): HTMLDivElement | undefined => {
    if (getContainerRef) return getContainerRef()
    return divRef.current
  }, [divRef, getContainerRef])

  const fetchNextAction = useAsyncHandler(async function getNextWrapperFn() {
    await getNext()
    await new Promise((r) => setTimeout(r, 100)) // small simple debounce
  })

  const emitFetchNext = () => {
    if (!isNextExists) return null
    if (isLoading) return null // isLoading might be used to handle initial fetch
    // ref is more reliable
    // state is also works almost same, but we can ignore warnings this way
    if (fetchNextAction.indicators.stateRef.current.isLoading) return null

    fetchNextAction.execute()
  }

  const emitRef = useReferredProp(emitFetchNext)

  const listenerScroll = useCallback((e) => {
    const el = e.target
    const { scrollTop, clientHeight, scrollHeight } = el

    const pxToEnd = scrollHeight - scrollTop - clientHeight
    const shouldBeEmitted = pxToEnd < threshold
    // console.log('listenerScroll', el, offsetTop, clientHeight, offsetHeight, scrollTop, pxToEnd, shouldBeEmitted)

    if (shouldBeEmitted) {
      emitRef.current()
    }
  }, [])

  const listenerResize = useCallback((e) => {
    console.warn('listenerResize [not handled]', e)
  }, [])

  useEffect(() => {
    if (!containerEl) return

    containerEl.addEventListener('scroll', listenerScroll)
    containerEl.addEventListener('resize', listenerResize)

    return () => {
      containerEl.removeEventListener('scroll', listenerScroll)
      containerEl.removeEventListener('resize', listenerResize)
    }
  }, [containerEl])

  const isLoaderVisible = (isLoading || fetchNextAction.isLoading) && renderLoading

  return (
    <div ref={divRef as any} className={className}>
      {children}

      {isLoaderVisible ? renderLoading() : null}
    </div>
  )
}

export default ScrollElement
