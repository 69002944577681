import { Box, Stack } from '@mui/material'
import TextField from '@mui/material/TextField'
import CompanyDispatcher from '@reducers/companies/dispatcher'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import ButtonOrange from '../../../../components/ButtonOrange'

type Props = {
  defaultValues: {
    subscriptionId: string
  }
  companyId: string
  onCancel: () => void
}

function CompanySubscriptionForm(props: Props) {
  const { defaultValues, onCancel, companyId } = props
  const dispatch = useDispatch()

  const { register, handleSubmit, reset } = useForm({
    defaultValues,
  })

  const onSubmit = (data) => {
    dispatch(CompanyDispatcher.changeCompanySubscriptionPlan(companyId, data))
  }

  return (
    <Box mt={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" alignItems="center" gap={2}>
          <TextField
            {...register('subscriptionId')}
            label="Subscription Id"
            variant="outlined"
            style={{ width: 320 }}
          />
          <ButtonOrange onClick={handleSubmit(onSubmit)} size="large">
            Update
          </ButtonOrange>
          <ButtonOrange color="#b8b8b8" onClick={onCancel}>
            Cancel
          </ButtonOrange>
        </Stack>
      </form>
    </Box>
  )
}

export default CompanySubscriptionForm
