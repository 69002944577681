import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { ReactNode } from 'react'
import Loader from './Loader'

const useStyles = makeStyles(
  {
    button: {
      backgroundColor: '#b8b8b8',
      borderRadius: 50,
      fontWeight: 'bold',
      fontSize: 12,
      lineHeight: '22px',
      padding: '6px 20px',
      textTransform: 'initial',
      minWidth: 90,
    },
    small: {
      width: 45,
      fontSize: 10,
      padding: '3px 5px',
    },
    large: {
      width: 120,
      fontSize: 14,
    },
    disabled: {
      cursor: 'pointer',
      opacity: '0.7',
    },
  },
  {
    classNamePrefix: 'ButtonOrange',
  },
)

interface Props {
  children: ReactNode
  color?: string
  size?: 'small' | 'mid' | 'large'
  onClick: () => void
  disabled?: boolean
  styles?: any
  className?: string
  isLoading?: boolean
}

function ButtonOrange(props: Props) {
  const { children, color, onClick, size, disabled = false, styles: customStyles, className = '', isLoading } = props

  const classes = useStyles()

  if (isLoading) return <Loader />

  const buttonStyle = {
    backgroundColor: color,
    ...customStyles,
  }

  const buttonStyles = [classes.button, className]

  if (size === 'small') buttonStyles.push(classes.small)
  if (size === 'large') buttonStyles.push(classes.large)
  if (disabled) buttonStyles.push(classes.disabled)

  return (
    <Button onClick={disabled ? () => ({}) : onClick} className={buttonStyles.join(' ')} style={buttonStyle}>
      {children}
    </Button>
  )
}

ButtonOrange.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
}

ButtonOrange.defaultProps = {
  color: '#F9B22D',
}

export default ButtonOrange
