import { Autocomplete } from '@mui/material'
import TextField from '@mui/material/TextField'
import { ChallengeType } from '@reducers/challengeAppplications/api'
import ChallengeApplicationDispatcher from '@reducers/challengeAppplications/dispatcher'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'

const options = [{
  value: 'total',
  label: 'Total'
}, {
  value: 'daily_avg',
  label: 'Daily average'
}]

interface Props {
  disabled: boolean
  value: string
  onChange: (value: string) => void
}
function ChallengeTypeSelect(props: Props) {
  const { value, onChange, disabled } = props

  const currentValue = useMemo(
    () => options.find((i) => i.value === value) || null,
    [value, options],
  )

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const label = e?.target.value
    const typeObject = options.find((i) => i.label === label)
    if (typeObject) {
      onChange(typeObject.value)
    }
  }

  return (
    <Autocomplete
      disablePortal
      disabled={disabled}
      options={options}
      value={currentValue}
      onSelect={handleSelect}
      disableClearable={true}
      getOptionLabel={(i) => i.label}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} required label="User Leaderboard Display Type" variant="outlined" margin="dense" />
      )}
    />
  )
}

export default ChallengeTypeSelect
