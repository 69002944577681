/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { ReduxState } from '@reducers/index'
import UserDispatcher from '@reducers/users/dispatcher'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../../components/Loader'
import UserSMSList from './sms/UserSMSList'

interface Props {
  user_id: string
}
function UserSmsLogs(props: Props) {
  const { user_id } = props
  const dispatch = useDispatch()

  const { user } = useSelector((state: ReduxState) => ({
    user: state.users[user_id],
  }))

  const dataFetch = useAsyncFetch(async () => {
    await dispatch(UserDispatcher.getSmsLogs(user_id))
  })

  const { sms_logs = [] } = user || {}

  return (
    <div
      css={css`
        min-height: 180px;
      `}
    >
      <RendererStatusSplit
        statuses={dataFetch}
        isEmpty={_.isEmpty(sms_logs)}
        renderError={(error) => <Typography color={'error'}>{error}</Typography>}
        renderLoading={() => (
          <Grid container justifyContent={'center'}>
            <Loader />
          </Grid>
        )}
        renderEmpty={() => (
          <div>
            <span style={{ fontSize: 30 }}>-</span>
          </div>
        )}
        render={() => <UserSMSList list={sms_logs} />}
      />
    </div>
  )
}

export default UserSmsLogs
