import { prefix_web } from './apiOld'

export default {
  async getInstanceCoaches(instance_id: string): Promise<Coach[]> {
    const result = await this.apiClient.get(`${prefix_web}playbook-instance/${instance_id}/coaches`)
    return result.data
  },
  async updateInstancePlaybook(instanceId: string, playbookId: string): Promise<Coach[]> {
    const result = await this.apiClient.post(`${prefix_web}playbook-instance/${instanceId}/playbook`, {
      playbook_id: playbookId,
    })
    return result.data
  },
  async makeCoachPrimary({ instance_id, coach_id }: { instance_id: string; coach_id: string }): Promise<Coach[]> {
    const result = await this.apiClient.post(`${prefix_web}playbook-instance/${instance_id}/coach-primary`, {
      coach_id,
    })
    return result.data
  },
  async addCoachToGroup({ instance_id, coach_id }: { instance_id: string; coach_id: string }): Promise<Coach[]> {
    const result = await this.apiClient.post(`${prefix_web}playbook-instance/${instance_id}/coach`, {
      coach_id,
    })
    return result.data
  },
  async createInstance(payload): Promise<PlaybookInstance> {
    const result = await this.apiClient.post(`${prefix_web}playbook-instance`, payload)
    return result.data
  },
  async updatePlaybookInstance(instance_id: string, payload): Promise<PlaybookInstance> {
    const result = await this.apiClient.post(`${prefix_web}playbook-instance/${instance_id}`, payload)
    return result.data
  },
}
