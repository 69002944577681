/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Typography } from '@mui/material'
import { ReduxState } from '@reducers/index'
import UserDispatcher from '@reducers/users/dispatcher'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../../components/Loader'

interface Props {
  user_id: string
}
function UserLocalAccount(props: Props) {
  const { user_id } = props
  const dispatch = useDispatch()

  const { localAccount } = useSelector((state: ReduxState) => ({
    localAccount: state.users[user_id]?.localAccount,
  }))

  const dataFetch = useAsyncFetch(async () => {
    await dispatch(UserDispatcher.getLocalAccount(user_id))
  })

  const { email, is_verified } = localAccount || {}

  return (
    <div
      css={css`
        margin-bottom: 20px;
      `}
    >
      <Typography
        variant={'h3'}
        css={css`
          font-size: 16px;
          margin: 10px 0 5px;
          font-weight: bold;
        `}
      >
        Local Account
      </Typography>

      <RendererStatusSplit
        statuses={dataFetch}
        isEmpty={_.isEmpty(localAccount)}
        renderError={(error) => <Typography color={'error'}>{error}</Typography>}
        renderLoading={() => (
          <Grid container justifyContent={'center'}>
            <Loader />
          </Grid>
        )}
        renderEmpty={() => (
          <div>
            <span style={{ fontSize: 30 }}>-</span>
          </div>
        )}
        render={() => (
          <Grid container alignItems={'center'}>
            <Typography fontWeight={'bold'}>Email: </Typography>
            <Typography
              css={css`
                margin-left: 7px;
                margin-right: 15px;
              `}
            >
              {email}
            </Typography>

            {is_verified ? (
              <FontAwesomeIcon
                title={'verified'}
                icon={faCheckCircle}
                css={css`
                  color: #58df16;
                `}
              />
            ) : null}
          </Grid>
        )}
      />
    </div>
  )
}

export default UserLocalAccount
