import _ from 'lodash'
import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode
  style?: StyleSheet
  className?: string
  offset?: number
  initialHeight?: number
}

const ScrollView = React.forwardRef((props: Props, ref) => {
  const { children, style = {}, className, offset = 0, initialHeight = 100 } = props

  const styles: React.CSSProperties = {
    overflowY: 'auto',
    maxHeight: `calc(${initialHeight}vh - 64px)`,
    ...style,
  }

  if (offset) {
  //   styles.marginTop = -offset
    styles.paddingTop = offset
  }

  return (
    <div className={className} style={styles} ref={ref}>
      {children}
    </div>
  )
})

export default ScrollView
