/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import AuthDispatcher from '@reducers/auth/dispatcher'
import { ReduxState } from '@reducers/index'
import { AxiosError } from 'axios'
import React from 'react'
import { connect } from 'react-redux'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../../typings/ReduxExtractor'
import { ExtractStyles } from '../../../../typings/stylesExtractor'
import ButtonOrange from '../../tasks/components/ButtonOrange'

interface Props {
  onLoggedIn(): any
}
interface PropsInner extends Props, ExtractConnectType<typeof connectStore>, ExtractStyles<typeof connectStyles> {}

interface State {
  email: string
  password: string
  isLoading?: boolean
  errorMessage?: string
}

class LocalAuth extends React.PureComponent<PropsInner, State> {
  state: State = {
    email: '',
    password: '',
  }

  render() {
    const { email, password, isLoading, errorMessage } = this.state

    const isLoginDisabled = !email || !password

    return (
      <div>
        <div>
          <TextField
            label="Email"
            name={'email'}
            variant="outlined"
            onChange={this.handleChangeEmail}
            margin={'normal'}
            value={email}
            fullWidth
          />
        </div>
        <div>
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            name={'password'}
            type="password"
            autoComplete="current-password"
            margin={'normal'}
            onChange={this.handleChangePassword}
            value={password}
          />
        </div>

        <Grid item xs={12}>
          <Typography
            color={'error'}
            css={css`
              display: block;
              line-height: 20px;
              height: 20px;
              margin-bottom: 5px;
            `}
          >
            {errorMessage}
          </Typography>

          <Grid
            container
            justifyContent={'center'}
            css={css`
              margin-bottom: 20px;
            `}
          >
            <ButtonOrange disabled={isLoginDisabled} onClick={this.handleClickLogin}>
              Login
            </ButtonOrange>
          </Grid>
        </Grid>
      </div>
    )
  }

  handleClickLogin = async () => {
    const { email, password, isLoading } = this.state
    const isValid = email && password
    if (isLoading) return null
    if (!isValid) return null

    this.setState({ isLoading: true, errorMessage: '' })

    try {
      await this.props.login({
        username: email,
        password,
      })
      this.props.onLoggedIn()
    } catch (e) {
      console.error(e)
      this.handleError(e)
    }

    this.setState({ isLoading: false })
  }

  handleError = (err: AxiosError) => {
    const { response } = err
    const status = response?.status
    let errorMessage = err.message

    if (status === 400) {
      errorMessage = 'Invalid credentials'
    }

    this.setState({ errorMessage })
  }

  handleChangeEmail: TextFieldProps['onChange'] = (e) => {
    this.setState({ email: e.target.value || '' })
  }

  handleChangePassword: TextFieldProps['onChange'] = (e) => {
    this.setState({ password: e.target.value || '' })
  }
}

const connectStore = connect(
  (state: ReduxState) => ({}),
  (dispatch: ReduxDispatch) => ({
    login: (params: { username: string; password: string }) => dispatch(AuthDispatcher.login(params)),
  }),
)

export default connectStore(LocalAuth)
