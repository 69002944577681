/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import { Employee } from '@reaction-club-types/core'
import EmployeeDispatcher from '@reducers/employees/dispatcher'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { ReduxDispatch } from '../../../../../typings/ReduxDispatch'
import Loader from '../../../../components/Loader'

interface Props {
  employee: Employee
  isVisible: boolean
  onClose: () => any
}
export default function ChangePointsModal(props: Props) {
  const { isVisible, onClose, employee } = props
  const form = useForm<{ points: string }>()
  const { register, handleSubmit } = form
  const { enqueueSnackbar } = useSnackbar()

  const dispatch = useDispatch<ReduxDispatch>()
  const changePointsActions = useAsyncHandler(async ({ points }: { points: string | number }) => {
    points = parseInt(points as string) || 0
    if (!points) return null
    await dispatch(EmployeeDispatcher.changePoints({ employee_id: employee.user_id, points }))

    enqueueSnackbar('Points are changed!', { variant: 'success' })
    onClose()
    form.reset({ points: '' })
  })

  return (
    <Dialog open={isVisible} onClose={onClose}>
      <DialogTitle>Change Points</DialogTitle>
      <DialogContent>
        <DialogContentText color={'black'}>
          Please select number of points you want to add or remove for employee.
          <br />
          Examples:
          <div>5 - will give 5 points to employee</div>
          <div>-5 - will remove 5 points from employee</div>
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Points"
          type="number"
          fullWidth
          variant="standard"
          {...register('points')}
        />
      </DialogContent>
      <DialogActions>
        <RendererStatusSplit
          statuses={changePointsActions}
          renderLoading={() => (
            <Loader
              css={css`
                margin-right: 10px;
              `}
            />
          )}
          render={() => (
            <Button
              onClick={handleSubmit(changePointsActions.execute)}
              css={css`
                font-weight: bold;
                color: #e82323;
              `}
            >
              Change
            </Button>
          )}
        />

        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
