import CompanyDispatcher from '@reducers/companies/dispatcher'
import _ from 'lodash'
import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import actions from './actions'
import apiAgents from './api'

class Dispatcher {
  actions = actions

  getAgents(): DispatcherResponse {
    return async dispatch => {
      const agents = await apiAgents.getAgentList({ offset: 0 })
      dispatch(actions.set(agents))
      dispatch(actions.setList(agents))
    }
  }

  getAgentsNext(): DispatcherResponse {
    return async (dispatch, getState) => {
      const { list, listIsBlocked } = getState().agents
      if (listIsBlocked) return null

      const agents = await apiAgents.getAgentList({ offset: list.length })
      dispatch(actions.set(agents))
      dispatch(actions.setListNext(agents))
    }
  }

  getAgent(agent_id: string): DispatcherResponse {
    return async dispatch => {
      const agent = await apiAgents.getAgent(agent_id)
      dispatch(actions.set(agent))
      return agent
    }
  }

  getAgentCompany(agent_id: string): DispatcherResponse {
    return async (dispatch, getState) => {
      const { company_id } = getState().agent[agent_id] || {}
      if (!company_id) return null

      const company = await dispatch(CompanyDispatcher.getCompany(company_id))
      dispatch(actions.setCompany({ agent_id, company }))
    }
  }

  deleteAgent(agent_id: string): DispatcherResponse {
    return async dispatch => {
      await apiAgents.deleteAgent(agent_id)
      dispatch(actions.remove(agent_id))
    }
  }
}

const AgentDispatcher = new Dispatcher()
export default AgentDispatcher
