import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material'
import { Employee } from '@reaction-club-types/core'
import React, { useState } from 'react'
import useModalState from '../../../../hooks/useModalState'
import ChangePointsModal from '../modals/ChangePointsModal'
import EmployeeDeleteModal from '../modals/EmployeeDeleteModal'
import EmployeeChangeCompanyModal from '../modals/EmployeeChangeCompanyModal'

interface Props {
  employee: Employee
}

function EmployeeHeadActions(props: Props) {
  const { employee } = props
  const [isMenuVisible, setMenuVisibility] = useState(false)
  const modalChangePoints = useModalState(false)
  const modalUserDelete = useModalState(false)
  const modalCompanySelect = useModalState(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMenuButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
    handleOpenMenu()
  }

  const handleOpenMenu = () => setMenuVisibility(true)
  const handleCloseMenu = () => setMenuVisibility(false)

  const handlePressChangePoints = () => {
    modalChangePoints.onOpen()
    handleCloseMenu()
  }

  const handlePressDelete = () => {
    modalUserDelete.onOpen()
    handleCloseMenu()
  }

  return (
    <div>
      <EmployeeDeleteModal employee={employee} {...modalUserDelete} />
      <ChangePointsModal employee={employee} {...modalChangePoints} />
      <EmployeeChangeCompanyModal employee={employee} {...modalCompanySelect} />
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleMenuButtonClick}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={isMenuVisible}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <MenuItem onClick={handlePressChangePoints}>
          <ListItemIcon>
            <EditIcon color={'primary'} fontSize={'small'} />
          </ListItemIcon>
          <Typography variant="inherit">Change Points</Typography>
        </MenuItem>

        <MenuItem onClick={modalCompanySelect.onOpen}>
          <ListItemIcon>
            <EditIcon color={'primary'} fontSize={'small'} />
          </ListItemIcon>
          <Typography variant="inherit" color={'error'}>
            Change Company
          </Typography>
        </MenuItem>

        <MenuItem onClick={handlePressDelete}>
          <ListItemIcon>
            <DeleteIcon color={'primary'} fontSize={'small'} />
          </ListItemIcon>
          <Typography variant="inherit" color={'error'}>
            Delete User
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default EmployeeHeadActions
