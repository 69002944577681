/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import { Company } from '@reaction-club-types/core'
import api from '@reducers/companies/api'
import React, { useState } from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { RendererStatusSplit } from 'react-renderer-status-split'
import ButtonOrange from '../../../components/ButtonOrange'
import Loader from '../../../components/Loader'
import ModalStyled from '../../../elements/ModalStyled'
import RedditTextField from '../../../elements/form/RedditTextField'

interface Props {
  company: Company
  isVisible: boolean
  onClose(): any
}
function CompanySendMessageModal(props: Props) {
  const { company } = props
  const [message, setMessage] = useState('')

  const sendMessagesAction = useAsyncHandler(async () => {
    await api.chatBroadcast(company.id, message)
  })

  return (
    <ModalStyled
      {...props}
      css={css`
        width: 100%;
        max-width: 400px;
      `}
    >
      <div>
        <Typography
          variant={'h2'}
          css={css`
            margin-top: 20px;
            margin-bottom: 10px;
          `}
        >
          Company chat broadcast
        </Typography>
      </div>
      <div>
        <Typography
          css={css`
            font-size: 12px;
            color: #6d6d6d;
            font-style: italic;
            margin-bottom: 7px;
          `}
        >
          Note: test message in test company before sending to users.
        </Typography>
        <RedditTextField
          multiline
          placeholder={'Type message here...'}
          fullWidth
          onChange={(e) => setMessage(e.target.value)}
          css={css`
            textarea {
              min-height: 150px;
            }
          `}
        />
      </div>

      <Grid
        container
        justifyContent={'flex-end'}
        css={css`
          margin-top: 20px;
        `}
      >
        <RendererStatusSplit
          statuses={sendMessagesAction}
          renderLoading={() => <Loader />}
          renderError={(error) => <Typography color={'error'}>{error}</Typography>}
          render={() =>
            sendMessagesAction.isDone ? (
              <Typography>Broadcast action sent</Typography>
            ) : (
              <ButtonOrange onClick={sendMessagesAction.execute} disabled={!message || sendMessagesAction.isDone}>
                Send
              </ButtonOrange>
            )
          }
        />
      </Grid>
    </ModalStyled>
  )
}

export default CompanySendMessageModal
