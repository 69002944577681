import { IPlan } from '@reducers/companies/types'
import apiClient from '../../lib/apiClient'

export interface CompanyCreateParams {
  name: string
  asset_id?: string
}

const apiCompanies = {
  async getCompanyAgents(company_id: string): Promise<Agent[]> {
    const result = await apiClient.get(`/company/${company_id}/agents`)
    return result.data
  },
  async getCompanySubscriptionPlan(company_id: string): Promise<IPlan> {
    const result = await apiClient.get(`/company/${company_id}/subscription-plan`)
    return result.data
  },
  async createCompanySubscriptionPlan(company_id: string, payload: IPlan): Promise<IPlan> {
    const result = await apiClient.post(`/company/${company_id}/subscription-plan`, payload)
    return result.data
  },
  async updateCompanySubscriptionPlan(company_id: string, payload: Partial<IPlan>): Promise<IPlan> {
    const result = await apiClient.patch(`/company/${company_id}/subscription-plan`, payload)
    return result.data
  },
  async changeCompanySubscriptionPlan(company_id: string, payload: { subscriptionId: string }): Promise<any> {
    const result = await apiClient.post(`/company/${company_id}/change-subscription`, payload)
    return result.data
  },
  async resetPoints(company_id: string): Promise<{ changed: number }> {
    const result = await apiClient.post(`/company/${company_id}/reset-points`)
    return result.data
  },

  async setPreferenceCoffeeCorner(company_id: string, payload: { status: boolean }): Promise<{ changed: number }> {
    const result = await apiClient.post(`/company/${company_id}/preferences/set-coffee-corner`, payload)
    return result.data
  },

  async setPreferenceGroupsOpened(company_id: string, payload: { status: boolean }): Promise<{ changed: number }> {
    const result = await apiClient.post(`/company/${company_id}/preferences/set-groups-opened`, payload)
    return result.data
  },

  async setConfigEventType(company_id: string, payload: { type: 'webinar' | 'meeting' }): Promise<{ changed: number }> {
    const result = await apiClient.post(`/company/${company_id}/config/live-event-type`, payload)
    return result.data
  },

  async setConfigPublicEventsEnabled(
    company_id: string,
    payload: { is_enabled: boolean },
  ): Promise<{ changed: number }> {
    const result = await apiClient.post(`/company/${company_id}/config/public-events-enabled`, payload)
    return result.data
  },

  async addCountry(company_id: string, country: string): Promise<{ changed: number }> {
    const result = await apiClient.post(`/company/${company_id}/config/location/add`, { country })
    return result.data
  },

  async removeCountry(company_id: string, country: string): Promise<{ changed: number }> {
    const result = await apiClient.post(`/company/${company_id}/config/location/remove`, { country })
    return result.data
  },

  async createCompany(company: CompanyCreateParams): Promise<{ company_id: string }> {
    const result = await apiClient.post(`/company/create`, company)
    return result.data
  },
  async chatBroadcast(company_id: string, message: string): Promise<{ company_id: string }> {
    const result = await apiClient.post(`/company/${company_id}/broadcast`, { message })
    return result.data
  },

  async deleteCompany(company_id: string): Promise<void> {
    await apiClient.delete(`/company/${company_id}`)
  },

  async clearFeed(company_id: string): Promise<void> {
    await apiClient.delete(`/company-feed/${company_id}`)
  },

  async createTestCompany(data): Promise<void> {
    await apiClient.post(`/company/test`, data)
  },
}

export default apiCompanies
