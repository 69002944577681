/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Paper, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { ReduxState } from '@reducers/index'
import ListDispatcher from '@reducers/lists/dispatcher'
import _ from 'lodash'
import React, { useRef, useState } from 'react'
import { useAsyncFetch, useAsyncHandler } from 'react-hooks-async-handlers'
import InfiniteScroll from 'react-infinite-scroller'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useHistory } from 'react-router-dom'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import ReactionLoader from '../../components/ReactionLoader'
import ScrollView from '../../components/ScrollView'
import EmployeeCard from './list/EmployeeCard'
import EmployeeListFilter from './list/EmployeeListFilter'

function EmployeeListPage() {
  const scrollRef = useRef()
  const history = useHistory()
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')

  const { list, listIsNextBlocked } = useSelector((state: ReduxState) => ({
    list: state.lists.employees,
    listIsNextBlocked: state.lists.employeesIsNextBlocked,
  }))

  const dispatch = useDispatch<ReduxDispatch>()
  const fetchInitial = useAsyncFetch(async () => {
    await dispatch(ListDispatcher.getEmployees({ name, phone, email }))
  }, [name, phone, email])

  const loadMoreAction = useAsyncHandler(async () => {
    if (!fetchInitial.isDone) return null
    await dispatch(ListDispatcher.getEmployeesNext({ name, phone, email }))
  })

  const isPagerEnabled = !listIsNextBlocked && fetchInitial.isDone

  return (
    <ScrollView offset={35} ref={scrollRef}>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMoreAction.execute}
        hasMore={isPagerEnabled}
        loader={
          <Grid container justifyContent="center">
            <ReactionLoader />
          </Grid>
        }
        getScrollParent={() => scrollRef.current}
        useWindow={false}
      >
        <Grid container justifyContent="center" key={'infinite-scroll-companies'}>
          <Grid item sm={10} xs={12}>
            <Paper>
              <Grid
                container
                justifyContent={'center'}
                css={css({
                  padding: '10px 0 30px',
                })}
              >
                <Grid
                  container
                  css={css`
                    padding: 0 10px;
                  `}
                >
                  <div css={css({ paddingBottom: 10 })}>
                    <EmployeeListFilter
                      // onFetch={handleFetchCompanies}
                      onChangeName={setName}
                      onChangePhone={setPhone}
                      onChangeEmail={setEmail}
                    />
                    {/* <Grid item sm={4} container justifyContent={'flex-end'} alignItems={'flex-start'}> */}
                    {/*  <Button href={createHref} variant={'contained'} color={'primary'}> */}
                    {/*    Create New */}
                    {/*  </Button> */}
                    {/* </Grid> */}
                  </div>

                  {/* <Typography variant={'body2'} align={'right'}> */}
                  {/*  total: {total_count} */}
                  {/* </Typography> */}
                  <Grid
                    container
                    spacing={4}
                    css={css({
                      paddingTop: 5,
                      minHeight: '200px',
                    })}
                  >
                    <RendererStatusSplit
                      statuses={fetchInitial}
                      isEmpty={_.isEmpty(list)}
                      renderLoading={() => <ReactionLoader />}
                      renderError={(error) => (
                        <Grid container justifyContent="center">
                          <Typography color={'error'}>{error}</Typography>
                        </Grid>
                      )}
                      render={() => {
                        let listFiltered = list
                        if (name) {
                          listFiltered = _.filter(list, (company) =>
                            (company.name || '').toLowerCase().includes(name.toLowerCase()),
                          )
                        }

                        return listFiltered.map((user) => (
                          <EmployeeCard user={user} key={`employee-page-item-${user.user_id}`} />
                        ))
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </InfiniteScroll>
    </ScrollView>
  )
}

export default EmployeeListPage
