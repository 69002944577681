import React from 'react'
import connect from 'react-redux/es/connect/connect'
import { withRouter } from 'react-router-dom'
import { Avatar, Button, Grid, List, ListItem, ListItemText, Paper, Typography } from '@mui/material'
import PlaybookTypesActionDispatcher from '../../reducers/playbookTypes/actionDispatcher'
import PlaybookType from './components/PlaybookType'
import { withStyles } from '@mui/styles';

const styles = {
  playbookTypesPaper: {
    padding: 15,
  },
  addPlaybookTypeItem: {
    marginRight: 'auto',
    marginLeft: 'auto',
  },
}

@connect(
  (state, props) => ({
    playbookTypes: state.playbookTypes.list,
  }),
  dispatch => ({
    getPlaybookTypes: PlaybookTypesActionDispatcher(dispatch).getPlaybookTypes,
  }),
)
@withRouter
@withStyles(styles)
export default class PlaybookTypes extends React.Component {
  toCreatePlaybookType = () => {
    const { history } = this.props
    history.push('/create-playbook-type')
  }

  componentDidMount = async () => {
    try {
      await this.props.getPlaybookTypes()
    } catch (e) {
      console.warn(e)
    }
  }

  render() {
    const { classes, playbookTypes } = this.props

    return (
      <Grid container justifyContent={'center'}>
        <Grid item xs={11} sm={10} lg={8}>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Paper className={classes.playbookTypesPaper}>
                <Grid container>
                  <Grid item xs={10}>
                    <Typography variant={'title'}>Playbook types:</Typography>
                  </Grid>
                  <Grid item xs={2} />
                  <Grid item xs={6}>
                    <List>
                      <ListItem>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={this.toCreatePlaybookType}
                          className={classes.addPlaybookTypeItem}
                        >
                          {' '}
                          Add Playbook type
                        </Button>
                      </ListItem>
                      {playbookTypes &&
                        playbookTypes.length > 0 &&
                        playbookTypes.map(playbookType => (
                          <PlaybookType key={playbookType.id} playbookType={playbookType} />
                        ))}
                    </List>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
