import Grid, { GridProps } from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import { Coach } from '@reaction-club-types/core'
import React from 'react'
import UserAvatar from '../../../../../../components/UserAvatar'

const useStyles = makeStyles(
  {
    container: {
      padding: 5,
      borderRadius: 4,
      border: '1px solid',
      borderColor: 'hsl(0,0%,80%)',
      cursor: 'pointer',
    },
    name: {
      fontWeight: 'bold',
    },
    preview: {
      height: '2rem',
      paddingLeft: 20,
      fontWeight: 'bold',
      color: '#ccc',
    },
  },
  {
    classNamePrefix: 'EventCoachSelectPreview',
  },
)

interface Props {
  coach?: Pick<Coach, 'name' | 'avatar_url'> | null
  onClick: GridProps['onClick']
}

export default function EventCoachSelectPreview(props: Props) {
  const { coach, onClick } = props
  const classes = useStyles()

  let reactNode = (
    <Grid container alignItems={'center'} className={classes.preview}>
      <span>Select Coach</span>
    </Grid>
  )

  if (coach) {
    const { name, avatar_url } = coach

    reactNode = (
      <>
        <UserAvatar avatar_url={avatar_url} name={name} />
        <div>
          <span className={classes.name}>{name}</span>
        </div>
      </>
    )
  }

  return (
    <Grid container alignItems={'center'} className={classes.container} onClick={onClick}>
      {reactNode}
    </Grid>
  )
}
