import { ZoomHost } from '@reaction-club-types/core'
import _ from 'lodash'
import React from 'react'
import ZoomHostItem from './components/ZoomHostItem'
import ZoomHostsTable from './components/ZoomHostsTable'

interface Props {
  zoomHosts: ZoomHost[]
}
export default function ZoomHosts(props: Props) {
  const { zoomHosts = [] } = props

  return (
    <ZoomHostsTable>
      {zoomHosts.map((item, index) => (
        <ZoomHostItem zoomHost={item} />
      ))}
    </ZoomHostsTable>
  )
}
