import _ from 'lodash'
import actions from './actions'
import api from '../../libs/api/apiOld'
import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import coachesActions from '../coaches/actions'

export default {
  actions,

  getCoaches(): DispatcherResponse {
    return async (dispatch, getState) => {
      const { name, coordinates } = getState().coachesPage
      const { coaches, total_count } = await api.getCoaches({
        name,
        offset: 0,
        coordinates,
      })

      await dispatch(actions.set(coaches))
      dispatch(actions.setCount(total_count))
      _.forEach(coaches, coach => dispatch(coachesActions.setCoach(coach)))
    }
  },

  getCoachesNext(): DispatcherResponse {
    return async (dispatch, getState) => {
      const { name, list, coordinates } = getState().coachesPage
      const offset = list.length

      const { coaches } = await api.getCoaches({
        offset,
        name,
        coordinates,
      })

      await dispatch(actions.setNext(coaches))
      _.forEach(coaches, coach => dispatch(coachesActions.setCoach(coach)))
    }
  },
}
