/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import CompanyDispatcher from '@reducers/companies/dispatcher'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { ReduxDispatch } from '../../../../../typings/ReduxDispatch'
import Loader from '../../../../components/Loader'

interface Props {
  isVisible: boolean
  onClose: () => any
}
export default function CreateTestCompanyModal(props: Props) {
  const { isVisible, onClose } = props
  const { enqueueSnackbar } = useSnackbar()

  const form = useForm<{ company_name: string; agent_email: string; agent_name }>()
  const { register, handleSubmit } = form

  const dispatch = useDispatch<ReduxDispatch>()
  const createTestCompanyAction = useAsyncHandler(async (data) => {
    await dispatch(CompanyDispatcher.createTestCompany(data))

    enqueueSnackbar('Test company created!', { variant: 'success' })
    onClose()
  })

  return (
    <Dialog open={isVisible} onClose={onClose}>
      <DialogTitle>Create test company</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Company Name"
          fullWidth
          variant="standard"
          {...register('company_name')}
        />
        <TextField
          autoFocus
          margin="dense"
          label="Agent Email"
          fullWidth
          variant="standard"
          {...register('agent_email')}
        />
        <TextField
          autoFocus
          margin="dense"
          label="Agent Name"
          fullWidth
          variant="standard"
          {...register('agent_name')}
        />
      </DialogContent>
      <DialogActions>
        <RendererStatusSplit
          statuses={createTestCompanyAction}
          renderLoading={() => (
            <Loader
              css={css`
                margin-right: 10px;
              `}
            />
          )}
          render={() => (
            <Button
              onClick={handleSubmit(createTestCompanyAction.execute)}
              css={css`
                font-weight: bold;
              `}
            >
              Create
            </Button>
          )}
        />

        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
