import { Grid, Typography } from '@mui/material'
import { APIEventCreatePayload } from '@reducers/events/apiEvents'
import EventDispatcher from '@reducers/events/dispatcher'
import { ReduxState } from '@reducers/index'
import { PlaybookInstanceExtended } from '@reducers/playbookInstances/reducer'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../typings/ReduxExtractor'
import PaperContainer from '../../components/PaperContainer'
import EventCreateForm from './modals/eventCreate/components/EventCreateForm'

const styles = {}

export interface CalendarEvent {
  title: string
  start: Date
  end: Date
  allDay?: boolean
  resource?: CalendarEventResources
}

interface CalendarEventResources {
  description: string
  instance: PlaybookInstanceExtended | null | any
}

interface Props extends RouteComponentProps {}

interface PropsInner extends Props, ExtractConnectType<typeof connectStore> {}

interface State {}

class EventCreate extends React.PureComponent<PropsInner, State> {
  state = {
    isLoading: false,
    isAgendaActive: false,
  }

  render() {
    return (
      <PaperContainer>
        <Typography variant={'h1'}>Create Event</Typography>
        <Grid item lg={6} md={8} sm={12}>
          <EventCreateForm onCreate={this.handleEventCreate} />
        </Grid>
      </PaperContainer>
    )
  }

  handleEventCreate = async (payload: APIEventCreatePayload) => {
    const { history } = this.props
    await this.props.createEvent(payload)
    history.push('/events')
  }
}

const connectStore = connect(
  (state: ReduxState) => ({
    events: state.events,
    groups: state.playbookInstances,
  }),
  (dispatch: ReduxDispatch) => ({
    createEvent: (payload: APIEventCreatePayload) => dispatch(EventDispatcher.createEvent(payload)),
  }),
)

export default connectStore(EventCreate)
