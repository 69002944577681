import { FormControl, RadioGroup, FormControlLabel, FormLabel, Chip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReduxStateCompanyItem } from '@reducers/companies/reducer'
import React from 'react'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import StyledRadio from '../../../elements/StyledRadio'
import CompanyDispatcher from '../../../reducers/companies/dispatcher'
import CompanyPreference from './components/CompanyPreference'
import ConfigLabel from './components/ConfigLabel'
import PageSection from '../../../layout/PageSection'
import CompanyConfigLocations from './config/CompanyConfigLocations'

const useStyles = makeStyles(
  {
    contentContainer: {
      padding: 20,
    },
  },
  {
    classNamePrefix: 'CompanyConfig',
  },
)

interface Props {
  company: ReduxStateCompanyItem
}

interface PropsInner extends Props {}

function CompanyConfig(props: PropsInner) {
  const { company } = props
  const dispatch = useDispatch<ReduxDispatch>()
  const classes = useStyles()
  const { config } = company
  let { live_session_type = 'meeting', is_public_events_enabled, locations } = config || {}

  // locations = locations.asMutable()
  // locations.push('UA')
  // locations.push('US')
  // locations.push('WW')
  if (!config) return null

  const handleEventTypeChange = async (event, type: any) => {
    await dispatch(CompanyDispatcher.setConfigLiveEventType(company.id, { type }))
    // await dispatch(CompanyDispatcher.setPreferenceCoffeeCorner(company.id, payload))
  }

  const handlePublicEventToggle = async () => {
    await dispatch(
      CompanyDispatcher.setConfigPublicEventsEnabled(company.id, { is_enabled: !is_public_events_enabled }),
    )
  }

  return (
    <PageSection title={'Configs'}>
      <div className={classes.contentContainer}>
        <CompanyPreference
          title={'Public events:'}
          isTrue={!!is_public_events_enabled}
          onChange={handlePublicEventToggle}
        />

        <FormControl component="fieldset">
          <ConfigLabel title={'Zoom Live Event Type:'} />
          <RadioGroup
            value={live_session_type}
            defaultValue="meeting"
            aria-label="live events"
            name="customized-radios"
            onChange={handleEventTypeChange}
          >
            <FormControlLabel value="meeting" control={<StyledRadio />} label="Meeting" />
            <FormControlLabel value="webinar" control={<StyledRadio />} label="Webinar" />
          </RadioGroup>
        </FormControl>

        <CompanyConfigLocations company={company} />
      </div>
    </PageSection>
  )
}

export default CompanyConfig
