/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Menu as MenuIcon } from '@mui/icons-material'
import Exit from '@mui/icons-material/ExitToApp'
import { AppBar, Toolbar, Typography, Button } from '@mui/material'
import IconButton from '@mui/material/IconButton/IconButton'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import packageJson from '../../package.json'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import Logo from '../assets/logo.png'
import AuthActionDispatcher from '../reducers/auth/dispatcher'

const drawerWidth = 240

interface Props {
  isDrawerOpened: boolean
  onOpenDrawer(): any
}

function AppBarCustom(props: Props) {
  const { isDrawerOpened, onOpenDrawer } = props
  const dispatch = useDispatch<ReduxDispatch>()

  const handleClickLogout = () => {
    setTimeout(() => {
      dispatch(AuthActionDispatcher.logout())
    }, 300)
  }

  return (
    <AppBar
      color={'default'}
      position="fixed"
      css={[
        css`
          background-color: #fff;
          height: 64px;
        `,
        isDrawerOpened &&
          css`
            @media (min-width: 700px) {
              width: calc(100% - ${drawerWidth}px);
              margin-left: ${drawerWidth}px;
            }
          `,
      ]}
    >
      <Toolbar disableGutters={!isDrawerOpened}>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={onOpenDrawer}
          css={[
            css({
              marginLeft: 12,
              marginRight: 20,
            }),
            isDrawerOpened &&
              css`
                display: none;
              `,
          ]}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          color="inherit"
          css={css`
            flex-grow: 1;
          `}
        >
          <Link to={'/'}>
            <img
              src={Logo}
              css={css({
                width: 100,
                height: 65,
                objectFit: 'cover',
                objectPosition: 'center',
              })}
              alt={'Reaction Club admin'}
            />
          </Link>
        </Typography>
        <Button
          size={'small'}
          variant="contained"
          color="secondary"
          css={css`
            position: fixed;
            right: 24px;
          `}
          onClick={handleClickLogout}
        >
          Logout
          <Exit
            css={css({
              marginLeft: 10,
              marginRight: -5,
            })}
          />
        </Button>

        <Typography
          css={css({
            position: 'absolute',
            right: 0,
            top: 0,
          })}
          variant={'body2'}
        >
          v{packageJson.version}
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export default AppBarCustom
