import { Grid, Typography, Avatar, ListItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import { Agent } from '@reaction-club-types/core'
import classNames from 'classnames'
import React from 'react'
import { matchPath, Link, useLocation } from 'react-router-dom'

const useStyles = makeStyles({
  coachBody: {
    marginLeft: 10,
    flexGrow: 1,
  },

  link: {
    width: '100%',
  },

  coachListItem: {
    paddingLeft: 15,
    paddingRight: 5,
    position: 'relative',
  },

  ownerText: {
    fontSize: 10,
    color: '#979797',
    fontWeight: 'bold',
  },
  coachListItemActive: {
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 2,
      top: 0,
      bottom: 0,
      right: 0,
      backgroundColor: '#F9B22D',
    },
  },
})

interface Props {
  agent: Agent
  agentRoutePath: string
}

function AgentListItem(props: Props) {
  const { agent, agentRoutePath } = props
  const classes = useStyles()

  const { user_id, name, avatar_url } = agent

  const { pathname } = useLocation()
  const locMatch = matchPath<{ agent_id: string }>(pathname, agentRoutePath)

  let isActive = false
  if (locMatch) isActive = locMatch.params.agent_id === user_id

  const agentUrl = agentRoutePath.replace(':agent_id', user_id)

  return (
    <Link to={agentUrl}>
      <ListItem
        button
        component="div"
        disableGutters
        className={classNames({
          [classes.coachListItem]: true,
          [classes.coachListItemActive]: isActive,
        })}
      >
        <Grid container alignItems={'center'} direction={'row'}>
          <Avatar src={avatar_url} variant={'circular'} />
          <div className={classes.coachBody}>
            <Typography variant={'body1'}>{name}</Typography>
          </div>
        </Grid>
      </ListItem>
    </Link>
  )
}

export default AgentListItem
