import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { arrowBackIcon } from './paperContainer/assets/facade'

const useStyles = makeStyles({
  profileContainer: {
    margin: '40px 50px 15px 50px',
  },
  image: {
    width: 7,
    height: 13,
    marginRight: 15,
  },
  backText: {
    fontSize: 14,
    color: '#000',
    lineHeight: '100%',
  },
  backContainer: {
    cursor: 'pointer',
  },
})

function ProfileHeader() {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Grid
      container
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      className={classes.profileContainer}
    >
      <Grid item>
        <Grid
          container
          direction={'row'}
          alignItems={'center'}
          className={classes.backContainer}
          onClick={history.goBack}
        >
          <img src={arrowBackIcon} alt="back icon" className={classes.image} />
          <Typography className={classes.backText}>Back</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ProfileHeader
