/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Company, Employee } from '@reaction-club-types/core'
import EmployeeDispatcher from '@reducers/employees/dispatcher'
import invariant from 'invariant'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useState } from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { ReduxDispatch } from '../../../../../typings/ReduxDispatch'
import Loader from '../../../../components/Loader'
import useModalState from '../../../../hooks/useModalState'
import CompanySelectModal from '../../../../modals/CompanySelectModal'

interface Props {
  employee: Employee
  isVisible: boolean
  onClose: () => any
}
export default function EmployeeChangeCompanyModal(props: Props) {
  const { isVisible, onClose, employee } = props
  const confirmModalState = useModalState()
  const { enqueueSnackbar } = useSnackbar()
  const [company, setCompany] = useState<Company | null>(null)
  const dispatch = useDispatch<ReduxDispatch>()

  const handleConfirmClose = () => {
    confirmModalState.onClose()
    onClose()
  }

  const changeCompanyAction = useAsyncHandler(async () => {
    invariant(company, 'company is not selected')
    await dispatch(EmployeeDispatcher.setCompany({ employee_id: employee.user_id, company_id: company.id }))

    enqueueSnackbar('User updated!', { variant: 'success' })
    handleConfirmClose()
  })

  const handleCompanySelect = (company: Company) => {
    setCompany(company)
    confirmModalState.onOpen()
  }

  return (
    <React.Fragment>
      <CompanySelectModal isVisible={isVisible} onSelect={handleCompanySelect} onClose={onClose} />
      <Dialog open={confirmModalState.isVisible} onClose={handleConfirmClose}>
        <DialogTitle fontWeight={'bold'}>Changing company for {employee.name}</DialogTitle>
        <DialogContent>
          <DialogContentText color={'black'}>
            Are you sure want to set <b>{company?.name}</b> company for {employee.name}?
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <RendererStatusSplit
            statuses={changeCompanyAction}
            renderError={(error) => <Typography color={'error'}>{error}</Typography>}
            renderLoading={() => (
              <Loader
                css={css`
                  margin-right: 10px;
                `}
              />
            )}
            render={() => (
              <Button
                onClick={changeCompanyAction.execute}
                css={css`
                  font-weight: bold;
                  color: #e82323;
                `}
              >
                Change
              </Button>
            )}
          />

          <Button onClick={handleConfirmClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
