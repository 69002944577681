import { makeStyles } from '@mui/styles'
import { ReactionEvent } from '@reaction-club-types/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import ModalStyled from '../../../elements/ModalStyled'
import EventDetails from './eventInfo/EventDetails'
import EventHeadActions from './eventInfo/EventHeadActions'

const useStyles = makeStyles({
  container: {
    minWidth: 700,
  },
})

interface Props {
  event: ReactionEvent
  isVisible: boolean
  onClose(): any
}

function EventInfoModal(props: Props) {
  const { event, isVisible, onClose } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  if (!event) return null

  const handleSaveClick = async () => {
    // if (mode === 'auto' && !program_id) return null
    // await dispatch<any>(
    //   PlaybookInstanceDispatcher.updateMode({
    //     instance_id: instance.id,
    //     mode,
    //     program_id,
    //     time: program_time,
    //   }),
    // )
    //
    // onClose()
  }

  return (
    <ModalStyled isVisible={isVisible} onClose={onClose} className={classes.container}>
      <EventHeadActions event={event} onClose={onClose} />
      <EventDetails event={event} />
    </ModalStyled>
  )
}

export default EventInfoModal
