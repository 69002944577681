/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { MoreVert as MoreVertIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { MenuItem, Typography, IconButton, Menu, ListItemIcon, Grid } from '@mui/material'
import AgentDispatcher from '@reducers/agent/dispatcher'
import { ReduxStateAgent } from '@reducers/agent/reducer'
import _ from 'lodash'
import React from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Immutable } from 'seamless-immutable'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import useModalState from '../../../hooks/useModalState'

interface Props {
  agent: Immutable<ReduxStateAgent>
}

function AgentActions(props: Props) {
  const { agent } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const menuState = useModalState(false)
  const dispatch = useDispatch<ReduxDispatch>()
  const history = useHistory()

  const deleteAction = useAsyncHandler(async () => {
    await dispatch(AgentDispatcher.deleteAgent(agent.user_id))
    history.replace('/agents')
  })

  const handleClickDelete = () => {
    const confirmation = prompt(`Are you sure want to delete "${agent.name}"? Input 'delete' to confirm.`)
    if (!confirmation) return null
    if (confirmation.trim().toLowerCase() !== 'delete') return null

    deleteAction.execute()
  }

  if (_.isEmpty(agent)) return null

  return (
    <div
      css={css`
        position: absolute;
        top: -20px;
        right: -10px;
        color: #323232;
        font-size: 1.2rem;
      `}
    >
      <Grid container alignItems={'center'}>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={menuState.onOpen}
          size="large"
          ref={(ref) => setAnchorEl(ref)}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          open={menuState.isVisible}
          onClose={menuState.onClose}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          {/* <MenuItem onClick={this.handleClickEditCoaches}> */}
          {/*  <ListItemIcon> */}
          {/*    <GroupIcon /> */}
          {/*  </ListItemIcon> */}
          {/*  <Typography variant="inherit">Manage Coaches</Typography> */}
          {/* </MenuItem> */}

          <MenuItem onClick={handleClickDelete}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <Typography variant="inherit" color={'error'} style={{ fontWeight: 'bold' }}>
              Delete
            </Typography>
          </MenuItem>
        </Menu>
      </Grid>
    </div>
  )
}

export default AgentActions
