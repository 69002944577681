import _ from 'lodash'
import actions from './actions'

export default dispatch =>
  bounded({
    // @params {modal: {
    //  modalType: string // which type of modal should be displayed
    //  modalProps: object // props which should be passed into modal window
    // }}
    showModal: modal => {
      dispatch(actions.showModal(modal))
    },
    closeModal: () => dispatch(actions.closeModal()),
  })

function bounded(object) {
  _.keys(object).forEach(key => {
    object[key] = object[key].bind(object)
  })
  return object
}

function timer() {
  return new Promise(resolve => setTimeout(resolve, 1000))
}
