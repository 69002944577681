import { Agent } from '@reaction-club-types/core'
import apiClient from '../../lib/apiClient'

const apiAgents = {
  async getAgentList(params: { offset: number; name?: string }): Promise<Agent[]> {
    const { data } = await apiClient.get(`/agent/list`, {
      params,
    })
    return data
  },

  async getAgent(agent_id: string): Promise<Agent> {
    const { data } = await apiClient.get(`/agent/${agent_id}`)
    return data
  },

  async getOnboardList(params: { offset: number }): Promise<any[]> {
    const { data } = await apiClient.get(`/agent/onboard-list`, {
      params,
    })
    return data
  },

  async deleteAgent(agent_id: string): Promise<Agent> {
    const { data } = await apiClient.post(`/agent/${agent_id}/delete`)
    return data
  },
}

export default apiAgents
