/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import AddIcon from '@mui/icons-material/Add'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import DeleteIcon from '@mui/icons-material/Delete'
import InfoIcon from '@mui/icons-material/Info'
import { Box, Button, Divider, Grid, IconButton, Link, Stack, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import ButtonOrange from '../../components/ButtonOrange'
import Loader from '../../components/Loader'
import PageSection from '../../layout/PageSection'
import api from '../../libs/api/apiOld'

const FeaturesForm = ({ control, register, name }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  })

  return (
    <Grid item xs={12}>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDownwardIcon />} aria-controls="panel1-content" id="panel1-header">
          <Typography color="#3C8AF0">Features</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack gap={4}>
            {fields.map((field, index) => (
              <Stack direction="row" gap={2} key={`${name}-${field.id}`}>
                <StyledTextField
                  {...register(`${name}.${index}.feature`)}
                  label="Feature"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  required
                />
                <StyledTextField
                  {...register(`${name}.${index}.description`)}
                  label="Description"
                  multiline
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  required
                />
                <IconButton aria-label="delete" onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
            ))}
            <Button
              variant="contained"
              endIcon={<AddIcon />}
              onClick={() => append({ order: fields.length + 1, feature: '', description: '' })}
            >
              Add More Features
            </Button>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

function StripeSettings() {
  const [loading, setLoading] = useState(false)
  const [subscriptionConfig, setSubscriptionConfig] = useState<any>({})
  const { register, handleSubmit, reset, control } = useForm()

  useEffect(() => {
    const fetchSubscriptionConfig = async () => {
      try {
        setLoading(true)
        const res = await api.getSubscriptionConfig()
        setSubscriptionConfig(res)
        reset(res)
      } finally {
        setLoading(false)
      }
    }

    fetchSubscriptionConfig()
  }, [])

  const onSubmit = async (newData) => {
    try {
      setLoading(true)
      await api.updateSubscriptionConfig(
        Object.keys(newData).reduce((prev, curr) => {
          return {
            ...prev,
            [curr]: newData[curr] && Object.hasOwn(newData[curr], 'priceId') ? newData[curr].priceId : newData[curr],
          }
        }, {}),
      )
    } finally {
      setLoading(false)
    }
  }

  const renderTooltip = (data) => {
    if (!data) {
      return null
    }

    return (
      <Tooltip
        title={
          <React.Fragment>
            <Typography>Interval Unit: {data.intervalUnit}</Typography>
            <Typography>Interval Count: {data.intervalCount}</Typography>
            <Typography>Currency: {data.currency}</Typography>
            {data.tiers.map((tier) => (
              <Typography>
                Tiers: {tier.amount} {data.currency} {tier.upTo ? `up to ${tier.upTo}` : ''}
              </Typography>
            ))}
            <Link target="_blank" href={`https://dashboard.stripe.com/prices/${data.priceId}`}>
              <Typography color="#F9B22D">View in Stripe</Typography>
            </Link>
          </React.Fragment>
        }
        arrow
      >
        <InfoIcon />
      </Tooltip>
    )
  }

  return (
    <div
      css={css`
        margin-top: 30px;
        padding: 0 20px;
      `}
    >
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        css={css`
          margin-bottom: 20px;
        `}
      >
        <Typography variant={'h1'}>Stripe Settings</Typography>
      </Grid>

      {loading ? (
        <Loader />
      ) : (
        <PageSection title={'Settings'}>
          <Box
            css={css`
              padding: 20px;
            `}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={4}>
                <Grid item xs={4} />
                <Grid item xs={4}>
                  <Typography variant="h2">Monthly</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h2">Yearly</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h2">Basic</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Stack gap={1}>
                    <Stack direction="row" gap={1} alignItems="center">
                      <StyledTextField
                        {...register('base_monthly_usd_id.priceId')}
                        label="USD"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                      {renderTooltip(subscriptionConfig?.base_monthly_usd_id)}
                    </Stack>
                    <Stack direction="row" gap={1} alignItems="center">
                      <StyledTextField
                        {...register('base_monthly_eur_id.priceId')}
                        label="EUR"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                      {renderTooltip(subscriptionConfig?.base_monthly_eur_id)}
                    </Stack>
                    <Stack direction="row" gap={1} alignItems="center">
                      <StyledTextField
                        {...register('base_monthly_gbp_id.priceId')}
                        label="GBP"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                      {renderTooltip(subscriptionConfig?.base_monthly_gbp_id)}
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack gap={1}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <StyledTextField
                        {...register('base_yearly_usd_id.priceId')}
                        label="USD"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                      {renderTooltip(subscriptionConfig?.base_yearly_usd_id)}
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <StyledTextField
                        {...register('base_yearly_eur_id.priceId')}
                        label="EUR"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                      {renderTooltip(subscriptionConfig?.base_yearly_eur_id)}
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <StyledTextField
                        {...register('base_yearly_gbp_id.priceId')}
                        label="GBP"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                      {renderTooltip(subscriptionConfig?.base_yearly_gbp_id)}
                    </Stack>
                  </Stack>
                </Grid>
                <FeaturesForm control={control} register={register} name="base_features" />
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h2">Pro</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Stack gap={1}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <StyledTextField
                        {...register('pro_monthly_usd_id.priceId')}
                        label="USD"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                      {renderTooltip(subscriptionConfig?.pro_monthly_usd_id)}
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <StyledTextField
                        {...register('pro_monthly_eur_id.priceId')}
                        label="EUR"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                      {renderTooltip(subscriptionConfig?.pro_monthly_eur_id)}
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <StyledTextField
                        {...register('pro_monthly_gbp_id.priceId')}
                        label="GBP"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                      {renderTooltip(subscriptionConfig?.pro_monthly_gbp_id)}
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack gap={1}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <StyledTextField
                        {...register('pro_yearly_usd_id.priceId')}
                        label="USD"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                      {renderTooltip(subscriptionConfig?.pro_yearly_usd_id)}
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <StyledTextField
                        {...register('pro_yearly_eur_id.priceId')}
                        label="EUR"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                      {renderTooltip(subscriptionConfig?.pro_yearly_eur_id)}
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <StyledTextField
                        {...register('pro_yearly_gbp_id.priceId')}
                        label="GBP"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                      {renderTooltip(subscriptionConfig?.pro_yearly_gbp_id)}
                    </Stack>
                  </Stack>
                </Grid>
                <FeaturesForm control={control} register={register} name="pro_features" />
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h2">Enterprise</Typography>
                </Grid>
                <FeaturesForm control={control} register={register} name="enterprise_features" />
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h2">Max users for free trial</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    {...register('max_free_trial_users', { valueAsNumber: true })}
                    label="Max Users"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={4}>
                  <Typography variant="h2">Days before blocking</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    {...register('days_before_blocking', { valueAsNumber: true })}
                    label="Days Before Blocking"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={4}>
                  <Typography variant="h2">Free trial days</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    {...register('free_trial_days', { valueAsNumber: true })}
                    label="Free Trial Days"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="flex-end">
                    <ButtonOrange onClick={handleSubmit(onSubmit)} size="large">
                      Save
                    </ButtonOrange>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Box>
        </PageSection>
      )}
    </div>
  )
}

const StyledTextField = styled(TextField)`
  flex: 1;
`

export default StripeSettings
