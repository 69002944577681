import { Agent, Company } from '@reaction-club-types/core'
import types from './types'

const agentActions = {
  set: (agent: Agent | Agent[]) => ({ type: types.SET, payload: agent }),
  setList: (list: Agent[]) => ({ type: types.SET_LIST, payload: list }),
  setListNext: (list: Agent[]) => ({ type: types.SET_LIST_NEXT, payload: list }),
  setCompany: (params: { agent_id: string; company: Company | null }) => ({
    type: types.SET_COMPANY,
    payload: params,
  }),
  remove: (agent_id: string) => ({ type: types.REMOVE, payload: agent_id }),
}

export default agentActions
