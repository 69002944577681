import React from 'react'
import { WithStyles } from 'react-jss'
import { Avatar } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux'
import _ from 'lodash'
import AssetImage, { ImageAssetProps } from './types/AssetImage'

const styles = {
  container: {
    width: 40,
    height: 40,
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    fontSize: '1.25rem',
    alignItems: 'center',
    flexShrink: 0,
    fontFamily: 'Open Sans, sans-serif',
    lineHeight: 1,
    userSelect: 'none',
    borderRadius: '50%',
    justifyContent: 'center',
  },
}

interface Props {
  containerStyles: React.CSSProperties
}

function ReactionAvatar(props: Props & ImageAssetProps & WithStyles<typeof styles>) {
  if (_.isEmpty(props.asset)) {
    return <Avatar style={props.containerStyles} src={''} />
  }

  return (
    <Avatar style={props.containerStyles}>
      <AssetImage {...props} />
    </Avatar>
  )
}

export default compose(React.memo, withStyles(styles as any))(ReactionAvatar) as any
