import types from './actionTypes'

export default {
  set: (coaches: Coach[]): ReduxAction => ({ type: types.SET, payload: coaches }),
  setNext: (coaches: Coach[]): ReduxAction => ({ type: types.SET_NEXT, payload: coaches }),
  setName: (name: string): ReduxAction => ({ type: types.SET_NAME, payload: name }),
  setCoordinates: (coordinates): ReduxAction => ({ type: types.SET_COORDINATES, payload: coordinates }),
  setCount: (count: number): ReduxAction => ({ type: types.SET_TOTAL_COUNT, payload: count }),
  deletePlaybook: (playbook_id: string): ReduxAction => ({ type: types.DELETE, payload: playbook_id }),
}
