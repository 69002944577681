import { Modal, Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Coach } from '@reaction-club-types/core'
import CoachesPageDispatcher from '@reducers/coachesPage/dispatcher'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../typings/ReduxExtractor'
import { ExtractStyles } from '../../typings/stylesExtractor'
import ScrollView from '../components/ScrollView'
import { RouteParams } from '../pages/PlayBooks/PlaybookPage'
import CoachItem from './coachesSelect/CoachItem'

const connectStyles = withStyles(
  {
    container: {
      backgroundColor: '#fff',
      width: 400,
      margin: '30px auto',
      padding: '1rem 0',
      borderRadius: 8,
      outline: 'none',
    },

    input: {
      border: `1px solid #aaa`,
      padding: '0.5rem 1rem',
      borderRadius: 8,
      color: '#323232',
      outline: 'none',
    },

    headerContainer: {
      padding: '0.3rem 1rem',
    },
  },
  {
    classNamePrefix: 'CoachSelectModal',
  },
)

interface Props {
  isVisible: boolean
  selectedList: string[]
  onSelect(coach: Coach, isChecked: boolean): any
  onClose(): void
}

interface PropsInner
  extends Props,
    ExtractStyles<typeof connectStyles>,
    ExtractConnectType<typeof connectStore>,
    RouteComponentProps<RouteParams> {}

interface State {
  isLoading: boolean
  isLoadingNext: boolean
}

class CoachSelectModal extends React.PureComponent<PropsInner, State> {
  scrollRef

  state: State = {
    isLoading: false,
    isLoadingNext: false,
  }

  render() {
    const { isVisible, onClose, name, classes, coaches, total_count, selectedList, onSelect } = this.props

    let coachesFiltered = coaches
    if (name) {
      coachesFiltered = _.filter(coachesFiltered, coach => coach.name.toLowerCase().includes(name))
    }

    return (
      <Modal open={isVisible} onClose={onClose}>
        <div className={classes.container}>
          <ScrollView ref={ref => (this.scrollRef = ref)} offset={0}>
            <InfiniteScroll
              pageStart={0}
              loadMore={this.handleLoadMore}
              hasMore={total_count > coaches.length}
              loader={
                <Grid container justifyContent="center">
                  <Loader type={'ThreeDots'} color={'#332323'} height={20} width={20} />
                </Grid>
              }
              getScrollParent={() => this.scrollRef}
              useWindow={false}
              key={'coaches-select-modal-inifinite'}
            >
              <div key={'coaches-select-modal'}>
                <Grid
                  container
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  className={classes.headerContainer}
                >
                  <Typography variant={'h4'}>Select Coach: </Typography>
                  <input
                    value={name}
                    type={'text'}
                    className={classes.input}
                    placeholder={'Coach name'}
                    onChange={this.handleChangeCoachName}
                  />
                </Grid>
                {_.map(coachesFiltered, coach => (
                  <CoachItem
                    key={`select-coach-item-${coach.user_id}`}
                    coach={coach}
                    isSelected={_.includes(selectedList, coach.user_id)}
                    onSelect={onSelect}
                  />
                ))}
              </div>
            </InfiniteScroll>
          </ScrollView>
        </div>
      </Modal>
    );
  }

  handleChangeCoachName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value.toLowerCase()
    this.props.setName(name)
    this.getCoachesDebounced()
  }

  handleGetCoaches = () => {
    this.setState({
      isLoading: true,
    })

    this.props.getCoaches().finally(() =>
      this.setState({
        isLoading: false,
      }),
    )
  }

  handleLoadMore = _.throttle(
    async () => {
      if (this.state.isLoading) return null
      if (this.state.isLoadingNext) return null

      this.state.isLoadingNext = true

      try {
        await this.props.getCoachesNext()
      } finally {
        this.setState({
          isLoadingNext: false,
        })
      }
    },
    1000,
    { maxWait: 300 },
  )

  getCoachesDebounced = _.debounce(this.handleGetCoaches, 1000, { maxWait: 300 })

  componentDidMount(): void {
    this.handleGetCoaches()
  }
}

const connectStore = connect(
  (state: ReduxState, { match }: Props) => ({
    total_count: state.coachesPage.total_count,
    name: state.coachesPage.name,
    coaches: state.coachesPage.list,
  }),
  (dispatch: ReduxDispatch) => ({
    setName: (text: string) => dispatch(CoachesPageDispatcher.actions.setName(text)),
    getCoaches: () => dispatch(CoachesPageDispatcher.getCoaches()),
    getCoachesNext: () => dispatch(CoachesPageDispatcher.getCoachesNext()),
  }),
)

export default compose<PropsInner, Props>(withRouter, connectStore, connectStyles)(CoachSelectModal)
