import _ from 'lodash'
import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import api from '../../libs/api/apiOld'
import challengeApplicationActions from '../challengeAppplications/actions'
import { actions } from './toolkit'

class Dispatcher {
  getChallengeApplications(filters: { name?: string }): DispatcherResponse {
    return async (dispatch, getState) => {
      const { challenge_applications }: { challenge_applications: any[]; total_count: number } =
        await api.getChallengeApplications({
          ...filters,
          offset: 0,
        })

      await dispatch(actions.setChallengeApplications(challenge_applications))
      _.forEach(challenge_applications, (company) => dispatch(challengeApplicationActions.set(company)))
    }
  }

  getChallengeApplicationsNext(filters: { name?: string }): DispatcherResponse {
    return async (dispatch, getState) => {
      const { list } = getState().challengeApplications
      const offset = list.length

      const { challenge_applications }: { challenge_applications } = await api.getChallengeApplications({
        ...filters,
        offset,
      })

      await dispatch(actions.setChallengeApplicationsNext(challenge_applications))
      _.forEach(challenge_applications, (company) => dispatch(challengeApplicationActions.set(company)))
    }
  }
}

const ChallengeApplicationPageDispatcher = new Dispatcher()
export default ChallengeApplicationPageDispatcher
