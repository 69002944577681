/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../typings/ReduxExtractor'
import { ExtractStyles } from '../../../typings/stylesExtractor'
import ScrollView from '../../components/ScrollView'
import CoachesDispatcher from '../../reducers/coaches/dispatcher'
import CoachesPageDispatcher from '../../reducers/coachesPage/dispatcher'
import ButtonOrange from '../tasks/components/ButtonOrange'
import CoachesFilter from './components/CoachesFilter'
import CoachesMap from './components/Location/CoachesMap'
import CoachCard from './list/CoachCard'

const connectStyles = withStyles({
  container: {
    paddingBottom: 30,
  },

  topContainer: {
    padding: '0 5px 30px 5px',
  },

  listContainer: {
    paddingTop: 5,
  },
})

interface State {
  isLoading: boolean
  isLoadingNext: boolean
  isMapActive: boolean
  error: string
}

interface Props {
  isChooseMode?: boolean
}

class CoachesPage extends React.PureComponent<
  Props & ExtractConnectType<typeof connectStore> & ExtractStyles<typeof connectStyles> & RouteComponentProps,
  State
> {
  scrollRef

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      error: '',
      isLoadingNext: false,
      isMapActive: false,
    }
  }

  render() {
    const { coaches, total_count, classes } = this.props
    const { error, isLoading, isMapActive } = this.state

    if (isLoading)
      return (
        <Grid container justifyContent="center">
          <Loader type={'CradleLoader'} color={'#332323'} height={100} width={100} />
        </Grid>
      )

    if (error)
      return (
        <Grid container direction={'column'} alignItems={'center'}>
          <Typography color={'error'} align={'center'}>
            Some error happen
          </Typography>
          <Typography color={'error'}>{error}</Typography>
        </Grid>
      )

    return (
      <ScrollView offset={35} ref={(ref) => (this.scrollRef = ref)}>
        <InfiniteScroll
          pageStart={0}
          loadMore={this.handleLoadMore}
          hasMore={total_count > coaches.length}
          loader={
            <Grid container justifyContent="center">
              <Loader type={'ThreeDots'} color={'#332323'} height={50} width={50} />
            </Grid>
          }
          getScrollParent={() => this.scrollRef}
          useWindow={false}
        >
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Grid container justifyContent={'center'} className={classes.container}>
                <Grid item sm={10}>
                  <Grid container justifyContent={'space-between'} className={classes.topContainer}>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      css={css`
                        margin-bottom: 10px;
                      `}
                    >
                      <CoachesFilter onFetch={this.handleFetchCoaches} />
                    </Grid>
                    <ButtonOrange onClick={this.handleToggleMap}>{isMapActive ? 'Hide map' : 'Show map'}</ButtonOrange>
                    <Typography variant={'body2'} align={'right'}>
                      total: {total_count}
                    </Typography>
                  </Grid>
                  {isMapActive ? <CoachesMap coaches={this.props.coaches} /> : null}
                  {this.renderCoachesList()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </InfiniteScroll>
      </ScrollView>
    )
  }

  renderCoachesList = () => {
    const { coaches, name, isChooseMode } = this.props
    let coachesFiltered = coaches
    if (name) {
      coachesFiltered = _.filter(coaches, (coach) => (coach.name || '').toLowerCase().includes(name.toLowerCase()))
    }
    return (
      <Grid container direction={'row'} justifyContent={'space-between'}>
        {coachesFiltered.map((coach) => (
          <CoachCard
            coach={coach}
            key={`coach-list-key-${coach.user_id}`}
            isChooseMode={isChooseMode}
            onChoose={this.handleChooseCoach}
          />
        ))}

        <Grid item xs={6} sm={3} />
        <Grid item xs={6} sm={3} />
        <Grid item xs={6} sm={3} />
        <Grid item xs={6} sm={3} />
      </Grid>
    )
  }

  handleChooseCoach = (coachId: string) => {
    const {
      addCoachToGroup,
      match: {
        params: { groupId },
      },
    } = this.props

    addCoachToGroup(coachId, groupId)
  }

  handleLoadMore = _.throttle(async () => {
    if (this.state.isLoading) return null
    if (this.state.isLoadingNext) return null

    this.setState({ isLoadingNext: true })

    try {
      await this.props.getCoachesNext()
    } finally {
      this.setState({
        isLoadingNext: false,
      })
    }
  }, 1000)

  handleFetchCoaches = async () => {
    this.props
      .getCoaches()
      .catch((error) => this.setState({ error: error.message }))
      .finally(() => this.setState({ isLoading: false }))
  }

  handleToggleMap = () => {
    const { isMapActive } = this.state
    this.setState({ isMapActive: !isMapActive })
  }

  componentDidMount() {
    this.handleFetchCoaches()
  }
}

const connectStore = connect(
  (state: ReduxState) => ({
    coaches: state.coachesPage.list,
    total_count: state.coachesPage.total_count,
    name: state.coachesPage.name,
  }),
  (dispatch: ReduxDispatch) => ({
    getCoaches: () => dispatch(CoachesPageDispatcher.getCoaches()),
    getCoachesNext: () => dispatch(CoachesPageDispatcher.getCoachesNext()),
    addCoachToGroup: (coach_id: string, instance_id: string) =>
      dispatch(CoachesDispatcher.addCoachToGroup(coach_id, instance_id)),
  }),
)

export default compose(connectStore, connectStyles)(CoachesPage)
