import _ from 'lodash'
import React from 'react'
import withStyles from '@mui/styles/withStyles';
import { WithStyles, Styles } from 'react-jss'
import { compose } from 'redux'
import { Checkbox, FormControl, FormControlLabel, Grid, Button, Typography, CircularProgress } from '@mui/material'

const styles: Styles = {
  permissionContainer: {
    margin: '10px 0 15px',
  },

  buttonContainer: {
    height: 40,
  },
}

interface State {
  isLoading: boolean
  permissions: string[]
  coach_id: string | null
}
interface Props {
  coach: Coach
  onChange(permissions: string[]): Promise<any>
}

function CustomCheckBox({ permissions, createHandleChangePermissions, permission, label }) {
  return (
    <FormControlLabel
      value={'rights'}
      labelPlacement={'end'}
      control={
        <Checkbox
          checked={_.includes(permissions, permission)}
          color={'secondary'}
          onChange={createHandleChangePermissions(permission)}
        />
      }
      label={label}
    />
  )
}

class CompanyCoachPermissions extends React.PureComponent<Props & WithStyles<typeof styles>, State> {
  state: State = {
    isLoading: false,
    coach_id: null,
    permissions: [],
  }

  static getDerivedStateFromProps(nextProps: Props, prevState) {
    const { coach } = nextProps
    if (coach.user_id === prevState.coach_id) return null

    return { permissions: coach.permissions || [], coach_id: coach.user_id }
  }

  render() {
    const { permissions } = this.state
    const { classes } = this.props

    return (
      <Grid container>
        <Grid item>
          <Typography variant={'h2'}>Company Permissions:</Typography>

          <div>
            <FormControl className={classes.permissionContainer}>
              <CustomCheckBox
                label={'Manage coaches'}
                createHandleChangePermissions={this.createHandleChangePermissions}
                permissions={permissions}
                permission={'MANAGE_COACHES'}
              />

              <CustomCheckBox
                label={'Invite employees'}
                createHandleChangePermissions={this.createHandleChangePermissions}
                permissions={permissions}
                permission={'INVITE_EMPLOYEE'}
              />

              <CustomCheckBox
                createHandleChangePermissions={this.createHandleChangePermissions}
                permissions={permissions}
                permission={'CREATE_GROUP'}
                label={'Create group'}
              />

              <CustomCheckBox
                createHandleChangePermissions={this.createHandleChangePermissions}
                permissions={permissions}
                permission={'EDIT_GROUP'}
                label={'Edit group'}
              />

              <CustomCheckBox
                createHandleChangePermissions={this.createHandleChangePermissions}
                permissions={permissions}
                permission={'DELETE_GROUP'}
                label={'Delete group'}
              />

            </FormControl>
          </div>

          <div className={classes.buttonContainer}>{this.renderButton()}</div>
        </Grid>
      </Grid>
    )
  }

  renderButton() {
    const { isLoading, permissions } = this.state
    const { coach } = this.props

    const isEqual = _.isEqual(coach.permissions || [], permissions)
    if (isEqual) return null

    return (
      <Button
        color={'primary'}
        variant={'contained'}
        size={'small'}
        onClick={this.handleClickSave}
        disabled={isLoading || isEqual}
      >
        {isLoading ? <CircularProgress color={'primary'} size={16} /> : 'Save'}
      </Button>
    )
  }

  handleClickSave = async () => {
    const { permissions } = this.state
    this.setState({ isLoading: true })
    try {
      await this.props.onChange(permissions)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  createHandleChangePermissions = (permission: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let { permissions } = this.state

    const isChecked = e.target.checked
    if (isChecked) permissions = permissions.concat(permission)
    else permissions = permissions.filter(type => type !== permission)

    this.setState({ permissions })
  }
}

export default compose(withStyles(styles))(CompanyCoachPermissions)
