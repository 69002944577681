import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import React, { MouseEventHandler, ReactNode } from 'react'

const useStyles = makeStyles(
  {
    button: {
      backgroundColor: '#b8b8b8',
      borderRadius: 50,
      fontWeight: 'bold',
      fontSize: 12,
      lineHeight: '22px',
      padding: '6px 20px',
      textTransform: 'initial',
      minWidth: 90,
    },
    small: {
      width: 45,
      fontSize: 10,
      padding: '3px 5px',
    },
    large: {
      width: 120,
      fontSize: 14,
    },
  },
  {
    classNamePrefix: 'ButtonOrange',
  },
)

interface Props {
  children: ReactNode
  color?: string
  size?: 'small' | 'mid' | 'large'
  onClick: MouseEventHandler<HTMLButtonElement>
  className?: string
  disabled?: boolean
}

function ButtonOrange(props: Props) {
  const { children, color, onClick, size, className = '', disabled } = props
  const classes = useStyles()

  let backgroundColor = color || '#F9B22D'
  if (disabled) backgroundColor = '#dfdfdf'

  const buttonStyle = {
    backgroundColor,
  }

  const buttonStyles: string[] = [className, classes.button]

  if (size === 'small') buttonStyles.push(classes.small)
  if (size === 'large') buttonStyles.push(classes.large)

  return (
    <Button onClick={onClick} className={buttonStyles.join(' ')} style={buttonStyle} disabled={disabled}>
      {children}
    </Button>
  )
}

export default ButtonOrange
