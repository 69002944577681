import invariant from 'invariant'
import _ from 'lodash'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import types from './types'

type State = Record<string, ReduxStateChallengeApplicationItem>

export interface ReduxStateChallengeApplicationItem {
  challengeApplications: any[]
}

let initialState: State = {}

export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case types.SET: {
      let challengeApplications = action.payload
      if (!_.isArray(challengeApplications)) challengeApplications = [challengeApplications]

      _.forEach(challengeApplications, (app) => {
        let stateApp = state[app.id]
        if (stateApp) stateApp = stateApp.merge(app)
        else
          stateApp = SeamlessImmutable({
            ...app,
          })

        state = state.merge({
          [app.id]: stateApp,
        })
      })

      return state
    }
    case types.SET_ENABLE: {
      const { challenge_application_id, is_enabled } = action.payload

      let challengeApp = state[challenge_application_id]
      invariant(challengeApp, 'challenge application is not found')

      challengeApp = challengeApp.merge({
        is_enabled,
      })

      return state.merge({
        [challenge_application_id]: challengeApp,
      })
    }

    case types.SET_PUBLISHED: {
      const { challenge_application_id, is_published } = action.payload

      let challengeApp = state[challenge_application_id]
      invariant(challengeApp, 'challenge application is not found')

      challengeApp = challengeApp.merge({
        is_published,
      })

      return state.merge({
        [challenge_application_id]: challengeApp,
      })
    }

    case types.DELETE: {
      const { challenge_application_id } = action.payload as { challenge_application_id: string }

      return state.without(challenge_application_id)
    }

    default:
      return state
  }
}
