import React from 'react'
import { compose } from 'redux'
import { matchPath, RouteComponentProps, withRouter } from 'react-router'
import { Avatar, Grid, ListItem, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles';
import { Styles } from 'jss'
import { Link } from 'react-router-dom'
import { WithStyles } from 'react-jss'
import classNames from 'classnames'

const styles: Styles = {
  coachBody: {
    marginLeft: 10,
    flexGrow: 1,
  },

  link: {
    width: '100%',
  },

  coachListItem: {
    paddingLeft: 15,
    paddingRight: 5,
    position: 'relative',
  },

  ownerText: {
    fontSize: 10,
    color: '#979797',
    fontWeight: 'bold',
  },
  coachListItemActive: {
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 2,
      top: 0,
      bottom: 0,
      right: 0,
      backgroundColor: '#F9B22D',
    },
  },
}

interface Props extends RouteComponentProps<{ id: string }> {
  instance: PlaybookInstance
}

function InstanceListItem(props: Props & WithStyles<typeof styles>) {
  const { instance, classes, match, history, location } = props
  const { id, name, employee_count, asset } = instance
  const avatar_url = asset ? asset.url : ''

  const { pathname } = location
  const locMatch = matchPath<{ instance_id: string }>(pathname, `${match.url}/instances/:instance_id`)

  let isActive = false
  if (locMatch) isActive = locMatch.params.instance_id === id

  return (
    <Link to={`${match.url}/instances/${id}`} className={classes.link}>
      <ListItem
        button
        component="div"
        disableGutters
        className={classNames({
          [classes.coachListItem]: true,
          [classes.coachListItemActive]: isActive,
        })}
      >
        <Grid container alignItems={'center'} direction={'row'}>
          <Avatar src={avatar_url} variant={'circular'} />
          <div className={classes.coachBody}>
            <Typography variant={'body1'}>{name}</Typography>
          </div>

          {<span className={classes.ownerText}>{employee_count} emp.</span>}
        </Grid>
      </ListItem>
    </Link>
  )
}

export default compose(React.memo, withRouter, withStyles(styles))(InstanceListItem)
