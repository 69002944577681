import React from 'react'
import { Button, Grid, Paper, TextField, Typography } from '@mui/material'
import connect from 'react-redux/es/connect/connect'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import PlaybookTypesActionDispatcher from '../../../reducers/playbookTypes/actionDispatcher'
import NotificationActionDispatcher from '../../../reducers/notification/actionDispatcher'
import CompanyActionDispatcher from '../../../reducers/company/actionDispatcher'
import { withStyles } from '@mui/styles';

const styles = {
  createPlaybookContainer: {
    padding: 15,
  },
  textField: {
    width: '100%',
  },
  imagePreview: {
    width: 150,
  },
}

@connect(
  (state, props) => ({}),
  dispatch => ({
    createPlaybookType: PlaybookTypesActionDispatcher(dispatch).addPlaybookType,
    addErrorNotify: NotificationActionDispatcher(dispatch).addErrorNotify,
    addSuccessNotify: NotificationActionDispatcher(dispatch).addSuccessNotify,
    uploadImg: CompanyActionDispatcher(dispatch).uploadImg,
  }),
)
@withRouter
@withStyles(styles)
export default class CreatePlaybookType extends React.Component {
  state = {
    name: '',
    color: '',
    showPreview: false,
    imagePreview: '',
    logoFile: '',
    fileNamePrev: '',
    description: '',
  }

  render() {
    const { classes } = this.props
    return (
      <Grid container justifyContent="center">
        <Grid item xs={8}>
          <Paper className={classes.createPlaybookContainer}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <Typography variant="title">Create playbook type</Typography>
              </Grid>
              <Grid item xs={5}>
                <Grid container>
                  <Grid item xs={12}>
                    {this.state.showPreview && this.state.logoFile ? (
                      <img className={classes.imagePreview} src={this.state.imagePreview} />
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.textField}
                      margin="dense"
                      name="name"
                      value={this.state.name}
                      onChange={this.changeTextField}
                      variant="outlined"
                      label="Name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="color"
                      value={this.state.color}
                      onChange={this.changeTextField}
                      margin="dense"
                      className={classes.textField}
                      variant="outlined"
                      label="Color"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      value={this.state.description}
                      onChange={this.changeTextField}
                      margin="dense"
                      className={classes.textField}
                      variant="outlined"
                      multiline
                      label="Description"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {/* <Typography className="previewName">{this.state.showPreview ? this.state.fileNamePrev : ''}</Typography> */}
                    <Button onClick={this.onLogoChange} color="secondary" size="small" variant="outlined">
                      {' '}
                      Select file
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" size="small" color="secondary" onClick={this.createPlaybookType}>
                  {' '}
                  Create{' '}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  onLogoChange = async () => {
    let input = document.createElement('input')
    input.type = 'file'
    let file = new FormData()
    let logo
    input.addEventListener('change', async event => {
      this.setState({
        fileNamePrev: event.target.files[0].name,
        imagePreview: URL.createObjectURL(event.target.files[0]),
      })
      file.append('file', event.target.files[0])
      try {
        this.props.addSuccessNotify({ message: 'Upload image started' })
        logo = await this.props.uploadImg(file, {
          onUploadProgress: event => {
            this.setState({ progress: (event.loaded / event.total) * 100 })
            if (this.state.progress === 100) {
              this.setState({ showPreview: true })
            }
          },
        })
        this.props.addSuccessNotify({ message: 'Upload image finished' })
        if (logo) {
          this.setState({ logoFile: logo.url })
        }
      } catch (e) {
        console.warn(e)
        this.props.addErrorNotify({ message: 'Upload image is failed !' })
      }
    })
    input.click()
  }

  createPlaybookType = async () => {
    const { history } = this.props
    let playbookType = {
      name: this.state.name,
      color: this.state.color,
      description: this.state.description,
    }

    if (!_.isEmpty(this.state.logoFile)) {
      playbookType['thumb'] = this.state.logoFile
    } else {
      this.props.addErrorNotify({ message: 'Upload image is failed !' })
    }
    try {
      await this.props.createPlaybookType(playbookType)
      this.props.addSuccessNotify({ message: 'Playbook is created !' })
    } catch (e) {
      console.warn(e)
      this.props.addErrorNotify({ message: 'Create playbook is failed !' })
    }
    history.goBack()
  }

  changeTextField = event => {
    this.setState({ [event.target.name]: event.target.value })
  }
}
