/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { Grid } from '@mui/material'
import React from 'react'
import RedditTextField from '../../../elements/form/RedditTextField'

interface Props {
  onChangeName(value: string): any
  onChangePhone(value: string): any
  onChangeEmail(value: string): any
}

function EmployeeListFilter(props: Props) {
  const { onChangeName, onChangePhone, onChangeEmail } = props

  const handleInputChange = (e) => {
    onChangeName(e.target.value || '')
  }

  const handlePhoneChange = (e) => {
    onChangePhone(e.target.value || '')
  }

  const handleEmailChange = (e) => {
    onChangeEmail(e.target.value || '')
  }

  return (
    <Grid container spacing={3} alignItems={'center'}>
      <Grid item>
        <FilterAltIcon
          css={css`
            color: #c2c2c2;
            font-size: 20px;
          `}
        />
      </Grid>

      <Grid item>
        <RedditTextField label={'Name'} onChange={handleInputChange} />
      </Grid>

      <Grid item>
        <RedditTextField label={'Phone'} onChange={handlePhoneChange} />
      </Grid>

      <Grid item>
        <RedditTextField label={'Email'} onChange={handleEmailChange} />
      </Grid>
    </Grid>
  )
}

export default EmployeeListFilter
