/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Avatar, Grid, List, Typography } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useHistory } from 'react-router-dom'
import { ReduxDispatch } from '../../../../../../typings/ReduxDispatch'
import Loader from '../../../../../components/Loader'
import PlaybookInstanceDispatcher from '../../../../../reducers/playbookInstances/dispatcher'
import GroupDetailPlaybook from './GroupDetailPlaybook'

interface Props {
  group_id: string
}

function GroupDetails(props: Props) {
  const { group_id } = props

  const history = useHistory()
  const dispatch = useDispatch<ReduxDispatch>()
  const fetchEmployees = useAsyncFetch(async () => {
    if (!group_id) return null
    await dispatch(PlaybookInstanceDispatcher.getEmployees(group_id))
  }, [group_id])

  const { group, groupEmployees } = useSelector((state: ReduxState) => ({
    group: state.playbookInstances[group_id],
    groupEmployees: _.filter(state.trainees, { company_playbook_id: group_id }),
  }))

  if (!group) return <Typography>Group is not found</Typography>

  const thumb =
    _.get(group, 'asset.sources[0].url') ||
    _.get(group, 'thumb') ||
    _.get(group, 'playbook.asset.url') ||
    _.get(group, 'playbook.thumb') ||
    ''

  return (
    <Grid
      container
      css={css`
        padding: 0 30px;
      `}
    >
      <Grid item>
        <Grid container>
          <Grid item>
            <Avatar
              css={css`
                width: 100px;
                height: 100px;
              `}
              src={thumb}
            />
          </Grid>
          <Grid item>
            <Grid container>
              <Typography variant={'h1'}>{group.name}</Typography>
              <Grid container alignItems={'center'}>
                <List>
                  <GroupDetailPlaybook group={group} />
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 10 }}>
          <Grid item>
            <Typography variant={'h2'}>Employees:</Typography>

            <RendererStatusSplit
              statuses={fetchEmployees}
              isEmpty={_.isEmpty(groupEmployees)}
              renderEmpty={() => <Typography>Group does not have a employee</Typography>}
              renderLoading={() => <Loader />}
              render={() => (
                <List component="nav" style={{ maxHeight: 400, overflow: 'auto' }}>
                  {_.map(groupEmployees, (employee) => (
                    <ListItem
                      key={employee.user_id}
                      component="a"
                      onClick={() => history.push(`/employee/${employee.user_id}`)}
                    >
                      <ListItemIcon style={{ marginRight: 10 }}>
                        <Avatar src={employee.avatar_url} />
                      </ListItemIcon>
                      <ListItemText primary={employee.name} />
                    </ListItem>
                  ))}
                </List>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default GroupDetails
