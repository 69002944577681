/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Paper } from '@mui/material'
import React, { ReactElement } from 'react'
import ProfileHeader from './ProfileHeader'

interface Props {
  children: ReactElement | ReactElement[]
  className?: string
}
function PaperContainer(props: Props) {
  const { className, children } = props

  return (
    <Grid container justifyContent="center">
      <ProfileHeader />
      <Grid xs={12} item>
        <Paper
          css={css`
            padding: 50px 50px;
            margin: 0 50px 50px 50px;
            //min-height: calc(100vh - 85px);

            @media (max-width: 520px) {
              margin: 0;
              padding: 10px;
            }
          `}
          className={className}
        >
          {children}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default PaperContainer
