import { PeopleAlt as PeopleIcon, MenuBook as MenuBookIcon } from '@mui/icons-material'
import { Typography, CardActionArea, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Company } from '@reaction-club-types/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import CompanyLogo from '../../../components/company/CompanyLogo'

const useStyles = makeStyles(
  {
    card: {
      padding: 5,
      borderRadius: 8,
      overflow: 'hidden',
      position: 'relative',
    },

    container: {
      position: 'relative',
      zIndex: 100,
      textAlign: 'center',
    },

    title: {
      fontSize: '1rem',
      lineHeight: '1.2rem',
      margin: '4px 0',
      fontWeight: 'bold',
    },

    icon: {
      color: '#8f918f',
    },

    employeeCount: {
      marginLeft: 5,
      fontSize: 12,
      color: '#282828',
    },
  },
  {
    classNamePrefix: 'CompanyCard',
  },
)

interface Props {
  company: Company
}
function CompanyCard(props: Props) {
  const { company } = props
  const { id, name, logo, employee_count, instance_count, created_at } = company

  const classes = useStyles()

  const history = useHistory()
  const href = history.createHref({
    pathname: `/company/${id}`,
  })

  return (
    <Grid item xs={4} sm={4} md={3} lg={2}>
      <CardActionArea href={href} className={classes.card}>
        <Grid className={classes.container} container direction={'column'} alignItems={'center'}>
          <CompanyLogo company={company} />

          <Typography className={classes.title}>{name || '-'}</Typography>

          <Grid container justifyContent={'center'} spacing={2}>
            <Grid item title={'Groups'}>
              <Grid container alignItems={'center'}>
                <MenuBookIcon className={classes.icon} fontSize={'small'} />
                <span className={classes.employeeCount}>{instance_count || ' - '}</span>
              </Grid>
            </Grid>
            <Grid item title={'Members'}>
              <Grid container alignItems={'center'}>
                <PeopleIcon className={classes.icon} fontSize={'small'} />
                <span className={classes.employeeCount}>{employee_count || ' - '}</span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActionArea>
    </Grid>
  )
}

export default CompanyCard
