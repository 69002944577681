import { Agent, Company, UserContacts } from '@reaction-club-types/core'
import invariant from 'invariant'
import _ from 'lodash'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import { setItemOrArray } from '../../libs/reducerUtils'
import types from './types'

interface State {
  [key: string]: ReduxStateAgent
}

const initialState: State = {
  /* { id: Agent } */
}

export interface ReduxStateAgent extends Agent, StoreUpdatedObject {
  contacts?: UserContacts
  company?: Company
}

export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case types.SET: {
      const data: Agent | Agent[] = action.payload
      return setItemOrArray(state, data, { idField: 'user_id' })
    }

    case types.SET_COMPANY: {
      const { agent_id, company } = action.payload

      let stateItem = state[agent_id]
      invariant(stateItem, 'agent is not found')

      stateItem = stateItem.merge({
        company,
      })

      return state.merge({
        [agent_id]: stateItem,
      })
    }

    case types.REMOVE: {
      const agent_id = action.payload
      return state.without(agent_id)
    }

    default:
      return state
  }
}
