import { makeStyles } from '@mui/styles'
import React from 'react'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import PageSection from '../../../layout/PageSection'
import CompanyDispatcher from '../../../reducers/companies/dispatcher'
import CompanyPreference from './components/CompanyPreference'

const useStyles = makeStyles({
  contentContainer: {
    padding: 20,
  },
})

interface Props {
  company: Company
}

interface PropsInner extends Props {}

function CompanyPreferences(props: PropsInner) {
  const { company } = props
  const { is_coffee_enabled = false, is_groups_opened } = company.preferences || {}
  const dispatch = useDispatch<ReduxDispatch>()
  const classes = useStyles()

  const handleChangeCoffee = async (payload: { status: boolean }) => {
    await dispatch(CompanyDispatcher.setPreferenceCoffeeCorner(company.id, payload))
  }

  const handleChangeGroupsOpened = async (payload: { status: boolean }) => {
    await dispatch(CompanyDispatcher.setPreferenceGroupsOpened(company.id, payload))
  }

  return (
    <PageSection title={'Preferences'}>
      <div className={classes.contentContainer}>
        <CompanyPreference title={'Coffee Corner:'} isTrue={is_coffee_enabled} onChange={handleChangeCoffee} />
        <CompanyPreference title={'Groups are opened:'} isTrue={is_groups_opened} onChange={handleChangeGroupsOpened} />
      </div>
    </PageSection>
  )
}

export default CompanyPreferences
