/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { UserDevice } from '@reaction-club-types/core'
import React from 'react'
import ModalStyled from '../../../../elements/ModalStyled'
import DeviceDiagnosticInfo from './device/DeviceDiagnosticInfo'

interface Props {
  isVisible: boolean
  onClose(): any
  device: UserDevice
}
export default function DeviceInfoModal(props: Props) {
  const { isVisible, onClose, device } = props

  return (
    <ModalStyled
      isVisible={isVisible}
      onClose={onClose}
      showCloseButton
      css={css`
        width: 90%;
        height: 90%;
        min-width: 360px;
        min-height: 500px;
        max-width: 1000px;
        overflow-y: auto;
      `}
    >
      <DeviceDiagnosticInfo device={device} />
    </ModalStyled>
  )
}
