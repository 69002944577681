import keyManager from 'constant-manager'

export default keyManager(
  {
    SET_COACH: '[SET_COACH]: Set detail information about coach',
    SET_DEVICES: '[SET_DEVICES]: Set list coach devices',
    EMPTY_COACH_DEVICES: '[EMPTY_COACH_DEVICES]: Delete coach devices',
    DROP_ROAD: '[DROP_ROAD]: Delete one coach road',
    ADD_ROAD: '[ADD_ROAD]: Add one coach road',
    SET_COACH_EMPLOYEES: '[SET_COACH_EMPLOYEES]: Add employee assigned to coach',
    SET_COACH_COMPANIES: '[SET_COACH_COMPANIES]: Add assigned company',
    SET_COACH_PLAYBOOK: '[COACH]: Set coach playbook',
  },
  { prefix: 'Coach__' },
)
