/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Paper, Typography } from '@mui/material'
import Button from '@mui/material/Button/Button'
import Grid from '@mui/material/Grid'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React, { useRef, useState } from 'react'
import { useAsyncFetch, useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useHistory } from 'react-router-dom'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import Loader from '../../components/Loader'
import ReactionLoader from '../../components/ReactionLoader'
import ScrollView from '../../components/ScrollView'
import useExportCompanies from '../../hooks/useExportCompanies'
import useModalState from '../../hooks/useModalState'
import ScrollElement from '../../libs/infinite-scroll/ScrollElement'
import CompanyPageDispatcher from '../../reducers/companiesPage/dispatcher'
import CreateTestCompanyModal from './company/modals/CreateTestCompanyModal'
import CompaniesFilter from './components/CompaniesFilter'
import CompanyCard from './components/CompanyCard'

function CompanyList() {
  const [withClub, setWithClub] = useState(false)
  const scrollRef = useRef()
  const history = useHistory()
  const [name, setName] = useState('')
  const exportCSV = useExportCompanies()
  const createTestCompanyModalState = useModalState()

  const createHref = history.createHref({ pathname: '/add-company' })

  const { companies, listIsNextBlocked } = useSelector((state: ReduxState) => ({
    companies: state.companiesPage.list,
    listIsNextBlocked: state.companiesPage.listIsNextBlocked,
  }))

  const createTestCompany = () => {
    createTestCompanyModalState.onOpen()
  }

  const dispatch = useDispatch<ReduxDispatch>()
  const fetchInitial = useAsyncFetch(
    async function companyFetchInitial() {
      await dispatch(CompanyPageDispatcher.getCompanies({ name }))
    },
    [name],
  )

  const loadMoreAction = useAsyncHandler(async function companiesFetchNext() {
    if (fetchInitial.isLoading) return null
    await dispatch(CompanyPageDispatcher.getCompaniesNext({ name }))
  })

  return (
    <ScrollView offset={35} ref={scrollRef}>
      <CreateTestCompanyModal {...createTestCompanyModalState} />
      <ScrollElement
        getNext={() => loadMoreAction.execute()}
        getContainerRef={() => scrollRef.current}
        isNextExists={!listIsNextBlocked}
        isLoading={fetchInitial.isLoading}
        renderLoading={() => (
          <Grid container justifyContent={'center'}>
            <Loader />
          </Grid>
        )}
      >
        <Grid container justifyContent="center" key={'infinite-scroll-companies'}>
          <Grid item xs={12} sm={10}>
            <Paper>
              <Grid
                container
                justifyContent={'center'}
                css={css({
                  padding: '30px 0',
                })}
              >
                <Grid item xs={10} sm={10}>
                  <Grid container justifyContent={'space-between'} css={css({ paddingBottom: 30 })}>
                    <Grid item sm={8} xs={12}>
                      <CompaniesFilter
                        // onFetch={handleFetchCompanies}
                        onClubToggle={() => setWithClub(!withClub)}
                        onChangeName={setName}
                        // withClub={this.state.withClub}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} container justifyContent={'flex-end'} alignItems={'flex-start'}>
                      <Button
                        onClick={exportCSV}
                        variant={'contained'}
                        color={'primary'}
                        css={css({ marginRight: 20 })}
                      >
                        Export CSV
                      </Button>
                      <Button
                        onClick={createTestCompany}
                        variant={'contained'}
                        color={'primary'}
                        css={css({ marginRight: 20 })}
                      >
                        Create New Test Company
                      </Button>
                      <Button href={createHref} variant={'contained'} color={'primary'}>
                        Create New
                      </Button>
                    </Grid>
                  </Grid>

                  {/* <Typography variant={'body2'} align={'right'}> */}
                  {/*  total: {total_count} */}
                  {/* </Typography> */}
                  <Grid
                    container
                    spacing={4}
                    justifyContent={'space-between'}
                    css={css({
                      paddingTop: 5,
                    })}
                  >
                    <RendererStatusSplit
                      statuses={fetchInitial}
                      isEmpty={_.isEmpty(companies)}
                      renderLoading={() => <ReactionLoader />}
                      renderError={(error) => (
                        <Grid container justifyContent="center">
                          <Typography color={'error'}>{error}</Typography>
                        </Grid>
                      )}
                      render={() => {
                        let companyFiltered = companies
                        if (name) {
                          const nameLow = name.toLowerCase()
                          companyFiltered = _.filter(companyFiltered, (company) =>
                            (company.name || '').toLowerCase().includes(nameLow),
                          )
                        }

                        if (!withClub) {
                          companyFiltered = _.filter(companyFiltered, (company) => !company.is_owned_by_coach)
                        }

                        return companyFiltered.map((company) => (
                          <CompanyCard company={company} key={`company-page-item-${company.id}`} />
                        ))
                      }}
                    />

                    <Grid item xs={4} sm={4} md={3} lg={2} />
                    <Grid item xs={4} sm={4} md={3} lg={2} />
                    <Grid item xs={4} sm={4} md={3} lg={2} />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </ScrollElement>
    </ScrollView>
  )
}

export default CompanyList
