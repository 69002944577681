/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import {
  Paper,
  Typography,
} from '@mui/material'
import Button from '@mui/material/Button/Button'
import Grid from '@mui/material/Grid'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React, { useRef, useState } from 'react'
import { useAsyncFetch, useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useHistory } from 'react-router-dom'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import Loader from '../../components/Loader'
import ReactionLoader from '../../components/ReactionLoader'
import ScrollView from '../../components/ScrollView'
import ScrollElement from '../../libs/infinite-scroll/ScrollElement'
import ChallengeTypesPageDispatcher from '../../reducers/challengeTypesPage/dispatcher'
import ChallengeTypeCard from './components/ChallengeTypeCard'
import ChallengeTypeFilter from './components/ChallengeTypeFilter'
import ProfileHeader from '../../components/ProfileHeader'

function ChallengeTypesList() {
  const scrollRef = useRef()
  const history = useHistory()
  const [name, setName] = useState('')

  const createHref = history.createHref({ pathname: '/add-challenge-type' })

  const { challenge_types, listIsNextBlocked } = useSelector((state: ReduxState) => ({
    challenge_types: state.challengeTypesPage.list,
    listIsNextBlocked: state.challengeTypesPage.listIsNextBlocked,
  }))

  const dispatch = useDispatch<ReduxDispatch>()
  const fetchInitial = useAsyncFetch(
    async function challengeAppsFetchInitial() {
      await dispatch(ChallengeTypesPageDispatcher.getChallengeTypes({ name }))
    },
    [name],
  )

  const loadMoreAction = useAsyncHandler(async function companiesFetchNext() {
    if (fetchInitial.isLoading) return null
    await dispatch(ChallengeTypesPageDispatcher.getChallengeTypesNext({ name }))
  })

  const renderList = () => {
    return (
      <Grid container direction={'column'} css={css({ paddingTop: 30 })}>
        <Grid item css={css({ backgroundColor: '#F9B22D' })}>
          <Grid container direction={'row'} alignItems={'center'}>
            <Grid xs={1}>
              <Typography variant={'h2'} textAlign={'center'}>
                №
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography variant={'h2'} textAlign={'center'}>
                Label
              </Typography>
            </Grid>
            <Grid xs={5}>
              <Typography variant={'h2'} textAlign={'center'}>
                Name
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {challenge_types.map((type, index) => (
          <ChallengeTypeCard type={type} index={index + 1} key={`challenge-type-page-item-${type.id}`} />
        ))}
      </Grid>
    )
  }

  return (
    <ScrollView offset={35} ref={scrollRef}>
      <ScrollElement
        getNext={() => loadMoreAction.execute()}
        getContainerRef={() => scrollRef.current}
        isNextExists={!listIsNextBlocked}
        isLoading={fetchInitial.isLoading}
        renderLoading={() => (
          <Grid container justifyContent={'center'}>
            <Loader />
          </Grid>
        )}
      >
        <Grid container justifyContent="center" key={'infinite-scroll-challenge-types'}>
          <Grid item xs={10} sm={8}>
            <ProfileHeader />
            <Paper>
              <Grid
                container
                justifyContent={'center'}
                css={css({
                  padding: '30px 0',
                })}
              >
                <Grid item xs={8} sm={8}>
                  <Grid container justifyContent={'space-between'} css={css({ paddingBottom: 30 })}>
                    <Grid item sm={8} xs={12}>
                      <ChallengeTypeFilter onChangeName={setName} />
                    </Grid>
                    <Grid item xs={12} sm={4} gap={2} container justifyContent={'flex-end'} alignItems={'flex-start'}>
                      <Button href={createHref} variant={'contained'} color={'primary'}>
                        Create New
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    flexDirection={'column'}
                    spacing={4}
                    justifyContent={'space-between'}
                    css={css({
                      paddingTop: 5,
                    })}
                  >
                    <RendererStatusSplit
                      statuses={fetchInitial}
                      isEmpty={_.isEmpty(challenge_types)}
                      renderLoading={() => <ReactionLoader />}
                      renderError={(error) => (
                        <Grid container justifyContent="center">
                          <Typography color={'error'}>{error}</Typography>
                        </Grid>
                      )}
                      render={renderList}
                    />
                    <Grid item xs={4} sm={4} md={3} lg={2} />
                    <Grid item xs={4} sm={4} md={3} lg={2} />
                    <Grid item xs={4} sm={4} md={3} lg={2} />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </ScrollElement>
    </ScrollView>
  )
}

export default ChallengeTypesList
