import { prefix_web } from './apiOld'

export default {
  async getPrograms() {
    const result = await this.apiClient.get(`${prefix_web}programs`)
    return result.data
  },
  async createProgram(playBookId, programObj) {
    const result = await this.apiClient.post(`${prefix_web}playbook/${playBookId}/program`, programObj)
    return result.data
  },
  async updateProgram(programId, programObj) {
    const result = await this.apiClient.post(`${prefix_web}program/${programId}`, programObj)
    return result.data
  },
  async duplicateProgram(programId) {
    const result = await this.apiClient.post(`${prefix_web}program/` + `${programId}/duplicate`)
    return result.data
  },
  async applyProgram(programId) {
    return await this.apiClient.post(`${prefix_web}program/` + `${programId}/apply`)
  },
}
