import { Company } from '@reaction-club-types/core'
import { IPlan } from '@reducers/companies/types'
import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import api from '../../libs/api/apiOld'
import actions from './actions'
import apiCompanies, { CompanyCreateParams } from './api'

class Dispatcher {
  getCompanies(): DispatcherResponse {
    return async (dispatch) => {
      const companies = await api.getCompanies()
      dispatch(actions.set(companies))
    }
  }

  getCompany(id: string): DispatcherResponse<Company> {
    return async (dispatch) => {
      const company = await api.getCompanyById(id)
      dispatch(actions.set(company))
      return company
    }
  }

  createCompany(company: CompanyCreateParams): DispatcherResponse<string> {
    return async (dispatch) => {
      const response = await apiCompanies.createCompany(company)
      const { company_id } = response || {}
      if (company_id) {
        dispatch(this.getCompany(company_id))
      }
      return company_id
    }
  }

  updateCompany(company_id: string, payload: { asset_id?: string; name: string }) {
    return async (dispatch) => {
      await api.updateCompany(company_id, payload)
      dispatch(this.getCompany(company_id))
    }
  }

  updateCompanyWorkspace(company_id: string, payload: { workspace: string }) {
    return async (dispatch) => {
      await api.updateCompanyWorkspace(company_id, payload)
      dispatch(this.getCompany(company_id))
    }
  }

  getEmployees(company_id: string): DispatcherResponse {
    return async (dispatch) => {
      const employees: Employee[] = await api.getCompanyEmployees(company_id)
      dispatch(actions.setEmployees(company_id, employees))
    }
  }

  getCoaches(company_id: string): DispatcherResponse {
    return async (dispatch) => {
      const coaches: Coach[] = await api.getCompanyCoaches(company_id)
      dispatch(actions.setCoaches(company_id, coaches))
    }
  }

  getAgents(company_id: string): DispatcherResponse<Agent[]> {
    return async (dispatch) => {
      const agents: Agent[] = await apiCompanies.getCompanyAgents(company_id)
      dispatch(actions.setAgents(company_id, agents))
      return agents
    }
  }

  getCompanySubscriptionPlan(company_id: string): DispatcherResponse<IPlan> {
    return async (dispatch) => {
      const plan = await apiCompanies.getCompanySubscriptionPlan(company_id)
      dispatch(actions.setPlan(company_id, plan))
      return plan
    }
  }

  createCompanySubscriptionPlan(company_id: string, payload: IPlan): DispatcherResponse<IPlan> {
    return async (dispatch) => {
      const plan = await apiCompanies.createCompanySubscriptionPlan(company_id, payload)
      dispatch(actions.setPlan(company_id, plan))
      return plan
    }
  }

  updateCompanySubscriptionPlan(company_id: string, payload: Partial<IPlan>): DispatcherResponse<IPlan> {
    return async (dispatch) => {
      const plan = await apiCompanies.updateCompanySubscriptionPlan(company_id, payload)
      dispatch(actions.setPlan(company_id, plan))
      return plan
    }
  }

  changeCompanySubscriptionPlan(
    company_id: string,
    payload: Partial<{ subscriptionId: string }>,
  ): DispatcherResponse<any> {
    return async (dispatch) => {
      await apiCompanies.changeCompanySubscriptionPlan(company_id, payload)
    }
  }

  getStickers(company_id: string): DispatcherResponse {
    return async (dispatch) => {
      const stickers = await api.getStickers(company_id)
      dispatch(actions.setStickers(company_id, stickers))
    }
  }

  createSticker({ company_id, asset_id }: { company_id: string; asset_id: string }) {
    return async (dispatch) => {
      await api.createSticker({ company_id, asset_id })
      dispatch(this.getStickers(company_id))
    }
  }

  deleteSticker({ company_id, sticker_id }: { company_id: string; sticker_id: string }) {
    return async (dispatch) => {
      await api.deleteSticker({ company_id, sticker_id })
      dispatch(this.getStickers(company_id))
    }
  }

  createHRToken(company_id: string): DispatcherResponse<string> {
    return async (dispatch) => {
      const { token } = await api.createHRToken(company_id)
      return token
    }
  }

  saveCoachPermissions({
    coach_id,
    company_id,
    permissions,
  }: {
    company_id: string
    coach_id: string
    permissions: string[]
  }): DispatcherResponse {
    return async (dispatch) => {
      await api.saveCoachPermissions({ coach_id, company_id, permissions })
      dispatch(this.getCoaches(company_id))
    }
  }

  getInstances(company_id: string): DispatcherResponse<PlaybookInstance[]> {
    return async (dispatch) => {
      const instances: PlaybookInstance[] = await api.getCompanyInstances(company_id)
      dispatch(actions.setInstances(company_id, instances))
      return instances
    }
  }

  getInstanceCoaches(company_id: string, instance_id: string): DispatcherResponse {
    return async (dispatch) => {
      const coaches = await api.getInstanceCoaches(instance_id)
      dispatch(actions.setInstanceCoaches({ company_id, instance_id, coaches }))
    }
  }

  makeCoachPrimary({
    coach_id,
    instance_id,
    company_id,
  }: {
    company_id: string
    instance_id: string
    coach_id: string
  }): DispatcherResponse {
    return async (dispatch) => {
      await api.makeCoachPrimary({ instance_id, coach_id })
      dispatch(this.getInstanceCoaches(company_id, instance_id))
    }
  }

  resetPoints(company_id: string) {
    return async (dispatch) => {
      const response = await apiCompanies.resetPoints(company_id)
      console.log('company.resetPoints', response)
      dispatch(actions.resetPoints(company_id))
    }
  }

  setPreferenceCoffeeCorner(company_id: string, payload: { status: boolean }): DispatcherResponse {
    return async (dispatch) => {
      const response = await apiCompanies.setPreferenceCoffeeCorner(company_id, payload)
      console.log('setPreferenceCoffeeCorner', response)
      await dispatch(this.getCompany(company_id))
    }
  }

  setPreferenceGroupsOpened(company_id: string, payload: { status: boolean }): DispatcherResponse {
    return async (dispatch) => {
      const response = await apiCompanies.setPreferenceGroupsOpened(company_id, payload)
      console.log('setPreferenceGroupsOpened', response)
      await dispatch(this.getCompany(company_id))
    }
  }

  setConfigLiveEventType(company_id: string, payload: { type: 'webinar' | 'meeting' }): DispatcherResponse {
    return async (dispatch) => {
      await apiCompanies.setConfigEventType(company_id, payload)
      await dispatch(this.getCompany(company_id))
    }
  }

  setConfigPublicEventsEnabled(company_id: string, payload: { is_enabled: boolean }): DispatcherResponse {
    return async (dispatch) => {
      await apiCompanies.setConfigPublicEventsEnabled(company_id, payload)
      await dispatch(this.getCompany(company_id))
    }
  }

  addCountry(company_id: string, location: string): DispatcherResponse {
    return async (dispatch) => {
      await apiCompanies.addCountry(company_id, location)
      await dispatch(this.getCompany(company_id))
    }
  }

  removeCountry(company_id: string, location: string): DispatcherResponse {
    return async (dispatch) => {
      await apiCompanies.removeCountry(company_id, location)
      await dispatch(this.getCompany(company_id))
    }
  }

  delete(company_id: string): DispatcherResponse {
    return async (dispatch) => {
      await apiCompanies.deleteCompany(company_id)
      await dispatch(actions.deleteCompany(company_id))
    }
  }

  clearFeed(company_id: string): DispatcherResponse {
    return async (dispatch) => {
      await apiCompanies.clearFeed(company_id)
    }
  }

  createTestCompany(data): DispatcherResponse {
    return async (dispatch) => {
      await apiCompanies.createTestCompany(data)
    }
  }
}

const CompanyDispatcher = new Dispatcher()
export default CompanyDispatcher
