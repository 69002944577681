import types from './actionTypes'

export default {
  set: company => ({ type: types.SET, payload: company }),
  setCompanyCoaches: coaches => ({ type: types.SET_COMPANY_COACHES, payload: coaches }),
  setCompanyEmployees: employees => ({ type: types.SET_COMPANY_EMPLOYEES, payload: employees }),
  setCoachFilter: search => ({ type: types.FILTER_COACHES, payload: search }),
  setCompanyPlaybook: playbooks => ({ type: types.SET_COMPANY_PLAYBOOK, payload: playbooks }),
  addOnePlaybook: playbook => ({ type: types.ADD_PLAYBOOK, payload: playbook }),
  removePlaybook: playbook => ({ type: types.REMOVE_PLAYBOOK, payload: playbook }),
  setCompanyStickers: stickers => ({ type: types.SET_COMPANY_STICKERS, payload: stickers }),
}
