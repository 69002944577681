import _ from 'lodash'
import React from 'react'
import Select from 'react-select'
import { Props as SelectProps } from 'react-select/src/Select'
import FormLabel from '../../../../../elements/form/FormLabel'

const repeatOptions = [
  { value: '', label: 'None' },
  { value: 'week', label: 'Every week' },
  { value: 'month', label: 'Every month' },
]

interface Props {
  value: string
  onChange(type: string | undefined): any
}

export default function EventRepeatSelect(props: Props) {
  const { value, onChange } = props

  const handleChange: SelectProps['onChange'] = item => {
    onChange(item?.value)
  }

  const itemSelected: SelectProps['value'] = _.find(repeatOptions, { value }) || null

  return (
    <div>
      <FormLabel>Repeat</FormLabel>
      <Select value={itemSelected} onChange={handleChange} options={repeatOptions} />
    </div>
  )
}
