import React from 'react'
import { useHistory } from 'react-router-dom'
import ButtonOrange from '../../components/ButtonOrange'

function EventCreateButton() {
  const history = useHistory()

  const handlePress = () => {
    history.push('/event-create')
  }

  return (
    <div>
      <ButtonOrange onClick={handlePress}>Create</ButtonOrange>
    </div>
  )
}

export default EventCreateButton
