import apiEmployees, { ApiEmployeesListParams } from '@reducers/employees/api'
import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import { actions as employeeActions } from '../employees/reducer'
import { actions } from './toolkit'

class Dispatcher {
  getEmployees(filters: Omit<ApiEmployeesListParams, 'offset'>): DispatcherResponse {
    return async (dispatch) => {
      const data = await apiEmployees.getEmployees({ ...filters, offset: 0 })
      const { employees } = data

      await dispatch(actions.setEmployees(employees))
      dispatch(employeeActions.set(employees))
    }
  }

  getEmployeesNext(filters: Omit<ApiEmployeesListParams, 'offset'>): DispatcherResponse {
    return async (dispatch, getState) => {
      const { list } = getState().companiesPage
      const offset = list.length

      const data = await apiEmployees.getEmployees({ ...filters, offset })
      const { employees } = data

      await dispatch(actions.setEmployeesNext(employees))
      dispatch(employeeActions.set(employees))
    }
  }
}

const ListDispatcher = new Dispatcher()
export default ListDispatcher
