import { Grid } from '@mui/material'
import EmployeeDispatcher from '@reducers/employees/dispatcher'
import { ReduxStateEmployeesItem } from '@reducers/employees/reducer'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../../components/Loader'
import PageSection from '../../../layout/PageSection'
import StepsDailyItem from './components/StepsDailyItem'
import StepsTable from './components/StepsTable'

interface Props {
  employee: ReduxStateEmployeesItem
}
function EmployeeSteps(props: Props) {
  const { employee } = props
  const { dailySteps } = employee
  const dispatch = useDispatch()

  const dataFetch = useAsyncFetch(async () => {
    await dispatch(EmployeeDispatcher.getStepsDaily({ employee_id: employee.user_id }))
  })

  return (
    <PageSection title={'Steps'}>
      <div>
        <RendererStatusSplit
          statuses={dataFetch}
          isEmpty={_.isEmpty(dailySteps)}
          renderLoading={() => (
            <Grid container justifyContent={'center'}>
              <Loader />
            </Grid>
          )}
          renderEmpty={() => (
            <div>
              <span style={{ fontSize: 30 }}>-</span>
            </div>
          )}
          render={() => (
            <div>
              <StepsTable>
                {_.map(dailySteps, (item) => (
                  <StepsDailyItem item={item} key={`employee-steps-item-${item.date}`} />
                ))}
              </StepsTable>
            </div>
          )}
        />
      </div>
    </PageSection>
  )
}

export default EmployeeSteps
