import { Grid } from '@mui/material'
import EmployeeDispatcher from '@reducers/employees/dispatcher'
import { ReduxStateEmployeesItem } from '@reducers/employees/reducer'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../../components/Loader'
import PageSection from '../../../layout/PageSection'
import PointLogItem from './components/PointLogItem'
import PointsTable from './components/PointsTable'

interface Props {
  employee: ReduxStateEmployeesItem
}
function EmployeePoints(props: Props) {
  const { employee } = props
  const { pointLogs } = employee
  const dispatch = useDispatch()

  const dataFetch = useAsyncFetch(async () => {
    await dispatch(EmployeeDispatcher.getPointLogs({ employee_id: employee.user_id }))
  })

  return (
    <PageSection title={'Points'}>
      <div>
        <RendererStatusSplit
          statuses={dataFetch}
          isEmpty={_.isEmpty(pointLogs)}
          renderLoading={() => (
            <Grid container justifyContent={'center'}>
              <Loader />
            </Grid>
          )}
          renderEmpty={() => (
            <div>
              <span style={{ fontSize: 30 }}>-</span>
            </div>
          )}
          render={() => (
            <div>
              <PointsTable>
                {_.map(pointLogs, (item) => (
                  <PointLogItem pointLog={item} key={`employee-point-item-${item.created_at}`} />
                ))}
              </PointsTable>
            </div>
          )}
        />
      </div>
    </PageSection>
  )
}

export default EmployeePoints
