/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import {
  Apple as AppleIcon,
  Android as AndroidIcon,
  ContentCopy as CopyIcon,
  Info as InfoIcon,
} from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import { UserDevice } from '@reaction-club-types/core'
import moment from 'moment'
import React, { ReactElement, useMemo } from 'react'
import useClipboard from 'react-use-clipboard'
import { StyledTableCell, StyledTableRow } from '../../../../elements/table/TableDefault'
import useModalState from '../../../../hooks/useModalState'
import DeviceInfoModal from '../modals/DeviceInfoModal'

interface Props {
  device: UserDevice
}
export default function UserDeviceItem(props: Props) {
  const { device } = props
  const { system, system_version, app_version, last_login_at, device_id } = device
  const [isCopied, setCopied] = useClipboard(device_id)
  const infoModal = useModalState(false)

  let icon: ReactElement = useMemo(() => {
    if (!system) return null
    const systemLow = system.toLowerCase()
    if (systemLow === 'ios') return <AppleIcon />
    if (systemLow === 'android') return <AndroidIcon css={css({ color: '#5bd514', marginTop: 3 })} />

    return null
  }, [system])

  const lastLoginFmtd = useMemo(() => {
    if (!last_login_at) return ''
    return moment(last_login_at).format('LLL')
  }, [last_login_at])

  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell align="left">
          <Grid container alignItems={'center'}>
            <div
              css={css`
                margin-right: 5px;
              `}
            >
              {icon}
            </div>
            <Typography>
              {system}
              <span
                css={css`
                  display: inline-block;
                  margin-left: 5px;
                  font-size: 12px;
                  color: #696a6a;
                `}
              >
                ({system_version})
              </span>
            </Typography>
          </Grid>
        </StyledTableCell>

        <StyledTableCell component="th" scope="row">
          {device.brand}
        </StyledTableCell>
        <StyledTableCell align="left">{device.model}</StyledTableCell>
        <StyledTableCell align="left">{app_version}</StyledTableCell>
        <StyledTableCell align="right">{lastLoginFmtd}</StyledTableCell>
        <StyledTableCell align="right">
          <Grid container alignItems={'center'}>
            <InfoIcon
              onClick={() => infoModal.onOpen()}
              css={css`
                cursor: pointer;
                margin-right: 5px;
              `}
            />

            <CopyIcon
              onClick={() => setCopied()}
              css={css`
                cursor: pointer;
              `}
            />
          </Grid>
        </StyledTableCell>
      </StyledTableRow>

      <DeviceInfoModal {...infoModal} device={device} />
    </React.Fragment>
  )
}
