import { Grid, Typography, Avatar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Agent } from '@reaction-club-types/core'
import React from 'react'
import ItemCreatedInfo from '../../../components/items/ItemCreatedInfo'
import { OpenSans } from '../../../helpers/commonStyles'

const useStyles = makeStyles(
  {
    container: {
      cursor: 'pointer',
      backgroundColor: '#fff',
      borderRadius: 5,
      margin: '0 5px',
      overflow: 'hidden',
      marginBottom: 25,
      paddingBottom: 5,
      transition: 'box-shadow .3s',
      maxWidth: 180,
      boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.05)',
      '&:hover': {
        boxShadow: '0 0 11px rgba(33,33,33,.5)',
      },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    avatarContainer: {
      // backgroundColor: '#dedede',
      padding: '20px 50px',
    },
    avatar: {
      width: 80,
      height: 80,
    },
    name: {
      padding: '10px 10px',
      fontFamily: OpenSans,
      fontSize: 16,
      textAlign: 'center',
    },
    info: {
      fontSize: 12,
    },
  },
  {
    classNamePrefix: 'AgentListItem',
  },
)

interface Props {
  agent: Agent
  onOpen(agent: Agent): any
}

function AgentListItem(props: Props) {
  const { agent, onOpen } = props
  const { name, avatar_url, contacts } = agent
  const { email, phone_number } = contacts || {}

  const classes = useStyles()

  const handlePress = () => {
    onOpen(agent)
  }

  return (
    <Grid item xs={6} sm={4} md={3} onClick={handlePress}>
      <div className={classes.container}>
        <div className={classes.avatarContainer}>
          <Avatar className={classes.avatar} src={avatar_url} />
        </div>
        <Typography className={classes.name}>{name}</Typography>

        <Typography className={classes.info}>{email}</Typography>
        <Typography className={classes.info}>{phone_number}</Typography>

        <ItemCreatedInfo item={agent} />
      </div>
    </Grid>
  )
}

export default AgentListItem
