import { Delete } from '@mui/icons-material'
import { FormControlLabel, Select, MenuItem, IconButton, FormGroup, FormHelperText } from '@mui/material'
import CoachesDispatcher from '@reducers/coaches/dispatcher'
import { ReduxStateCoach } from '@reducers/coaches/reducer'
import { ReduxState } from '@reducers/index'
import ZoomHostsDispatcher from '@reducers/zoomHosts/dispatcher'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch, useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxDispatch } from '../../../../../typings/ReduxDispatch'

interface Props {
  coach: ReduxStateCoach
}

function CoachSettingsZoom(props: Props) {
  const { coach } = props
  const { host_id } = coach

  const dispatch = useDispatch<ReduxDispatch>()

  const { availableHosts } = useSelector((state: ReduxState) => ({
    availableHosts: state.zoomHosts.available,
  }))

  useAsyncFetch(async () => {
    await dispatch(CoachesDispatcher.getPersonalZoomHost(coach.user_id))
  })

  const actionSetZoomHost = useAsyncHandler(async (next_host_id: string) => {
    await dispatch(ZoomHostsDispatcher.setCoachHost(coach.user_id, next_host_id))
  })

  const actionRemoveZoomHost = useAsyncHandler(async () => {
    if (!host_id) return
    await dispatch(ZoomHostsDispatcher.removeCoachHost(coach.user_id, host_id))
  })

  const handleChangeZoomHost = async (event) => {
    const { value } = event.target
    if (host_id === value) return

    await actionSetZoomHost.execute(value)
  }

  const handleRemoveZoomHost = async () => {
    await actionRemoveZoomHost.execute()
  }

  function renderZoomHostInput() {
    return (
      <FormGroup row>
        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
          <Select error={actionSetZoomHost.isErrored} onChange={handleChangeZoomHost} value={_.get(coach, 'host_id')}>
            {renderZoomHostsOptions()}
          </Select>
          {actionSetZoomHost.error ? <FormHelperText error>{actionSetZoomHost.error}</FormHelperText> : null}
        </div>
        <IconButton
          onClick={handleRemoveZoomHost}
          color="primary"
          aria-label="remove host"
          component="span"
          size="large"
        >
          <Delete />
        </IconButton>
      </FormGroup>
    )
  }

  function renderZoomHostsOptions() {
    const hosts = _.clone(availableHosts)
    if (coach.host_id && !_.find(hosts, (h) => h.id === coach.host_id)) {
      hosts.push({ id: coach.host_id, zoom_host_id: coach.host_name })
    }
    return _.map(hosts, (item) => <MenuItem value={item.id}>{item.zoom_host_id}</MenuItem>)
  }

  return (
    <FormControlLabel value="top" control={renderZoomHostInput()} label="Personal zoom host" labelPlacement="top" />
  )
}

export default CoachSettingsZoom
