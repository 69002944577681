import { ReduxState } from '@reducers/index'
import React, { ReactNode } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { ExtractConnectType } from '../typings/ReduxExtractor'

interface Props {
  children: ReactNode
}
interface PropsInner extends Props, ExtractConnectType<typeof connectStore> {}

function AuthGuard(props: PropsInner) {
  const { children, token, role } = props

  const shouldRedirect = !token || role !== 'admin'

  if (shouldRedirect) {
    return <Redirect to="/login" />
  }

  return children
}

const connectStore = connect((state: ReduxState) => ({
  token: state.auth.token,
  role: state.auth.role,
}))

export default connectStore(AuthGuard)
