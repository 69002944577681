import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReactionEvent } from '@reaction-club-types/core'
import { APIEventUpdatePayload } from '@reducers/events/apiEvents'
import EventDispatcher from '@reducers/events/dispatcher'
import React from 'react'
import { useDispatch } from 'react-redux'
import ModalStyled from '../../../elements/ModalStyled'
import EventUpdateForm from './eventUpdate/EventUpdateForm'

const useStyles = makeStyles({
  container: {
    minWidth: 400,
    paddingTop: 20,
  },
})

interface Props {
  event: ReactionEvent
  isVisible: boolean
  onClose(): any
}

function EventUpdateModal(props: Props) {
  const { event, isVisible, onClose } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  if (!event) return null

  const handleSaveClick = async (payload: APIEventUpdatePayload) => {
    await dispatch<any>(EventDispatcher.updateEvent(event.id, payload))
    onClose()
  }

  return (
    <ModalStyled isVisible={isVisible} onClose={onClose} className={classes.container}>
      <Typography variant={'h1'}>Edit Event</Typography>
      <Grid item lg={12} md={12} sm={12}>
        <EventUpdateForm event={event} onUpdate={handleSaveClick} />
      </Grid>
    </ModalStyled>
  )
}

export default EventUpdateModal
