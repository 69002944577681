/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Company } from '@reaction-club-types/core'
import CompanyDispatcher from '@reducers/companies/dispatcher'
import { useSnackbar } from 'notistack'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { ReduxDispatch } from '../../../../../typings/ReduxDispatch'
import Loader from '../../../../components/Loader'

interface Props {
  company: Company
  isVisible: boolean
  onClose: () => any
}
export default function ClearFeedModal(props: Props) {
  const { isVisible, onClose, company } = props
  const { enqueueSnackbar } = useSnackbar()

  const dispatch = useDispatch<ReduxDispatch>()
  const clearFeedAction = useAsyncHandler(async () => {
    await dispatch(CompanyDispatcher.clearFeed(company.id))

    enqueueSnackbar('Feed is cleared!', { variant: 'success' })
    onClose()
  })

  return (
    <Dialog open={isVisible} onClose={onClose}>
      <DialogTitle>Clearing feed</DialogTitle>
      <DialogContent>
        <DialogContentText color={'black'}>Are you sure want to clear feed for this company?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <RendererStatusSplit
          statuses={clearFeedAction}
          renderLoading={() => (
            <Loader
              css={css`
                margin-right: 10px;
              `}
            />
          )}
          render={() => (
            <Button
              onClick={() => clearFeedAction.execute()}
              css={css`
                font-weight: bold;
                color: #e82323;
              `}
            >
              Clear Feed
            </Button>
          )}
        />

        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
