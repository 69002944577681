/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { FormControl, RadioGroup, FormControlLabel, Box, Typography, Grid, Switch } from '@mui/material'
import { ReduxStateCompanyItem } from '@reducers/companies/reducer'
import { PlanStatus, PlanType } from '@reducers/companies/types'
import moment from 'moment'
import React, { useState } from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import Loader from '../../../components/Loader'
import StyledRadio from '../../../elements/StyledRadio'
import PageSection from '../../../layout/PageSection'
import CompanyDispatcher from '../../../reducers/companies/dispatcher'
import CompanySubscriptionForm from './components/CompanySubscriptionForm'
import ConfigLabel from './components/ConfigLabel'

interface Props {
  company: ReduxStateCompanyItem
}

interface PropsInner extends Props {}

function CompanyPayment(props: PropsInner) {
  const { company } = props
  const [showSubscriptionForm, setShowSubscriptionForm] = useState(false)
  const dispatch = useDispatch<ReduxDispatch>()
  const { plan, id, stripe_customer_id: stripeCustomerId } = company
  let {
    type,
    expireAt,
    manual_status,
    status,
    is_manual,
    free_plan_exceed_at,
    blocked_at,
    quantity,
    cancelAt,
    intervalUnit,
    renewsAt,
    subscriptionId,
  } = plan || {}

  const fetchAction = useAsyncFetch(async () => {
    await dispatch(CompanyDispatcher.getCompanySubscriptionPlan(company.id))
  }, [company.id])

  const handleSubmitPlan = (payload) => {
    const defaultPlan = {
      type: PlanType.FreeTrial,
      manual_status: PlanStatus.Active,
      is_manual: true,
    }

    if (!plan) {
      return dispatch(CompanyDispatcher.createCompanySubscriptionPlan(id, { ...defaultPlan, ...payload }))
    }

    return dispatch(CompanyDispatcher.updateCompanySubscriptionPlan(id, payload))
  }

  const handlePlanTypeChange = async (event, type: any) => {
    return handleSubmitPlan({ type })
  }

  const handlePlanManualStatusChange = async (event, manual_status: any) => {
    return handleSubmitPlan({ manual_status })
  }

  const handlePlanIsManualChange = async (event, is_manual: boolean) => {
    return handleSubmitPlan({ is_manual })
  }

  return (
    <PageSection title={'Subscription'}>
      <Box
        css={css`
          padding: 20px;
        `}
      >
        <RendererStatusSplit
          statuses={fetchAction}
          renderError={(error) => <Typography color={'error'}>{error}</Typography>}
          renderLoading={() => (
            <Grid container justifyContent={'center'}>
              <Loader />
            </Grid>
          )}
          render={() => (
            <React.Fragment>
              <Grid container>
                <Grid item xs={4}>
                  <Box mt={4} mb={2}>
                    <Typography variant="h1" fontSize={18}>
                      Auto Settings
                    </Typography>
                    <ConfigLabel title={'Plan Type:'} />
                    <Typography>{type}</Typography>
                  </Box>
                  <Box mb={2}>
                    <ConfigLabel title={'Plan Status:'} />
                    <Typography>{status}</Typography>
                  </Box>
                  {!is_manual && type === PlanType.FreeTrial ? null : (
                    <Box mb={2}>
                      <ConfigLabel title={'Expire At:'} />
                      <Typography>{moment(expireAt).format('yyyy, MMMM DD')}</Typography>
                    </Box>
                  )}
                  {status === PlanStatus.Suspended && free_plan_exceed_at ? (
                    <Box mb={2}>
                      <ConfigLabel title={'Suspended At:'} />
                      <Typography>{moment(free_plan_exceed_at).format('yyyy, MMMM DD')}</Typography>
                    </Box>
                  ) : null}
                  {blocked_at ? (
                    <Box mb={2}>
                      <ConfigLabel
                        title={moment(blocked_at).isBefore(moment()) ? 'Blocked At:' : 'Will Be Blocked At:'}
                      />
                      <Typography>{moment(blocked_at).format('yyyy, MMMM DD')}</Typography>
                    </Box>
                  ) : null}
                </Grid>
                <Grid item xs={4}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <Typography fontWeight="bold" fontSize={16}>
                      Auto
                    </Typography>
                    <Switch color="warning" checked={is_manual} onChange={handlePlanIsManualChange} />
                    <Typography fontWeight="bold" fontSize={16}>
                      Manual
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <FormControl component="fieldset">
                    <Box mt={4} mb={2}>
                      <Typography variant="h1" fontSize={18}>
                        Manual Settings
                      </Typography>
                      <ConfigLabel title={'Plan Type:'} />
                      <RadioGroup
                        value={type}
                        defaultValue={PlanType.FreeTrial}
                        aria-label="plan type"
                        name="customized-radios"
                        onChange={handlePlanTypeChange}
                      >
                        <FormControlLabel
                          value={PlanType.FreeTrial}
                          control={<StyledRadio />}
                          label="Free trial"
                          disabled={!is_manual}
                        />
                        <FormControlLabel
                          value={PlanType.Base}
                          control={<StyledRadio />}
                          label="Base"
                          disabled={!is_manual}
                        />
                      </RadioGroup>
                    </Box>
                    <Box mb={2}>
                      <ConfigLabel title={'Plan Status:'} />
                      <RadioGroup
                        value={manual_status}
                        defaultValue={PlanStatus.Active}
                        aria-label="plan status"
                        name="customized-radios"
                        onChange={handlePlanManualStatusChange}
                      >
                        <FormControlLabel
                          value={PlanStatus.Active}
                          control={<StyledRadio />}
                          label="Active"
                          disabled={!is_manual}
                        />
                        <FormControlLabel
                          value={PlanStatus.Blocked}
                          control={<StyledRadio />}
                          label="Blocked"
                          disabled={!is_manual}
                        />
                      </RadioGroup>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={4} mb={2}>
                    <Typography variant="h1" fontSize={18}>
                      Subscription Details
                    </Typography>
                    <ConfigLabel title={'Quantity:'} />
                    <Typography>{quantity}</Typography>
                  </Box>
                  <Box mb={2}>
                    <ConfigLabel title={'Interval:'} />
                    <Typography>{intervalUnit}</Typography>
                  </Box>
                  {cancelAt ? (
                    <Box mb={2}>
                      <ConfigLabel title={'Canceled at:'} />
                      <Typography>{moment(cancelAt).format('yyyy, MMMM DD')}</Typography>
                    </Box>
                  ) : null}
                  {renewsAt ? (
                    <Box mb={2}>
                      <ConfigLabel title={'Renews at:'} />
                      <Typography>{moment(renewsAt).format('yyyy, MMMM DD')}</Typography>
                    </Box>
                  ) : null}
                  {stripeCustomerId ? (
                    <Box mb={2}>
                      <ConfigLabel title={'Customer id:'} />
                      <Typography color="#0047FF">
                        <a
                          href={`https://dashboard.stripe.com/customers/${stripeCustomerId}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Go to stripe customer
                        </a>
                      </Typography>
                    </Box>
                  ) : null}
                  {subscriptionId ? (
                    <Box mb={2}>
                      <ConfigLabel title={'Subscription id:'} />
                      <Typography color="#0047FF">
                        <a
                          href={`https://dashboard.stripe.com/subscriptions/${subscriptionId}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Go to subscription ( {subscriptionId} )
                        </a>
                      </Typography>
                      <Typography color="#F9B22D" mt={2}>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault()
                            setShowSubscriptionForm(true)
                          }}
                        >
                          Edit Subscription Id
                        </a>
                      </Typography>
                    </Box>
                  ) : null}
                  {showSubscriptionForm || !subscriptionId ? (
                    <CompanySubscriptionForm
                      companyId={company.id}
                      defaultValues={{ subscriptionId }}
                      onCancel={() => {
                        setShowSubscriptionForm(false)
                      }}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        />
      </Box>
    </PageSection>
  )
}

export default CompanyPayment
