import _ from 'lodash'
import React from 'react'
import UserSMSItem from './UserSMSItem'
import SMSTable from './components/SMSTable'

interface Props {
  list: any[]
}
export default function UserSMSList(props: Props) {
  const { list } = props

  const listOrdered = _.orderBy(list, ['created_at'], 'desc')

  return (
    <SMSTable>
      {listOrdered.map((item, index) => (
        <UserSMSItem item={item} key={`sms-list-${item.id}`} />
      ))}
    </SMSTable>
  )
}
