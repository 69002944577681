import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles(
  {
    label: {
      fontWeight: 'bold',
      marginRight: 5,
    },
  },
  {
    classNamePrefix: 'ConfigLabel',
  },
)

interface Props {
  title: string
}

function ConfigLabel(props: Props) {
  const { title } = props
  const classes = useStyles()

  return <span className={classes.label}>{title}</span>
}

export default ConfigLabel
