import { Divider } from '@mui/material'
import Button from '@mui/material/Button/Button'
import Grid from '@mui/material/Grid/Grid'
import TextField from '@mui/material/TextField/TextField'
import { withStyles } from '@mui/styles'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import React, { useMemo, useRef, useState } from 'react'
import ChallengeResultTypeSelect from '../../challengeApplications/components/ChallengeResultTypeSelect'
import ChallengeTypePageActions from './ChallengeTypePageActions'

export const FORM_TYPES = {
  CREATE: 'create',
  UPDATE: 'update',
}

const connectStyles = withStyles({
  buttonsContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  imageContainer: {
    maxHeight: 200,
    minHeight: 80,
    objectFit: 'contain',
    width: '100%',
    backgroundColor: '#cacaca',
    borderRadius: 6,
    boxShadow: '0 2px 5px 0px rgba(0,0,0,0.3)',
    overflow: 'hidden',
  },
  container: {
    position: 'relative',
    padding: '1rem 2rem',
  },
})

const ChallengeTypeForm = (props) => {
  const { classes, onSubmit, initialData = null, formType = FORM_TYPES.CREATE } = props
  const [isEditMode, setIsEditMode] = useState(false)
  const formikRef = useRef<FormikProps<any>>(null)
  const isDisabled = useMemo(() => {
    return formType === FORM_TYPES.UPDATE ? !isEditMode : false
  }, [isEditMode])

  const initialValues = useMemo(
    () => ({
      name: initialData?.name || '',
      label: initialData?.label || '',
      result_type: { label: initialData?.result_type || '', id: null },
    }),
    [initialData, formType],
  )

  const handleSubmit = (values) => {
    const payload = {
      name: values.name,
      label: values.label,
      result_type: undefined,
    }

    if (values.result_type.id) {
      payload.result_type = values.result_type.id
    }

    onSubmit(payload)
    if (formType === FORM_TYPES.UPDATE) {
      setIsEditMode(false)
    }
  }

  const handleName = (field, form, value: string) => {
    const filteredValue = value?.toLowerCase()?.replace(' ', '_')
    form.setFieldValue(field.name, filteredValue)
  }

  const renderBottomBar = () => {
    if (isEditMode) {
      return (
        <Grid container justifyContent={'flex-end'} className={classes.buttonsContainer} gap={2}>
          <Button variant="contained" type="submit" color="primary">
            Submit
          </Button>
        </Grid>
      )
    }

    if (formType === 'create') {
      return (
        <Grid container justifyContent={'flex-end'} className={classes.buttonsContainer}>
          <Button variant="contained" type="submit" color="primary">
            Submit
          </Button>
        </Grid>
      )
    }

    return null
  }

  return (
    <Grid item xs={12}>
      <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={handleSubmit} validateOnChange>
        <Form className={classes.container}>
          {formType === FORM_TYPES.UPDATE ? (
            <ChallengeTypePageActions setEditMode={setIsEditMode} isEditMode={isEditMode} challengeType={initialData} />
          ) : null}
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={4}>
              <Field name={'name'}>
                {({ field, form }: FieldProps) => (
                  <TextField
                    {...field}
                    onChange={(e) => handleName(field, form, e.target.value)}
                    disabled={isDisabled || formType === FORM_TYPES.UPDATE}
                    label="Name"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field name={'label'}>
                {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    disabled={isDisabled}
                    label="Label"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4} alignSelf={'end'}>
              <Field name={'result_type'}>
                {({ field, form }: FieldProps) => (
                  <ChallengeResultTypeSelect
                    {...field}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                    disabled={isDisabled}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={8} />
            {renderBottomBar()}
          </Grid>
        </Form>
      </Formik>
    </Grid>
  )
}

export default connectStyles(ChallengeTypeForm)
