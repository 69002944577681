import apiChallengeApplications, {
  ChallengeApplicationMode,
  ChallengeItem,
  ChallengeProvider,
  ChallengeType,
} from '@reducers/challengeAppplications/api'
import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import api from '../../libs/api/apiOld'
import actions from './actions'

class Dispatcher {
  getChallengeApplications(): DispatcherResponse {
    return async (dispatch) => {
      const challengeApplications = await api.getChallengeApplications()
      dispatch(actions.set(challengeApplications))
    }
  }

  getChallengeApplication(id: string): DispatcherResponse<any> {
    return async (dispatch) => {
      const challengeApp = await api.getChallengeApplicationById(id)
      dispatch(actions.set(challengeApp))
      return challengeApp
    }
  }

  createChallengeApp(app): DispatcherResponse<string> {
    return async (dispatch) => {
      const response = await apiChallengeApplications.createChallengeApplication(app)
      const { challenge_app_id } = response || {}
      if (challenge_app_id) {
        dispatch(this.getChallengeApplication(challenge_app_id))
      }
      return challenge_app_id
    }
  }

  updateChallengeApp(id: string, body): DispatcherResponse {
    return async (dispatch) => {
      await apiChallengeApplications.updateChallengeApplication(id, body)
      dispatch(this.getChallengeApplication(id))
    }
  }

  getChallengeTypes(): DispatcherResponse<ChallengeType[]> {
    return async () => {
      return apiChallengeApplications.getChallengeTypes()
    }
  }

  getChallengeProviders(): DispatcherResponse<ChallengeProvider[]> {
    return async () => {
      return apiChallengeApplications.getChallengeProviders()
    }
  }

  getChallengeItems(
    id: string,
    data: { offset: number; name: string },
  ): DispatcherResponse<{ challenge_application_items: ChallengeItem[]; offset: number }> {
    return async () => {
      return apiChallengeApplications.getChallengeCompaniesConfig(id, data)
    }
  }

  updateChallengeItem(application_id: string, company_id: string, data): DispatcherResponse {
    return async () => {
      return apiChallengeApplications.updateChallengeCompanyConfig(application_id, company_id, data)
    }
  }

  updateChallengeItemsBulk(
    application_id: string,
    data: { is_enabled?: boolean; mode?: ChallengeApplicationMode },
  ): DispatcherResponse {
    return async () => {
      return apiChallengeApplications.updateChallengeCompanyBulk(application_id, data)
    }
  }

  setEnabled(app_id: string, state: boolean): DispatcherResponse {
    return async (dispatch) => {
      await apiChallengeApplications.setEnabledApplication(app_id, { is_enabled: state })
      await dispatch(actions.setEnableChallengeApplication(app_id, state))
    }
  }

  setPublished(app_id: string, state: boolean): DispatcherResponse {
    return async (dispatch) => {
      await apiChallengeApplications.setPublishedApplication(app_id, { is_published: state })
      await dispatch(actions.setPublishedApplication(app_id, state))
    }
  }

  delete(app_id: string): DispatcherResponse {
    return async (dispatch) => {
      await apiChallengeApplications.deleteChallengeApplication(app_id)
      await dispatch(actions.deleteChallengeApplication(app_id))
    }
  }
}

const ChallengeApplicationDispatcher = new Dispatcher()
export default ChallengeApplicationDispatcher
