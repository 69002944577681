import Immutable from 'seamless-immutable'

import types from './actionTypes'

export default function reducer(state = Immutable({}), action = {}) {
  switch (action.type) {
    case types.SET_EMPLOYEE: {
      action.payload.program = {}
      action.payload.devices = {}
      return state.merge(action.payload)
    }
    case types.SET_EMPLOYEE_PROGRAM: {
      return state.merge({
        program: action.payload,
      })
    }
    case types.SET_EMPLOYEE_DEVICES: {
      return state.merge({
        devices: action.payload,
      })
    }
    case types.EMPTY_EMPLOYEE_DEVICES: {
      return state.merge({
        devices: {},
      })
    }
    default:
      return state
  }
}
