/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Button, Grid, Typography } from '@mui/material'
import ZoomHostDispatcher from '@reducers/zoomHosts/dispatcher'
import React, { useState } from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../../components/Loader'
import ModalStyled, { Props as ModalStyledProps } from '../../../elements/ModalStyled'
import RedditTextField from '../../../elements/form/RedditTextField'

interface Props extends Omit<ModalStyledProps, 'children'> {}

function ZoomHostCreateModal(props: Props) {
  const [email, setEmail] = useState('')
  const dispatch = useDispatch()

  const actionCreate = useAsyncHandler(async () => {
    await dispatch(ZoomHostDispatcher.createHost(email))
    props.onClose()
  })

  return (
    <ModalStyled
      showCloseButton
      {...props}
      css={css`
        padding: 40px 20px 20px;
        min-width: 310px;
      `}
    >
      <div>
        <RedditTextField
          value={email}
          onChange={(e) => setEmail(e.target.value || '')}
          placeholder={'email'}
          fullWidth
        />

        <Grid
          container
          justifyContent={'center'}
          css={css`
            margin-top: 40px;
          `}
        >
          <RendererStatusSplit
            statuses={actionCreate}
            renderLoading={() => <Loader />}
            renderError={(error) => <Typography color={'error'}>{error}</Typography>}
            render={() => (
              <Button variant={'contained'} color={'primary'} onClick={actionCreate.execute}>
                Create
              </Button>
            )}
          />
        </Grid>
      </div>
    </ModalStyled>
  )
}

export default ZoomHostCreateModal
