import languages from '@cospired/i18n-iso-languages'
import _ from 'lodash'
import React, { useMemo } from 'react'
import Select from 'react-select'
import { Props as SelectProps } from 'react-select/src/Select'
import FormLabel from '../../../../../elements/form/FormLabel'

interface Props {
  value: string
  onChange(type: string | undefined): any
}

export default function EventLocaleSelect(props: Props) {
  const { value, onChange } = props

  const languageList = useMemo(() => {
    const langs = languages.getNames('en')
    const keys = Object.keys(langs)
    const list = _.map(keys, key => ({ label: langs[key], value: key }))

    return _.orderBy(list, item => item.label)
  }, [])

  const handleChange: SelectProps['onChange'] = item => {
    onChange(item?.value)
  }

  const itemSelected: SelectProps['value'] = _.find(languageList, { value }) || null

  return (
    <div>
      <FormLabel>Language</FormLabel>
      <Select value={itemSelected} onChange={handleChange} options={languageList} />
    </div>
  )
}
