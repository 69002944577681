import _ from 'lodash'
import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import api from '../../libs/api/apiOld'
import challengeTypeActions from '../challengeAppplications/actions'
import { actions } from './toolkit'

class Dispatcher {
  getChallengeTypes(filters: { name?: string }): DispatcherResponse {
    return async (dispatch, getState) => {
      const { challenge_types }: { challenge_types: any[]; total_count: number } = await api.getChallengeTypes({
        ...filters,
        offset: 0,
      })

      await dispatch(actions.setChallengeTypes(challenge_types))
      _.forEach(challenge_types, (company) => dispatch(challengeTypeActions.set(company)))
    }
  }

  getChallengeTypesNext(filters: { name?: string }): DispatcherResponse {
    return async (dispatch, getState) => {
      const { list } = getState().challengeTypes
      const offset = list.length

      const { challenge_types }: { challenge_types } = await api.getChallengeTypes({
        ...filters,
        offset,
      })

      await dispatch(actions.setChallengeTypesNext(challenge_types))
      _.forEach(challenge_types, (company) => dispatch(challengeTypeActions.set(company)))
    }
  }
}

const ChallengeTypePageDispatcher = new Dispatcher()
export default ChallengeTypePageDispatcher
