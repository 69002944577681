/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Grid, useMediaQuery, Theme } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline/CssBaseline'
import { makeStyles } from '@mui/styles'
import { ReduxState } from '@reducers/index'
import classNames from 'classnames'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import useModalState from '../hooks/useModalState'
import AppBarCustom from './AppBarCustom'
import Notifications from './Notifications'
import AppDrawer from './drawer/AppDrawer'

const drawerWidth = 240

const useStyles = makeStyles(
  (theme) => ({
    content: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }),
  {
    classNamePrefix: 'AppContainer',
  },
)

interface Props {
  children: ReactElement
}

function AppContainer(props: Props) {
  const { children } = props
  const classes = useStyles()
  const history = useHistory()

  const isDesktop: boolean = useMediaQuery(
    (t: Theme) => {
      const { breakpoints } = t
      if (!breakpoints) return '(max-width: 500)'
      return t.breakpoints.up('md')
    },
    { noSsr: true },
  )
  const drawerState = useModalState(isDesktop)

  const { token, auth } = useSelector((state: ReduxState) => ({
    token: state.auth.token,
    auth: state.auth,
  }))

  if (!token && history.location.pathname !== '/login') {
    return <Redirect to="/login" />
  }

  const handleDrawerClose = () => {
    drawerState.onClose()
    window.dispatchEvent(new Event('resize'))
  }

  return (
    <div
      css={css`
        flex-grow: 1;
      `}
    >
      <Notifications />
      <Grid>
        <CssBaseline />

        {token && <AppBarCustom isDrawerOpened={drawerState.isVisible} onOpenDrawer={drawerState.onOpen} />}

        <AppDrawer isOpened={drawerState.isVisible} onClose={handleDrawerClose} />
        <div
          css={[
            css`
              flex-grow: 1;
              margin-top: 64px;
              height: calc(100vh - 64px);
              overflow-y: auto;
              background-color: #ececec;
              margin-left: 0;
            `,
            drawerState.isVisible &&
              css`
                @media (min-width: 700px) {
                  margin-left: ${drawerWidth}px;
                }
              `,
          ]}
          className={classNames(classes.content, { [classes.contentShift]: drawerState.isVisible })}
        >
          {children}
        </div>
      </Grid>
    </div>
  )
}

export default AppContainer
