/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { MoreVert as MoreVertIcon, Edit as EditIcon, Close as CloseIcon } from '@mui/icons-material'
import { MenuItem, Typography, IconButton, Menu, ListItemIcon, Grid } from '@mui/material'
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks'
import React from 'react'
import useModalState from '../../../hooks/useModalState'

interface Props {
  challengeType
  isEditMode: boolean
  setEditMode: (isEditMode: boolean) => any
}

function ChallengeTypePageActions(props: Props) {
  const { isEditMode, setEditMode } = props
  const menuState = usePopupState({ variant: 'popover', popupId: 'company-menu-actions' })
  const deleteModalState = useModalState()

  const handleClickEditMode = (state: boolean) => {
    setEditMode(state)
    menuState.close()
  }

  const handleClickDelete = () => {
    menuState.close()
    deleteModalState.onOpen()
  }

  return (
    <div
      css={css({
        position: 'absolute',
        top: 10,
        right: 10,
        color: '#323232',
        fontSize: '1.2rem',
      })}
    >
      <Grid container alignItems={'center'}>
        <IconButton size="large" {...bindTrigger(menuState)}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          {...bindMenu(menuState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          {isEditMode ? (
            <MenuItem onClick={() => handleClickEditMode(false)}>
              <ListItemIcon>
                <CloseIcon />
              </ListItemIcon>
              <Typography variant="inherit">Disable Edit Mode</Typography>
            </MenuItem>
          ) : (
            <MenuItem onClick={() => handleClickEditMode(true)}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <Typography variant="inherit">Edit Mode</Typography>
            </MenuItem>
          )}

          {/* <MenuItem onClick={handleClickDelete}> */}
          {/*  <ListItemIcon> */}
          {/*    <DeleteIcon /> */}
          {/*  </ListItemIcon> */}
          {/*  <Typography variant="inherit" color={'error'} style={{ fontWeight: 'bold' }}> */}
          {/*    Delete Type */}
          {/*  </Typography> */}
          {/* </MenuItem> */}
        </Menu>
      </Grid>
    </div>
  )
}

export default ChallengeTypePageActions
