/**
 * process.env.API_URL should be only this style.
 * When there other way it can be not replaced. For example: const { API_URL } = process.env
 */
import invariant from 'invariant'

const version = process.env.VERSION
const app = process.env.APP

console.log(`
********************************************
******  APP: ${app} (${version})
********************************************
`)

const config = {
  env: process.env.APP_ENV,
  api_url: process.env.API_URL as string,
  chat_api_url: process.env.CHAT_URL as string,
  playbooks_url: process.env.PLAYBOOKS_URL as string,
  assets_api_url: process.env.ASSETS_API_URL as string,

  timeout: process.env.APP_ENV === 'production' ? 60000 : 10000,

  bugsnag_api_key: '84e4bc01f4767790d83920afab278b6b',

  HRURL: process.env.HR_URL as string,
  HRNEXTURL: process.env.HR_NEXT_URL as string,

  buildTime: process.env.BUILD_TIME as string,
}

console.log('env', config.env)
console.log('config', config)

invariant(config.api_url, 'config.api_url is not set')
invariant(config.chat_api_url, 'config.chat_api_url is not set')

export default config
