import { Coach, Company, Employee, Group, UserContacts } from '@reaction-club-types/core'
import _ from 'lodash'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import types from './types'

interface State {
  [key: string]: ReduxStateCoach
}

const initialState: State = {
  /* { id: Coach } */
}

export interface ReduxStateCoach extends Coach {
  contacts: UserContacts
  groups?: Group[]
  companies?: Company[]
  employees?: Employee[]
  host_id?: string
  host_name?: string
}

export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case types.SET: {
      const coach = action.payload
      const { user_id } = coach

      const prevCoach = state[user_id]
      if (prevCoach) {
        const newCoach = _.merge(coach, prevCoach)
        return state.merge({
          [user_id]: _.merge(newCoach, prevCoach),
        })
      }
      const stateItem: ReduxStateCoach = {
        companies: [],
        ...coach,
      }

      return (state = state.merge({
        [user_id]: stateItem,
      }))
    }
    case types.SET_COMPANIES: {
      const { coach_id, companies } = action.payload
      return state.updateIn([coach_id], (coachItem: Immutable<ReduxStateCoach>) => {
        return coachItem.merge({
          companies,
        })
      })
    }
    case types.SET_EMPLOYEES: {
      const { coach_id, employees } = action.payload
      return state.updateIn([coach_id], (coachItem: Immutable<ReduxStateCoach>) => {
        return coachItem.merge({
          employees,
        })
      })
    }
    case types.SET_GROUPS: {
      const { coach_id, groups } = action.payload
      return state.updateIn([coach_id], (coachItem: Immutable<ReduxStateCoach>) => {
        return coachItem.merge({
          groups,
        })
      })
    }
    case types.SET_PERSONAL_HOST: {
      const { coach_id, host } = action.payload
      return state.updateIn([coach_id], (coachItem: Immutable<ReduxStateCoach>) => {
        return coachItem.merge({
          host_id: _.get(host, 'id', ''),
          host_name: _.get(host, 'zoom_host_id', ''),
        })
      })
    }

    case types.SET_CONTACTS: {
      const { coach_id, contacts } = action.payload as { coach_id: string; contacts: UserContacts }

      let stateItem = state[coach_id]
      if (!stateItem) return state

      stateItem = stateItem.merge({
        contacts,
      })

      return state.merge({
        [coach_id]: stateItem,
      })
    }

    default:
      return state
  }
}
