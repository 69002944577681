import { UserStepsDaily } from '@reaction-club-types/core'
import React from 'react'
import { StyledTableCell, StyledTableRow } from '../../../../elements/table/TableDefault'
import StatusBoolean from '../../../company/components/StatusBoolean'

interface Props {
  item: UserStepsDaily
}
function StepsDailyItem(props: Props) {
  const { item } = props
  const { steps, date, is_point_earned, goal } = item

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row">
        {steps}
      </StyledTableCell>
      <StyledTableCell align="right">{goal}</StyledTableCell>
      <StyledTableCell align="right">
        <StatusBoolean isTrue={is_point_earned} />
      </StyledTableCell>
      <StyledTableCell align="right">{date}</StyledTableCell>
    </StyledTableRow>
  )
}

export default StepsDailyItem
