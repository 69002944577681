import { Employee, Group, UserContacts, UserPointsLog, UserStepsDaily, UserDevice } from '@reaction-club-types/core'
import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import invariant from 'invariant'
import _ from 'lodash'

interface ReduxStateEmployees {
  [key: string]: ReduxStateEmployeesItem
}

export interface ReduxStateEmployeesItem extends Employee, StoreUpdatedObject {
  groups: Group[]
  contacts?: UserContacts
  pointLogs?: UserPointsLog[]
  dailySteps?: UserStepsDaily[]
  devices?: UserDevice[]
}

const initialState: ReduxStateEmployees = {}

const employeesSlice = createSlice({
  name: 'Employees',
  initialState,
  extraReducers: {
    [appTypes.RESET]: (state, action) => {
      return initialState
    },
  },
  reducers: {
    set(state, action: PayloadAction<Employee | Employee[]>) {
      let itemOrArr = action.payload
      const list: Employee[] = _.isArray(itemOrArr) ? itemOrArr : [itemOrArr]

      _.forEach(list, (item) => {
        state[item.user_id] = { ...state[item.user_id], ...item }
      })
    },

    delete(state, action: PayloadAction<string>) {
      const user_id: string = action.payload
      state[user_id] = undefined
    },

    setGroups(state, action: PayloadAction<{ user_id: string; groups: Group[] }>) {
      const { groups, user_id } = action.payload
      invariant(state[user_id], 'employee item is not found for data')
      state[user_id].groups = groups
    },

    setContacts(state, action: PayloadAction<{ user_id: string; contacts: UserContacts }>) {
      const { contacts, user_id } = action.payload
      invariant(state[user_id], 'employee item is not found for data')
      state[user_id].contacts = contacts
    },

    setPointsLog(state, action: PayloadAction<{ user_id: string; list: UserPointsLog[] }>) {
      const { list, user_id } = action.payload
      invariant(state[user_id], 'employee item is not found for data')
      state[user_id].pointLogs = list
    },

    setDailySteps(state, action: PayloadAction<{ user_id: string; list: UserStepsDaily[] }>) {
      const { list, user_id } = action.payload
      invariant(state[user_id], 'employee item is not found for data')
      state[user_id].dailySteps = list
    },

    setDevices(state, action: PayloadAction<{ user_id: string; list: UserDevice[] }>) {
      const { list, user_id } = action.payload
      invariant(state[user_id], 'employee item is not found for data')
      state[user_id].devices = list
    },
  },
})

export const { actions, reducer } = employeesSlice
export default reducer
