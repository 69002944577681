import { Grid, Typography } from '@mui/material'
import AgentDispatcher from '@reducers/agent/dispatcher'
import { ReduxState } from '@reducers/index'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useParams } from 'react-router-dom'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import Loader from '../../components/Loader'
import PaperContainer from '../../components/PaperContainer'
import AgentHeadInfo from './agent/AgentHeadInfo'
import AgentPageTabs from './agent/AgentPageTabs'

interface Props {}

function AgentPage(props: Props) {
  const { id } = useParams<{ id: string }>()

  const dispatch = useDispatch<ReduxDispatch>()
  const { agent } = useSelector((state: ReduxState) => ({ agent: state.agent[id] }))

  const agentFetch = useAsyncFetch(async () => {
    await dispatch(AgentDispatcher.getAgent(id))
    await dispatch(AgentDispatcher.getAgentCompany(id))
  }, [id])

  return (
    <>
      <PaperContainer>
        <RendererStatusSplit
          statuses={agentFetch}
          isEmpty={!agent}
          renderLoading={() => (
            <Grid container justifyContent={'center'}>
              <Loader />
            </Grid>
          )}
          renderError={(error) => (
            <Typography color={'error'} textAlign={'center'}>
              {error}
            </Typography>
          )}
          renderEmpty={() => (
            <Grid container justifyContent={'center'}>
              User is not found
            </Grid>
          )}
          render={() => <AgentHeadInfo agent={agent} />}
        />
      </PaperContainer>

      <Grid container justifyContent="center">
        <Grid xs={12} item sx={{ margin: '0 50px 50px 50px' }}>
          {agent && (
            <>
              <AgentPageTabs agent={agent} />

              {/* <EmployeeCompany employee={employee} /> */}

              {/* <EmployeeSteps employee={employee} /> */}

              {/* <EmployeePoints employee={employee} /> */}
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default AgentPage
