import { Grid, Typography, Checkbox } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Coach } from '@reaction-club-types/core'
import React from 'react'
import UserAvatar from '../../components/UserAvatar'

const useStyles = makeStyles(
  {
    container: {
      padding: '0.2rem 1rem',

      '&:hover': {
        backgroundColor: '#f5f1f1',
      },
    },
  },
  {
    classNamePrefix: 'CoachItem',
  },
)

interface Props {
  coach: Coach
  isSelected: boolean
  onSelect(coach: Coach, isChecked: boolean): any
}

function CoachItem(props: Props) {
  const { coach, isSelected, onSelect } = props
  const { user_id, name, avatar_url } = coach

  const classes = useStyles()

  const handleChangeCheckbox: React.ChangeEventHandler<HTMLInputElement> = e => {
    const isChecked: boolean = e.target.checked
    onSelect(coach, isChecked)
  }

  return (
    <Grid container className={classes.container} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
      <Grid sm={10} item container direction={'row'} alignItems={'center'}>
        <UserAvatar avatar_url={avatar_url} name={name} />
        <Typography>{name}</Typography>
      </Grid>

      <Grid sm={2} item container justifyContent={'flex-end'}>
        <Checkbox
          // className={classes.checkBox}
          checked={isSelected}
          onChange={handleChangeCheckbox}
          value={user_id}
          color={'secondary'}
        />
      </Grid>
    </Grid>
  );
}

export default CoachItem
