import { UserDevice } from '@reaction-club-types/core'
import apiClient from '../../lib/apiClient'

export interface ApiEmployeesListParams {
  name?: string
  phone?: string
  email?: string
  offset: number
}

const apiUsers = {
  getLocalAccount: async (user_id: string): Promise<{ email: string; is_verified: boolean }> => {
    const result = await apiClient.get(`/user/${user_id}/local-account`)
    return result.data
  },

  getUserDevices: async (user_id: string): Promise<UserDevice[]> => {
    const result = await apiClient.get(`/user/${user_id}/devices`)
    return result.data
  },

  getSmsLogs: async (user_id: string): Promise<any[]> => {
    const result = await apiClient.get(`/user/${user_id}/sms-logs`)
    return result.data
  },
  async getDeviceDiagnostic(device_id: string) {
    const result = await apiClient.get(`/device/${device_id}/diagnostic`)
    return result.data
  },
  async setContactEmail(user_id: string, email: string) {
    const result = await apiClient.post(`/user/${user_id}/contacts/email`, {
      email,
    })
    return result.data
  },
}

export default apiUsers
