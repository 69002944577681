import { Company } from '@reaction-club-types/core'
import _ from 'lodash'
import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import api from '../../libs/api/apiOld'
import companyActions from '../companies/actions'
import { actions } from './toolkit'

class Dispatcher {
  getCompanies(filters: { name?: string }): DispatcherResponse {
    return async (dispatch, getState) => {
      const { companies }: { companies: Company[]; total_count: number } = await api.getCompanies({
        ...filters,
        offset: 0,
      })

      await dispatch(actions.setCompanies(companies))
      _.forEach(companies, (company) => dispatch(companyActions.set(company)))
    }
  }

  getCompaniesNext(filters: { name?: string }): DispatcherResponse {
    return async (dispatch, getState) => {
      const { list } = getState().companiesPage
      const offset = list.length

      const { companies }: { companies: Company[] } = await api.getCompanies({
        ...filters,
        offset,
      })

      await dispatch(actions.setCompaniesNext(companies))
      _.forEach(companies, (company) => dispatch(companyActions.set(company)))
    }
  }
}

const CompanyPageDispatcher = new Dispatcher()
export default CompanyPageDispatcher
