import SeamlessImmutable, { Immutable } from 'seamless-immutable'

import types from './actionTypes'

const initialState: ReduxStatePlaybookPage = {
  name: '',
  category_id: null,
  list: [],
  offset: 0,
  total_count: 0,
}
export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction) {
  switch (action.type) {
    case types.SET: {
      const playbooks: Playbook[] = action.payload
      return state.merge({ list: playbooks })
    }

    case types.SET_NEXT: {
      const playbooks: Playbook[] = action.payload

      return state.updateIn(['list'], list => list.concat(playbooks))
    }

    case types.SET_TOTAL_COUNT: {
      return state.merge({ total_count: action.payload })
    }

    case types.DELETE: {
      const playbook_id = action.payload

      return state.updateIn(['list'], list => list.filter(playbook => playbook.id !== playbook_id))
    }

    case types.SET_NAME: {
      const name: string = action.payload
      return state.merge({ name })
    }

    default:
      return state
  }
}
