import { Divider, Grid, Typography, Box } from '@mui/material'
import { withStyles } from '@mui/styles'
import { ReduxState } from '@reducers/index'
import PlaybookInstancesDispatcher from '@reducers/playbookInstances/dispatcher'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../typings/ReduxExtractor'
import { ExtractStyles } from '../../typings/stylesExtractor'
import EventGeneral from './components/EventGeneral'
import ClockIcon from './eventDetails/assets/clock.svg'
import RegIcon from './eventDetails/assets/reg.svg'
import VideoIcon from './eventDetails/assets/video.svg'

const connectStyles = withStyles({
  infoLineWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 20,
  },
  text: {
    fontSize: 18,
  },
  link: {
    fontSize: 18,
    color: '#0047FF',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  description: {
    fontSize: 14,
  },
})

interface Props {
  event: ReactionEvent
}

interface PropsInner
  extends Props,
    RouteComponentProps,
    ExtractStyles<typeof connectStyles>,
    ExtractConnectType<typeof connectStore> {}

const Line = () => <Divider style={{ width: '100%', margin: '20px 0' }} />

function EventDetails(props: PropsInner) {
  const { event, instances, getPlaybookInstances, classes } = props
  const { url, time, duration, description, participant_count, playbook_instance_id } = event

  const group = playbook_instance_id && instances[playbook_instance_id]

  React.useEffect(() => {
    if (playbook_instance_id && !group) {
      getPlaybookInstances()
    }
  }, [])

  return (
    <Grid container direction={'column'} style={{ padding: '0 30px' }}>
      <EventGeneral event={event} />
      <Line />
      {url && (
        <Box className={classes.infoLineWrapper} style={{ marginBottom: 20 }}>
          <img src={VideoIcon} alt="" className={classes.icon} />
          <a href={url} className={classes.link}>
            {url}
          </a>
        </Box>
      )}
      <Box className={classes.infoLineWrapper} style={{ marginBottom: 20 }}>
        <img src={ClockIcon} alt="" className={classes.icon} />
        <Typography className={classes.text}>
          {moment(time).format('MMM DD, hh:mm')} for {duration} minutes
        </Typography>
      </Box>
      <Box className={classes.infoLineWrapper} style={{ marginBottom: 20 }}>
        <img src={RegIcon} alt="" className={classes.icon} />
        <Typography className={classes.text}>{participant_count} registers</Typography>
      </Box>
      <Line />
      <Typography className={classes.description}>{description}</Typography>
    </Grid>
  )
}

const connectStore = connect(
  (state: ReduxState) => ({
    instances: state.playbookInstances,
  }),
  (dispatch: ReduxDispatch) => ({
    getPlaybookInstances: () => dispatch(PlaybookInstancesDispatcher.getPlaybookInstances()),
  }),
)

export default compose<PropsInner, Props>(withRouter, connectStore, connectStyles)(EventDetails)
