/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Grid, Tab } from '@mui/material'
import { ReduxStateEmployeesItem } from '@reducers/employees/reducer'
import React, { useState } from 'react'
import UserSmsLogs from '../../users/user/UserSmsLogs'
import EmployeeDevices from './EmployeeDevices'

interface Props {
  employee: ReduxStateEmployeesItem
}

const cssTabPanel = css`
  padding: 0;
`

function EmployeePageTabs(props: Props) {
  const { employee } = props
  const [activeTab, setActiveTab] = useState('devices')
  const { user_id } = employee

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Grid container justifyContent={'flex-end'}>
            <TabList onChange={(e, tab) => setActiveTab(tab)}>
              <Tab label="Devices" value="devices" />
              <Tab label="SMS logs" value="sms" />
            </TabList>
          </Grid>
        </Box>
        <TabPanel value="devices" css={cssTabPanel}>
          <EmployeeDevices employee={employee} />
        </TabPanel>
        <TabPanel value="sms" css={cssTabPanel}>
          <UserSmsLogs user_id={user_id} />
        </TabPanel>
        {/* <TabPanel value="3">Item Three</TabPanel> */}
      </TabContext>
    </Box>
  )
}

export default EmployeePageTabs
