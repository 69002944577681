import keyManager from 'constant-manager'

export default keyManager(
  {
    SET_EMPLOYEE: '',
    SET_EMPLOYEE_PROGRAM: '',
    SET_EMPLOYEE_DEVICES: '',
    EMPTY_EMPLOYEE_DEVICES: '',
  },
  { prefix: 'Employee__' },
)
