/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import Moment from 'react-moment'

interface Props {
  user: { last_seen: string | null }
}

function UserLastSeen(props: Props) {
  const { user } = props
  const { last_seen } = user

  return (
    <Grid container alignItems={'center'}>
      <Typography
        fontWeight={'bold'}
        css={css`
          display: inline-block;
          margin-right: 7px;
        `}
      >
        Last Seen:
      </Typography>
      <Typography>{last_seen ? <Moment format={'LLL'}>{last_seen}</Moment> : 'never'}</Typography>
    </Grid>
  )
}

export default UserLastSeen
