import React from 'react'
import { Grid } from '@mui/material'
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react'
import { compose } from 'redux'
import withStyles, { WithStyles } from 'react-jss'
import { connect } from 'react-redux'
import ReactionMap from '../../../../components/Location/ReactionMap'

const styles = {
  container: {
    padding: '5px',
  },
}

interface Props {
  coaches: Coach[]
}

class CoachesMap extends React.PureComponent<Props & WithStyles<typeof styles> & ReturnType<typeof mapStateToProps>> {
  render() {
    const { classes, coaches, coordinates } = this.props
    const initialCenter = coordinates ? { lat: coordinates.latitude, lng: coordinates.longitude } : null
    const markers = coordinates
      ? [{ lat: coordinates.latitude, lng: coordinates.longitude }]
      : coaches.map(coach => ({ lat: coach.location.latitude, lng: coach.location.longitude }))
    return (
      <Grid className={classes.container} style={{ width: '100%', height: '200px' }}>
        <ReactionMap markers={markers} center={initialCenter} />
      </Grid>
    )
  }
}

const mapStateToProps = (state: ReduxState) => ({
  coordinates: state.coachesPage.coordinates,
})

export default compose(withStyles(styles), connect(mapStateToProps))(CoachesMap)
