import { prefix_web } from './apiOld'
import apiClient from '../../lib/apiClientOld'

export default {
  async getPlaybooks(options: ApiWebRequestPlaybooksQuery): Promise<ApiWebResponsePlaybooks> {
    const { offset = 0, name } = options

    const query: ApiWebRequestPlaybooksQuery = {
      offset,
      name,
    }
    const result = await apiClient.get(`${prefix_web}playbooks`, {
      params: query,
    })
    return result.data
  },
  async getPlaybook(playbookId) {
    const result = await apiClient.get(`${prefix_web}playbook/${playbookId}`)
    return result.data
  },
  async getPlaybookPrograms(playbookId) {
    const result = await apiClient.get(`${prefix_web}playbook/${playbookId}/programs`)
    return result.data
  },

  async deletePlaybook(playbookId) {
    const result = await apiClient.delete(`${prefix_web}playbook/${playbookId}`)
    return result.data
  },
  async deletePlaybookProgram(playbookId, programId) {
    const result = await apiClient.delete(`${prefix_web}playbook/${playbookId}/program`, {
      data: { program_id: programId },
    })
    return result.data
  },
  async updatePlaybook(playbookId, playbook) {
    const result = await apiClient.post(`${prefix_web}playbook/${playbookId}`, playbook)
    return result.data
  },
  async duplicatePlaybook(playbookId) {
    const result = await apiClient.post(`${prefix_web}playbook/${playbookId}/duplicate`)
    return result.data
  },
  async unverifyPlaybook(playbookId) {
    const result = await apiClient.post(`${prefix_web}playbook/${playbookId}/unverify`)
    return result.data
  },
  async verifyPlaybook(playbookId) {
    const result = await apiClient.post(`${prefix_web}playbook/${playbookId}/verify`)
    return result.data
  },
  async getPlaybookTypes() {
    const result = await apiClient.get(`${prefix_web}playbook_types`)
    return result.data
  },
  async addPlaybookType(playbook) {
    const result = await apiClient.post(`${prefix_web}playbook_types`, playbook)
    return result.data
  },
  async setPlaybookType(playbookId, playbookTypeId) {
    const result = await apiClient.post(`${prefix_web}playbook/${playbookId}/playbook_type/${playbookTypeId}/`)
    return result.data
  },
  async editPlaybookType(id, playbookType) {
    const result = await apiClient.post(`${prefix_web}playbook_types/${id}`, playbookType)
    return result.data
  },
  async deletePlaybookType(id, playbookType) {
    const result = await apiClient.delete(`${prefix_web}playbook_types/${id}`)
    return result.data
  },
  async makePublicPlaybook(id) {
    const result = await apiClient.post(`${prefix_web}playbook/${id}/public`)
    return result.data
  },
  async makePrivatePlaybook(id) {
    const result = await apiClient.post(`${prefix_web}playbook/${id}/private`)
    return result.data
  },
  async getPrivateCoaches(id) {
    const result = await apiClient.get(`${prefix_web}playbook/${id}/private/coaches`)
    return result.data
  },
  async getPrivateCompanies(id) {
    const result = await apiClient.get(`${prefix_web}playbook/${id}/private/companies`)
    return result.data
  },
  async setPrivateCompany(id, companyId) {
    const result = await apiClient.post(`${prefix_web}playbook/${id}/private/company/${companyId}`)
    return result.data
  },
}
