/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Typography,
  styled,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import apiAgents from '@reducers/agent/api'
import _ from 'lodash'
import React, { useRef, useState } from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import InfiniteScroll from 'react-infinite-scroller'
import ReactionLoader from '../../components/ReactionLoader'
import OnboardListItem from './onboard/OnboardListItem'

const useStyles = makeStyles(
  {
    container: {
      padding: '0 10px',
    },
    root: {
      padding: 50,
    },
  },
  {
    classNamePrefix: 'OnboardList',
  },
)

const TableCellStyled = styled(TableCell)`
  font-weight: bold;
`

function OnboardList() {
  const classes = useStyles()
  const scrollRef = useRef<HTMLDivElement>(null)
  const [list, setList] = useState<any[]>([])
  const [isCompleted, setIsCompleted] = useState(false)

  const loadMoreHandler = useAsyncHandler(async () => {
    const offset = list.length
    const data = await apiAgents.getOnboardList({ offset })
    setList(list.concat(data))
    if (_.isEmpty(data)) setIsCompleted(true)
  })

  const handleLoadMore = () => {
    loadMoreHandler.execute()
  }

  return (
    <Grid className={classes.container}>
      <div className={classes.root}>
        <Typography variant={'h1'}>Onboard list</Typography>

        <div ref={scrollRef}>
          <InfiniteScroll
            pageStart={0}
            loadMore={handleLoadMore}
            hasMore={!isCompleted}
            loader={
              <Grid container justifyContent="center">
                <ReactionLoader />
              </Grid>
            }
            getScrollParent={() => scrollRef.current}
            useWindow={false}
          >
            <div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow
                      css={css`
                        font-weight: bold;
                      `}
                    >
                      <TableCellStyled>Email</TableCellStyled>
                      <TableCellStyled align="right">Is Onboarded</TableCellStyled>
                      <TableCellStyled align="left">Agent</TableCellStyled>
                      <TableCellStyled align="right">Latest Date</TableCellStyled>
                      <TableCellStyled align="right">Started At</TableCellStyled>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.map(list, (row) => (
                      <OnboardListItem item={row} key={`onboard-list-item-${row.email}`} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </Grid>
  )
}

export default OnboardList
