import { Modal, FormControl, FormHelperText, TextField, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Autocomplete } from '@mui/material';
import { countries, Country } from 'country-data'
import React from 'react'
import { compose } from 'recompose'
import { ExtractStyles } from '../../typings/stylesExtractor'
import ButtonOrange from '../components/ButtonOrange'

const connectStyles = withStyles(
  {
    container: {
      backgroundColor: '#fff',
      width: 400,
      margin: '30px auto',
      padding: '1rem',
      borderRadius: 8,
      outline: 'none',
    },

    buttonContainer: {
      marginTop: '2rem',
    },
  },
  {
    classNamePrefix: 'CoachSelectModal',
  },
)

interface Props {
  isVisible: boolean
  onSelect(country: string): any
  onClose(): void
}

interface PropsInner extends Props, ExtractStyles<typeof connectStyles> {}

interface State {
  isLoading: boolean
  location: Country | null
}

class CountrySelectModal extends React.PureComponent<PropsInner, State> {
  state: State = {
    isLoading: false,
    location: null,
  }

  render() {
    const { isVisible, onClose, classes } = this.props
    const { location, isLoading } = this.state

    return (
      <Modal open={isVisible} onClose={onClose}>
        <div className={classes.container}>
          <div>
            <FormControl
              required
              fullWidth
              // error={!!formErrors.locations && (touchedFields.has('locations') || formValidated)}
              variant="standard"
            >
              <FormHelperText>Location</FormHelperText>
              <Autocomplete
                id="locations"
                options={countries.all}
                getOptionLabel={(option) => option.name}
                // isOptionEqualToValue={(option, value) => option.alpha2 === value.alpha2}
                value={location}
                // onBlur={(e) => setBasicValueTouched(e.target.id, this)}
                onChange={this.handleChange}
                renderTags={() => null}
                renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Search" />}
              />
            </FormControl>
          </div>

          {location && (
            <Grid container justifyContent={'flex-end'} className={classes.buttonContainer}>
              <ButtonOrange isLoading={isLoading} onClick={this.handleConfirm}>
                Select
              </ButtonOrange>
            </Grid>
          )}
        </div>
      </Modal>
    );
  }

  handleConfirm = async () => {
    const { location } = this.state
    if (!location) return null

    this.setState({ isLoading: true })

    try {
      await this.props.onSelect(location.alpha2)
      this.props.onClose()
    } finally {
      this.setState({ isLoading: false })
    }
  }

  handleChange = (e, item) => {
    this.setState({ location: item })
  }
}

export default compose<PropsInner, Props>(connectStyles)(CountrySelectModal)
