/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import {
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Check as EnableIcon,
  Publish as PublishIcon,
  Unpublished as UnpublishedIcon,
} from '@mui/icons-material'
import { MenuItem, Typography, IconButton, Menu, ListItemIcon, Grid } from '@mui/material'
import ChallengeApplicationDispatcher from '@reducers/challengeAppplications/dispatcher'
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks'
import React from 'react'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import useModalState from '../../../hooks/useModalState'
import ChallengeApplicationDeleteModal from '../modals/ChallengeApplicationDeleteModal'

interface Props {
  challengeApplication
  isEditMode: boolean
  setEditMode: (isEditMode: boolean) => any
}

function ChallengeAppPageActions(props: Props) {
  const { challengeApplication, isEditMode, setEditMode } = props
  const dispatch = useDispatch<ReduxDispatch>()
  const menuState = usePopupState({ variant: 'popover', popupId: 'company-menu-actions' })
  const deleteModalState = useModalState()

  const handleClickEditMode = (state: boolean) => {
    setEditMode(state)
    menuState.close()
  }

  const handleClickDelete = () => {
    menuState.close()
    deleteModalState.onOpen()
  }

  const handleEnableApplication = async (state: boolean) => {
    menuState.close()
    await dispatch(ChallengeApplicationDispatcher.setEnabled(challengeApplication.id, state))
  }

  const handlePublishApplication = async (state: boolean) => {
    menuState.close()
    await dispatch(ChallengeApplicationDispatcher.setPublished(challengeApplication.id, state))
  }

  return (
    <div
      css={css({
        position: 'absolute',
        top: 10,
        right: 10,
        color: '#323232',
        fontSize: '1.2rem',
      })}
    >
      <ChallengeApplicationDeleteModal challengeApplication={challengeApplication} {...deleteModalState} />
      <Grid container alignItems={'center'}>
        <IconButton size="large" {...bindTrigger(menuState)}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          {...bindMenu(menuState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          {isEditMode ? (
            <MenuItem onClick={() => handleClickEditMode(false)}>
              <ListItemIcon>
                <CloseIcon />
              </ListItemIcon>
              <Typography variant="inherit">Disable Edit Mode</Typography>
            </MenuItem>
          ) : (
            <MenuItem onClick={() => handleClickEditMode(true)}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <Typography variant="inherit">Edit Mode</Typography>
            </MenuItem>
          )}
          {challengeApplication?.is_published ? (
            <MenuItem onClick={() => handlePublishApplication(false)}>
              <ListItemIcon>
                <UnpublishedIcon />
              </ListItemIcon>
              <Typography variant="inherit">Suppress application</Typography>
            </MenuItem>
          ) : (
            <MenuItem onClick={() => handlePublishApplication(true)}>
              <ListItemIcon>
                <PublishIcon />
              </ListItemIcon>
              <Typography variant="inherit">Publish Application</Typography>
            </MenuItem>
          )}

          <MenuItem onClick={handleClickDelete}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <Typography variant="inherit" color={'error'} style={{ fontWeight: 'bold' }}>
              Delete Application
            </Typography>
          </MenuItem>
        </Menu>
      </Grid>
    </div>
  )
}

export default ChallengeAppPageActions
