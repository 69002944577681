import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import withStyles from '@mui/styles/withStyles';
import { WithStyles, Styles } from 'react-jss'
import { compose } from 'redux'
import { Fab } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { Progress } from 'react-sweet-progress'
import CompanyDispatcher from '../../../../reducers/companies/dispatcher'
import fileExplorer from 'web-file-explorer'
import api from '../../../../libs/api/apiOld'

const styles: Styles = {
  container: {
    // width: 100,
    position: 'absolute',
    right: 20,
    bottom: 20,
  },

  progressText: {
    color: '#fff',
  },
}

interface State {
  isLoading: boolean
  status: string
  progress: number
}

interface Props {
  company_id: string
}

class CompanyStickerAddButton extends React.PureComponent<
  Props & WithStyles<typeof styles> & ReturnType<typeof mapDispatchToProps>,
  State
> {
  state: State = {
    isLoading: false,
    status: 'idle',
    progress: 0,
  }

  render() {
    const { classes } = this.props
    return <div className={classes.container}>{this.renderContent()}</div>
  }

  renderContent() {
    const { isLoading, progress, status } = this.state

    if (isLoading)
      return (
        <Progress
          type="circle"
          percent={progress}
          status={status}
          width={30}
          color={'#fff'}
          // symbolClassName={classes.progressText}
          theme={{
            active: {
              symbol: `${progress.toFixed(0)}%`,
              trailColor: 'rgba(255, 255, 255, 0.3)',
              color: '#fff',
            },
          }}
        />
      )

    return (
      <Fab color="primary" aria-label="add" size={'medium'} onClick={this.handlePressAdd}>
        <AddIcon />
      </Fab>
    )
  }

  handlePressAdd = async () => {
    // const {  } = this.props
    const file = await fileExplorer.getFile({
      acceptImage: true,
    })

    await this.upload(file)
  }

  handleAssetReady = async (asset: AssetFull) => {
    try {
      await this.props.createSticker(asset.id)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  upload = async file => {
    const uploadFile = new FormData()
    uploadFile.append('file', file)

    this.setState({ isLoading: true, status: 'active' })

    try {
      const asset = await api.uploadImg(uploadFile, {
        onUploadProgress: event => {
          this.setState({ progress: (event.loaded / event.total) * 100 })
        },
      })

      this.setState({ status: 'success' })
      this.handleAssetReady(asset)
    } catch (e) {
      this.setState({ status: 'error' })
      console.error('upload error', e)
    }
  }
}

const mapDispatchToProps = (dispatch, { company_id }: Props) => ({
  createSticker: (asset_id: string) =>
    dispatch(
      CompanyDispatcher.createSticker({
        company_id,
        asset_id,
      }),
    ),
})

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(CompanyStickerAddButton)
