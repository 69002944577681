import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react'
import ReactionAvatar from '../../../components/assets/ReactionAvatar'
import { CalendarEvent } from '../Events'
import { cameraIcon } from '../../../components/filters/assets/facade'

interface Props {
  event: CalendarEvent
}

function HorizontalCustomEvent(props: Props) {
  const { event } = props
  const { title, resource } = event
  const { coach, participants, url } = resource
  const { name } = coach
  return (
    <Grid direction={'row'} container alignItems={'center'} style={{ position: 'relative' }}>
      {url && (
        <img
          src={cameraIcon}
          alt="cameraIcon"
          style={{
            width: 10,
            top: -12,
            left: 100,
            position: 'absolute',
          }}
        />
      )}
      <ReactionAvatar asset={{ url: coach.avatar_url }} resizeMode={'contain'} style={{ width: 20, height: 20 }} />
      <Grid item style={{ marginLeft: 10 }}>
        <Grid direction={'row'} container>
          <Typography style={{ fontWeight: 'bold' }}>{title}</Typography>
          <Typography style={{ fontSize: 10 }}>
            By {name} / {participants?.length || 0} participants
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default HorizontalCustomEvent
