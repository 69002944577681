/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import { ReduxStateAgent } from '@reducers/agent/reducer'
import React from 'react'
import UserContacts from '../../../components/user/UserContacts'
import UserLocalAccount from '../../users/user/UserLocalAccount'
import AgentActions from './AgentActions'
import AgentCompany from './AgentCompany'
import AgentGeneral from './AgentGeneral'

interface Props {
  agent: ReduxStateAgent
}

function AgentHeadInfo(props: Props) {
  const { agent } = props

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <Grid container direction={'row'} justifyContent={'space-between'}>
        <Grid container>
          <AgentActions agent={agent} />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <AgentGeneral agent={agent} />
        </Grid>
        <Grid item lg={9} sm={9} xs={12}>
          <AgentCompany agent={agent} />
          {/* <CoachDetails */}
          {/*  handleSwitchCertifiedToggle={this.handleSwitchCertifiedToggle} */}
          {/*  handleSwitchContractToggle={this.handleSwitchContractToggle} */}
          {/*  handleSwitchZoomToggle={this.handleSwitchZoomToggle} */}
          {/*  handleModalOpen={this.handleModalOpen} */}
          {/*  handleDelete={this.handleDeleteCompany} */}
          {/*  handleChangeZoomHost={this.handleChangeZoomHost} */}
          {/*  handleRemoveZoomHost={this.handleRemoveZoomHost} */}
          {/*  isContractSigned={is_contract_signed} */}
          {/*  isCertified={is_certified} */}
          {/*  isZoomEnabled={is_zoom_enabled} */}
          {/*  addZoomHostError={addZoomHostError} */}
          {/*  companies={companies} */}
          {/*  employees={employees} */}
          {/*  coach={coach} */}
          {/*  availableHosts={availableHosts} */}
          {/* /> */}
        </Grid>
      </Grid>

      <UserLocalAccount user_id={agent.user_id} />
      <UserContacts user={agent} />
    </div>
  )
}

export default AgentHeadInfo
