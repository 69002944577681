import { Grid, Typography } from '@mui/material'
import { WithStyles, withStyles } from '@mui/styles'
import { ReactionEvent } from '@reaction-club-types/core'
import EventDispatcher from '@reducers/events/dispatcher'
import { ReduxState } from '@reducers/index'
import PlaybookInstancesDispatcher from '@reducers/playbookInstances/dispatcher'
import { PlaybookInstanceExtended } from '@reducers/playbookInstances/reducer'
import moment from 'moment'
import React from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../typings/ReduxExtractor'
import PaperContainer from '../../components/PaperContainer'
import ReactionLoader from '../../components/ReactionLoader'
import GroupFilter from '../../components/filters/GroupFilter'
import EventCreateButton from './EventCreateButton'
import HorizontalCustomEvent from './events/HorizontalCustomEvent'
import VerticalCustomEvent from './events/VerticalCustomEvent'
import EventInfoModal from './modals/EventInfoModal'

const localizer = momentLocalizer(moment)

const styles = {
  captionContainer: {
    marginBottom: 30,
  },
  eventItem: {
    color: '#323232',
    backgroundColor: '#F9B22D',
    border: 'none !important',
  },
}

export interface CalendarEvent {
  title: string
  start: Date
  end: Date
  allDay?: boolean
  resource?: CalendarEventResources
}

interface CalendarEventResources {
  description: string
  instance: PlaybookInstanceExtended | null | any
}

interface State {
  isLoading: boolean
  isAgendaActive: boolean
  selectedEvent?: ReactionEvent | null
}

class Events extends React.PureComponent<ExtractConnectType<typeof connectStore> & WithStyles<typeof styles>, State> {
  state: State = {
    isLoading: false,
    isAgendaActive: false,
  }

  render() {
    const { isLoading, isAgendaActive, selectedEvent } = this.state

    if (isLoading)
      return (
        <PaperContainer>
          <ReactionLoader />
        </PaperContainer>
      )

    const { events, classes, groups } = this.props
    const calendarEvents: CalendarEvent[] = this.convertEvents(events)

    return (
      <PaperContainer>
        <EventInfoModal event={selectedEvent} isVisible={!!selectedEvent} onClose={this.handleEventReset} />
        <Grid container justifyContent={'flex-end'}>
          <EventCreateButton />
        </Grid>
        <Grid container justifyContent={'space-between'} className={classes.captionContainer}>
          <Typography variant={'h1'}>Events</Typography>
          <GroupFilter instances={groups} onInstanceChange={this.fetchEvents} />
        </Grid>
        <Calendar
          eventPropGetter={() => (!isAgendaActive ? { className: classes.eventItem } : {})}
          onView={this.handleViewChange}
          components={{
            month: {
              event: VerticalCustomEvent,
            },
            agenda: {
              event: VerticalCustomEvent,
            },
            day: {
              event: HorizontalCustomEvent,
            },
          }}
          startAccessor="start"
          events={calendarEvents}
          endAccessor="end"
          localizer={localizer}
          style={{ height: 500 }}
          hideTimeIndicator
          onSelectEvent={this.handleSelectEvent}
        />
      </PaperContainer>
    );
  }

  handleViewChange = (newViewStyles: 'month' | 'week' | 'work_week' | 'day' | 'agenda') => {
    this.setState({ isAgendaActive: newViewStyles === 'agenda' })
  }

  handleSelectEvent = (event: CalendarEvent) => {
    if (!event.resource) return null
    this.setState({
      selectedEvent: event.resource,
    })
  }

  handleEventReset = () => {
    this.setState({ selectedEvent: null })
  }

  convertEvents = (events: Record<string, ReactionEventFull>): CalendarEvent[] => {
    const { groups } = this.props
    const eventArray = Object.values(events)
    return eventArray.map(event => ({
      title: event.name,
      start: moment(event.time).toDate(),
      end: moment(event.time)
        .add(event.duration, 'minutes')
        .toDate(),
      allDay: false,
      resource: {
        description: event.description,
        instance: event.playbook_instance_id ? groups[event.playbook_instance_id] : null,
        ...event,
      },
    }))
  }

  fetchEvents = (value?: string) => {
    const instance_id = value !== 'all' ? value : undefined
    this.props.getEvents(instance_id)
  }

  fetchData = async () => {
    this.setState({ isLoading: true })

    const timeStart = moment()
      .startOf('month')
      .toISOString()

    try {
      await this.props.getEvents({
        timeStart,
      })
    } finally {
      this.setState({ isLoading: false })
    }
  }

  componentDidMount(): void {
    this.fetchData()
  }
}

const connectStore = connect(
  (state: ReduxState) => ({
    events: state.events,
    groups: state.playbookInstances,
  }),
  (dispatch: ReduxDispatch) => ({
    getEvents: (params: { timeStart?: string }) => dispatch(EventDispatcher.getEvents(params)),
  }),
)

export default compose(connectStore, withStyles(styles))(Events)
