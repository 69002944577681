/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'
import ChallengeApplicationDispatcher from '@reducers/challengeAppplications/dispatcher'
import NotificationActionDispatcher from '@reducers/notification/actionDispatcher'
import React from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PaperContainer from '../../components/PaperContainer'
import apiErrorUtil from '../../libs/errors/apiErrorUtil'
import ChallengeApplicationForm from './components/ChallengeApplicationForm'

function ChallengeApplicationCreate() {
  const history = useHistory()
  const dispatch = useDispatch()
  const createAction = useAsyncHandler(async (form) => {
    try {
      const challenge_app_id = await dispatch(ChallengeApplicationDispatcher.createChallengeApp(form))
      if (!challenge_app_id) {
        NotificationActionDispatcher(dispatch).addErrorNotify({ message: 'Failed to create Challenge Application' })
        return null
      }
      history.push(`/challenge-applications`)
    } catch (e) {
      NotificationActionDispatcher(dispatch).addErrorNotify({ message: apiErrorUtil.getFormattedApiError(e) })
    }
  })

  return (
    <PaperContainer>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        css={css`
          margin-bottom: 20px;
        `}
      >
        <Typography variant={'h1'}>Create Application</Typography>
      </Grid>
      <ChallengeApplicationForm onSubmit={createAction.execute} />
    </PaperContainer>
  )
}

export default ChallengeApplicationCreate
