import _ from 'lodash'
import actions from './actions'
import api from '../../libs/api/apiOld'
import playbookActionDispatcher from '../playbooks/actionDispatcher'

export default dispatch =>
  bounded({
    async getPlaybookTypes() {
      const result = await api.getPlaybookTypes()
      dispatch(actions.set(result))
    },
    async addPlaybookType(playbookType) {
      await api.addPlaybookType(playbookType)
      return await this.getPlaybookTypes()
    },
    async setPlaybookType(playbookId, playbookTypeId) {
      await api.setPlaybookType(playbookId, playbookTypeId)
      return await playbookActionDispatcher(dispatch).getPlaybook(playbookId)
    },
    async editPlaybookType(id, playbookType) {
      await api.editPlaybookType(id, playbookType)
      return await this.getPlaybookTypes()
    },
    async deletePlaybookType(id) {
      await api.deletePlaybookType(id)
      return await this.getPlaybookTypes()
    },
  })

function bounded(object) {
  _.keys(object).forEach(key => {
    object[key] = object[key].bind(object)
  })
  return object
}

function timer() {
  return new Promise(resolve => setTimeout(resolve, 1000))
}
