/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Grid, Tab } from '@mui/material'
import { ReduxStateAgent } from '@reducers/agent/reducer'
import React, { useState } from 'react'
import UserSmsLogs from '../../users/user/UserSmsLogs'

interface Props {
  agent: ReduxStateAgent
}

const cssTabPanel = css`
  padding: 0;
`

function AgentPageTabs(props: Props) {
  const { agent } = props
  const [activeTab, setActiveTab] = useState('sms')
  const { user_id } = agent

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Grid container justifyContent={'flex-end'}>
            <TabList onChange={(e, tab) => setActiveTab(tab)}>
              {/* <Tab label="Devices" value="devices" /> */}
              <Tab label="SMS logs" value="sms" />
            </TabList>
          </Grid>
        </Box>
        {/* <TabPanel value="devices" css={cssTabPanel}> */}
        {/*  <EmployeeDevices employee={employee} /> */}
        {/* </TabPanel> */}
        <TabPanel value="sms" css={cssTabPanel}>
          <UserSmsLogs user_id={user_id} />
        </TabPanel>
        {/* <TabPanel value="3">Item Three</TabPanel> */}
      </TabContext>
    </Box>
  )
}

export default AgentPageTabs
