import React from 'react'
import { compose } from 'redux'
import { matchPath, RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Box, Divider, Grid, Dialog, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Styles } from 'jss'
import { Link } from 'react-router-dom'
import { WithStyles } from 'react-jss'
import _ from 'lodash'
import CoachListItem from './components/CoachListItem'
import ButtonOrange from '../../tasks/components/ButtonOrange'
import CompanyDispatcher from '../../../reducers/companies/dispatcher'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import EmployeeListItem from './components/EmployeeListItem'
import EditInstanceModal from './components/EditInstanceModal'

const styles: Styles = {
  container: {
    padding: '10px 20px',
  },
  paper: {
    marginTop: 30,
    width: '100%',
    overflow: 'hidden',
  },

  title: {
    backgroundColor: '#F9B22D',
    padding: '7px 30px',
    // color: '#'
  },

  playbooksListContainer: {
    width: 250,
    borderRight: '1px solid #e8d2d2',
    height: 300,
    overflowY: 'auto',
  },

  playbookName: {
    fontWeight: 'bold',
    marginLeft: 10,
  },

  playbookChange: {
    marginLeft: 16,
  },

  linkCoach: {
    marginLeft: 10,
    fontSize: 12,
  },

  permissionContainer: {
    margin: '10px 0 15px',
  },
  headContainer: {
    margin: '10px 0',
  },

  primaryCoachLink: {
    border: '1px #F9B22D solid',
    borderRadius: '8px',
    margin: '10px 0',
  },
  modal: {
    minWidth: 300,
    maxWidth: 1200,
    width: '40%',
  },
}

interface Props extends RouteComponentProps<{ id: string; instance_id: string }> {}

type ComponentProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WithStyles<typeof styles>
interface State {
  isModalOpened: boolean
}
class CompanyInstancesSubPage extends React.PureComponent<ComponentProps & Props, State> {
  state = {
    isModalOpened: false,
  }

  render() {
    const {
      company,
      classes,
      history,
      match: {
        params: { instance_id },
      },
    } = this.props
    const instance = _.find(company.instances, { id: instance_id })
    if (!instance) return null
    const employees = _.filter(company.employees, employee => employee.company_playbook_id === instance.id)

    const { playbook } = instance

    return (
      <Grid container justifyContent={'space-between'} className={classes.container}>
        <Grid item xs={6}>
          <Grid container direction={'row'} alignItems={'center'} className={classes.headContainer}>
            Playbook:
            {playbook && (
              <>
                <span className={classes.playbookName}>{playbook.name}</span>
                <Link to={`/playbook/${playbook.id}`} target={'_blank'} className={classes.linkCoach}>
                  [open page]
                </Link>
                <Link className={classes.playbookChange} to={`/playbooks/choose/${instance_id}`}> Change </Link>
              </>
            )}
          </Grid>
          <Divider />
        </Grid>
        <Grid item>
          <Grid container direction={'row'} alignItems={'center'} className={classes.headContainer}>
            <ButtonOrange onClick={() => this.setState({ isModalOpened: true })}> Edit </ButtonOrange>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction={'row'} alignItems={'center'} className={classes.headContainer}>
            <Typography variant={'h2'}>Coaches:</Typography>
            <Box ml={'auto'}>
              <ButtonOrange onClick={() => history.push(`/coaches/choose/${instance_id}`)}>Add coach</ButtonOrange>
            </Box>
            <Grid container>{!_.isEmpty(instance.coaches) && this.renderCoachList(instance.coaches)}</Grid>
          </Grid>
          <Grid container direction={'row'} alignItems={'center'} className={classes.headContainer}>
            <Typography variant={'h2'}>Employees:</Typography>
            <Grid container>{!_.isEmpty(employees) && this.renderEmployeeList(employees)}</Grid>
          </Grid>
        </Grid>
        {this.renderModal()}
      </Grid>
    );
  }

  renderModal = () => {
    const { isModalOpened } = this.state
    const { company, match, classes } = this.props
    const instance = _.find(company.instances, { id: match.params.instance_id })
    return (
      <Dialog
        classes={{
          paper: classes.modal,
        }}
        open={isModalOpened}
        onClose={() => this.setState({ isModalOpened: false })}
      >
        <EditInstanceModal finish={() => this.setState({ isModalOpened: false })} instance={instance} />
      </Dialog>
    )
  }

  renderCoachList = (coaches: Coach[]) => {
    const { classes } = this.props
    const primaryCoach = _.find(coaches, { is_primary: true })
    const filteredCoaches = _.filter(coaches, coach => !coach.is_primary)

    return (
      <>
        <Typography className={classes.primaryCoach}>Primary coach:</Typography>
        <CoachListItem
          coach={primaryCoach}
          style={{
            link: classes.primaryCoachLink,
          }}
          link_url={`/coach/${primaryCoach.user_id}`}
        />
        {filteredCoaches.map(coach => (
          <CoachListItem
            key={`coach-user-id-${coach.user_id}`}
            coach={coach}
            button={
              <ButtonOrange type={'button'} onClick={this.handleMakePrimary(coach)} size={'small'}>
                Make primary
              </ButtonOrange>
            }
            link_url={`/coach/${coach.user_id}`}
          />
        ))}
      </>
    )
  }

  renderEmployeeList = (employees: Employee[]) => {
    return _.orderBy(employees, ['points'], ['desc']).map(employee => (
      <Grid item lg={4}>
        <EmployeeListItem employee={employee} />
      </Grid>
    ))
  }

  handleMakePrimary = (coach: Coach) => e => {
    e.preventDefault()
    if (!window.confirm(`Are you sure you want make coach ${coach.name} primary?`)) return
    this.props.makeCoachPrimary(coach.user_id)
  }
}

const mapStateToProps = (state: ReduxState, { match }: Props) => {
  const company = state.companies[match.params.id]

  return {
    company,
  }
}

const mapDispatchToProps = (dispatch: ReduxDispatch, { match }: Props) => ({
  makeCoachPrimary: (coach_id: string) =>
    dispatch(
      CompanyDispatcher.makeCoachPrimary({
        instance_id: match.params.instance_id,
        coach_id,
        company_id: match.params.id,
      }),
    ),
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(CompanyInstancesSubPage)
