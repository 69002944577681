import { Grid, TextField } from '@mui/material'
import { InputProps as StandardInputProps } from '@mui/material/Input/Input'
import { withStyles } from '@mui/styles'
import { APIEventUpdatePayload } from '@reducers/events/apiEvents'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import validate from 'validate.js'
import { ExtractStyles } from '../../../../../typings/stylesExtractor'
import ButtonOrange from '../../../../components/ButtonOrange'
import EventCoachSelect from '../eventCreate/components/EventCoachSelect'
import EventTimeSelect from '../eventCreate/components/EventTimeSelect'

const connectStyles = withStyles({
  buttonsContainer: {
    marginTop: 20,
  },
})

interface Errors {
  name?: string
  description?: string
  coach_id?: string
  duration?: string
  location_formatted?: string
  time?: string
}

interface State {
  name: string
  description: string
  coach_id: string
  location: string
  time: Date
  errors: Errors
  submitErrorMessage?: string
}

interface Props {
  event: ReactionEvent
  onUpdate(payload: APIEventUpdatePayload): Promise<any>
}

interface PropsInner extends Props, ExtractStyles<typeof connectStyles> {}

class EventUpdateForm extends React.PureComponent<PropsInner, State> {
  state: State = {
    name: '',
    description: '',
    coach_id: '',
    location: '',
    time: new Date(),
    // playbook_instance_id: null,
    errors: {},
    submitErrorMessage: '',
  }

  constructor(props: PropsInner) {
    super(props)

    const { event } = props
    this.state.name = event.name
    this.state.coach_id = event.coach_id
    this.state.location = event.location_formatted
    this.state.description = event.description
    this.state.time = moment(event.time).toDate()
  }

  render() {
    const { name, description, coach_id, location, time, errors } = this.state
    const { classes } = this.props

    return (
      <div>
        <EventCoachSelect value={coach_id} onChange={this.handleCoachChange} isErrored={!!errors.coach_id} />

        <EventTimeSelect value={time} onChange={this.handleTimeChange} isErrored={!!errors.time} />

        <TextField
          multiline
          value={location}
          label={'Location'}
          onChange={this.handleLocationChange}
          fullWidth
          error={!!errors.location_formatted}
        />

        <TextField value={name} label={'Name'} onChange={this.handleNameChange} fullWidth error={!!errors.name} />

        <TextField
          multiline
          value={description}
          label={'Description'}
          onChange={this.handleDescriptionChange}
          fullWidth
          error={!_.isEmpty(errors.description)}
        />

        <Grid container justifyContent={'center'} className={classes.buttonsContainer}>
          <ButtonOrange onClick={this.handleSubmit}>Update</ButtonOrange>
        </Grid>
      </div>
    );
  }

  handleNameChange: StandardInputProps['onChange'] = e => {
    const { errors } = this.state
    delete errors.name
    this.setState({ name: e.target.value, errors })
  }

  handleDescriptionChange: StandardInputProps['onChange'] = e => {
    const { errors } = this.state
    delete errors.description
    this.setState({ description: e.target.value, errors })
  }

  handleLocationChange: StandardInputProps['onChange'] = e => {
    const { errors } = this.state
    delete errors.location_formatted
    this.setState({ location: e.target.value, errors })
  }

  handleCoachChange = (coach_id: string) => {
    const { errors } = this.state
    delete errors.coach_id
    this.setState({ coach_id, errors })
  }

  handleTimeChange = (time: Date) => {
    const { errors } = this.state
    delete errors.time
    this.setState({ time, errors })
  }

  handleSubmit = async () => {
    const { coach_id, description, location, name, time, submitErrorMessage } = this.state

    if (submitErrorMessage) {
      this.setState({ submitErrorMessage: '' })
    }

    const form: APIEventUpdatePayload = {
      coach_id,
      description,
      location_formatted: location,
      name,
      time: moment(time).toISOString(),
    }

    if (!this.isValid(form)) {
      return null
    }

    try {
      await this.props.onUpdate(form)
    } catch (e) {
      const { data } = e?.response || {}
      const eMessage = data?.error || e.message
      this.setState({
        submitErrorMessage: eMessage,
      })
    }
  }

  isValid = (form: APIEventUpdatePayload): boolean => {
    const errors: Errors = validate(form, validationSchema) || {}

    const nowMoment = moment()
    if (moment(form.time).isBefore(nowMoment)) {
      errors.time = 'Time is invalid. Select future date'
    }

    if (!form.coach_id) {
      errors.coach_id = 'Coach is not set'
    }

    console.log('errors', errors)

    this.setState({ errors })

    return _.isEmpty(errors)
  }
}

export default connectStyles(EventUpdateForm)

const validationSchema = {
  name: {
    presence: {
      message: 'Name is required',
    },
    length: {
      minimum: 3,
      tooShort: 'Name at least %{count} letters',
    },
  },
  description: {
    presence: {
      message: 'Description is required',
    },
    length: {
      minimum: 3,
      tooShort: 'Description at least %{count} letters',
    },
  },
  location_formatted: {
    presence: {
      message: 'Fill meet place',
    },
    length: {
      minimum: 3,
      tooShort: 'Location at least %{count} letters',
    },
  },
}
