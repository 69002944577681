import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import * as React from 'react'
import { StyledTableCell } from '../../../../elements/table/TableDefault'

interface Props {
  children: React.ReactNode
}
export default function ZoomHostsTable(props: Props) {
  const { children } = props

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table" stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Email</StyledTableCell>
            <StyledTableCell align="center">Is Pool</StyledTableCell>
            <StyledTableCell align="center">Attached</StyledTableCell>
            <StyledTableCell align="center">Webinars</StyledTableCell>
            <StyledTableCell align="center">Enabled</StyledTableCell>
            <StyledTableCell align="center">Target</StyledTableCell>
            <StyledTableCell align="center">Comment</StyledTableCell>
            <StyledTableCell align="left" />
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  )
}
