import { DeleteOutline } from '@mui/icons-material'
import { Box, Button, CardActionArea } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { Asset } from '@reaction-club-types/core'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Progress } from 'react-sweet-progress'
import fileExplorer from 'web-file-explorer'
import api from '../../../libs/api/apiOld'
import uploadIcon from '../../tasks/assets/uploadIcon.png'
import AssetPicture from './AssetPicture'

const connectStyles = withStyles({
  container: {
    height: 150,
    backgroundColor: '#7f7f7f',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },

  uploadIcon: {
    width: 16,
    height: 16,
    objectFit: 'contain',
    marginBottom: 12,
  },

  uploadTitle: {
    color: '#fff',
    fontSize: 11,
    fontWeight: 'bold',
  },

  loadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.8)',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  progressText: {
    color: '#fff',
  },

  deleteIcon: {
    color: 'white',
  },
})

interface Props {
  classes: any
  asset: Asset | null
  onChange: (asset: Asset) => void
  disabled?: boolean
  className?: string
  deleteActive?: boolean
  imageClassName?: string
}

interface State {
  progress: number
  isLoadingVisible: boolean
  status: 'idle' | 'active' | 'success' | 'error'
}
class AssetEditable extends React.PureComponent<Props, State> {
  static propTypes: any = {
    asset: PropTypes.object,
    onChange: PropTypes.func.isRequired,
  }

  state: State = {
    progress: 0,
    status: 'idle',
    isLoadingVisible: false,
  }

  render() {
    const { classes, className, disabled = false } = this.props

    const containerClasses = [classes.container]
    if (className) containerClasses.push(className)

    return (
      <Box sx={{ position: 'relative' }}>
        <CardActionArea
          onClick={this.handlePress}
          onKeyPress={this.handlePress}
          tabIndex={-1}
          role={'button'}
          disabled={disabled}
          className={containerClasses.join(' ')}
        >
          {this.renderBody()}
          {this.renderLoading()}
        </CardActionArea>
        {this.renderDelete()}
      </Box>
    )
  }

  renderDelete() {
    const { classes, disabled = false, asset, deleteActive = false } = this.props
    if (!asset || disabled || !deleteActive) {
      return null
    }

    return (
      <Button
        sx={{ position: 'absolute', backgroundColor: 'red', height: 25, right: 5, top: 5 }}
        disabled={disabled}
        onClick={() => this.handleDelete()}
      >
        <DeleteOutline className={classes.deleteIcon} />
      </Button>
    )
  }

  renderBody() {
    const { classes, asset, imageClassName } = this.props
    const imageClasses = [classes.uploadIcon]
    if (imageClassName) imageClasses.push(imageClassName)

    if (_.isEmpty(asset)) {
      return (
        <>
          <img src={uploadIcon} alt={''} className={imageClasses.join(' ')} />
          <span className={classes.uploadTitle}>Upload Image</span>
        </>
      )
    }

    return <AssetPicture asset={asset} />
  }

  renderLoading() {
    const { classes } = this.props
    const { isLoadingVisible, progress, status } = this.state
    if (!isLoadingVisible) return null

    // const status = progress != 100 ? 'active' : 'success'

    return (
      <div className={classes.loadingContainer} onClick={this.handlePressLoading} onKeyDown={this.handlePressLoading}>
        <Progress
          type="circle"
          percent={progress}
          status={status}
          width={50}
          color={'#fff'}
          symbolClassName={classes.progressText}
          theme={{
            active: {
              symbol: `${progress.toFixed(0)}%`,
              trailColor: 'rgba(255, 255, 255, 0.3)',
              color: '#fff',
            },
          }}
        />
        {/* <CircularProgress value={90} size={60} color={'inherit'} className={classes.progress} variant={'determinate'} /> */}
      </div>
    )
  }

  handleDelete = () => {
    this.setState({ status: 'idle' })
    this.props.onChange(null)
  }

  handlePressLoading = (e) => {
    e.stopPropagation() // doesn't help
  }

  handlePress = async () => {
    if (this.state.isLoadingVisible) return null

    const file = await fileExplorer.getFile({
      acceptImage: true,
    })

    await this.upload(file)
  }

  upload = async (file: File) => {
    const uploadFile = new FormData()
    uploadFile.append('file', file)

    this.setState({ isLoadingVisible: true, status: 'active' })

    try {
      const asset = await api.uploadImg(uploadFile, {
        onUploadProgress: (event) => {
          this.setState({ progress: (event.loaded / event.total) * 100 })
        },
      })

      this.setState({ status: 'success' })
      this.props.onChange(asset)
    } catch (e) {
      this.setState({ status: 'error' })
      console.error('upload error', e)
    }

    setTimeout(() => this.setState({ isLoadingVisible: false }), 300)
  }
}

export default connectStyles(AssetEditable)
