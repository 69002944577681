import Immutable from 'seamless-immutable'

import types from './actionTypes'

const initialState = Immutable({
  list: [],
  LOADED: false,
  programs: [],
})
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET: {
      return state.merge({
        list: action.payload,
        LOADED: true,
        current: state.current ? state.current : [],
        programs: state.programs ? state.programs : [],
      })
    }
    case types.SET_PLAYBOOK_DETAIL: {
      return state.merge({
        ...state,
        current: action.payload,
      })
    }
    // case types.SET_PLAYBOOK_PROGRAMS: {
    //   return state.merge({
    //     ...state,
    //     programs: action.payload,
    //   })
    // }
    case types.SET_PRIVATE_COACHES: {
      return state.merge({
        ...state,
        current: {
          ...state.current,
          privateCoaches: action.payload,
        },
      })
    }
    case types.SET_PRIVATE_COMPANIES: {
      return state.merge({
        ...state,
        current: {
          ...state.current,
          privateCompanies: action.payload,
        },
      })
    }
    default:
      return state
  }
}
