import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React from 'react'
import DatePicker from 'react-datepicker'
import FormLabel from '../../../../../elements/form/FormLabel'
import EventTimeCustomInput from './elements/EventTimeCustomInput'

const useStyles = makeStyles(
  {
    input: {
      padding: '12px 10px !important',
      borderRadius: '4px !important',
      '&:before': {
        content: 'none !important',
      },
    },

    inputErrored: {
      borderColor: 'red !important',
    },
  },
  {
    classNamePrefix: 'EventTimeSelect',
  },
)

interface Props {
  value: Date
  onChange(type: string | undefined | Date): any
  isErrored?: boolean
}

export default function EventTimeSelect(props: Props) {
  const { value, onChange, isErrored } = props

  const classes = useStyles()

  const handleChange = (date: Date) => {
    console.log('EventTimeSelect handleChange', date)
    onChange(date)
  }

  return (
    <div>
      <FormLabel>Time</FormLabel>
      <DatePicker
        selected={value}
        showTimeSelect
        // className={errors.startDate ? classes.error : ''}
        onChange={handleChange}
        placeholderText="Start date"
        customInput={
          <EventTimeCustomInput
            time={value}
            className={classNames(classes.input, {
              [classes.inputErrored]: isErrored,
            })}
          />
        }
      />
    </div>
  )
}
