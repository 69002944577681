import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import withStyles from '@mui/styles/withStyles';
import { WithStyles, Styles } from 'react-jss'
import { compose } from 'redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { Divider, Grid } from '@mui/material'
import CompanyCoachPermissions from './components/CompanyCoachPermissions'
import CompanyDispatcher from '../../../reducers/companies/dispatcher'

const styles: Styles = {
  container: {
    padding: '10px 20px',
  },
  paper: {
    marginTop: 30,
    width: '100%',
    overflow: 'hidden',
  },

  title: {
    backgroundColor: '#F9B22D',
    padding: '7px 30px',
    // color: '#'
  },

  coachesListContainer: {
    width: 250,
    borderRight: '1px solid #e8d2d2',
    height: 300,
    overflowY: 'auto',
  },

  coachName: {
    fontWeight: 'bold',
    marginLeft: 10,
  },

  linkCoach: {
    marginLeft: 10,
    fontSize: 12,
  },

  permissionContainer: {
    margin: '10px 0 15px',
  },
  headContainer: {
    marginBottom: 20,
  },
}

interface Props extends RouteComponentProps<{ id: string; coach_id: string }> {}

class CompanyCoachSubPage extends React.PureComponent<
  Props & WithStyles<typeof styles> & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> {
  state = {}

  render() {
    const { company, classes, match } = this.props
    const coach = _.find(company.coaches, { user_id: match.params.coach_id })
    if (!coach) return null

    const { user_id } = coach

    return (
      <Grid container justifyContent={'space-between'} className={classes.container}>
        <Grid item>
          <Grid container direction={'row'} alignItems={'center'} className={classes.headContainer}>
            Coach: <span className={classes.coachName}>{coach.name}</span>
            <Link to={`/coach/${user_id}`} target={'_blank'} className={classes.linkCoach}>
              [open page]
            </Link>
          </Grid>

          <Divider />

          <CompanyCoachPermissions coach={coach} onChange={this.handlePermissionsChange} />

          <Divider />
        </Grid>
      </Grid>
    );
  }

  handlePermissionsChange = async (permissions: string[]) => {
    await this.props.saveCoachPermissions(permissions)
  }
}

const mapStateToProps = (state: ReduxState, { match }: Props) => {
  const company = state.companies[match.params.id]

  return {
    company,
  }
}

const mapDispatchToProps = (dispatch, { match }: Props) => ({
  saveCoachPermissions: (permissions: string[]) =>
    dispatch(
      CompanyDispatcher.saveCoachPermissions({
        coach_id: match.params.coach_id,
        company_id: match.params.id,
        permissions,
      }),
    ),
})

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(CompanyCoachSubPage)
