import React from 'react'
import { Grid } from '@mui/material'
import Loader from 'react-loader-spinner'

function Reactionloader() {
  return (
    <Grid container justifyContent="center">
      <Loader type={'CradleLoader'} color={'#332323'} height={100} width={100} />
    </Grid>
  );
}

export default React.memo(Reactionloader)
