import { Grid } from '@mui/material'
import { Styles } from 'jss'
import _ from 'lodash'
import React from 'react'
import withStyles, { WithStyles } from 'react-jss'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import ReactionPlacesAutocomplete from '../../../components/Location/PlacesAutocomplete'
import CoachesPageDispatcher from '../../../reducers/coachesPage/dispatcher'

const styles: Styles = {
  input: {
    border: `1px solid #aaa`,
    padding: '0.5rem 1rem',
    borderRadius: 8,
    color: '#323232',
    outline: 'none',
  },

  categoriesContainer: {
    marginTop: 10,
  },

  categoryItem: {
    display: 'inline-block',
    padding: '5px 15px',
    borderRadius: 6,
    border: '1px solid #F9B22D',
    color: '#323232',
    fontWeight: 'bold',
    textAlign: 'center',
    marginRight: 10,
    cursor: 'pointer',
  },

  categoryItemActive: {
    backgroundColor: '#F9B22D',
  },
}

interface Props {
  onFetch(): Promise<void>
}

class CoachesFilter extends React.PureComponent<
  Props & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & WithStyles<typeof styles>
> {
  render() {
    const { classes, name } = this.props
    return (
      <Grid container>
        <div>
          <input
            type="text"
            value={name}
            className={classes.input}
            placeholder="Coach Name"
            onChange={this.handleChangeName}
          />
        </div>
        <ReactionPlacesAutocomplete handleAddressGeocoded={this.handleAddressGeocoded} />
      </Grid>
    )
  }

  handleAddressGeocoded = (coordinates) => {
    this.props.setCoordinates(coordinates)
    this.getCoachDebounced()
  }

  handleChangeName: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    this.props.setName(e.target.value || '')
    this.getCoachDebounced()
  }

  getCoachDebounced = _.debounce(
    function fetch() {
      // @ts-ignore
      this.props.onFetch()
    },
    1000,
    { maxWait: 300 },
  )
}

const mapStateToProps = (state: ReduxState) => ({
  name: state.coachesPage.name,
  coordinates: state.coachesPage.cooringates,
})

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  setName: (name) => dispatch(CoachesPageDispatcher.actions.setName(name)),
  setCoordinates: (coordinates) => dispatch(CoachesPageDispatcher.actions.setCoordinates(coordinates)),
})

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(CoachesFilter as any)
