import { Employee } from '@reaction-club-types/core'
import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
  employees: Employee[]
  employeesIsNextBlocked?: boolean
}

const initialState: State = {
  employees: [],
}

const dashboardNextSlice = createSlice({
  name: 'CompaniesPage',
  initialState,
  extraReducers: {
    [appTypes.RESET]: (state, action) => {
      return initialState
    },
  },
  reducers: {
    setEmployees(state, action: PayloadAction<Employee[]>) {
      const list: Employee[] = action.payload
      state.employees = list
      state.employeesIsNextBlocked = list.length < 20
    },

    setEmployeesNext(state, action: PayloadAction<Employee[]>) {
      const list: Employee[] = action.payload
      state.employees = state.employees.concat(list)
      state.employeesIsNextBlocked = list.length < 20
    },
  },
})

export const { actions, reducer } = dashboardNextSlice
export default reducer
