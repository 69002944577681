import { Autocomplete } from '@mui/material'
import TextField from '@mui/material/TextField'
import { ChallengeType } from '@reducers/challengeAppplications/api'
import ChallengeApplicationDispatcher from '@reducers/challengeAppplications/dispatcher'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'

interface Props {
  disabled?: boolean
  value: { name: string; label: string; id: string }
  onChange: ({ id, name, label }: { name: string; label: string; id: string; result_type: string }) => void
}
function ChallengeTypeSelect(props: Props) {
  const { disabled = false, value, onChange } = props
  const dispatch = useDispatch<ReduxDispatch>()
  const [challengeTypes, setChallengeTypes] = useState<ChallengeType[]>([])

  useEffect(() => {
    getChallengeTypes()
  }, [])

  const currentValue = useMemo(
    () => challengeTypes?.find((i) => i.name === value.name) || null,
    [value, challengeTypes],
  )

  const getChallengeTypes = async () => {
    const response = await dispatch(ChallengeApplicationDispatcher.getChallengeTypes())
    setChallengeTypes(response)
  }

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const label = e?.target.value
    const typeObject = challengeTypes.find((i) => i.label === label)
    if (typeObject) {
      onChange({ name: typeObject.name, label, id: typeObject.id, result_type: typeObject.result_type })
    }
  }

  return (
    <Autocomplete
      disablePortal
      options={challengeTypes}
      value={currentValue}
      onSelect={handleSelect}
      getOptionLabel={(i) => i.label}
      disabled={disabled}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} required label="Challenge Type" variant="outlined" margin="dense" />
      )}
    />
  )
}

export default ChallengeTypeSelect
