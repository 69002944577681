import { EventRecording } from '@reaction-club-types/core'
import apiClient from '../../lib/apiClient'

export interface APIEventCreatePayload {
  name: string
  coach_id: string
  group_id?: string | null
  description: string
  time: string
  duration?: number
  location_formatted: string
  location?: string
  repeat?: string
  participant_limit?: number
  workout_type: string
  language?: string
}

export interface APIEventUpdatePayload {
  name: string
  coach_id: string
  description: string
  time: string
  location_formatted: string
  participant_limit?: number
}

const apiEvents = {
  getEvents: async ({ timeStart }: { timeStart?: string }) => {
    const result = await apiClient.get('/event/list', { params: { timeStart } })
    return result.data
  },
  getReports: async ({ date }: { date?: string }) => {
    const result = await apiClient.get('/reports', { params: { date } })
    return result.data
  },
  getById: async (event_id: string) => {
    const result = await apiClient.get(`/event/${event_id}`)
    return result.data
  },
  getEmployees: async (event_id: string) => {
    const result = await apiClient.get(`/event/${event_id}/employees`)
    return result.data
  },
  async getParticipants(event_id: string): Promise<EventParticipant[]> {
    const { data } = await apiClient.get(`/event/${event_id}/participants`)
    return data
  },
  async getRecordings(event_id: string): Promise<EventRecording[]> {
    const { data } = await apiClient.get(`/event/${event_id}/recordings`)
    return data
  },
  async createEvent(payload: APIEventCreatePayload) {
    const { data } = await apiClient.post('/event/create', payload)
    return data
  },
  async updateEvent(event_id: string, payload: APIEventUpdatePayload) {
    const { data } = await apiClient.post(`/event/${event_id}`, payload)
    return data
  },
  async deleteEvent(event_id: string) {
    const { data } = await apiClient.delete(`/event/${event_id}`)
    return data
  },
  async deleteRecording(event_id: string, key: string) {
    const { data } = await apiClient.post(`/event/${event_id}/recording/delete`, null, {
      params: {
        key,
      },
    })
    return data
  },
}

export default apiEvents
