// COMPANY REQUESTS
import apiClient from '../../lib/apiClientOld'
import { prefix_web } from './apiOld'

export default {
  async getChallengeApplications(options = {}): Promise<any[]> {
    const { offset = 0, name } = options

    const query = {
      offset,
      name,
    }
    const result = await apiClient.get(`${prefix_web}challenge-apps`, {
      params: query,
    })
    return result.data
  },

  async getChallengeApplicationById(application_id: string): Promise<any> {
    const result = await apiClient.get(`${prefix_web}challenge-app/${application_id}`)
    return result.data
  },
}
