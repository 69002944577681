import apiClient from '../../lib/apiClient'
import apiClientOld from '../../lib/apiClientOld'
import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import appActions from '../app/actions'
import actions from './actions'
import apiAuth from './api'

const AuthDispatcher = {
  login(payload: { username: string; password: string }): DispatcherResponse {
    return async dispatch => {
      const data = await apiAuth.login(payload)
      await dispatch(this.setTokens({ token: data.token, role: data.role }))
    }
  },

  loginWithFirebaseToken(firebaseToken: string): DispatcherResponse {
    return async dispatch => {
      const data = await apiAuth.loginWithFirebaseToken(firebaseToken, null)
      await dispatch(this.setTokens({ token: data.token, role: data.role }))
      return data
    }
  },

  setTokens(params: { token: string; role: string }): DispatcherResponse {
    return async dispatch => {
      dispatch(actions.setToken(params))
      await dispatch(this.useCredentials())
      await dispatch(actions.setIsAuthorized(true))
    }
  },

  useCredentials(): DispatcherResponse {
    return async (dispatch, getState) => {
      const { auth } = getState()
      const { token } = auth

      apiClient.defaults.headers.common['Authorization'] = token
      apiClientOld.defaults.headers.common['Authorization'] = token // new
    }
  },

  logout(): DispatcherResponse {
    return async dispatch => {
      delete apiClient.defaults.headers.common['Authorization']
      dispatch(actions.logout())
      dispatch(appActions.resetStore())

      // location.reload() // update this row to different logic
    }
  },
}

export default AuthDispatcher
