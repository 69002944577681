import { Grid, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material'
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { makeStyles } from '@mui/styles'
import { ReactionEvent } from '@reaction-club-types/core'
import EventDispatcher from '@reducers/events/dispatcher'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import CloseButton from '../../../../components/CloseButton'
import EventUpdateModal from '../EventUpdateModal'

const useStyles = makeStyles(
  {},
  {
    classNamePrefix: 'EventInfoHeadActions',
  },
)

interface Props {
  event: ReactionEvent
  onClose(): any
}

function EventInfoHeadActions(props: Props) {
  const { event, onClose } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isMenuVisible, setMenuVisibility] = useState(false)
  const [isEditModalVisible, setEditModalVisibility] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMenuButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
    handleOpenMenu()
  }

  const handleDeleteEvent = async () => {
    const isConfirmed = confirm('Are you sure want to delete this event?')
    if (!isConfirmed) return null
    await dispatch(EventDispatcher.deleteEvent(event.id))
    onClose()
  }

  const handleOpenMenu = () => setMenuVisibility(true)
  const handleCloseMenu = () => setMenuVisibility(false)

  const handleUpdateModalOpen = () => setEditModalVisibility(true)
  const handleUpdateModalClose = () => setEditModalVisibility(false)

  return (
    <div>
      <EventUpdateModal event={event} isVisible={isEditModalVisible} onClose={handleUpdateModalClose} />
      <Grid container justifyContent={'flex-end'} alignItems={'center'}>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleMenuButtonClick}
          size="large">
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          open={isMenuVisible}
          onClose={handleCloseMenu}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          <MenuItem onClick={handleUpdateModalOpen}>
            <ListItemIcon>
              <EditIcon color={'primary'} fontSize={'small'} />
            </ListItemIcon>
            <Typography variant="inherit">Edit Event</Typography>
          </MenuItem>

          <MenuItem onClick={handleDeleteEvent}>
            <ListItemIcon>
              <DeleteIcon color={'primary'} fontSize={'small'} />
            </ListItemIcon>
            <Typography variant="inherit">Delete Event</Typography>
          </MenuItem>
        </Menu>
        <CloseButton position={'static'} onPress={onClose} />
      </Grid>
    </div>
  );
}

export default EventInfoHeadActions
