/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import ChallengeTypeDispatcher from '@reducers/challengeTypes/dispatcher'
import { ReduxState } from '@reducers/index'
import NotificationActionDispatcher from '@reducers/notification/actionDispatcher'
import React from 'react'
import { useAsyncFetch, useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useParams } from 'react-router-dom'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import Loader from '../../components/Loader'
import PaperContainer from '../../components/PaperContainer'
import apiErrorUtil from '../../libs/errors/apiErrorUtil'
import ChallengeTypeForm, { FORM_TYPES } from './components/ChallengeTypeForm'

function ChallengeTypePage() {
  const params = useParams<{ id: string }>()
  const appId = params.id

  const { challengeType } = useSelector((state: ReduxState) => ({
    challengeType: state.challengeTypes[appId],
  }))

  const dispatch = useDispatch<ReduxDispatch>()
  const fetchData = useAsyncFetch(async () => {
    await dispatch(ChallengeTypeDispatcher.getChallengeType(appId))
  }, [appId])

  const updateAction = useAsyncHandler(async (form) => {
    try {
      await dispatch(ChallengeTypeDispatcher.updateChallengeType(appId, form))
    } catch (e) {
      NotificationActionDispatcher(dispatch).addErrorNotify({ message: apiErrorUtil.getFormattedApiError(e) })
    }
  })

  return (
    <PaperContainer>
      <RendererStatusSplit
        statuses={fetchData}
        isEmpty={!challengeType}
        renderLoading={() => (
          <Grid
            container
            justifyContent={'center'}
            css={css`
              padding-top: 20px;
            `}
          >
            <Loader />
          </Grid>
        )}
        renderEmpty={() => (
          <Grid container justifyContent={'center'}>
            <Typography>Challenge Type is not found</Typography>
          </Grid>
        )}
        render={() => (
          <ChallengeTypeForm onSubmit={updateAction.execute} initialData={challengeType} formType={FORM_TYPES.UPDATE} />
        )}
      />
    </PaperContainer>
  )
}

export default ChallengeTypePage
