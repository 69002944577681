import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Coach } from '@reaction-club-types/core'
import { ReduxState } from '@reducers/index'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import FormLabel from '../../../../../elements/form/FormLabel'
import CoachSelectModal from '../../../../../modals/CoachSelectModal'
import EventCoachSelectPreview from './components/EventCoachSelectPreview'

const useStyles = makeStyles(
  {
    container: {
      position: 'relative',
    },
  },
  {
    classNamePrefix: 'EventCoachSelect',
  },
)

interface Props {
  value: string
  isErrored?: boolean
  onChange(coach_id: string | undefined): any
}

export default function EventCoachSelect(props: Props) {
  const { value, onChange, isErrored } = props
  const classes = useStyles()
  const [isModalVisible, setModalVisibility] = useState(false)
  const { coaches } = useSelector((state: ReduxState) => ({
    coaches: state.coaches,
  }))

  const itemSelected = coaches[value]

  let controlStyle: { borderColor?: string } = {}
  if (isErrored) {
    controlStyle.borderColor = 'red'
  }

  const handleClickSelect = () => {
    setModalVisibility(true)
  }

  const handleModalClose = () => setModalVisibility(false)

  const handleSelect = async (coach: Coach, isChecked: boolean) => {
    onChange(coach.user_id)
    handleModalClose()
  }

  return (
    <div className={classes.container}>
      <CoachSelectModal
        isVisible={isModalVisible}
        onClose={handleModalClose}
        onSelect={handleSelect}
        selectedList={[]}
      />
      <Grid container justifyContent={'space-between'}>
        <FormLabel>Coach</FormLabel>
      </Grid>
      <div>
        <EventCoachSelectPreview coach={itemSelected} onClick={handleClickSelect} />
      </div>
    </div>
  );
}
