import React from 'react'
import { Grid, Box } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactionAvatar from '../../../../components/asset/ReactionAvatar'
import ButtonOrange from '../../../tasks/components/ButtonOrange'
import { ReduxDispatch } from '../../../../../typings/ReduxDispatch'
import PlaybookInstanceDispatcher from '../../../../reducers/playbookInstances/dispatcher'
import fileExplorer from 'web-file-explorer'
import api from '../../../../libs/api/apiOld'

interface Props {
  instance: PLaybookInstance
}

const styles = {
  container: {
    padding: 50,
  },
  textInputContainer: {
    margin: '15px 0',
    width: '100%',
  },
  titleInput: {
    fontSize: 18,
    fontWeight: 600,
  },
  textInput: {
    fontSize: 12,
  },
  avatarWrapper: {
    cursor: 'pointer',
  },
}

interface State {
  name: string
  description: string
  asset?: AssetFull
}

interface Props {
  finish: () => void
}

class EditInstanceModal extends React.PureComponent<
  Props & WithStyles<typeof styles> & ReturnType<typeof mapDispatchToProps>,
  State
> {
  constructor(props) {
    super(props)
    const { instance } = this.props

    if (instance) {
      const { name, description, asset } = instance
      this.state = {
        name,
        description,
        asset,
      }
    } else {
      this.state = {
        name: '',
        description: '',
        asset: undefined,
      }
    }
  }

  render() {
    const { classes } = this.props
    const { name, asset, description } = this.state

    return (
      <Grid container direction={'column'} className={classes.container} justifyContent={'center'} alignItems={'center'}>
        <Box className={classes.avatarWrapper} onClick={this.handleClickAvatar}>
          <ReactionAvatar asset={asset} resizeMode={'cover'} containerStyles={{ width: 100, height: 100 }} />
        </Box>
        <TextField
          type="text"
          value={name}
          inputProps={{
            className: classes.titleInput,
          }}
          onChange={this.handleChange('name')}
          placeholder={'Name'}
        />

        <TextField
          type="text"
          value={description}
          className={classes.textInputContainer}
          rows={6}
          inputProps={{
            className: classes.textInput,
          }}
          onChange={this.handleChange('description')}
          placeholder={'Description'}
          multiline
        />
        <ButtonOrange size={'large'} onClick={this.handleSave}>
          {' '}
          Save{' '}
        </ButtonOrange>
      </Grid>
    );
  }

  handleChange = (name: string) => e => {
    this.setState({ [name]: e.target.value })
  }

  handleClickAvatar = async () => {
    const file = await fileExplorer.getFile()
    const asset: AssetFull = await api.uploadFile(file)

    if (asset) {
      this.setState({ asset })
    }
  }

  handleSave = async () => {
    const { name, asset, description } = this.state
    const { instance, editInstance, createInstance, finish, match } = this.props

    if (instance) {
      await editInstance(instance, { name, asset, description })
    } else {
      await createInstance({ name, asset, description, company_id: match.params.id })
    }
    finish()
  }
}

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  createInstance: (payload: { name: string; description?: string; asset?: AssetFull; company_id: string }) =>
    dispatch(PlaybookInstanceDispatcher.create(payload)),
  editInstance: (instance: PlaybookInstance, payload: { name: string; description?: string; asset?: AssetFull }) =>
    dispatch(PlaybookInstanceDispatcher.edit(instance, payload)),
})

export default compose(connect(null, mapDispatchToProps), withStyles(styles), withRouter)(EditInstanceModal)
