import Immutable from 'seamless-immutable'

import types from './actionTypes'

const initialState: ReduxStatePlaybookCategories = {
  nutrition: {
    id: 'nutrition',
    name: 'Nutrition',
    types: [],
  },

  fitness: {
    id: 'fitness',
    name: 'Fitness',
    types: [],
  },

  mindfulness: {
    id: 'mindfulness',
    name: 'Mindfulness',
    types: [],
  },
}

export default function reducer(state = Immutable(initialState), action = {}) {
  switch (action.type) {
    case types.SET_TYPES: {
      const { category_id, types: playbook_types } = action.payload

      return state.updateIn([category_id], (category: Immutable<ReduxStatePlaybookCategoriesItem>) =>
        category.merge({
          types: playbook_types,
          typesRemoteUpdated: true,
        }),
      )
    }

    default:
      return state
  }
}
