import Immutable from 'seamless-immutable'

import types from './actionTypes'

export default function reducer(state = Immutable({}), action = {}) {
  switch (action.type) {
    case types.SET: {
      return state.merge({
        current: action.payload,
        coaches: state.coaches ? state.coaches : [],
        employees: state.employees ? state.employees : [],
        playbooks: state.playbooks ? state.playbooks : [],
        stickers: state.stickers ? state.stickers : [],
        _playbooksOriginal: state._playbooksOriginal ? state._playbooksOriginal : [],
        searchCoach: '',
      })
    }
    case types.SET_COMPANY_EMPLOYEES: {
      return state.merge({
        ...state,
        employees: action.payload,
      })
    }
    case types.SET_COMPANY_COACHES: {
      return state.merge({
        ...state,
        coaches: action.payload,
      })
    }
    case types.FILTER_COACHES: {
      return state.merge({
        searchCoach: action.payload,
      })
    }
    case types.SET_COMPANY_PLAYBOOK: {
      return state.merge({
        ...state,
        playbooks: action.payload,
        _playbooksOriginal: action.payload,
      })
    }
    case types.REMOVE_PLAYBOOK: {
      let originPlaybooks = state.playbooks
      const removeByItem = (arr, value) => arr.filter(item => item.playbook_id !== value.id)
      const newPaybooks = removeByItem(originPlaybooks, action.payload)
      return state.merge({
        playbooks: newPaybooks,
      })
    }
    case types.ADD_PLAYBOOK: {
      let { playbooks } = state
      const addItem = (arrays, value) => arrays.concat(value)
      return state.merge({
        playbooks: addItem(playbooks, action.payload),
      })
    }
    case types.SET_COMPANY_STICKERS: {
      return state.merge({
        ...state,
        stickers: action.payload,
      })
    }

    default:
      return state
  }
}
