// COACHES REQUESTS
import { prefix_web } from './apiOld'

export default {
  async getHosts(): Promise<ZoomHost[]> {
    const result = await this.apiClient.get(`${prefix_web}zoom-hosts`)
    return result.data
  },
  async getAvailableHosts(): Promise<ZoomHost[]> {
    const result = await this.apiClient.get(`${prefix_web}zoom-hosts/available`)
    return result.data
  },
  async createHost(zoom_host_id: string): Promise<boolean> {
    const result = await this.apiClient.post(`${prefix_web}zoom-hosts`, { zoom_host_id })
    return result.data
  },
  async deleteHost(id: string): Promise<boolean> {
    const result = await this.apiClient.delete(`${prefix_web}zoom-hosts`, { data: { id } })
    return result.data
  },
  async addCoachToHost(coach_id: string, id: string): Promise<boolean> {
    const result = await this.apiClient.post(`${prefix_web}zoom-hosts/${id}/coach`, { coach_id })
    return result.data
  },
  async removeCoachFromHost(id: string): Promise<boolean> {
    const result = await this.apiClient.delete(`${prefix_web}zoom-hosts/${id}/coach`)
    return result.data
  },
}
