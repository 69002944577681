import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import api from '../../libs/api/apiOld'
import coachesDispatchers from '../coaches/dispatcher'
import apiZoomHosts from './api'
import { actions } from './reducer'

class Dispatcher {
  actions = actions

  getAvailableHosts(): DispatcherResponse {
    return async (dispatch) => {
      const hosts = await api.getAvailableHosts()
      dispatch(actions.setAvailableList(hosts))
    }
  }

  getHosts(): DispatcherResponse {
    return async (dispatch) => {
      const hosts = await api.getHosts()
      dispatch(actions.setList(hosts))
    }
  }

  updateHostsList(): DispatcherResponse {
    return async (dispatch) => {
      await dispatch(this.getHosts())
      await dispatch(this.getAvailableHosts())
    }
  }

  createHost(id: string): DispatcherResponse {
    return async (dispatch) => {
      const result = await api.createHost(id)
      await dispatch(this.updateHostsList())
      return result
    }
  }

  deleteHost(id: string): DispatcherResponse {
    return async (dispatch) => {
      let result
      try {
        result = await api.deleteHost(id)
      } catch (e) {
        result = e.response.data
      }
      await dispatch(this.updateHostsList())
      return result
    }
  }

  setCoachHost(coach_id: string, id: string): DispatcherResponse {
    return async (dispatch) => {
      let result
      try {
        result = await api.addCoachToHost(coach_id, id)
      } catch (e) {
        result = e.response.data
      }
      await dispatch(this.getHosts())
      await dispatch(this.updateHostsList())
      await dispatch(coachesDispatchers.getPersonalZoomHost(coach_id))
      return result
    }
  }

  removeCoachHost(coach_id: string, id: string): DispatcherResponse {
    return async (dispatch) => {
      const result = await api.removeCoachFromHost(id)
      await dispatch(this.updateHostsList())
      await dispatch(coachesDispatchers.getPersonalZoomHost(coach_id))
      return result
    }
  }

  setHostEnabled({ id, is_enabled }: { id: string; is_enabled: boolean }) {
    return async (dispatch) => {
      await apiZoomHosts.setZoomHostEnabled(id, is_enabled)
      await dispatch(this.getHosts())
    }
  }
}

const ZoomHostDispatcher = new Dispatcher()
export default ZoomHostDispatcher
