import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Link } from '@mui/material'

const useStyles = makeStyles({
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 5,
  }
}, { classNamePrefix: 'CompanyAgentContacts'})

interface Props {
  agent: Agent
}

function CompanyAgentContacts(props: Props) {
  const { agent } = props
  const classes = useStyles()

  const { contacts } = agent
  const { email, phone_number } = contacts || {}

  return (
    <div>
      <Typography variant={'h3'} className={classes.title}>Contacts</Typography>

      <Grid>
        <b>Email:</b> <Link href={'mailto:' + email}><span>{email}</span></Link>
      </Grid>

      <Grid>
        <b>Phone:</b> <span>{phone_number || '-'}</span>
      </Grid>
    </div>
  )
}

export default CompanyAgentContacts
