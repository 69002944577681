import JSONEditor, { JSONEditorMode, JSONEditorOptions } from 'jsoneditor'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import React, { Component, createRef } from 'react'
import 'jsoneditor/dist/jsoneditor.css'

type EditorProps = {
  json?: Record<string, any>
  schema?: object
  schemaRefs?: object
  disabled?: boolean
  text?: string
  mode?: JSONEditorMode
}

type EditorOptions = EditorProps & JSONEditorOptions

export default class JSONEditorReact extends Component<EditorProps> {
  public jsoneditor: any

  private container = createRef<HTMLElement>()

  public schema?: Record<string, any>

  public schemaRefs?: object

  componentDidMount() {
    // copy all properties into options for the editor
    // (except the properties for the JSONEditorReact component itself)
    const options: EditorOptions = { ...this.props }
    delete options.json
    delete options.disabled
    delete options.text

    if ('disabled' in this.props) {
      options.mode = this.props.disabled ? 'view' : 'tree'
    }

    this.jsoneditor = new JSONEditor(this.container, options)

    if ('json' in this.props) {
      this.jsoneditor.set(this.props.json)
    }
    if ('text' in this.props) {
      this.jsoneditor.setText(this.props.text)
    }
    this.schema = cloneDeep(this.props.schema)
    this.schemaRefs = cloneDeep(this.props.schemaRefs)
  }

  componentDidUpdate() {
    if ('json' in this.props) {
      this.jsoneditor.update(this.props.json)
    }

    if ('text' in this.props) {
      this.jsoneditor.updateText(this.props.text)
    }

    if ('mode' in this.props) {
      this.jsoneditor.setMode(this.props.mode)
    }

    if ('disabled' in this.props) {
      this.jsoneditor.setMode(this.props.disabled ? 'view' : 'tree')
    }

    // store a clone of the schema to keep track on when it actually changes.
    // (When using a PureComponent all of this would be redundant)
    const schemaChanged = !isEqual(this.props.schema, this.schema)
    const schemaRefsChanged = !isEqual(this.props.schemaRefs, this.schemaRefs)
    if (schemaChanged || schemaRefsChanged) {
      this.schema = cloneDeep(this.props.schema)
      this.schemaRefs = cloneDeep(this.props.schemaRefs)
      this.jsoneditor.setSchema(this.props.schema, this.props.schemaRefs)
    }
  }

  componentWillUnmount() {
    if (this.jsoneditor) {
      this.jsoneditor.destroy()
    }
  }

  render() {
    return <div className="jsoneditor-react-container" ref={(elem) => (this.container = elem)} />
  }
}
