import Immutable from 'seamless-immutable'

import types from './actionTypes'

const initialState = Immutable({
  list: [],
})
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_TYPES: {
      return state.merge({
        list: action.payload.types,
      })
    }

    default:
      return state
  }
}
