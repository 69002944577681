/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import React from 'react'
import Moment from 'react-moment'
import { StyledTableCell, StyledTableRow } from '../../../../elements/table/TableDefault'
import StatusBoolean from '../../../company/components/StatusBoolean'

interface Props {
  item: any
}
export default function UserSMSItem(props: Props) {
  const { item } = props
  const { country, flow, is_success, status, message, created_at, client_ip, provider, price } = item

  // let icon: ReactElement = useMemo(() => {
  //   if (!system) return null
  //   const systemLow = system.toLowerCase()
  //   if (systemLow === 'ios') return <AppleIcon />
  //   if (systemLow === 'android') return <AndroidIcon css={css({ color: '#5bd514', marginTop: 3 })} />
  //
  //   return null
  // }, [system])

  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell align="left">
          <Typography>{client_ip}</Typography>
        </StyledTableCell>

        <StyledTableCell align="left">
          <Typography>{flow}</Typography>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography>{provider}</Typography>
        </StyledTableCell>

        <StyledTableCell align="left">
          <Typography>{message}</Typography>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography>{country}</Typography>
          {price && <Typography>${price}</Typography>}
        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography>{status}</Typography>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography>
            <StatusBoolean isTrue={is_success} onlyDefined />
          </Typography>
        </StyledTableCell>

        <StyledTableCell align="right">
          <Typography>
            <Moment format={'LLL'}>{created_at}</Moment>
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  )
}
