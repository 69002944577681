/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import React from 'react'

interface Props {
  onChangeName(value: string): any
  onClubToggle(): void
  withClub: boolean
}

function CompaniesFilter(props: Props) {
  const { onClubToggle, withClub, onChangeName } = props

  // club toggle is disabled for now. Theoretically it is because it is easier to find by coach, then showing trash in company list.

  const handleInputChange = (e) => {
    onChangeName(e.target.value || '')
  }

  return (
    <Grid container>
      <div>
        <input
          type="text"
          css={css({
            border: `1px solid #aaa`,
            padding: '0.5rem 1rem',
            borderRadius: 8,
            color: '#323232',
            outline: 'none',
            marginRight: 20,
          })}
          placeholder="Company Name"
          onChange={handleInputChange}
        />
        {/* <FormControlLabel */}
        {/*  control={ */}
        {/*    <Checkbox */}
        {/*      checked={withClub} */}
        {/*      className={classes.checkbox} */}
        {/*      onChange={onClubToggle} */}
        {/*      value="With club" */}
        {/*      color="primary" */}
        {/*    /> */}
        {/*  } */}
        {/*  label="With club" */}
        {/* /> */}
      </div>
      {/* <Grid container className={classes.categoriesContainer}> */}
      {/*  {_.map(_.toArray(categories), category => ( */}
      {/*    <span */}
      {/*      className={[classes.categoryItem].join(' ')} */}
      {/*      onClick={this.createHandleClickCategory(category.id)} */}
      {/*      key={`categories-filter-item-${category.id}`} */}
      {/*    > */}
      {/*      {category.name} */}
      {/*    </span> */}
      {/*  ))} */}
      {/*  <div /> */}
      {/* </Grid> */}
    </Grid>
  )
}

export default CompaniesFilter
