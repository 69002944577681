import { TableCell, TableRow, Link as MuiLink } from '@mui/material'
import moment from 'moment'
import React, { useMemo } from 'react'
import StatusBoolean from '../../company/components/StatusBoolean'
import OnboardAgentPreview from './OnboardAgentPreview'

interface Props {
  item: OnboardEmail
}

function OnboardListItem(props: Props) {
  const { item } = props
  const { email, is_onboarded, agent, updated_at, created_at } = item

  const createdFormatted = useMemo(() => {
    return moment(created_at).format('LLL')
  }, [created_at])

  const updatedFormatted = useMemo(() => {
    return moment(updated_at).format('LLL')
  }, [updated_at])

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        <MuiLink href={`mailto:${email}`}>{email}</MuiLink>
      </TableCell>
      <TableCell align="right">
        <StatusBoolean isTrue={is_onboarded} />
      </TableCell>
      <TableCell align="left">{agent ? <OnboardAgentPreview agent={agent} /> : <span>-</span>}</TableCell>
      <TableCell align="right">{updatedFormatted}</TableCell>
      <TableCell align="right">{createdFormatted}</TableCell>
    </TableRow>
  )
}

export default OnboardListItem
