import { Avatar, List, ListItemText, Typography } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import CoachesDispatcher from '@reducers/coaches/dispatcher'
import { ReduxStateCoach } from '@reducers/coaches/reducer'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useHistory } from 'react-router-dom'
import { ReduxDispatch } from '../../../../../typings/ReduxDispatch'
import Loader from '../../../../components/Loader'
import PageSection from '../../../../layout/PageSection'

interface Props {
  coach: ReduxStateCoach
}

function CoachEmployees(props: Props) {
  const { coach } = props
  const { user_id, employees } = coach
  const dispatch = useDispatch<ReduxDispatch>()

  const history = useHistory()

  const fetchAction = useAsyncFetch(async () => {
    await dispatch(CoachesDispatcher.getCoachEmployees(user_id))
  }, [user_id])

  return (
    <PageSection title={'Coach employees'}>
      <RendererStatusSplit
        statuses={fetchAction}
        isEmpty={_.isEmpty(employees)}
        renderLoading={() => <Loader />}
        renderEmpty={() => <Typography>Coach does not have a company</Typography>}
        render={() => (
          <List component="nav" style={{ maxHeight: 400, overflow: 'auto' }}>
            {_.map(employees, (employee) => (
              <ListItem
                key={`coach-employee-item-${employee.user_id}`}
                component="a"
                onClick={() => history.push(`/employee/${employee.user_id}`)}
              >
                <ListItemIcon style={{ marginRight: 10 }}>
                  <Avatar src={employee.avatar_url} />
                </ListItemIcon>
                <ListItemText primary={employee.name} />
              </ListItem>
            ))}
          </List>
        )}
      />
    </PageSection>
  )
}

export default CoachEmployees
