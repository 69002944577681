import { Company } from '@reaction-club-types/core'
import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

interface State {
  list: any[]
  listIsNextBlocked?: boolean
}

const initialState: State = {
  list: [],
}

const dashboardNextSlice = createSlice({
  name: 'ChallengeApplicationsPage',
  initialState,
  extraReducers: {
    [appTypes.RESET]: (state, action) => {
      return initialState
    },
  },
  reducers: {
    setChallengeApplications(state, action: PayloadAction<any[]>) {
      const challengeApps: any[] = action.payload
      state.list = challengeApps
      state.listIsNextBlocked = challengeApps.length < 20
    },

    setChallengeApplicationsNext(state, action: PayloadAction<any[]>) {
      const challengeApps: any[] = action.payload
      const challengeAppsFiltered = _.filter(challengeApps, (item) => {
        return !_.find(state.list, (stateItem) => stateItem.id === item.id)
      })
      state.list = state.list.concat(challengeAppsFiltered)
      state.listIsNextBlocked = challengeApps.length < 20
    },
  },
})

export const { actions, reducer } = dashboardNextSlice
export default reducer
