// COACHES REQUESTS
import { prefix_web } from './apiOld'

export default {
  async getSubscriptionConfig() {
    const result = await this.apiClient.get(`${prefix_web}subscription-config`)
    return result.data
  },
  async updateSubscriptionConfig(data) {
    const result = await this.apiClient.put(`${prefix_web}subscription-config`, data)
    return result.data
  },
}
