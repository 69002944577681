import Immutable from 'seamless-immutable'

import types from './actionTypes'

export default function reducer(state = Immutable({ list: [] }), action = {}) {
  switch (action.type) {
    case types.SET_PROGRAMS: {
      return state.merge({
        list: action.payload,
      })
    }
    default:
      return state
  }
}
