/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography, Avatar } from '@mui/material'
import { Coach } from '@reaction-club-types/core'
import invariant from 'invariant'
import React from 'react'
import { useHistory } from 'react-router-dom'
import ItemCreatedInfo from '../../../components/items/ItemCreatedInfo'
import { OpenSans } from '../../../helpers/commonStyles'
import ButtonOrange from '../../tasks/components/ButtonOrange'

const cssName = css({
  padding: '10px 10px',
  fontFamily: OpenSans,
  fontSize: 16,
  textAlign: 'center',
})

interface Props {
  coach: Coach
  isChooseMode: boolean
  onChoose?(coach_id: string): any
}

function CoachCard(props: Props) {
  const { coach, isChooseMode, onChoose } = props
  const { user_id } = coach
  const history = useHistory()

  const handleChooseCoach = (e) => {
    e.stopPropagation()
    invariant(onChoose, 'onChoose is not provided')

    onChoose(user_id)
  }

  const handleClick = () => {
    history.push(`/coach/${user_id}`)
  }

  return (
    <Grid item xs={6} sm={3} onClick={handleClick}>
      <div
        css={css({
          cursor: 'pointer',
          backgroundColor: '#fff',
          borderRadius: 5,
          margin: '0 5px',
          overflow: 'hidden',
          marginBottom: 25,
          transition: 'box-shadow .3s',
          maxWidth: 180,
          boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.05)',
          '&:hover': {
            boxShadow: '0 0 11px rgba(33,33,33,.5)',
          },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        })}
      >
        <div
          css={css`
            padding: 20px 50px;
          `}
        >
          <Avatar
            css={css`
              width: 80px;
              height: 80px;
            `}
            src={coach.avatar_url}
          />
        </div>
        <Typography css={cssName}>{coach.name}</Typography>
        <ItemCreatedInfo item={coach} />
        <Typography css={cssName}>
          {coach.location?.formatted_address ? `${coach.location.formatted_address}` : 'No location'}
        </Typography>
        {isChooseMode && (
          <ButtonOrange
            css={css`
              margin: 16px;
            `}
            onClick={handleChooseCoach}
          >
            Choose
          </ButtonOrange>
        )}
      </div>
    </Grid>
  )
}

export default CoachCard
