import Immutable from 'seamless-immutable'
import _ from 'lodash'

import types from './actionTypes'

const initialState = {
  name: '',
  description: '',
  milestones: [],
}
export default function reducer(state = Immutable({}), action = {}) {
  switch (action.type) {
    case types.INIT_EDIT_PROGRAM: {
      return state.merge(initialState)
    }
    case types.SET_PROG_DESCR: {
      return state.merge({
        description: action.payload,
      })
    }
    case types.SET_PROG_NAME: {
      return state.merge({
        name: action.payload,
      })
    }
    case types.SET_PROG_MILESTONE: {
      return state.merge({
        milestones: action.payload,
      })
    }
    case types.ADD_MILESTONE: {
      let { milestones } = state
      const addItem = (milestones, value) => milestones.concat(value)
      return state.merge({
        milestones: addItem(milestones, action.payload),
      })
    }
    case types.REMOVE_MILESTONE: {
      // return state.set('milestones', state.get('milestones').filter(o => o.get('id') !== action.payload.id))
      const removeItem = (milestones, id) => _.filter(milestones, milestone => milestone.id !== id)
      return state.merge({
        milestones: removeItem(state.milestones, action.payload.id),
      })
    }
    case types.ADD_TASK: {
      let tasks = _.head(_.filter(state.milestones, { id: action.payload.milestone }))
      const addItem = (tasks, value) => tasks.concat(value)
      let milestones = state.milestones.map((milestone, index) => {
        if (action.payload.milestone === milestone.id) {
          return milestone.merge({ tasks: addItem(tasks.tasks, action.payload.task) })
        }
        return milestone
      })
      return state.merge({
        milestones,
      })
    }
    case types.REMOVE_TASK: {
      let tasks = _.head(_.filter(state.milestones, { id: action.payload.milestone }))
      const removeItem = (tasks, value) => _.filter(tasks, task => !_.isEqual(task, value))
      let milestones = state.milestones.map((milestone, index) => {
        if (action.payload.milestone === milestone.id) {
          return milestone.merge({ tasks: removeItem(tasks.tasks, action.payload.task) })
        }
        return milestone
      })
      return state.merge({
        milestones,
      })
    }
    case types.EDIT_TASK: {
      let milestone = _.head(_.filter(state.milestones, { id: action.payload.milestone }))
      let { tasks } = milestone
      tasks = tasks.map((taskItem, index) => {
        if (action.payload.taskIndex === index) {
          return taskItem.merge({ ...action.payload.task })
        }
        return taskItem
      })

      let milestones = state.milestones.map((milestone, index) => {
        if (action.payload.milestone === milestone.id) {
          return milestone.merge({ tasks })
        }
        return milestone
      })
      return state.merge({
        milestones,
      })
    }
    default:
      return state
  }
}
