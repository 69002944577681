import keyManager from 'constant-manager'

export default keyManager(
  {
    INIT_EDIT_PROGRAM: '[INIT EDIT PROGRAM]: Set init state',
    SET_PROG_NAME: '[SET_PROG_NAME]: Set program name',
    SET_PROG_DESCR: '[SET_PROG_DESCR]: Set program description',
    SET_PROG_MILESTONE: '[SET_PROG_MILESTONE]: Set program milestone',
    ADD_MILESTONE: '[ADD_MILESTONE]: Add new program milestone',
    REMOVE_MILESTONE: '[REMOVE_MILESTONE]: Remove one milestone',
    ADD_TASK: '[ADD_TASK]: Add task to milestone',
    REMOVE_TASK: '[REMOVE_TASK]: Remove task with milestone',
    EDIT_TASK: '[EDIT_TASK]: Edit milestone task',
  },
  { prefix: 'EditProgram__' },
)
