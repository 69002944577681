import { UserPointsLog } from '@reaction-club-types/core'
import moment from 'moment'
import React, { useMemo } from 'react'
import { StyledTableCell, StyledTableRow } from '../../../../elements/table/TableDefault'

interface Props {
  pointLog: UserPointsLog
}
function PointLogItem(props: Props) {
  const { pointLog } = props
  const { key, data, count, created_at } = pointLog

  let label = useMemo(() => {
    // switch (key){
    // }
    return key
  }, [key])

  const dateFormatted = useMemo(() => {
    return moment(created_at).format('LLL')
  }, [created_at])

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row">
        {label}
      </StyledTableCell>
      <StyledTableCell align="right">{JSON.stringify(data)}</StyledTableCell>
      <StyledTableCell align="right">{count}</StyledTableCell>
      <StyledTableCell align="right">{dateFormatted}</StyledTableCell>
    </StyledTableRow>
  )
}

export default PointLogItem
