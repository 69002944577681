/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import StatusBoolean from '../components/StatusBoolean'
import { ReduxStateCompanyItem } from '@reducers/companies/reducer'

const cssLabel = css`
  font-weight: bold;
  margin-right: 5px;
`

interface Props {
  company: ReduxStateCompanyItem
}

function CompanyPageDetails(props: Props) {
  const { company } = props

  if (_.isEmpty(company)) return null

  const { is_owned_by_coach, created_at, instance_count, employee_count, is_feed_enabled, employees = [] } = company

  const createdAtFormatted = moment(created_at).format('LL')

  return (
    <Grid
      item
      css={css`
        margin-top: 20px;
        margin-bottom: 20px;
      `}
    >
      <Grid container justifyContent={'flex-start'} alignItems={'center'}>
        <Typography variant={'body1'}>
          <span css={cssLabel}>Is Club:</span>
          <StatusBoolean isTrue={is_owned_by_coach} />
        </Typography>
      </Grid>

      <Grid container justifyContent={'flex-start'} alignItems={'center'}>
        <Typography variant={'body1'}>
          <span css={cssLabel}>Is Feed Enabled:</span>
          <StatusBoolean isTrue={is_feed_enabled} />
        </Typography>
      </Grid>

      <Grid container justifyContent={'flex-start'} alignItems={'center'}>
        <Typography variant={'body1'}>
          <span css={cssLabel}>Create Date:</span>
          {createdAtFormatted}
        </Typography>
      </Grid>

      <Grid container justifyContent={'flex-start'} alignItems={'center'}>
        <Typography variant={'body1'}>
          <span css={cssLabel}>Number of Groups:</span>
          {instance_count}
        </Typography>
      </Grid>

      <Grid container justifyContent={'flex-start'} alignItems={'center'}>
        <Typography variant={'body1'}>
          <span css={cssLabel}>Number of Employees:</span>
          {employee_count}
        </Typography>
      </Grid>

      <Grid container justifyContent={'flex-start'} alignItems={'center'}>
        <Typography variant={'body1'}>
          <span css={cssLabel}>Active users for last 24 hours:</span>
          {employees.filter((e) => e.last_seen && moment(e.last_seen).isAfter(moment().subtract(24, 'hour'))).length}
        </Typography>
      </Grid>

      <Grid container justifyContent={'flex-start'} alignItems={'center'}>
        <Typography variant={'body1'}>
          <span css={cssLabel}>Active users for last week:</span>
          {employees.filter((e) => e.last_seen && moment(e.last_seen).isAfter(moment().subtract(7, 'day'))).length}
        </Typography>
      </Grid>

      <Grid container justifyContent={'flex-start'} alignItems={'center'}>
        <Typography variant={'body1'}>
          <span css={cssLabel}>Active users for last month:</span>
          {employees.filter((e) => e.last_seen && moment(e.last_seen).isAfter(moment().subtract(1, 'month'))).length}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default CompanyPageDetails
