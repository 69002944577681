import apiChallengeTypes, { ChallengeType } from '@reducers/challengeTypes/api'
import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import actions from './actions'

class Dispatcher {
  getChallengeTypes(): DispatcherResponse {
    return async (dispatch) => {
      const challengeTypes = await apiChallengeTypes.getChallengeTypes()
      dispatch(actions.set(challengeTypes))
    }
  }

  getChallengeType(id: string): DispatcherResponse<ChallengeType> {
    return async (dispatch) => {
      const challengeType = await apiChallengeTypes.getChallengeTypeById(id)
      dispatch(actions.set(challengeType))
      return challengeType
    }
  }

  createChallengeType(type): DispatcherResponse<string> {
    return async (dispatch) => {
      const response = await apiChallengeTypes.createChallengeType(type)
      const { challenge_type_id } = response || {}
      if (challenge_type_id) {
        await dispatch(this.getChallengeType(challenge_type_id))
      }
      return challenge_type_id
    }
  }

  updateChallengeType(id: string, body): DispatcherResponse {
    return async (dispatch) => {
      await apiChallengeTypes.updateChallengeType(id, body)
      dispatch(this.getChallengeType(id))
    }
  }

  // delete(app_id: string): DispatcherResponse {
  //   return async (dispatch) => {
  //     await apiChallengeTypes.deleteChallengeType(app_id)
  //     await dispatch(actions.deleteChallengeType(app_id))
  //   }
  // }
}

const ChallengeTypeDispatcher = new Dispatcher()
export default ChallengeTypeDispatcher
