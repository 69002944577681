/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, List, Paper, Typography } from '@mui/material'
import { ReduxStateCoach } from '@reducers/coaches/reducer'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { withRouter, matchPath, Route } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import { ReduxDispatch } from '../../../../../typings/ReduxDispatch'
import ReactionLoader from '../../../../components/ReactionLoader'
import CoachesDispatcher from '../../../../reducers/coaches/dispatcher'
import CoachGroupItem from './CoachGroupItem'
import GroupDetails from './components/GroupDetails'

interface Props extends RouteComponentProps {
  coach: ReduxStateCoach
}

function CoachGroups(props: Props) {
  const { match, coach, location } = props
  const { groups } = coach
  const { path } = match
  const { url } = match
  const dispatch = useDispatch<ReduxDispatch>()

  const groupPath = `${path}/g/:group_id`
  const matchResult = matchPath<{ group_id: string; id: string }>(location.pathname, {
    path: groupPath,
  })

  const { group_id } = matchResult?.params || {}

  const fetchAction = useAsyncFetch(async () => {
    await dispatch(CoachesDispatcher.getCoachGroups(coach.user_id))
  }, [coach.user_id])

  return (
    <Paper
      css={css`
        padding: 50px 50px 20px;
        margin-top: 20px;

        @media (max-width: 500px) {
          padding: 30px 20px 10px;
        }
      `}
    >
      <Typography variant={'h1'}>Coach Groups</Typography>

      <RendererStatusSplit
        statuses={fetchAction}
        isEmpty={_.isEmpty(groups)}
        renderLoading={() => <ReactionLoader />}
        renderError={(error) => <Typography color={'error'}>{error}</Typography>}
        render={() => (
          <Grid container>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <List
                component="nav"
                css={css`
                  max-height: 300px;
                  overflow-y: auto;
                `}
              >
                {_.map(groups, (group) => (
                  <CoachGroupItem group={group} url={url} isSelected={!!matchResult && group.id === group_id} />
                ))}
              </List>
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={9}>
              <Route sensitive exact path={groupPath} render={() => group_id && <GroupDetails group_id={group_id} />} />
            </Grid>
          </Grid>
        )}
      />
    </Paper>
  )
}

export default withRouter(CoachGroups)
