/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { ChallengeApplicationMode, ChallengeItem } from '@reducers/challengeAppplications/api'
import ChallengeApplicationDispatcher from '@reducers/challengeAppplications/dispatcher'
import NotificationActionDispatcher from '@reducers/notification/actionDispatcher'
import React, { useMemo, useRef, useState } from 'react'
import { useAsyncFetch, useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import Loader from '../../../components/Loader'
import ScrollView from '../../../components/ScrollView'
import ScrollElement from '../../../libs/infinite-scroll/ScrollElement'
import CompaniesFilter from '../../company/components/CompaniesFilter'
import CompanyConfigItem from './CompanyConfigItem'

interface Props {
  application_id: string
}
function ChallengeAppCompanyConfig(props: Props) {
  const { application_id } = props
  const scrollRef = useRef()
  const dispatch = useDispatch<ReduxDispatch>()
  const [challengeItems, setChallengeItems] = useState<ChallengeItem[]>([])
  const [search, setName] = useState('')
  const [isNextExists, setIsNextExists] = useState(true)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  async function challengeItemsFetchInitial() {
    try {
      const response = await dispatch(
        ChallengeApplicationDispatcher.getChallengeItems(application_id, { name: search, offset: 0 }),
      )
      setIsNextExists(response.challenge_application_items.length >= 50)
      setChallengeItems(response.challenge_application_items)
    } catch (e) {
      NotificationActionDispatcher(dispatch).addErrorNotify({ message: 'Failed to load Company Configuration' })
    }
  }

  const fetchInitial = useAsyncFetch(challengeItemsFetchInitial, [search])

  const loadMoreAction = useAsyncHandler(async function challengeItemsFetchNext() {
    if (fetchInitial.isLoading) return null
    try {
      const response = await dispatch(
        ChallengeApplicationDispatcher.getChallengeItems(application_id, {
          name: search,
          offset: challengeItems.length,
        }),
      )
      setIsNextExists(response.challenge_application_items.length >= 50)
      setChallengeItems((prevState) => [...prevState, ...response.challenge_application_items])
    } catch (e) {
      NotificationActionDispatcher(dispatch).addErrorNotify({ message: 'Failed to load Company Configuration' })
    }
  })

  const handleEnable = async (state: boolean) => {
    try {
      handleClose()
      await dispatch(ChallengeApplicationDispatcher.updateChallengeItemsBulk(application_id, { is_enabled: state }))
      setChallengeItems((prevState) => prevState.map((i) => ({ ...i, is_enabled: state })))
    } catch (e) {
      NotificationActionDispatcher(dispatch).addErrorNotify({ message: 'Failed to save Company Configuration' })
    }
  }

  const handleMode = async (mode: ChallengeApplicationMode) => {
    try {
      handleClose()
      await dispatch(ChallengeApplicationDispatcher.updateChallengeItemsBulk(application_id, { mode }))
      setChallengeItems((prevState) => prevState.map((i) => ({ ...i, mode })))
    } catch (e) {
      NotificationActionDispatcher(dispatch).addErrorNotify({ message: 'Failed to save Company Configuration' })
    }
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Company Configuration</AccordionSummary>
      <AccordionDetails>
        <Grid container justifyContent={'space-between'} alignItems={'center'} css={css({ paddingBottom: 30 })}>
          <Grid item xs={6}>
            <CompaniesFilter onChangeName={setName} />
          </Grid>
          <Grid item xs={6} container justifyContent={'flex-end'} gap={2}>
            <Button
              variant="outlined"
              color="primary"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              Bulk actions
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => handleEnable(true)}>Enable for all</MenuItem>
              <MenuItem onClick={() => handleEnable(false)}>Disable for all</MenuItem>
              <MenuItem onClick={() => handleMode(ChallengeApplicationMode.DEV)}>Switch all to Test</MenuItem>
              <MenuItem onClick={() => handleMode(ChallengeApplicationMode.PROD)}>Switch all to Live</MenuItem>
            </Menu>
          </Grid>
        </Grid>
        <ScrollView ref={scrollRef} initialHeight={50}>
          <ScrollElement
            getNext={() => loadMoreAction.execute()}
            getContainerRef={() => scrollRef.current}
            isNextExists={isNextExists}
            isLoading={fetchInitial.isLoading}
            renderLoading={() => (
              <Grid container justifyContent={'center'}>
                <Loader />
              </Grid>
            )}
          >
            {challengeItems.map((i) => (
              <CompanyConfigItem key={i.id} challengeItem={i} />
            ))}
          </ScrollElement>
        </ScrollView>
      </AccordionDetails>
    </Accordion>
  )
}

export default ChallengeAppCompanyConfig
