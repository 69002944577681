import _ from 'lodash'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'

import types from './actionTypes'

const initialState: ReduxStatePlaybooks = {}
export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction) {
  switch (action.type) {
    case types.SET_PLAYBOOK: {
      const playbook: Playbook = action.payload

      const existingPlaybook = state[playbook.id]
      if (existingPlaybook) {
        state = state.setIn([playbook.id], existingPlaybook.merge(playbook))
      } else {
        state = state.merge({
          [playbook.id]: {
            programs: [],
            ...playbook,
          },
        })
      }

      return state
    }

    case types.SET_PLAYBOOK_PROGRAMS: {
      const { playbook_id, programs } = action.payload

      let playbook = state[playbook_id]
      if (!playbook) return state

      playbook = playbook.merge({
        programs,
      })

      return state.merge({
        [playbook_id]: playbook,
      })
    }

    case types.SET_PROGRAM: {
      const program: Program = action.payload

      state = state.updateIn([program.playbook_id, 'programs'], (programs: Immutable<Program[]>) => {
        const index = _.findIndex(programs, { id: program.id })
        if (index === -1) return programs.concat(program)
        return programs.setIn([index], program)
      })

      return state
    }

    case types.DELETE_PROGRAM: {
      const { program_id, playbook_id } = action.payload

      state = state.updateIn([playbook_id, 'programs'], (programs: Immutable<Program[]>) =>
        programs.filter(_program => _program.id !== program_id),
      )

      return state
    }

    case types.DELETE_PLAYBOOK: {
      const playbook_id = action.payload

      return state.without(playbook_id)
    }

    case types.SET_PRIVATE_COACHES: {
      const { playbook_id, coaches }: { playbook_id: string; coaches: string[] } = action.payload

      let playbook = state[playbook_id]
      if (!playbook) return state

      playbook = playbook.merge({
        coaches,
      })

      return state.merge({
        [playbook_id]: playbook,
      })
    }

    default:
      return state
  }
}
