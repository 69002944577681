import keyManager from 'constant-manager'

export default keyManager(
  {
    SET: '[SET]: Set detail info about company',
    SET_COMPANY_COACHES: '[SET_COMPANY_COACHES]: Set coaches assigned to company  ',
    SET_COMPANY_EMPLOYEES: '[SET_COMPANY_EMPLOYEES]: Set employees assigned to company',
    FILTER_COACHES: '[FILTER_COACHES]: Set value for filtering coach',
    SET_COMPANY_PLAYBOOK: '[SET_COMPANY_PLAYBOOK]: Set company playbook',
    ADD_PLAYBOOK: '[ADD_PLAYBOOK]: Add one playbook to company ',
    REMOVE_PLAYBOOK: '[REMOVE_PLAYBOOK]: Remove one playbook with company ',
    SET_COMPANY_STICKERS: '[SET_COMPANY_STICKERS]: Set company stickers',
  },
  { prefix: 'Company__' },
)
