import { Grid, Typography, Box } from '@mui/material'
import { withStyles } from '@mui/styles'
import { ReactionEvent } from '@reaction-club-types/core'
import { ChatDispatcher } from '@reaction-club/rocketchat-redux'
import { ReduxState } from '@reducers/index'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import { compose } from 'recompose'
import { ReduxDispatch } from '../../../../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../../../../typings/ReduxExtractor'
import { ExtractStyles } from '../../../../../../typings/stylesExtractor'
import ReactionAvatar from '../../../../../components/assets/ReactionAvatar'

const connectStyles = withStyles({
  avatar: {
    width: 60,
    height: 60,
  },
  title: {
    marginTop: 0,
    marginBottom: 5,
    fontSize: 18,
    fontWeight: 'bold',
  },
  text: {
    fontSize: 18,
  },
})

interface Props {
  event: ReactionEvent
}

interface PropsInner
  extends Props,
    RouteComponentProps,
    ExtractStyles<typeof connectStyles>,
    ExtractConnectType<typeof connectStore> {}

function EventDetails(props: PropsInner) {
  const { event, instances, classes, createMockDirectRoom } = props
  const { name: title, coach, playbook_instance_id } = event
  const { name, avatar_url, chat_user_id } = coach || {}

  const group = playbook_instance_id && instances[playbook_instance_id]

  return (
    <Grid container>
      <Box onClick={() => createMockDirectRoom('mock-room-id', chat_user_id)} style={{ width: 'fit-content' }}>
        <Link to={`/chats/direct/mock-room-id`} style={{ textDecoration: 'none' }}>
          <ReactionAvatar asset={{ url: avatar_url }} className={classes.avatar} />
        </Link>
      </Box>
      <div>
        <Typography variant={'h1'} className={classes.title}>
          {title} (for {group ? `${group.name} group` : 'all groups'})
        </Typography>
        <Typography className={classes.text}>Coach {name}</Typography>
      </div>
    </Grid>
  )
}

const connectStore = connect(
  (state: ReduxState) => ({
    instances: state.playbookInstances,
  }),
  (dispatch: ReduxDispatch) => ({
    createMockDirectRoom: (roomId: string, userId: string) =>
      dispatch(ChatDispatcher.actions.createMockDirectRoom(roomId, userId)),
  }),
)

export default compose<PropsInner, Props>(withRouter, connectStore, connectStyles)(EventDetails)
