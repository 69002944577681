import apiClient from '../../lib/apiClient'

const apiAuth = {
  async login(payload: { username: string; password: string }): Promise<{ token: string; role: string }> {
    const result = await apiClient.post(`/signin`, payload)
    return result.data
  },

  async loginWithFirebaseToken(token, deviceId): Promise<{ token: string; role: string }> {
    const result = await apiClient.post(`/login`, { accessToken: token, deviceId })
    return result.data
  },
}

export default apiAuth
