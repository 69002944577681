import keyManager from 'constant-manager'

export default keyManager(
  {
    SET_TOKEN: '',
    SET_IS_AUTHORIZED: '',
    LOGOUT: '',
  },
  { prefix: 'AUTH__' },
)
