import apiClient from '../../lib/apiClient'

export default {
  apiClient,

  async getApps() {
    const { data } = await apiClient.get(`/app/list`)
    return data
  },

  async getUserContacts(user_id: string) {
    const { data } = await apiClient.get(`/user/${user_id}/contacts`)
    return data
  },
}
