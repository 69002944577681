import React from 'react'
import withStyles from '@mui/styles/withStyles';
import { WithStyles } from 'react-jss'
import { compose } from 'redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Grid } from '@mui/material'
import { useDispatch } from 'react-redux'
import DeleteButton from '../../../../elements/DeleteButton'
import CompanyDispatcher from '../../../../reducers/companies/dispatcher'

const styles = {
  coachBody: {
    marginLeft: 10,
    flexGrow: 1,
  },

  coachListItem: {
    paddingLeft: 15,
    paddingRight: 5,
    position: 'relative',
  },

  ownerText: {
    fontSize: 10,
    color: '#979797',
    fontWeight: 'bold',
  },
  coachListItemActive: {
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 2,
      top: 0,
      bottom: 0,
      right: 0,
      backgroundColor: '#F9B22D',
    },
  },

  stickerContainer: {
    position: 'relative',
    border: '1px solid #eee',
    margin: '0 10px',

    '&:hover $delete': {
      display: 'block',
    },
  },
  sticker: {
    width: 80,
    height: 80,
    objectFit: 'contain',
    objectPosition: 'center',
  },

  delete: {
    position: 'absolute',
    top: -8,
    right: -8,
    display: 'none',
  },
}

interface Props extends RouteComponentProps<{ id: string }> {
  sticker: CompanyStickers
}

function StickerListItem(props: Props & WithStyles<typeof styles>) {
  const { sticker, classes } = props
  const { id, url, company_id } = sticker

  const dispatch = useDispatch()

  const handlePressDelete = async () => {
    const isDelete = confirm('Are you sure want to delete this sticker?')
    if (!isDelete) return null

    await dispatch(CompanyDispatcher.deleteSticker({ sticker_id: id, company_id }))
  }

  return (
    <Grid item className={classes.stickerContainer}>
      <img src={url} className={classes.sticker} alt={'sticker'} />
      <DeleteButton className={classes.delete} onClick={handlePressDelete} />
    </Grid>
  )
}

export default compose(
  React.memo,
  withRouter,
  withStyles(styles),
)(StickerListItem)
