import React from 'react'
import connect from 'react-redux/es/connect/connect'
import { withRouter } from 'react-router-dom'
import { Avatar, ListItem, ListItemText, ListIte, Button } from '@mui/material'
import classNames from 'classnames'
import { Delete } from '@mui/icons-material'
import ModalActionDispatcher from '../../../reducers/modal/actionDispatcher'
import PlaybookTypesActionDispatcher from '../../../reducers/playbookTypes/actionDispatcher'
import { withStyles } from '@mui/styles';

const styles = {
  playbookTypesPaper: {
    padding: 15,
  },
  duplicateButton: {
    '&&': {
      background: 'red',
      color: '#fff',
    },
  },
}

@connect(
  (state, props) => ({}),
  dispatch => ({
    showModal: ModalActionDispatcher(dispatch).showModal,
    deletePlaybookType: PlaybookTypesActionDispatcher(dispatch).deletePlaybookType,
  }),
)
@withRouter
@withStyles(styles)
export default class PlaybookType extends React.Component {
  state = {
    isHovering: false,
  }

  handleMouseHover = newState => () => {
    this.setState(this.toggleHoverState(newState))
  }

  toggleHoverState = newState => ({
    isHovering: newState,
  })

  deletePlaybookType = id => async e => {
    e.stopPropagation()
    await this.props.deletePlaybookType(id)
  }

  editPlaybookType = playbookType => async () => {
    window.scrollTo(0, 0)
    const { showModal } = this.props
    showModal({
      modalType: 'EDIT_PLAYBOOK_TYPE',
      modalProps: playbookType,
    })
  }

  render() {
    const { playbookType, classes } = this.props
    const { isHovering } = this.state

    const deleteButton = (
      <Button className={classNames(classes.duplicateButton)} onClick={this.deletePlaybookType(playbookType.id)}>
        <Delete />
      </Button>
    )

    return (
      <ListItem
        button
        onMouseEnter={this.handleMouseHover(true)}
        onMouseLeave={this.handleMouseHover(false)}
        onClick={this.editPlaybookType(playbookType)}
      >
        <Avatar alt="Playbook thumb" src={playbookType.thumb} />
        <ListItemText primary={playbookType.name} />
        {isHovering && deleteButton}
      </ListItem>
    )
  }
}
