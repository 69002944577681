import { Grid, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { ReactNode } from 'react'

const useStyles = makeStyles(
  {
    container: {
      flexGrow: 1,
    },
    paper: {
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 15,
      paddingBottom: 15,
      minWidth: 300,
    },
  },
  {
    classNamePrefix: 'LoginContainer',
  },
)

interface Props {
  children: ReactNode
}

function LoginContainer(props: Props) {
  const { children } = props
  const classes = useStyles()

  return (
    <Grid container className={classes.container} justifyContent={'center'} alignItems={'center'}>
      <Paper className={classes.paper}>{children}</Paper>
    </Grid>
  );
}

export default LoginContainer
