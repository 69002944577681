import { initializeApp } from 'firebase/app'

const app = initializeApp({
  apiKey: 'AIzaSyDoxuKls0d7xCFJ7aNVYdHM-CDrMl2Abl8',
  authDomain: 'reaction-test-41f8a.firebaseapp.com',
  databaseURL: 'https://reaction-test-41f8a.firebaseio.com',
  projectId: 'reaction-test-41f8a',
  storageBucket: 'reaction-test-41f8a.appspot.com',
  messagingSenderId: '1036316655745',
})

export default app
