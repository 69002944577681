import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import invariant from 'invariant'

interface ReduxStateEmployees {
  [key: string]: ReduxStateUserItem
}

export interface ReduxStateUserItem {
  // contacts?: UserContacts
  // dailySteps?: UserStepsDaily[]
  // devices?: UserDevice[]
  sms_logs?: any[]
  localAccount?: {
    email: string
    is_verified: boolean
  }
}

const initialState: ReduxStateEmployees = {}

const slice = createSlice({
  name: 'Users',
  initialState,
  extraReducers: {
    [appTypes.RESET]: (state, action) => {
      return initialState
    },
  },
  reducers: {
    // set(state, action: PayloadAction<Employee | Employee[]>) {
    //   let itemOrArr = action.payload
    //   const list: Employee[] = _.isArray(itemOrArr) ? itemOrArr : [itemOrArr]
    //
    //   _.forEach(list, (item) => {
    //     state[item.user_id] = { ...state[item.user_id], ...item }
    //   })
    // },
    //
    // delete(state, action: PayloadAction<string>) {
    //   const user_id: string = action.payload
    //   state[user_id] = undefined
    // },

    setSmsLogs(state, action: PayloadAction<{ user_id: string; list: any[] }>) {
      const { list, user_id } = action.payload
      if (!state[user_id]) state[user_id] = {}
      state[user_id].sms_logs = list
    },

    setLocalAccount(
      state,
      action: PayloadAction<{ user_id: string; localAccount: ReduxStateUserItem['localAccount'] }>,
    ) {
      const { localAccount, user_id } = action.payload
      if (!state[user_id]) state[user_id] = {}
      state[user_id].localAccount = localAccount
    },
  },
})

export const { actions, reducer } = slice
export default reducer
