import { EventRecording } from '@reaction-club-types/core'
import invariant from 'invariant'
import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import actions from './actions'
import api, { APIEventCreatePayload, APIEventUpdatePayload } from './apiEvents'

class Dispatcher {
  actions = actions

  getEvents(params?: { timeStart?: string }): DispatcherResponse {
    const { timeStart } = params || {}
    return async dispatch => {
      const events = await api.getEvents({ timeStart })
      dispatch(actions.setEvents(events))
    }
  }

  getById(event_id: string): DispatcherResponse {
    return async dispatch => {
      const event = await api.getById(event_id)
      dispatch(actions.setEvent(event))
    }
  }

  getRecordings(event_id: string): DispatcherResponse {
    return async dispatch => {
      const recordings = await api.getRecordings(event_id)
      dispatch(actions.setRecordings({ event_id, recordings }))
    }
  }

  deleteRecording({ event_id, key }: Pick<EventRecording, 'event_id' | 'key'>): DispatcherResponse {
    return async dispatch => {
      invariant(event_id, 'event_id invalid')
      await api.deleteRecording(event_id, key)
      dispatch(this.getRecordings(event_id))
    }
  }

  createEvent(payload: APIEventCreatePayload): DispatcherResponse {
    return async dispatch => {
      const data = await api.createEvent(payload)
      dispatch(this.getEvents())
      // todo fetch specific event???
      return data
    }
  }

  updateEvent(event_id: string, payload: APIEventUpdatePayload): DispatcherResponse {
    return async dispatch => {
      const data = await api.updateEvent(event_id, payload)
      dispatch(this.getById(event_id))
      return data
    }
  }

  deleteEvent(event_id: string): DispatcherResponse {
    return async dispatch => {
      const data = await api.deleteEvent(event_id)
      console.log('deleteEvent', data)
      dispatch(actions.deleteEvent(event_id))
    }
  }
}

const EventDispatcher = new Dispatcher()
export default EventDispatcher
