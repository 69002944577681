import _ from 'lodash'
import React from 'react'
import Select from 'react-select'
import { Props as SelectProps } from 'react-select/src/Select'
import FormLabel from '../../../../../elements/form/FormLabel'

const durationOptions = [
  { value: '30', label: '30 minutes' },
  { value: '60', label: '1 hour' },
  { value: '120', label: '2 hours' },
  { value: '180', label: '3 hours' },
  { value: '240', label: '4 hours' },
]

interface Props {
  value: string
  onChange(type: string | undefined): any
}

export default function EventDurationSelect(props: Props) {
  const { value, onChange } = props

  const handleChange: SelectProps['onChange'] = item => {
    onChange(item?.value)
  }

  const itemSelected: SelectProps['value'] = _.find(durationOptions, { value }) || null

  return (
    <div>
      <FormLabel>Duration</FormLabel>
      <Select value={itemSelected} onChange={handleChange} options={durationOptions} />
    </div>
  )
}
