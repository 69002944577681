/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Avatar, Grid, Typography } from '@mui/material'
import { Employee } from '@reaction-club-types/core'
import React from 'react'
import UserLastSeen from '../../../../components/user/UserLastSeen'

interface Props {
  employee: Employee
}

const EmployeeGeneral = (props: Props) => {
  const { employee } = props
  const { name, avatar_url, points } = employee

  return (
    <Grid container alignItems={'center'} direction={'column'} justifyContent={'center'}>
      <Grid>
        <Avatar
          css={css`
            width: 100px;
            height: 100px;
          `}
          src={avatar_url}
        />
      </Grid>
      <Grid container direction={'column'} alignItems={'center'} sx={{ marginBottom: '10px' }}>
        <Typography
          variant={'h1'}
          css={css`
            margin-bottom: 0;
            margin-top: 15px;
          `}
        >
          {name}
        </Typography>
        <div>
          <span
            css={css`
              color: #acacac;
              font-weight: bold;
            `}
          >
            {points} points
          </span>
        </div>
      </Grid>
      {/* <Grid item lg={10} sm={10} xs={10}> */}
      {/*  <Grid container alignItems={'center'} justifyContent={'center'} direction={'row'}> */}
      {/*    <WcIcon htmlColor={'#ffa726'} /> */}
      {/*    <Typography align={'left'} className={classes.text}> */}
      {/*      {(employee.profile && employee.profile.gender) || 'Gender not set'} */}
      {/*    </Typography> */}
      {/*  </Grid> */}
      {/* </Grid> */}

      <UserLastSeen user={employee} />
    </Grid>
  )
}

export default EmployeeGeneral
