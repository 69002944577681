/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import {
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Chat as ChatIcon,
} from '@mui/icons-material'
import { MenuItem, Typography, IconButton, Menu, ListItemIcon, Grid } from '@mui/material'
import { Company } from '@reaction-club-types/core'
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks'
import React, { useState } from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { Immutable } from 'seamless-immutable'
import { ReduxDispatch } from '../../../../../typings/ReduxDispatch'
import useModalState from '../../../../hooks/useModalState'
import CompanyDispatcher from '../../../../reducers/companies/dispatcher'
import CompanySendMessageModal from '../../modals/CompanySendMessageModal'
import ClearFeedModal from '../modals/ClearFeedModal'
import CompanyDeleteModal from '../modals/CompanyDeleteModal'

interface Props {
  company: Company | Immutable<Company>
  onEditModeChange: (isEditMode: boolean) => any
}

function CompanyPageActions(props: Props) {
  const { company } = props
  const [isEditMode, setEditMode] = useState(false)
  const dispatch = useDispatch<ReduxDispatch>()
  const menuState = usePopupState({ variant: 'popover', popupId: 'company-menu-actions' })
  const deleteModalState = useModalState()
  const clearFeedModalState = useModalState()
  const sendMessageModalState = useModalState()

  const handleClickEditMode = () => {
    setEditMode(true)
    menuState.close()

    props.onEditModeChange(true)
  }

  const handleClickDisableEditMode = () => {
    setEditMode(false)
    menuState.close()

    props.onEditModeChange(false)
  }

  const actionResetPoints = useAsyncHandler(async () => {
    menuState.close()
    const isConfirmed = confirm('Are you sure want to reset points?')
    if (!isConfirmed) return null
    await dispatch(CompanyDispatcher.resetPoints(company.id))
    alert('Points have been reset for this company.')
  })

  const handleClickDelete = () => {
    menuState.close()
    deleteModalState.onOpen()
  }

  const handleClickClearFeed = () => {
    menuState.close()
    clearFeedModalState.onOpen()
  }

  const handleSelectSendMessages = () => {
    menuState.close()
    sendMessageModalState.onOpen()
  }

  return (
    <div
      css={css({
        position: 'absolute',
        top: 10,
        right: 10,
        color: '#323232',
        fontSize: '1.2rem',
      })}
    >
      <CompanySendMessageModal company={company} {...sendMessageModalState} />
      <CompanyDeleteModal company={company} {...deleteModalState} />
      <ClearFeedModal company={company} {...clearFeedModalState} />
      <Grid container alignItems={'center'}>
        <IconButton size="large" {...bindTrigger(menuState)}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          {...bindMenu(menuState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          {isEditMode ? (
            <MenuItem onClick={handleClickDisableEditMode}>
              <ListItemIcon>
                <CloseIcon />
              </ListItemIcon>
              <Typography variant="inherit">Disable Edit Mode</Typography>
            </MenuItem>
          ) : (
            <MenuItem onClick={handleClickEditMode}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <Typography variant="inherit">Edit Mode</Typography>
            </MenuItem>
          )}

          <MenuItem onClick={handleSelectSendMessages}>
            <ListItemIcon>
              <ChatIcon />
            </ListItemIcon>
            <Typography variant="inherit">Chat Broadcast</Typography>
          </MenuItem>

          <MenuItem onClick={actionResetPoints.execute}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <Typography variant="inherit" color={'error'} style={{ fontWeight: 'bold' }}>
              Reset Points
            </Typography>
          </MenuItem>

          <MenuItem onClick={handleClickDelete}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <Typography variant="inherit" color={'error'} style={{ fontWeight: 'bold' }}>
              Delete Company
            </Typography>
          </MenuItem>

          <MenuItem onClick={handleClickClearFeed}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <Typography variant="inherit" color={'error'} style={{ fontWeight: 'bold' }}>
              Clear Feed
            </Typography>
          </MenuItem>
        </Menu>
      </Grid>
    </div>
  )
}

export default CompanyPageActions
