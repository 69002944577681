import keyManager from 'constant-manager'

const coachesTypes = keyManager(
  {
    SET: '',
    SET_COACHES: '',
    SET_COMPANIES: '',
    SET_EMPLOYEES: '',
    SET_GROUPS: '',
    SET_PERSONAL_HOST: '',
    SET_CONTACTS: '',
  },
  { prefix: 'Coaches__' },
)

export default coachesTypes
