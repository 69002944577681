import { Agent } from '@reaction-club-types/core'
import _ from 'lodash'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import reducerUtils from '../../libs/reducerUtils'
import types from '../agent/types'

interface State {
  name: string
  list: Agent[]
  listIsBlocked: boolean
}

const initialState: State = {
  name: '',
  list: [],
  listIsBlocked: false,
}
export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case types.SET_LIST: {
      const list: Agent[] = action.payload

      return state.merge({
        list,
        listIsBlocked: false,
      })
    }

    case types.SET_LIST_NEXT: {
      const list: Agent[] = action.payload

      console.log('SET_LIST_NEXT', list)

      return state.merge({
        list: reducerUtils.setArrayNext(state.list, list, { key: 'user_id' }),
        listIsBlocked: _.isEmpty(list),
      })
    }
    default:
      return state
  }
}
