/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Paper, Typography } from '@mui/material'
import Button from '@mui/material/Button/Button'
import Grid from '@mui/material/Grid'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React, { useRef, useState } from 'react'
import { useAsyncFetch, useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useHistory } from 'react-router-dom'
import { ReduxDispatch } from '../../../typings/ReduxDispatch'
import Loader from '../../components/Loader'
import ReactionLoader from '../../components/ReactionLoader'
import ScrollView from '../../components/ScrollView'
import ScrollElement from '../../libs/infinite-scroll/ScrollElement'
import ChallengeApplicationsPageDispatcher from '../../reducers/challengeApplicationsPage/dispatcher'
import ChallengeAppCard from './components/ChallengeAppCard'
import ChallengeAppFilter from './components/ChallengeAppFilter'

function ChallengeApplicationsList() {
  const scrollRef = useRef()
  const history = useHistory()
  const [name, setName] = useState('')

  const createHref = history.createHref({ pathname: '/add-challenge-application' })
  const typesHref = history.createHref({ pathname: '/challenge-types' })

  const { challenge_applications, listIsNextBlocked } = useSelector((state: ReduxState) => ({
    challenge_applications: state.challengeApplicationsPage.list,
    listIsNextBlocked: state.challengeApplicationsPage.listIsNextBlocked,
  }))

  const dispatch = useDispatch<ReduxDispatch>()
  const fetchInitial = useAsyncFetch(
    async function challengeAppsFetchInitial() {
      await dispatch(ChallengeApplicationsPageDispatcher.getChallengeApplications({ name }))
    },
    [name],
  )

  const loadMoreAction = useAsyncHandler(async function companiesFetchNext() {
    if (fetchInitial.isLoading) return null
    await dispatch(ChallengeApplicationsPageDispatcher.getChallengeApplicationsNext({ name }))
  })

  return (
    <ScrollView offset={35} ref={scrollRef}>
      <ScrollElement
        getNext={() => loadMoreAction.execute()}
        getContainerRef={() => scrollRef.current}
        isNextExists={!listIsNextBlocked}
        isLoading={fetchInitial.isLoading}
        renderLoading={() => (
          <Grid container justifyContent={'center'}>
            <Loader />
          </Grid>
        )}
      >
        <Grid container justifyContent="center" key={'infinite-scroll-companies'}>
          <Grid item xs={12} sm={10}>
            <Paper>
              <Grid
                container
                justifyContent={'center'}
                css={css({
                  padding: '30px 0',
                })}
              >
                <Grid item xs={10} sm={10}>
                  <Grid container justifyContent={'space-between'} css={css({ paddingBottom: 30 })}>
                    <Grid item sm={8} xs={12}>
                      <ChallengeAppFilter onChangeName={setName} />
                    </Grid>
                    <Grid item xs={12} sm={4} gap={2} container justifyContent={'flex-end'} alignItems={'flex-start'}>
                      <Button href={typesHref} variant={'contained'} color={'secondary'}>
                        Edit Types
                      </Button>
                      <Button href={createHref} variant={'contained'} color={'primary'}>
                        Create New
                      </Button>
                    </Grid>
                  </Grid>

                  {/* <Typography variant={'body2'} align={'right'}> */}
                  {/*  total: {total_count} */}
                  {/* </Typography> */}
                  <Grid
                    container
                    spacing={4}
                    justifyContent={'space-between'}
                    css={css({
                      paddingTop: 5,
                    })}
                  >
                    <RendererStatusSplit
                      statuses={fetchInitial}
                      isEmpty={_.isEmpty(challenge_applications)}
                      renderLoading={() => <ReactionLoader />}
                      renderError={(error) => (
                        <Grid container justifyContent="center">
                          <Typography color={'error'}>{error}</Typography>
                        </Grid>
                      )}
                      render={() => {
                        let appsFiltered = challenge_applications
                        if (name) {
                          const nameLow = name.toLowerCase()
                          appsFiltered = _.filter(appsFiltered, (app) =>
                            (app.name || '').toLowerCase().includes(nameLow),
                          )
                        }

                        return appsFiltered.map((app) => (
                          <ChallengeAppCard app={app} key={`challenge-app-page-item-${app.id}`} />
                        ))
                      }}
                    />

                    <Grid item xs={4} sm={4} md={3} lg={2} />
                    <Grid item xs={4} sm={4} md={3} lg={2} />
                    <Grid item xs={4} sm={4} md={3} lg={2} />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </ScrollElement>
    </ScrollView>
  )
}

export default ChallengeApplicationsList
