import { Grid, Typography, CircularProgress } from '@mui/material'
import { withStyles } from '@mui/styles'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { Route, RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../../typings/ReduxExtractor'
import { ExtractStyles } from '../../../../typings/stylesExtractor'
import PageSection from '../../../layout/PageSection'
import CompanyDispatcher from '../../../reducers/companies/dispatcher'
import CompanyCoachSubPage from './CompanyCoachSubPage'
import CoachListItem from './components/CoachListItem'

const connectStyles = withStyles({
  coachesListContainer: {
    width: 250,
    borderRight: '1px solid #e8d2d2',
    height: 300,
    overflowY: 'auto',
  },

  emptyContainer: {
    padding: 10,
  },
})

interface State {
  isLoading: boolean
}

interface Props extends RouteComponentProps<{ id: string }> {}
interface PropsInner extends Props, ExtractConnectType<typeof connectStore>, ExtractStyles<typeof connectStyles> {}

class CompanyCoaches extends React.PureComponent<PropsInner, State> {
  state: State = {
    isLoading: true,
  }

  render() {
    const { company, classes, match } = this.props
    if (_.isEmpty(company)) return null

    const coachPath = `${match.path}/coach/:coach_id`

    return (
      <PageSection title={'Coaches'}>
        <Grid container direction={'row'}>
          <Grid item>
            <Grid container direction={'row'} alignItems={'center'}>
              <div className={classes.coachesListContainer}>{this.renderList()}</div>
            </Grid>
          </Grid>

          <Grid item>
            <Route sensitive exact path={coachPath} render={(props) => <CompanyCoachSubPage {...props} />} />
          </Grid>
        </Grid>
      </PageSection>
    )
  }

  renderList() {
    const { company, classes } = this.props
    const { isLoading } = this.state
    const { coaches } = company

    if (isLoading)
      return (
        <Grid container justifyContent={'center'} className={classes.emptyContainer}>
          <CircularProgress color={'primary'} size={30} />
        </Grid>
      )

    if (_.isEmpty(coaches))
      return (
        <Grid container justifyContent={'center'} className={classes.emptyContainer}>
          <Typography variant={'h4'}>No Coaches</Typography>
        </Grid>
      )

    return _.map(coaches, (coach) => <CoachListItem coach={coach} key={`company-list-coach-${coach.user_id}`} />)
  }

  async fetchData() {
    const { coaches } = this.props.company || {}
    this.setState({ isLoading: _.isEmpty(coaches) })
    try {
      await this.props.getCoaches()
    } finally {
      this.setState({ isLoading: false })
    }
  }

  componentDidMount(): void {
    this.fetchData()
  }
}

const connectStore = connect(
  (state: ReduxState, { match }: Props) => ({
    company: state.companies[match.params.id],
  }),
  (dispatch: ReduxDispatch, { match }: Props) => ({
    getCoaches: () => dispatch(CompanyDispatcher.getCoaches(match.params.id)),
  }),
)

export default compose<PropsInner, Props>(withRouter, connectStore, connectStyles)(CompanyCoaches)
