/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid } from '@mui/material'
import React from 'react'

interface Props {
  onChangeName(value: string): any
}

function ChallengeTypeFilter(props: Props) {
  const { onChangeName } = props

  const handleInputChange = (e) => {
    onChangeName(e.target.value || '')
  }

  return (
    <Grid container>
      <div>
        <input
          type="text"
          css={css({
            border: `1px solid #aaa`,
            padding: '0.5rem 1rem',
            borderRadius: 8,
            color: '#323232',
            outline: 'none',
            marginRight: 20,
          })}
          placeholder="Challenge Type Name"
          onChange={handleInputChange}
        />
      </div>
    </Grid>
  )
}

export default ChallengeTypeFilter
