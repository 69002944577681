/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'
import CompanyDispatcher from '@reducers/companies/dispatcher'
import React from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CompanyDetails from './components/add-company/CompanyDetails'

function CompanyCreate(props: {}) {
  const history = useHistory()
  const dispatch = useDispatch()
  const companyCreateAction = useAsyncHandler(async (form: { name: string; asset_id?: string }) => {
    const company_id = await dispatch(CompanyDispatcher.createCompany(form))
    if (!company_id) return null

    history.push(`/company/${company_id}`)
  })

  return (
    <Grid
      container
      justifyContent="center"
      direction={'column'}
      alignItems={'center'}
      css={css`
        padding-top: 50px;
        @media (max-width: 677px) {
          padding-top: 10px;
        }
      `}
    >
      <Typography
        variant="h1"
        align="center"
        css={css`
          margin-bottom: 1rem;
        `}
      >
        Create company
      </Typography>

      <CompanyDetails onSubmit={companyCreateAction.execute} />
    </Grid>
  )
}

export default CompanyCreate
