import { Button, CircularProgress, Grid, Input, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { Company } from '@reaction-club-types/core'
import React from 'react'
import { connect } from 'react-redux'
import { Progress } from 'react-sweet-progress'
import { compose } from 'redux'
import fileExplorer from 'web-file-explorer'
import CompanyLogo from '../../../components/company/CompanyLogo'
import CompanyDispatcher from '../../../reducers/companies/dispatcher'
import { ExtractStyles } from '../../../../typings/stylesExtractor'

const connectStyles = withStyles((theme) => ({
  nameInput: {
    marginRight: 8,
  },

  logoContainer: {
    position: 'relative',
    marginRight: 10,

    '&:hover $logoHover': {
      opacity: 1,
    },
  },

  logoHover: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 10,
    backgroundColor: 'rgba(33,33,33,0.7)',
    opacity: 0,
    color: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'opacity 0.3s ease',
  },

  uploadingContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 20,
    backgroundColor: 'rgba(33,33,33,0.8)',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  progressText: {
    color: '#fff',
  },

  button: {
    marginLeft: 15,
  },
}))

interface State {
  isLoading: boolean
  isEditMode: boolean
  isUploading: boolean
  name: string
  workspace: string
  status: 'active' | 'success' | 'error' | 'idle'
  progress: number
  asset: AssetFull | null
}

interface Props {
  company: Company
  isEditMode: boolean
}

class CompanyHead extends React.PureComponent<
  ExtractStyles<typeof connectStyles> & Props & ReturnType<typeof mapDispatchToProps>,
  State
> {
  state: State = {
    isLoading: false,
    isEditMode: false,
    name: '',
    workspace: '',
    isUploading: false,
    status: 'idle',
    progress: 0,
    asset: null,
  }

  static getDerivedStateFromProps = (props, state) => {
    if (state.isEditMode === props.isEditMode) return null

    const { company } = props
    return {
      isEditMode: props.isEditMode,
      name: company.name,
      workspace: company.workspace,
      asset: company.asset || null,
    }
  }

  render() {
    const { company, classes } = this.props
    const { isEditMode } = this.state

    const { name, workspace, logo } = company

    if (isEditMode) return this.renderEdit()

    return (
      <Grid container direction={'row'} alignItems={'center'}>
        <div className={classes.logoContainer}>
          <CompanyLogo company={company} />
        </div>
        <Typography variant={'h1'} component={'h1'}>
          {name} ({workspace})
        </Typography>
      </Grid>
    )
  }

  renderEdit() {
    const { company, classes } = this.props
    const { name, workspace, asset } = this.state
    const { logo } = company

    let previewUrl = logo
    if (asset) previewUrl = asset.url

    return (
      <Grid container direction={'row'} alignItems={'center'}>
        <div className={classes.logoContainer} onClick={this.handlePressChangePicture}>
          <CompanyLogo
            company={{
              ...company,
              logo: previewUrl,
            }}
          />
          <div className={classes.logoHover}>change</div>
          {this.renderUploading()}
        </div>
        <Input value={name} className={classes.nameInput} onChange={this.handleNameChange} />
        <Input value={workspace} className={classes.nameInput} onChange={this.handleWorkspaceChange} />
        {this.renderSaveButton()}
      </Grid>
    )
  }

  renderSaveButton() {
    const { isLoading, isUploading, name, asset, workspace } = this.state
    const { classes, company } = this.props

    const isEqual = name === company.name && !asset && workspace === company.workspace
    if (isEqual) return null

    return (
      <Button
        variant={'contained'}
        color={'primary'}
        size={'small'}
        disabled={isLoading || isUploading}
        className={classes.button}
        onClick={this.handleClickSave}
      >
        {isLoading ? <CircularProgress color={'primary'} size={20} /> : 'Save'}
      </Button>
    )
  }

  renderUploading() {
    const { classes } = this.props
    const { isUploading, progress, status } = this.state
    if (!isUploading) return null

    // const status = progress != 100 ? 'active' : 'success'

    return (
      <div className={classes.uploadingContainer}>
        <Progress
          type="circle"
          percent={progress}
          status={status}
          width={50}
          color={'#fff'}
          symbolClassName={classes.progressText}
          theme={{
            active: {
              symbol: `${progress.toFixed(0)}%`,
              trailColor: 'rgba(255, 255, 255, 0.3)',
              color: '#fff',
            },
          }}
        />
      </div>
    )
  }

  handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: e.target.value })
  }

  handleWorkspaceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ workspace: e.target.value })
  }

  handlePressChangePicture = async () => {
    if (this.state.isUploading) return null

    const file = await fileExplorer.getFile({
      acceptImage: true,
    })

    await this.upload(file)
  }

  handleAssetReady = (asset: AssetFull) => {
    this.setState({ asset })
  }

  handleClickSave = async () => {
    const { name, workspace, asset } = this.state
    this.setState({ isLoading: true })

    try {
      if (name) {
        const payload = { name }
        if (asset) {
          payload.asset_id = asset.id
        }
        await this.props.updateCompany(payload)
      }
      if (workspace) {
        const payload = { workspace }
        await this.props.updateCompanyWorkspace(payload)
      }
    } finally {
      this.setState({ isLoading: false })
    }
  }
}

const mapDispatchToProps = (dispatch, { company }: Props) => ({
  updateCompany: (payload: { name: string; asset_id?: string }) =>
    dispatch(CompanyDispatcher.updateCompany(company.id, payload)),
  updateCompanyWorkspace: (payload: { workspace: string }) =>
    dispatch(CompanyDispatcher.updateCompanyWorkspace(company.id, payload)),
})

export default compose(connect(null, mapDispatchToProps), connectStyles)(CompanyHead)
