import moment from 'moment'
import React from 'react'

interface Props {
  value?: string
  onClick?(e: any): any
  className?: string
  time: Date
}

const EventTimeCustomInput = React.forwardRef((props: Props, ref) => {
  const {
    value, // value comses from lib
    onClick,
    className,
    time,
  } = props

  const dateStr = moment(time).format('yyyy, MMMM DD. HH:mm')
  return <input ref={ref} value={dateStr} onClick={onClick} className={className} />
})

export default EventTimeCustomInput
