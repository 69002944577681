// import { IPlan } from '@reducers/companies/types'
import types from './types'

export default {
  set: (challengeApplications: any): ReduxAction => ({ type: types.SET, payload: challengeApplications }),
  deleteChallengeApplication: (challenge_application_id: string): ReduxAction => ({
    type: types.DELETE,
    payload: { challenge_application_id },
  }),
  setEnableChallengeApplication: (challenge_application_id: string, state: boolean): ReduxAction => ({
    type: types.SET_ENABLE,
    payload: { challenge_application_id, is_enabled: state },
  }),
  setPublishedApplication: (challenge_application_id: string, state: boolean): ReduxAction => ({
    type: types.SET_PUBLISHED,
    payload: { challenge_application_id, is_published: state },
  }),
}
