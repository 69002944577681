import { Grid, Typography } from '@mui/material'
import { ReduxState } from '@reducers/index'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useParams } from 'react-router-dom'
import PaperContainer from '../../components/PaperContainer'
import ReactionLoader from '../../components/ReactionLoader'
import EmployeeDispatcher from '../../reducers/employees/dispatcher'
import EmployeeCompany from './employee/EmployeeCompany'
import EmployeeInfo from './employee/EmployeeInfo'
import EmployeePageTabs from './employee/EmployeePageTabs'
import EmployeePoints from './employee/EmployeePoints'
import EmployeeSteps from './employee/EmployeeSteps'

interface Props {}

function EmployeePage(props: Props) {
  const { id } = useParams<{ id: string }>()

  const dispatch = useDispatch()
  const { employee } = useSelector((state: ReduxState) => ({ employee: state.employees[id] }))

  const employeeFetch = useAsyncFetch(async () => {
    await dispatch(EmployeeDispatcher.getEmployee(id))
    await dispatch(EmployeeDispatcher.getContacts({ employee_id: id }))
  }, [id])

  return (
    <>
      <PaperContainer>
        <RendererStatusSplit
          statuses={employeeFetch}
          isEmpty={!employee}
          renderLoading={() => <ReactionLoader />}
          renderError={(error) => (
            <Typography color={'error'} textAlign={'center'}>
              {error}
            </Typography>
          )}
          renderEmpty={() => (
            <Grid container justifyContent={'center'}>
              User is not found
            </Grid>
          )}
          render={() => <EmployeeInfo employee={employee} />}
        />
      </PaperContainer>

      <Grid container justifyContent="center">
        <Grid xs={12} item sx={{ margin: '0 50px 50px 50px' }}>
          {employee && (
            <>
              <EmployeePageTabs employee={employee} />

              <EmployeeCompany employee={employee} />

              <EmployeeSteps employee={employee} />

              <EmployeePoints employee={employee} />
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default EmployeePage
