import React from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { GoogleApiWrapper } from 'google-maps-react'
import withStyles, { WithStyles } from 'react-jss'
import { compose } from 'redux'

const YOUR_GOOGLE_API_KEY_GOES_HERE = 'AIzaSyCaUJ8uieZIvOAlQGAcOOo99meQrbOa5MQ'

interface State {
  latitude: number | null
  longitude: number | null
  address: string
  errorMessage: string
  isGeocoding: boolean
}

interface Props {
  handleAddressGeocoded: (coordinates) => void
}

const styles = {
  container: {
    width: '100%',
  },
  inputContainer: {
    position: 'relative',
  },
  searchInput: {
    color: '#323232',
    border: '1px solid #aaa',
    outline: 'none',
    padding: '0.5rem 1rem',
    borderRadius: '8px',
    marginLeft: '10px',
  },
  clearButton: {
    position: 'absolute',
    right: '5px',
    top: '50%',
    transform: 'translateY(-50%)',
    background: 'transparent',
    border: 'none',
    outline: 'none',
    fontWeight: '600',
    color: '#999',
  },
  autocompleteContainer: {
    borderTop: '1px solid #e6e6e6',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    position: 'absolute',
    background: 'white',
    zIndex: 10,
    marginLeft: '10px',
    padding: '10px',
    borderRadius: '5px',
  },
  suggestionItem: {
    padding: '8px',
    textAlign: 'left',
    backgroundColor: '#fff',
    cursor: 'pointer',
  },
  suggestionItemActive: {
    backgroundColor: '#fafafa',
  },
  errorMessage: {
    color: 'red',
  },
}

class ReactionPlacesAutocomplete extends React.Component<Props & WithStyles<typeof styles>, State> {
  constructor(props) {
    super(props)
    this.state = {
      address: '',
      errorMessage: '',
      latitude: null,
      longitude: null,
      isGeocoding: false,
    }
  }

  render() {
    const { address, errorMessage } = this.state
    const { classes } = this.props

    return (
      <div>
        <PlacesAutocomplete
          onChange={this.handleChange}
          value={address}
          onSelect={this.handleSelect}
          onError={this.handleError}
          shouldFetchSuggestions={address.length > 2}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            return (
              <div className={classes.container}>
                <div className={classes.inputContainer}>
                  <input
                    {...getInputProps({
                      placeholder: 'Search Places...',
                      className: classes.searchInput,
                    })}
                  />
                  {this.state.address.length > 0 && (
                    <button type={'button'} className={classes.clearButton} onClick={this.handleCloseClick}>
                      x
                    </button>
                  )}
                </div>
                {suggestions.length > 0 && (
                  <div className={classes.autocompleteContainer}>
                    {suggestions.map(suggestion => {
                      const className = [
                        classes.suggestionItem,
                        suggestion.active ? classes.suggestionItemActive : '',
                      ].join(',')

                      return (
                        /* eslint-disable react/jsx-key */
                        <div {...getSuggestionItemProps(suggestion, { className })}>
                          <strong>{suggestion.formattedSuggestion.mainText}</strong>{' '}
                          <small>{suggestion.formattedSuggestion.secondaryText}</small>
                        </div>
                      )
                      /* eslint-enable react/jsx-key */
                    })}
                  </div>
                )}
              </div>
            )
          }}
        </PlacesAutocomplete>
        {errorMessage.length > 0 && <div className={classes.errorMessage}>{this.state.errorMessage}</div>}
      </div>
    )
  }

  handleChange = address => {
    this.setState({
      address,
      latitude: null,
      longitude: null,
      errorMessage: '',
    })
  }

  handleSelect = selected => {
    this.setState({ isGeocoding: true, address: selected })
    geocodeByAddress(selected)
      .then(res => getLatLng(res[0]))
      .then(({ lat, lng }) => {
        this.setState({
          latitude: lat,
          longitude: lng,
          isGeocoding: false,
        })
        this.props.handleAddressGeocoded({
          latitude: lat,
          longitude: lng,
        })
      })
      .catch(error => {
        this.setState({ isGeocoding: false })
        console.log('error', error) // eslint-disable-line no-console
      })
  }

  handleCloseClick = () => {
    this.setState({
      address: '',
      latitude: null,
      longitude: null,
    })
    this.props.handleAddressGeocoded({
      latitude: null,
      longitude: null,
    })
  }

  handleError = (status, clearSuggestions) => {
    console.log('Error from Google Maps API', status) // eslint-disable-line no-console
    this.setState({ errorMessage: status }, () => {
      clearSuggestions()
    })
  }
}

export default compose(
  GoogleApiWrapper({
    apiKey: YOUR_GOOGLE_API_KEY_GOES_HERE,
  }),
  withStyles(styles),
)(ReactionPlacesAutocomplete) as any
