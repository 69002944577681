import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import api from '../../libs/api/api'
import apiOld from '../../libs/api/apiOld'
import apiUsers from '../users/apiUsers'
import apiEmployees from './api'
import { actions } from './reducer'

class Dispatcher {
  getEmployee(employee_id: string): DispatcherResponse {
    return async (dispatch) => {
      const employee = await apiOld.getEmployeeById(employee_id)
      dispatch(actions.set(employee))
    }
  }

  deleteEmployee({ employee_id }: { employee_id: string }): DispatcherResponse {
    return async (dispatch) => {
      await apiEmployees.deleteEmployee(employee_id)
      dispatch(actions.delete(employee_id))
    }
  }

  destroyEmployee({ employee_id, password }: { employee_id: string; password: string }): DispatcherResponse {
    return async (dispatch) => {
      await apiOld.destroyEmployee(employee_id, { password })
      // await dispatch(actions.removeEmployee(employee_id))
    }
  }

  getContacts({ employee_id }: { employee_id: string }) {
    return async (dispatch) => {
      const contacts = await api.getUserContacts(employee_id)
      dispatch(actions.setContacts({ user_id: employee_id, contacts }))
    }
  }

  getGroups({ employee_id }: { employee_id: string }) {
    return async (dispatch) => {
      const groups = await apiEmployees.getGroups(employee_id)
      dispatch(actions.setGroups({ user_id: employee_id, groups }))
    }
  }

  getPointLogs({ employee_id }: { employee_id: string }) {
    return async (dispatch) => {
      const list = await apiEmployees.getPointsLog(employee_id, { offset: 0 })
      dispatch(actions.setPointsLog({ user_id: employee_id, list }))
    }
  }

  getStepsDaily({ employee_id }: { employee_id: string }) {
    return async (dispatch) => {
      const list = await apiEmployees.getStepsHistory(employee_id)
      dispatch(actions.setDailySteps({ user_id: employee_id, list }))
    }
  }

  changePoints({ employee_id, points }: { employee_id: string; points: number }) {
    return async (dispatch) => {
      await apiEmployees.changePoints(employee_id, points)
      dispatch(this.getEmployee(employee_id))
    }
  }

  setCompany({ employee_id, company_id }: { employee_id: string; company_id: string }) {
    return async (dispatch) => {
      await apiEmployees.setCompany(employee_id, { company_id })
      dispatch(this.getEmployee(employee_id))
    }
  }

  getDevices(user_id: string) {
    return async (dispatch) => {
      const devices = await apiUsers.getUserDevices(user_id)
      dispatch(actions.setDevices({ user_id, list: devices }))
    }
  }

  // getDeviceDiagnostic(device_id: string) {
  //   return async (dispatch) => {
  //     const diagnostic = await apiUsers.getDeviceDiagnostic(device_id)
  //     dispatch(actions.setSmsLogs({ user_id, list }))
  //   }
  // }
}

const EmployeeDispatcher = new Dispatcher()
export default EmployeeDispatcher
