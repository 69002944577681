export const ContainerStyle = {
  padding: 50,
  '&&': {
    '@media (max-width: 1600px)': {
      padding: '50px 25px',
    },
  },
}

export const OpenSans = '"Open Sans", sans-serif'

export const Helvetica = 'Helvetica'

export const boxShadow = '0px 0px 19px -3px rgba(0,0,0,0.75)'

export const containerMarginWizard = 53

export const mediaWidthLaptop = '@media (max-width: 1400px)'
export const mediaWidthMidSize = '@media (max-width: 1600px)'
