import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { Close as CloseIcon } from '@mui/icons-material'

const useStyles = makeStyles({
  deleteContainer: {
    padding: '0.15rem',
    borderRadius: '50%',
    textAlign: 'center',
    cursor: 'pointer',
    display: 'inline-block',
    '&:hover': {
      backgroundColor: '#eb3939',
      color: '#fff',
    },
  },

  deleteIcon: {
    height: '0.7rem',
    width: '0.7rem',
  },
})

interface Props {
  onClick(): void
  className?: string
}

function DeleteButton(props: Props) {
  const classes = useStyles()
  const { onClick, className } = props

  const containerClasses = [classes.deleteContainer, className]

  return (
    <div className={containerClasses.join(' ')} onClick={onClick}>
      <CloseIcon className={classes.deleteIcon} />
    </div>
  )
}

export default DeleteButton
