/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import ChallengeApplicationDispatcher from '@reducers/challengeAppplications/dispatcher'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { RendererStatusSplit } from 'react-renderer-status-split'
import { useHistory } from 'react-router-dom'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import Loader from '../../../components/Loader'

interface Props {
  challengeApplication: any
  isVisible: boolean
  onClose: () => any
}
export default function ChallengeApplicationDeleteModal(props: Props) {
  const { isVisible, onClose, challengeApplication } = props
  const history = useHistory()
  const form = useForm<{ message: string }>()
  const { register, handleSubmit } = form
  const { enqueueSnackbar } = useSnackbar()

  const dispatch = useDispatch<ReduxDispatch>()
  const deleteAction = useAsyncHandler(async ({ message }: { message: string | number }) => {
    if (message !== 'delete') return null
    await dispatch(ChallengeApplicationDispatcher.delete(challengeApplication.id))

    enqueueSnackbar('Application is deleted!', { variant: 'success' })
    onClose()
    form.reset({ message: '' })
    history.replace('/challenge-applications')
  })

  return (
    <Dialog open={isVisible} onClose={onClose}>
      <DialogTitle>Deleting Application</DialogTitle>
      <DialogContent>
        <DialogContentText color={'black'}>
          Are you sure want to delete this application?
          <br />
          Write
          <Typography
            color={'error'}
            display={'inline-block'}
            fontWeight={'bold'}
            css={css`
              margin: 0 3px;
            `}
          >
            delete
          </Typography>
          to confirm
        </DialogContentText>
        <TextField autoFocus margin="dense" label="delete" fullWidth variant="standard" {...register('message')} />
      </DialogContent>
      <DialogActions>
        <RendererStatusSplit
          statuses={deleteAction}
          renderLoading={() => (
            <Loader
              css={css`
                margin-right: 10px;
              `}
            />
          )}
          render={() => (
            <Button
              onClick={handleSubmit(deleteAction.execute)}
              css={css`
                font-weight: bold;
                color: #e82323;
              `}
            >
              Delete
            </Button>
          )}
        />

        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
