import { Grid, Typography, Avatar, ListItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Coach } from '@reaction-club-types/core'
import classNames from 'classnames'
import React, { ReactNode } from 'react'
import { matchPath } from 'react-router'
import { useLocation, useParams, Link, useRouteMatch } from 'react-router-dom'

const useStyles = makeStyles(
  {
    coachBody: {
      marginLeft: 10,
      flexGrow: 1,
    },

    link: {
      width: '100%',
    },

    coachListItem: {
      paddingLeft: 15,
      paddingRight: 5,
      position: 'relative',
    },

    ownerText: {
      fontSize: 10,
      color: '#979797',
      fontWeight: 'bold',
    },
    coachListItemActive: {
      '&:before': {
        content: '""',
        position: 'absolute',
        width: 2,
        top: 0,
        bottom: 0,
        right: 0,
        backgroundColor: '#F9B22D',
      },
    },
  },
  {
    classNamePrefix: 'CoachListItem',
  },
)

interface Props {
  coach: Coach
  style?: {
    link: string
  }
  button?: ReactNode
  link_url?: string
}

function CoachListItem(props: Props) {
  const { coach, style, button, link_url } = props
  const { user_id, name } = coach

  const classes = useStyles()

  const match = useRouteMatch()
  const location = useLocation()
  const params = useParams<{ id: string }>()

  const company_id = params.id
  const isOwner = company_id === coach.company_id

  const { pathname } = location
  const locMatch = matchPath<{ coach_id: string }>(pathname, `${match.url}/coach/:coach_id`)

  let isActive = false
  if (locMatch) isActive = locMatch.params.coach_id === user_id

  const linkStyles = [classes.link]

  if (style && style.link) {
    linkStyles.push(style.link)
  }

  return (
    <Link to={link_url || `${match.url}/coach/${user_id}`} className={linkStyles.join(' ')}>
      <ListItem
        button
        component="div"
        disableGutters
        className={classNames({
          [classes.coachListItem]: true,
          [classes.coachListItemActive]: isActive,
        })}
      >
        <Grid container alignItems={'center'} direction={'row'}>
          <Avatar src={coach.avatar_url} variant={'circular'} />
          <div className={classes.coachBody}>
            <Typography variant={'body1'}>{name}</Typography>
          </div>

          {button}
          {isOwner && <span className={classes.ownerText}>owner</span>}
        </Grid>
      </ListItem>
    </Link>
  )
}

export default CoachListItem
