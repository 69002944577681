import { Grid, TextField } from '@mui/material'
import { InputProps as StandardInputProps } from '@mui/material/Input/Input'
import { withStyles } from '@mui/styles'
import { ReactionEvent } from '@reaction-club-types/core'
import { APIEventCreatePayload } from '@reducers/events/apiEvents'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import validate from 'validate.js'
import { ExtractStyles } from '../../../../../../typings/stylesExtractor'
import ButtonOrange from '../../../../../components/ButtonOrange'
import EventCoachSelect from './EventCoachSelect'
import EventDurationSelect from './EventDurationSelect'
import EventLocaleSelect from './EventLocaleSelect'
import EventRepeatSelect from './EventRepeatSelect'
import EventTimeSelect from './EventTimeSelect'
import EventTypeSwitch from './EventTypeSwitch'

const connectStyles = withStyles({
  buttonsContainer: {
    marginTop: 20,
  },
})

interface Errors {
  name?: string
  description?: string
  coach_id?: string
  duration?: string
  location_formatted?: string
  time?: string
}

interface State {
  name: string
  description: string
  type: ReactionEvent['workout_type']
  coach_id: string
  duration: string
  location: string
  language: string
  repeat: ReactionEvent['repeat']
  time: Date
  errors: Errors
  submitErrorMessage?: string
}

interface Props {
  onCreate(payload: APIEventCreatePayload): Promise<any>
}

interface PropsInner extends Props, ExtractStyles<typeof connectStyles> {}

class EventCreateForm extends React.PureComponent<PropsInner, State> {
  state: State = {
    name: '',
    description: '',
    type: 'on_site',
    coach_id: '',
    duration: '60',
    location: '',
    language: 'en',
    time: new Date(),
    errors: {},
    submitErrorMessage: '',
  }

  render() {
    const {
      name,
      description,
      type,
      coach_id,
      duration,
      location,
      time,
      group_id,
      errors,
      language,
      repeat,
    } = this.state
    const { classes } = this.props

    return (
      <div>
        <EventTypeSwitch value={type} onChange={this.handleTypeChange} />

        <EventCoachSelect value={coach_id} onChange={this.handleCoachChange} isErrored={!!errors.coach_id} />
        <EventLocaleSelect value={language} onChange={this.handleLocaleChange} />

        {/* <EventGroupSelect value={group_id} onChange={this.handleGroupChange} /> */}

        <TextField
          multiline
          value={location}
          label={'Location'}
          onChange={this.handleLocationChange}
          fullWidth
          error={!!errors.location_formatted}
        />

        <TextField value={name} label={'Name'} onChange={this.handleNameChange} fullWidth error={!!errors.name} />

        <TextField
          multiline
          value={description}
          label={'Description'}
          onChange={this.handleDescriptionChange}
          fullWidth
          error={!_.isEmpty(errors.description)}
        />

        <EventTimeSelect value={time} onChange={this.handleTimeChange} isErrored={!!errors.time} />

        <EventDurationSelect value={duration} onChange={this.handleDurationChange} />

        <EventRepeatSelect value={repeat} onChange={this.handleRepeatChange} />

        {/*  participant limit */}
        <Grid container justifyContent={'center'} className={classes.buttonsContainer}>
          <ButtonOrange onClick={this.handleSubmit}>Create</ButtonOrange>
        </Grid>
      </div>
    );
  }

  handleNameChange: StandardInputProps['onChange'] = e => {
    const { errors } = this.state
    delete errors.name
    this.setState({ name: e.target.value, errors })
  }

  handleDescriptionChange: StandardInputProps['onChange'] = e => {
    const { errors } = this.state
    delete errors.description
    this.setState({ description: e.target.value, errors })
  }

  handleTypeChange = (type: State['type']) => {
    this.setState({ type })
  }

  handleLocationChange: StandardInputProps['onChange'] = e => {
    const { errors } = this.state
    delete errors.location_formatted
    this.setState({ location: e.target.value, errors })
  }

  handleCoachChange = (coach_id: string) => {
    const { errors } = this.state
    delete errors.coach_id
    this.setState({ coach_id, errors })
  }

  handleDurationChange = (duration: string) => {
    this.setState({ duration })
  }

  handleLocaleChange = (language: string) => this.setState({ language })

  handleRepeatChange = (repeat: string) => this.setState({ repeat: repeat || null })

  handleTimeChange = (time: Date) => {
    const { errors } = this.state
    delete errors.time
    this.setState({ time, errors })
  }

  handleSubmit = async () => {
    const {
      type,
      duration,
      coach_id,
      description,
      location,
      name,
      time,
      submitErrorMessage,
      language,
      repeat,
    } = this.state

    if (submitErrorMessage) {
      this.setState({ submitErrorMessage: '' })
    }

    const form: APIEventCreatePayload = {
      workout_type: type,
      duration: Number(duration),
      coach_id,
      description,
      location_formatted: location,
      name,
      time: moment(time).toISOString(),
      language,
      repeat,
    }

    if (!this.isValid(form)) {
      return null
    }

    try {
      await this.props.onCreate(form)
    } catch (e) {
      const { data } = e?.response || {}
      const eMessage = data?.error || e.message
      this.setState({
        submitErrorMessage: eMessage,
      })
    }
  }

  isValid = (form: APIEventCreatePayload): boolean => {
    const errors: Errors = validate(form, validationSchema) || {}

    const nowMoment = moment()
    if (moment(form.time).isBefore(nowMoment)) {
      errors.time = 'Time is invalid. Select future date'
    }

    if (!form.coach_id) {
      errors.coach_id = 'Coach is not set'
    }

    console.log('errors', errors)

    this.setState({ errors })

    return _.isEmpty(errors)
  }
}

export default connectStyles(EventCreateForm)

const validationSchema = {
  name: {
    presence: {
      message: 'Name is required',
    },
    length: {
      minimum: 3,
      tooShort: 'Name at least %{count} letters',
    },
  },
  description: {
    presence: {
      message: 'Description is required',
    },
    length: {
      minimum: 3,
      tooShort: 'Description at least %{count} letters',
    },
  },
  location_formatted: {
    presence: {
      message: 'Fill meet place',
    },
    length: {
      minimum: 3,
      tooShort: 'Location at least %{count} letters',
    },
  },
}
