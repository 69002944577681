import { DispatcherResponse } from '../../../typings/ReduxDispatch'
import api from '../../libs/api/api'
import AuthDispatcher from '../auth/dispatcher'

class Dispatcher {
  install(): DispatcherResponse {
    return async dispatch => {
      dispatch(this.installLazy())
    }
  }

  installLazy(): DispatcherResponse {
    return async (dispatch, getState) => {
      const { auth } = getState()
      const { token } = auth
      if (!token) return null

      dispatch(this.fetchApps())
    }
  }

  fetchApps() {
    return async dispatch => {
      try {
        const apps = await api.getApps()
      } catch (e) {
        const status = e.response?.status
        if (status === 401) {
          await dispatch(AuthDispatcher.logout())
        }
      }
    }
  }
}

const AppDispatcher = new Dispatcher()
export default AppDispatcher
