/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@mui/material'
import { ZoomHost } from '@reaction-club-types/core'
import React, { ReactElement, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { StyledTableCell, StyledTableRow } from '../../../../elements/table/TableDefault'
import useModalState from '../../../../hooks/useModalState'
import StatusBoolean from '../../../company/components/StatusBoolean'
import ZoomHostActions from '../../components/ZoomHostActions'

interface Props {
  zoomHost: ZoomHost
}
export default function ZoomHostItem(props: Props) {
  const { zoomHost } = props
  const { zoom_host_id, coach_id, company_id, is_webinar_supported, target, is_pool, comment, is_enabled } = zoomHost
  const infoModal = useModalState(false)

  // let icon: ReactElement = useMemo(() => {
  //   if (!system) return null
  //   const systemLow = system.toLowerCase()
  //   if (systemLow === 'ios') return <AppleIcon />
  //   if (systemLow === 'android') return <AndroidIcon css={css({ color: '#5bd514', marginTop: 3 })} />
  //
  //   return null
  // }, [system])

  // const lastLoginFmtd = useMemo(() => {
  //   if (!last_login_at) return ''
  //   return moment(last_login_at).format('LLL')
  // }, [last_login_at])

  const filterNode = useMemo(() => {
    if (coach_id) return <Link to={`/coach/${coach_id}`}>Coach</Link>
    if (company_id) return <Link to={`/company/${company_id}`}>Company</Link>

    return '-'
  }, [coach_id, company_id])

  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell align="left">{zoom_host_id}</StyledTableCell>
        <StyledTableCell align="center">
          <StatusBoolean isTrue={is_pool} onlyDefined />
        </StyledTableCell>
        <StyledTableCell align="center">
          <Typography>{filterNode}</Typography>
        </StyledTableCell>
        <StyledTableCell align="center">
          <StatusBoolean isTrue={is_webinar_supported} onlyTrue />
        </StyledTableCell>
        <StyledTableCell align="center">
          <StatusBoolean isTrue={is_enabled} />
        </StyledTableCell>
        <StyledTableCell align="center">{target}</StyledTableCell>
        <StyledTableCell align="center">{comment}</StyledTableCell>
        <StyledTableCell align="center">
          <ZoomHostActions zoomHost={zoomHost} />
        </StyledTableCell>
      </StyledTableRow>

      {/* <DeviceInfoModal {...infoModal} device={device} /> */}
    </React.Fragment>
  )
}
