import keyManager from 'constant-manager'

export default keyManager(
  {
    SET: '',
    SET_ENABLE: '',
    SET_PUBLISHED: '',
    DELETE: '',
  },
  { prefix: 'challengeApplications__' },
)
