import { Grid } from '@mui/material'
import { ReduxStateEmployeesItem } from '@reducers/employees/reducer'
import React from 'react'
import UserContacts from '../../../components/user/UserContacts'
import EmployeeGeneral from './components/EmployeeGeneral'
import EmployeeHeadActions from './components/EmployeeHeadActions'

interface Props {
  employee: ReduxStateEmployeesItem
}

function EmployeeInfo(props: Props) {
  const { employee } = props

  return (
    <div>
      <Grid container direction={'row'} justifyContent={'space-between'}>
        <Grid item xs={10} sm={6} md={3}>
          <EmployeeGeneral employee={employee} />
        </Grid>
        <Grid item xs={2} sm={2}>
          <Grid container justifyContent={'flex-end'}>
            <EmployeeHeadActions employee={employee} />
          </Grid>
        </Grid>
      </Grid>

      <UserContacts user={employee} />
    </div>
  )
}

export default EmployeeInfo
