/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Modal, Grid, Typography } from '@mui/material'
import { Company } from '@reaction-club-types/core'
import CompanyPageDispatcher from '@reducers/companiesPage/dispatcher'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React, { useRef, useState } from 'react'
import { useAsyncFetch, useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxDispatch } from '../../typings/ReduxDispatch'
import Loader from '../components/Loader'
import ScrollView from '../components/ScrollView'
import ScrollElement from '../libs/infinite-scroll/ScrollElement'
import CompanyItem from './companySelect/CompanyItem'

interface Props {
  isVisible: boolean
  onSelect(company: Company): any
  onClose(): void
}

function CompanySelectModal(props: Props) {
  const { isVisible, onClose, onSelect } = props

  const scrollRef = useRef()
  const [name, setName] = useState('')

  const { companies, listIsNextBlocked } = useSelector((state: ReduxState) => ({
    companies: state.companiesPage.list,
    listIsNextBlocked: state.companiesPage.listIsNextBlocked,
  }))

  const dispatch = useDispatch<ReduxDispatch>()
  const fetchInitial = useAsyncFetch(
    async function companyFetchInitial() {
      await dispatch(CompanyPageDispatcher.getCompanies({ name }))
    },
    [name],
  )

  const loadMoreAction = useAsyncHandler(async function companiesFetchNext() {
    if (fetchInitial.isLoading) return null
    await dispatch(CompanyPageDispatcher.getCompaniesNext({ name }))
  })

  let companyFiltered = companies
  if (name) {
    const nameLow = name.toLowerCase()
    companyFiltered = _.filter(companyFiltered, (company) => (company.name || '').toLowerCase().includes(nameLow))
  }

  return (
    <Modal open={isVisible} onClose={onClose}>
      <div
        css={css({
          backgroundColor: '#fff',
          width: 400,
          margin: '30px auto',
          padding: '1rem 0',
          borderRadius: 8,
          outline: 'none',
        })}
      >
        <Grid
          container
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          css={css`
            padding: 0.2rem 1rem;
          `}
        >
          <Typography variant={'h4'}>Choose Company: </Typography>

          <input
            value={name}
            type={'text'}
            css={css({
              border: `1px solid #aaa`,
              padding: '0.5rem 1rem',
              borderRadius: 8,
              color: '#323232',
              outline: 'none',
            })}
            placeholder={'Company name'}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>

        <ScrollView offset={0} ref={scrollRef}>
          <ScrollElement
            getNext={() => loadMoreAction.execute()}
            getContainerRef={() => scrollRef.current}
            isNextExists={!listIsNextBlocked}
            isLoading={fetchInitial.isLoading}
            renderLoading={() => (
              <Grid container justifyContent={'center'}>
                <Loader />
              </Grid>
            )}
          >
            <div>
              {_.map(companyFiltered, (company) => (
                <CompanyItem key={`select-company-item-${company.id}`} company={company} onSelect={onSelect} />
              ))}
            </div>
          </ScrollElement>
        </ScrollView>
      </div>
    </Modal>
  )
}

export default CompanySelectModal
