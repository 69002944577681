/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import EditIcon from '@mui/icons-material/Edit'
import { Avatar, IconButton, ListItemAvatar, ListItemSecondaryAction } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { Group } from '@reaction-club-types/core'
import _ from 'lodash'
import React from 'react'
import { useHistory } from 'react-router-dom'

interface Props {
  group: Group
}

function GroupDetailPlaybook(props: Props) {
  const { group } = props
  const { playbook } = group
  const history = useHistory()

  const handleClickOnPlaybook = () => {
    if (!playbook) return null
    history.push(`/playbook/${playbook.id}`)
  }

  return (
    <ListItem onClick={handleClickOnPlaybook}>
      <ListItemAvatar>
        <Avatar src={_.get(group, 'playbook.asset.url') || _.get(group, 'playbook.thumb') || ''} />
      </ListItemAvatar>
      <ListItemText>{`Playbook: ${group.playbook ? group.playbook.name : 'No playbook'}`}</ListItemText>
      <ListItemSecondaryAction>
        <IconButton edge="end" size="large">
          <EditIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default GroupDetailPlaybook
