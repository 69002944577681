import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types'
import React, { CSSProperties } from 'react'

const styles: any = {
  image: {
    height: 200,
    width: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    backgroundColor: '#fff',
  },
}

export interface ImageAssetProps {
  asset: AssetFull
  className?: string
  resizeMode?: 'contain' | 'cover'
  style?: CSSProperties
}

function AssetImage(props: ImageAssetProps & WithStyles<typeof styles>) {
  const { classes, asset, className, resizeMode, style = {} } = props

  const { url, name } = asset

  const completeStyle: CSSProperties = { ...style }
  const classNames = [classes.image]
  if (className) classNames.push(className)
  if (resizeMode) completeStyle.objectFit = resizeMode

  return <img src={url} style={completeStyle} alt={name} className={classNames.join(' ')} />
}

AssetImage.propTypes = {
  asset: PropTypes.object.isRequired,
} as any

export default withStyles(styles)(AssetImage)
