import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React, { useState } from 'react'
import Loader from '../../../../components/Loader'

const useStyles = makeStyles(
  {
    buttonContainer: {
      marginBottom: 10,
    },
    button: {
      border: '1px solid #DEDEDE',
      borderRadius: 10,
      width: '90%',
      height: 42,
      backgroundColor: '#fff',
      outline: 'none',
      marginLeft: '5%',
      userSelect: 'none',
      minWidth: 200,
    },

    buttonText: {
      color: '#1A1A1A',
      fontSize: 12,
    },

    buttonInteractions: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f7f8ff',
      },
    },

    buttonIcon: {
      width: 22,
      height: 22,
      objectFit: 'contain',
    },

    buttonIconContainer: {
      width: 50,
      paddingRight: 10,
    },

    loader: {
      transform: 'scale(0.5)',
      marginTop: -10,
    },

    loaderContainer: {
      width: 40,
    },
  },
  {
    classNamePrefix: 'LoginButton',
  },
)

interface Props {
  icon: string
  title: string
  onLogin(): Promise<any>
}

function LoginButton(props: Props) {
  const { icon, title, onLogin } = props
  const classes = useStyles()
  const [isLoading, setLoading] = useState(false)

  const handleClick = () => {
    setLoading(true)

    onLogin().finally(() => {
      setLoading(false)
    })
  }

  return (
    <div className={classes.buttonContainer}>
      <button
        type={'button'}
        onClick={handleClick}
        className={classNames(classes.button, {
          [classes.buttonInteractions]: !isLoading,
        })}
        disabled={isLoading}
      >
        {isLoading ? (
          <Grid container alignItems={'center'} justifyContent={'center'}>
            <Loader />
          </Grid>
        ) : (
          <Grid container direction={'row'} alignItems={'center'}>
            <div className={classes.buttonIconContainer}>
              <img src={icon} alt="google" className={classes.buttonIcon} />
            </div>
            <span className={classes.buttonText}>{title}</span>
          </Grid>
        )}
      </button>
    </div>
  );
}

export default LoginButton
