/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Divider, Grid, Typography } from '@mui/material'
import { UserDevice } from '@reaction-club-types/core'
import apiUsers from '@reducers/users/apiUsers'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import ReactJson from 'react-json-view'
import Moment from 'react-moment'
import { RendererStatusSplit } from 'react-renderer-status-split'
import Loader from '../../../../../components/Loader'
import PermissionStatus from './components/PermissionStatus'

interface Props {
  device: UserDevice
}
export default function DeviceDiagnosticInfo(props: Props) {
  const { device } = props

  // const dispatch = useDispatch<ReduxDispatch>()
  const infoFetch = useAsyncFetch(async () => {
    const info = await apiUsers.getDeviceDiagnostic(device.device_id)
    return info
  })

  return (
    <RendererStatusSplit
      statuses={infoFetch}
      renderPreview={() => null}
      renderEmpty={() => <Typography textAlign={'center'}>No info found</Typography>}
      renderError={(error) => (
        <Typography color={'error'} textAlign={'center'}>
          {error}
        </Typography>
      )}
      renderLoading={() => (
        <Grid
          container
          justifyContent={'center'}
          css={css`
            padding-top: 30px;
          `}
        >
          <Loader />
        </Grid>
      )}
      render={(dataObj) => {
        const { data, actual_at } = dataObj || {}
        const { permissions } = data || {}
        const {
          CAMERA,
          CALENDARS,
          WRITE_CALENDAR,
          MOTION,
          LOCATION_WHEN_IN_USE,
          ACCESS_FINE_LOCATION,
          notifications,
          ACTIVITY_RECOGNITION,
          WRITE_EXTERNAL_STORAGE,
        } = permissions

        return (
          <div
            css={css`
              padding: 10px 10px 30px;
            `}
          >
            <div>
              <Typography
                variant={'h2'}
                css={css`
                  font-size: 18px;
                  margin-bottom: 5px;
                  margin-top: 10px;
                `}
              >
                Permissions
              </Typography>
              <div>
                <PermissionStatus label={'Notifications'} value={notifications?.status} />
                <PermissionStatus label={'Camera'} value={CAMERA} />
                <PermissionStatus label={'Calendars'} value={CALENDARS || WRITE_CALENDAR} />
                <PermissionStatus label={'LOCATION_WHEN_IN_USE'} value={LOCATION_WHEN_IN_USE} />
                <PermissionStatus label={'ACCESS_FINE_LOCATION'} value={ACCESS_FINE_LOCATION} />
                <PermissionStatus label={'WRITE_EXTERNAL_STORAGE'} value={WRITE_EXTERNAL_STORAGE} />
                <PermissionStatus label={'Motion'} value={MOTION} />
                <PermissionStatus label={'ACTIVITY_RECOGNITION'} value={ACTIVITY_RECOGNITION} />
              </div>
            </div>

            <Divider
              css={css`
                margin-top: 10px;
                margin-bottom: 10px;
              `}
            />

            <Typography
              variant={'h2'}
              css={css`
                font-size: 18px;
                margin-bottom: 10px;
              `}
            >
              Full Diagnostic
              <Typography
                css={css`
                  display: inline-block;
                  margin-left: 10px;
                  font-size: 12px;
                  color: #656565;
                  font-weight: normal;
                `}
              >
                {`actual at `}
                <Moment format={'LLL'}>{actual_at}</Moment>
              </Typography>
            </Typography>
            <ReactJson src={data} collapsed />
            {/* <JSONTree data={data} /> */}
          </div>
        )
      }}
    />
  )
}
