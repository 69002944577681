/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Paper, Typography } from '@mui/material'
import React, { ReactNode } from 'react'

interface Props {
  title: string
  children: ReactNode
}

function PageSection(props: Props) {
  const { children, title } = props

  return (
    <Paper
      css={css({
        marginTop: 30,
        width: '100%',
        overflow: 'hidden',
      })}
    >
      <Typography
        variant={'h2'}
        css={css({
          backgroundColor: '#F9B22D',
          padding: '7px 30px',
        })}
      >
        {title}
      </Typography>
      {children}
    </Paper>
  )
}

export default PageSection
