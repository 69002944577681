/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React, { useMemo } from 'react'

interface Props {
  label: string
  value: string
}
export default function PermissionStatus(props: Props) {
  const { label, value } = props

  let color = useMemo(() => {
    if (value === 'denied') return '#4c77e7'
    if (value === 'unavailable') return '#868686'
    if (value === 'granted') return 'rgb(7,189,28)'
    if (value === 'blocked') return '#db1616'

    return '#323232'
  }, [value])

  if (!value) return null

  return (
    <Grid container alignItems={'center'}>
      <Typography
        fontWeight={'bold'}
        css={css`
          margin-right: 5px;
          line-height: 24px;
        `}
      >
        {label}:
      </Typography>
      <Typography style={{ color }}>{value}</Typography>
    </Grid>
  )
}
