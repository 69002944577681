import { FormLabel as MaterialFormLabel } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { ReactNode } from 'react'

const useStyles = makeStyles(
  {
    container: {
      marginTop: 10,
      marginBottom: 3,
    },
    label: {
      color: '#333',
      fontWeight: 'bold',
      fontSize: 12,
    },
  },
  {
    classNamePrefix: 'FormLabel',
  },
)

interface Props {
  children: ReactNode
}
function FormLabel(props: Props) {
  const { children } = props
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <MaterialFormLabel className={classes.label}>{children}</MaterialFormLabel>
    </div>
  )
}

export default FormLabel
