/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import Grid from '@mui/material/Grid/Grid'
import Typography from '@mui/material/Typography/Typography'
import ChallengeTypeDispatcher from '@reducers/challengeTypes/dispatcher'
import NotificationActionDispatcher from '@reducers/notification/actionDispatcher'
import React from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PaperContainer from '../../components/PaperContainer'
import apiErrorUtil from '../../libs/errors/apiErrorUtil'
import ChallengeTypeForm from './components/ChallengeTypeForm'

function ChallengeTypeCreate() {
  const history = useHistory()
  const dispatch = useDispatch()
  const createAction = useAsyncHandler(async (form) => {
    try {
      const challenge_type_id = await dispatch(ChallengeTypeDispatcher.createChallengeType(form))
      if (!challenge_type_id) {
        NotificationActionDispatcher(dispatch).addErrorNotify({ message: 'Failed to create Challenge type' })
        return null
      }
      history.push(`/challenge-types`)
    } catch (e) {
      NotificationActionDispatcher(dispatch).addErrorNotify({ message: apiErrorUtil.getFormattedApiError(e) })
    }
  })

  return (
    <PaperContainer>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        css={css`
          margin-bottom: 20px;
        `}
      >
        <Typography variant={'h1'}>Create Type</Typography>
      </Grid>
      <ChallengeTypeForm onSubmit={createAction.execute} />
    </PaperContainer>
  )
}

export default ChallengeTypeCreate
