import keyManager from 'constant-manager'

export default keyManager(
  {
    SET: '',
    SET_PLAYBOOK_DETAIL: '[SET_PLAYBOOK_DETAIL]',
    SET_PLAYBOOK: '',
    SET_PLAYBOOK_PROGRAMS: '[SET_PLAYBOOK_PROGRAM]',
    SET_PROGRAM: '',
    DELETE_PROGRAM: '',
    SET_PRIVATE_COMPANIES: '[SET_PRIVATE_COMPANIES]',
    SET_PRIVATE_COACHES: '',
    DELETE_PLAYBOOK: '',
  },
  { prefix: 'Playbooks__' },
)
