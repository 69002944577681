import keyManager from 'constant-manager'

export default keyManager(
  {
    SET: '',
    SET_NEXT: '',
    SET_TOTAL_COUNT: '',
    DELETE: '',
    SET_NAME: '',
    SET_COORDINATES: '',
  },
  { prefix: 'CoachesPage__' },
)
