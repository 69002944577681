import { Coach, Company, Employee, CompanySticker, Group, Agent, CompanyConfig } from '@reaction-club-types/core'
import invariant from 'invariant'
import _ from 'lodash'
import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import types from './types'
import { IPlan } from '@reducers/companies/types'

type State = Record<string, ReduxStateCompanyItem>

export interface ReduxStateCompanyItem extends Company {
  employees: Employee[]
  coaches: Coach[]
  stickers: CompanySticker[]
  instances: Group[]
  agents: Agent[]
  config?: CompanyConfig
  plan?: IPlan
}

let initialState: State = {}

export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case types.SET: {
      let companies: Company | Company[] = action.payload
      if (!_.isArray(companies)) companies = [companies]

      _.forEach(companies, (company: Company) => {
        let stateCompany = state[company.id]
        if (stateCompany) stateCompany = stateCompany.merge(company)
        else
          stateCompany = SeamlessImmutable({
            ...company,
            employees: [],
            coaches: [],
            stickers: [],
          })

        state = state.merge({
          [company.id]: stateCompany,
        })
      })

      return state
    }

    case types.SET_EMPLOYEES: {
      const { company_id, employees } = action.payload

      let company = state[company_id]
      if (!company) return state

      company = company.merge({
        employees,
      })

      return state.merge({
        [company_id]: company,
      })
    }

    case types.SET_COACHES: {
      const { company_id, coaches } = action.payload

      let company = state[company_id]
      if (!company) return state

      company = company.merge({
        coaches,
      })

      return state.merge({
        [company_id]: company,
      })
    }

    case types.SET_STICKERS: {
      const { company_id, stickers } = action.payload

      let company = state[company_id]
      if (!company) return state

      company = company.merge({
        stickers,
      })

      return state.merge({
        [company_id]: company,
      })
    }

    case types.SET_INSTANCES: {
      const { company_id, instances } = action.payload

      let company = state[company_id]
      if (!company) return state

      company = company.merge({
        instances,
      })

      return state.merge({
        [company_id]: company,
      })
    }

    case types.SET_INSTANCE_COACHES: {
      const { company_id, instance_id, coaches } = action.payload

      let company = state[company_id]
      if (!company) return state

      if (!company.instances) return state

      company = company.updateIn(['instances'], (instances: Immutable<PlaybookInstance[]>) => {
        instances = instances.map((instance) => {
          if (instance.id === instance_id) {
            return instance.merge({
              coaches,
            })
          }
          return instance
        })
        return instances
      })
      return state.merge({
        [company_id]: company,
      })
    }

    case types.SET_AGENTS: {
      const { company_id, agents } = action.payload

      let company = state[company_id]
      invariant(company, 'company is not found')

      company = company.merge({
        agents,
      })

      return state.merge({
        [company_id]: company,
      })
    }

    case types.SET_PLAN: {
      const { company_id, plan } = action.payload

      let company = state[company_id]
      invariant(company, 'company is not found')

      company = company.merge({
        plan,
      })

      return state.merge({
        [company_id]: company,
      })
    }

    case types.RESET_POINTS: {
      const { company_id } = action.payload as { company_id: string }
      let companyItem = state[company_id]
      if (!companyItem) return state

      let employees = companyItem.employees.map((employee) => {
        return employee.merge({ points: 0 })
      })

      companyItem = companyItem.merge({
        employees,
      })

      return state.merge({
        [company_id]: companyItem,
      })
    }

    case types.DELETE: {
      const { company_id } = action.payload as { company_id: string }

      return state.without(company_id)
    }

    default:
      return state
  }
}
