import { Grid, Paper, Typography, CircularProgress } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import _ from 'lodash'
import React from 'react'
import { Styles } from 'react-jss'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'redux'
import PageSection from '../../../layout/PageSection'
import CompanyDispatcher from '../../../reducers/companies/dispatcher'
import CompanyStickerAddButton from './components/CompanyStickerAddButton'
import StickerListItem from './components/StickerListItem'

const styles: Styles = {
  listContainer: {
    overflowY: 'auto',
    padding: 10,
    position: 'relative',
  },

  emptyContainer: {
    padding: 10,
  },
}

interface State {
  isLoading: boolean
}

interface Props extends RouteComponentProps<{ id: string }> {}

class CompanyStickers extends React.PureComponent<
  Props & WithStyles<typeof styles> & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>,
  State
> {
  state: State = {
    isLoading: false,
  }

  render() {
    const { classes, company } = this.props

    // const subPath = `${match.path}/employee/:employee_id`

    return (
      <PageSection title={'Stickers'}>
        <Grid
          container
          direction={'row'}
          alignItems={'center'}
          wrap={'wrap'}
          spacing={1}
          className={classes.listContainer}
        >
          {this.renderList()}

          <CompanyStickerAddButton company_id={company.id} />
        </Grid>
      </PageSection>
    )
  }

  renderList() {
    const { company, classes } = this.props
    const { isLoading } = this.state
    const { stickers } = company

    if (isLoading)
      return (
        <Grid container justifyContent={'center'} className={classes.emptyContainer}>
          <CircularProgress color={'primary'} size={30} />
        </Grid>
      )

    if (_.isEmpty(stickers))
      return (
        <Grid container justifyContent={'center'} className={classes.emptyContainer}>
          <Typography variant={'h4'}>No Stickers</Typography>
        </Grid>
      )

    return _.map(stickers, (sticker) => (
      <StickerListItem sticker={sticker} key={`company-list-sticker-${sticker.id}`} />
    ))
  }

  async fetchData() {
    const { stickers } = this.props.company || {}
    this.setState({ isLoading: _.isEmpty(stickers) })
    try {
      await this.props.getStickers()
    } finally {
      this.setState({ isLoading: false })
    }
  }

  componentDidMount(): void {
    this.fetchData()
  }
}

const mapStateToProps = (state: ReduxState, { match }: Props) => {
  const company = state.companies[match.params.id]

  return {
    company,
  }
}

const mapDispatchToProps = (dispatch, { match }: Props) => ({
  getStickers: () => dispatch(CompanyDispatcher.getStickers(match.params.id)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(CompanyStickers)
