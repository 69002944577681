import { combineReducers } from 'redux'
import agent from './agent/reducer'
import agents from './agents/reducer'
import auth from './auth/reducer'
import challengeApplicationsPage from './challengeApplicationsPage/toolkit'
import challengeApplications from './challengeAppplications/reducer'
import challengeTypes from './challengeTypes/reducer'
import challengeTypesPage from './challengeTypesPage/toolkit'
import coach from './coach/reducer'
import coaches from './coaches/reducer'
import coachesPage from './coachesPage/reducer'
import companies from './companies/reducer'
import companiesPage from './companiesPage/toolkit'
import company from './company/reducer'
import createProgram from './createProgram/reducer'
import editProgram from './editProgram/reducer'
import employee from './employee/reducer'
import employees from './employees/reducer'
import events from './events/reducer'
import lists from './lists/toolkit'
import modal from './modal/reducer'
import notification from './notification/reducer'
import playbookCategories from './playbookCategories/reducer'
import playbookInstances from './playbookInstances/reducer'
import playbookTypes from './playbookTypes/reducer'
import books from './playbooks/bookReducer' // tmp. should be migrated from old playbooks
import playbooks from './playbooks/reducer'
import playbooksPage from './playbooksPage/reducer'
import programs from './programs/reducer'
import users from './users/reducer'
import zoomHosts from './zoomHosts/reducer'

const AppReducer = combineReducers({
  lists,
  agent,
  agents,
  companies,
  companiesPage,
  company,
  employees,
  employee,
  coaches,
  coach,
  programs,
  notification,
  auth,
  events,
  createProgram,
  editProgram,
  playbookTypes,
  playbooks,
  modal,
  books,
  playbooksPage,
  playbookCategories,
  coachesPage,
  playbookInstances,
  zoomHosts,
  users,
  challengeApplications,
  challengeApplicationsPage,
  challengeTypes,
  challengeTypesPage,
})

export type ReduxState = ReturnType<typeof AppReducer>

export default AppReducer
