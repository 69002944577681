import types from './types'

const coachActions = {
  setCoach: (coach: Coach) => ({ type: types.SET, payload: coach }),
  setCoachCompanies: (coach_id: string, companies: Company[]) => ({
    type: types.SET_COMPANIES,
    payload: { coach_id, companies },
  }),
  setCoachEmployees: (coach_id: string, employees: Employee[]) => ({
    type: types.SET_EMPLOYEES,
    payload: { coach_id, employees },
  }),
  setCoachGroups: (coach_id: string, groups: PlaybookInstance[]) => ({
    type: types.SET_GROUPS,
    payload: { coach_id, groups },
  }),
  setPersonalHost: (coach_id: string, host: ZoomHost | string) => ({
    type: types.SET_PERSONAL_HOST,
    payload: { coach_id, host },
  }),
  setContacts: (payload: { coach_id: string; contacts: any }) => ({
    type: types.SET_CONTACTS,
    payload,
  }),
}

export default coachActions
