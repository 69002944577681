import ListItem from '@mui/material/ListItem/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon'
import ListItemText from '@mui/material/ListItemText/ListItemText'
import { makeStyles } from '@mui/styles'
import React, { ReactNode } from 'react'
import { Link, useLocation } from 'react-router-dom'

const useStyles = makeStyles(
  {
    link: {
      margin: 10,
      textDecoration: 'none',
      color: '#000',
      maxWidth: 100,
    },
    iconActive: {
      color: '#ffa726',
    },
  },
  {
    classNamePrefix: 'AppContainer',
  },
)

interface Props {
  to: string
  title: string
  icon: ReactNode
}
interface PropsInner extends Props {}

function AppContainer(props: PropsInner) {
  const { to, title, icon } = props
  const location = useLocation()
  const classes = useStyles()
  const { pathname } = location

  const isActive = pathname.indexOf(to) === 0

  return (
    <Link to={to} className={classes.link}>
      <ListItem button>
        <ListItemIcon className={isActive ? classes.iconActive : ''}>{icon}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    </Link>
  )
}

export default AppContainer
