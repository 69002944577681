import { Employee } from '@reaction-club-types/core'
import apiClient from '../../lib/apiClient'

const apiGroup = {
  async getGroupUsers(group_id: string): Promise<Employee[]> {
    const { data } = await apiClient.get(`/group/${group_id}/employees`)
    return data
  },
}

export default apiGroup
