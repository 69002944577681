import _ from 'lodash'
import actions from './actions'
import api from '../../libs/api/apiOld'

export default dispatch =>
  bounded({
    async getPlaybooks() {
      const playbooks = await api.getPlaybooks()
      dispatch(actions.set(playbooks))
    },
    async getPlaybook(playbookId) {
      const playbook = await api.getPlaybook(playbookId)
      dispatch(actions.setPlaybookDetail(playbook))
    },

    async createPlaybook(playbook) {
      await api.createPlaybook(playbook)
    },
    async deletePlaybook(playbookId) {
      await api.deletePlaybook(playbookId)
    },
    async deletePlaybookProgram(playbookId, programId) {
      await api.deletePlaybookProgram(playbookId, programId)
    },
    async updatePlaybook(playbookId, playbook) {
      await api.updatePlaybook(playbookId, playbook)
      dispatch(actions.setPlaybookDetail(playbook))
    },
    async duplicatePlaybook(playbookId) {
      await api.duplicatePlaybook(playbookId)
      this.getPlaybooks()
    },
    async toggleVerifyPlaybook(playbookId, verify) {
      if (verify) await api.verifyPlaybook(playbookId)
      else await api.unverifyPlaybook(playbookId)
      this.getPlaybook(playbookId)
    },
    async togglePrivatePlaybook(playbookId, isPrivate) {
      if (isPrivate) await api.makePrivatePlaybook(playbookId)
      else await api.makePublicPlaybook(playbookId)
      this.getPlaybook(playbookId)
    },
    async getPrivateCompanies(playbookId) {
      const companies = await api.getPrivateCompanies(playbookId)
      dispatch(actions.setPrivateCompanies(companies))
    },
    async getPrivateCoaches(playbookId) {
      const companies = await api.getPrivateCoaches(playbookId)
      dispatch(actions.setPrivateCoaches(companies))
    },
    async setPrivateCoach(playbookId, coachId) {
      await api.setPrivateCoach(playbookId, coachId)
      this.getPrivateCoaches(playbookId)
    },
    async setPrivateCompany(playbookId, companyId) {
      await api.setPrivateCompany(playbookId, companyId)
      this.getPrivateCompanies(playbookId)
    },
  })

function bounded(object) {
  _.keys(object).forEach(key => {
    object[key] = object[key].bind(object)
  })
  return object
}

function timer() {
  return new Promise(resolve => setTimeout(resolve, 1000))
}
