import SeamlessImmutable, { Immutable } from 'seamless-immutable'
import types from './types'

interface State {
  token: string | null
  role: string | null
  isAuthorized: boolean
}

const initialState: State = {
  token: null,
  role: null,
  isAuthorized: false,
}

function authReducer(state = SeamlessImmutable(initialState), action: ReduxAction): Immutable<State> {
  switch (action.type) {
    case types.SET_TOKEN: {
      const data: Partial<State> = action.payload
      return state.merge(data)
    }

    case types.SET_IS_AUTHORIZED: {
      const isAuthorized: boolean = action.payload
      return state.merge({
        isAuthorized,
      })
    }

    case types.LOGOUT: {
      return state.merge({
        token: null,
        role: null,
        isAuthorized: false,
      })
    }

    default:
      return state
  }
}

export default authReducer
