import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React, { ReactElement, ReactNode } from 'react'

const useStyles = makeStyles(
  {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      padding: '0 20px 20px',
      position: 'relative',
      minWidth: 100,
    },

    closeContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    closeIcon: {
      padding: 5,
      fontSize: 20,
      marginRight: 5,
      cursor: 'pointer',
    },
  },
  {
    classNamePrefix: 'ModalStyled',
  },
)

export interface Props {
  isVisible: boolean
  onClose(): any
  children: ReactNode
  className?: string
  showCloseButton?: boolean
}
function ModalStyled(props: Props): ReactElement {
  const { isVisible, onClose, children, className, showCloseButton } = props
  const classes = useStyles()

  return (
    <Modal open={isVisible} onClose={onClose} className={classes.modal}>
      <Paper className={classNames(classes.paper, className)}>
        {showCloseButton && (
          <div className={classes.closeContainer}>
            <FontAwesomeIcon icon={faXmark} className={classes.closeIcon} color={'#444444'} onClick={onClose} />
          </div>
        )}
        {children}
      </Paper>
    </Modal>
  )
}

export default ModalStyled
