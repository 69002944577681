/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import WcIcon from '@mui/icons-material/Wc'
import { Avatar, Grid, Typography } from '@mui/material'
import { Coach } from '@reaction-club-types/core'
import React from 'react'
import UserLastSeen from '../../../components/user/UserLastSeen'

const cssText = css`
  width: 85%;
  margin: 5px 0 5px 10px;
`

interface Props {
  coach: Coach
}

const CoachGeneral = (props: Props) => {
  const { coach } = props
  const { contacts, avatar_url, name } = coach

  return (
    <div>
      <Avatar
        css={css`
          width: 100px;
          height: 100px;
        `}
        src={avatar_url}
      />

      <Typography
        css={css`
          margin: 15px 0px 10px;
        `}
        variant={'h1'}
      >
        {name}
      </Typography>

      <Grid
        css={css`
          margin-bottom: 10px;
        `}
      >
        <Grid container alignItems={'center'} direction={'row'}>
          <PhoneIcon htmlColor={'#ffa726'} />
          <Typography align={'left'} css={cssText}>
            {contacts.phone || 'No phone number'}
          </Typography>
        </Grid>
        <Grid container alignItems={'center'} direction={'row'}>
          <EmailIcon htmlColor={'#ffa726'} />
          <Typography align={'left'} css={cssText}>
            {contacts.email}
          </Typography>
        </Grid>
        <Grid container alignItems={'center'} direction={'row'}>
          <WcIcon htmlColor={'#ffa726'} />
          <Typography align={'left'} css={cssText}>
            {coach.profile.gender || 'Gender not set'}
          </Typography>
        </Grid>
      </Grid>

      <UserLastSeen user={coach} />
    </div>
  )
}

export default CoachGeneral
