import React from 'react'
import connect from 'react-redux/es/connect/connect'
import Snackbar from '@mui/material/Snackbar/Snackbar'
import NotificationsActionDispatcher from '../reducers/notification/actionDispatcher'
import { withStyles } from '@mui/styles';

const styles = {
  success: {
    color: '#27DB69',
  },
  warning: {
    color: '#dbc63c',
  },
  error: {
    color: '#db3444',
  },
}

@connect(
  state => {
    const notify = state.notification
    let setOpen = false
    if (state.notification.message) {
      setOpen = true
    }
    return { notify, openMessage: setOpen }
  },
  dispatch => ({
    makeEmptyNotify: NotificationsActionDispatcher(dispatch).makeEmptyNotify,
  }),
)
@withStyles(styles)
export default class Notifications extends React.Component {
  render() {
    const { notify, openMessage, classes } = this.props
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={openMessage}
        autoHideDuration={4000}
        onClose={this.handleCloseMessage}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <span className={classes[notify.type]} id="message-id">
            {notify.message}
          </span>
        }
      />
    )
  }

  handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.props.makeEmptyNotify()
  }
}
