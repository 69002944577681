import { Grid, Typography, CircularProgress } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { ReduxState } from '@reducers/index'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { Route, RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import { ExtractConnectType } from '../../../../typings/ReduxExtractor'
import { ExtractStyles } from '../../../../typings/stylesExtractor'
import PageSection from '../../../layout/PageSection'
import CompanyDispatcher from '../../../reducers/companies/dispatcher'
import CompanyEmployeeSubPage from './CompanyEmployeeSubPage'
import EmployeeListItem from './components/EmployeeListItem'

const connectStyles = withStyles({
  coachesListContainer: {
    width: 250,
    borderRight: '1px solid #e8d2d2',
    height: 500,
    overflowY: 'auto',
  },

  emptyContainer: {
    padding: 10,
  },
})

interface State {
  isLoading: boolean
}

interface Props extends RouteComponentProps<{ id: string }> {}
interface PropsInner extends Props, ExtractConnectType<typeof connectStore>, ExtractStyles<typeof connectStyles> {}

class CompanyEmployees extends React.PureComponent<PropsInner, State> {
  state: State = {
    isLoading: true,
  }

  render() {
    const { classes, match, company } = this.props
    const { employee_count } = company

    const subPath = `${match.path}/employee/:employee_id`

    let title = 'Employees'
    if (employee_count) title += ` (${employee_count})`

    return (
      <PageSection title={title}>
        <Grid container direction={'row'}>
          <Grid item>
            <Grid container direction={'row'} alignItems={'center'}>
              <div className={classes.coachesListContainer}>{this.renderList()}</div>
            </Grid>
          </Grid>

          <Grid item>
            <Route sensitive exact path={subPath} render={(props) => <CompanyEmployeeSubPage {...props} />} />
          </Grid>
        </Grid>
      </PageSection>
    )
  }

  renderList() {
    const { company, classes } = this.props
    const { isLoading } = this.state
    const { employees } = company

    let employeesOrdered = _.orderBy(employees, 'points').reverse()

    if (isLoading)
      return (
        <Grid container justifyContent={'center'} className={classes.emptyContainer}>
          <CircularProgress color={'primary'} size={30} />
        </Grid>
      )

    if (_.isEmpty(employees))
      return (
        <Grid container justifyContent={'center'} className={classes.emptyContainer}>
          <Typography variant={'h4'}>No Employees</Typography>
        </Grid>
      )

    return _.map(employeesOrdered, (employee) => (
      <EmployeeListItem employee={employee} key={`company-list-employee-${employee.user_id}`} />
    ))
  }

  async fetchData() {
    const { employees } = this.props.company || {}
    this.setState({ isLoading: _.isEmpty(employees) })
    try {
      await this.props.getEmployees()
    } finally {
      this.setState({ isLoading: false })
    }
  }

  componentDidMount(): void {
    this.fetchData()
  }
}

const connectStore = connect(
  (state: ReduxState, { match }: Props) => ({
    company: state.companies[match.params.id],
  }),
  (dispatch: ReduxDispatch, { match }: Props) => ({
    getEmployees: () => dispatch(CompanyDispatcher.getEmployees(match.params.id)),
  }),
)

export default compose<PropsInner, Props>(withRouter, connectStore, connectStyles)(CompanyEmployees)
