import keyManager from 'constant-manager'

export default keyManager(
  {
    SET_EVENT: '',
    SET_EVENTS: '',
    SET_PARTICIPANTS: '',
    SET_RECORDINGS: '',
    DELETE: '',
  },
  { prefix: 'EVENTS__' },
)
