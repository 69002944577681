import { makeStyles } from '@mui/styles'
import React from 'react'
import defaultLogo from '../../../assets/bird.png'

const useStyles = makeStyles(
  {
    image: {
      display: 'block',
      objectFit: 'contain',
      objectPosition: 'center',
      width: '100%',
      height: 80,
    },
  },
  {
    classNamePrefix: 'CompanyLogo',
  },
)

interface Props {
  challengeApplication: any
}
function ChallengeAppLogo(props: Props) {
  const { challengeApplication } = props
  const { name, icon_url } = challengeApplication

  const classes = useStyles()

  if (!icon_url) return <img src={defaultLogo} className={classes.image} alt={name} />

  return <img src={icon_url} className={classes.image} alt={name} />
}

export default ChallengeAppLogo
