import apiClient from '../../lib/apiClient'

export type ChallengeType = {
  id: string
  name: string
  label: string
  is_active: boolean
  created_at: string
}

const apiChallengeTypes = {
  async getChallengeTypes(): Promise<ChallengeType[]> {
    const result = await apiClient.get<ChallengeType[]>(`/challenge-types/all`)
    return result.data
  },

  async getChallengeTypeById(id: string): Promise<ChallengeType> {
    const result = await apiClient.get<ChallengeType>(`/challenge-type/${id}`)
    return result.data
  },

  async createChallengeType(payload): Promise<{ challenge_type_id }> {
    const result = await apiClient.post<{ challenge_type_id }>(`/challenge-type/create`, payload)
    return result.data
  },

  async updateChallengeType(id: string, body) {
    const result = await apiClient.put(`/challenge-type/${id}`, body)
    return result.data
  },
}

export default apiChallengeTypes
