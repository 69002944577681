import { Grid } from '@mui/material'
import withStyles from '@mui/styles/withStyles';
import React from 'react'
import { ExtractStyles } from '../../../../../typings/stylesExtractor'
import Loader from '../../../../components/Loader'
import AntSwitch from '../../../../elements/switch/AntSwitch'
import StatusBoolean from '../../components/StatusBoolean'
import ConfigLabel from './ConfigLabel'

const connectStyles = withStyles({
  container: {
    marginBottom: 10,
  },
})

interface State {
  isLoading: boolean
}

interface Props {
  title: string
  isTrue: boolean
  onChange(params: { status: boolean }): Promise<any>
}

interface PropsInner extends Props, ExtractStyles<typeof connectStyles> {}

class CompanyPreference extends React.PureComponent<PropsInner, State> {
  state: State = {
    isLoading: false,
  }

  render() {
    const { classes, isTrue, title } = this.props

    return (
      <Grid container alignItems={'center'} className={classes.container}>
        <ConfigLabel title={title} />
        <StatusBoolean isTrue={isTrue} />
        {this.renderSwitch()}
      </Grid>
    )
  }

  renderSwitch() {
    const { isTrue } = this.props
    const { isLoading } = this.state

    if (isLoading) return <Loader scale={0.3} />

    return <AntSwitch checked={isTrue} onChange={this.handleChangeCoffee} />
  }

  handleChangeCoffee = async (e) => {
    const isChecked = e.target.checked
    this.setState({ isLoading: true })
    try {
      await this.props.onChange({ status: isChecked })
    } finally {
      this.setState({ isLoading: false })
    }
  }
}

export default connectStyles(CompanyPreference)
