import { useEffect, useRef } from 'react'

function useReferredProp<Any>(prop: Any) {
  const ref = useRef<Any>(prop)

  useEffect(() => {
    ref.current = prop
  }, [prop])

  return ref
}

export default useReferredProp
