import _ from 'lodash'
import Immutable from 'seamless-immutable'

import types from './actionTypes'

export default function reducer(state = Immutable({}), action = {}) {
  switch (action.type) {
    case types.SET_COACH: {
      const coach = action.payload
      coach.devices = coach.devices ? coach.devices : {}
      coach.employees = coach.employees ? coach.employees : {}
      coach.companies = coach.companies ? coach.companies : {}
      coach.playbook = coach.playbook ? coach.playbook : {}
      coach._original = _.cloneDeep(coach)
      return state.merge(action.payload)
    }
    case types.SET_DEVICES: {
      return state.merge({
        devices: action.payload,
      })
    }
    case types.SET_COACH_EMPLOYEES: {
      return state.merge({
        employees: action.payload,
      })
    }
    case types.SET_COACH_COMPANIES: {
      return state.merge({
        companies: action.payload,
      })
    }
    case types.EMPTY_COACH_DEVICES: {
      return state.merge({
        devices: {},
      })
    }
    case types.ADD_ROAD: {
      let { roads } = state
      const addItem = (roads, value) => roads.concat(value)
      return state.merge({
        roads: addItem(roads, action.payload),
      })
    }
    case types.SET_COACH_PLAYBOOK: {
      return state.merge({
        playbook: action.payload,
      })
    }
    case types.DROP_ROAD: {
      let originRoads = state.roads
      const removeByItem = (arr, value) => arr.filter(item => item !== value)
      return state.merge({
        roads: removeByItem(originRoads, action.payload),
      })
    }
    default:
      return state
  }
}
