import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

interface State {
  list: any[]
  listIsNextBlocked?: boolean
}

const initialState: State = {
  list: [],
}

const dashboardNextSlice = createSlice({
  name: 'ChallengeTypesPage',
  initialState,
  extraReducers: {
    [appTypes.RESET]: (state, action) => {
      return initialState
    },
  },
  reducers: {
    setChallengeTypes(state, action: PayloadAction<any[]>) {
      const challengeTypes: any[] = action.payload
      state.list = challengeTypes
      state.listIsNextBlocked = challengeTypes.length < 20
    },

    setChallengeTypesNext(state, action: PayloadAction<any[]>) {
      const challengeTypes: any[] = action.payload
      const challengeTypesFiltered = _.filter(challengeTypes, (item) => {
        return !_.find(state.list, (stateItem) => stateItem.id === item.id)
      })
      state.list = state.list.concat(challengeTypesFiltered)
      state.listIsNextBlocked = challengeTypes.length < 20
    },
  },
})

export const { actions, reducer } = dashboardNextSlice
export default reducer
