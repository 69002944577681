import { makeStyles } from '@mui/styles'
import moment from 'moment'
import React from 'react'

const useStyles = makeStyles(
  {
    label: {
      fontSize: 12,
      color: '#909090',
      fontWeight: 'bold',
      marginRight: 5,
    },
    time: {
      fontSize: 12,
      color: '#333',
    },
  },
  {
    classNamePrefix: 'ItemCreatedInfo',
  },
)

interface Props {
  item: { created_at: string | Date }
}

function ItemCreatedInfo(props: Props) {
  const { item } = props
  const { created_at } = item

  const classes = useStyles()
  const createdMoment = moment(created_at)

  return (
    <div>
      <span className={classes.label}>created:</span>
      {/* <br /> */}
      <span className={classes.time}>{createdMoment.fromNow()}</span>
    </div>
  )
}

export default ItemCreatedInfo
