import { Divider, Grid } from '@mui/material'
import withStyles from '@mui/styles/withStyles';
import _ from 'lodash'
import React from 'react'
import { Styles } from 'react-jss'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import { compose } from 'redux'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import { ReduxState } from '../../../reducers'
import CompanyAgentContacts from './agent/CompanyAgentContacts'

const styles: Styles = {
  container: {
    padding: '10px 20px',
  },
  paper: {
    marginTop: 30,
    width: '100%',
    overflow: 'hidden',
  },

  title: {
    backgroundColor: '#F9B22D',
    padding: '7px 30px',
    // color: '#'
  },

  coachesListContainer: {
    width: 250,
    borderRight: '1px solid #e8d2d2',
    height: 300,
    overflowY: 'auto',
  },

  coachName: {
    fontWeight: 'bold',
    marginLeft: 10,
  },

  linkCoach: {
    marginLeft: 10,
    fontSize: 12,
  },

  permissionContainer: {
    margin: '10px 0 15px',
  },
  headContainer: {
    marginBottom: 20,
  },
}

interface Props extends RouteComponentProps<{ id: string; agent_id: string }> {
  company: any
}

class CompanyCoachSubPage extends React.PureComponent<
  Props & WithStyles<typeof styles> & ExractConnectType<typeof connectStore>
> {
  state = {}

  render() {
    const { company, classes, match } = this.props
    console.log('render sub page', this.props)
    const agent = _.find(company.agents, { user_id: match.params.agent_id })
    if (!agent) return null

    const { user_id, name, contacts } = agent

    return (
      <Grid container justifyContent={'space-between'} className={classes.container}>
        <Grid item>
          <Grid container direction={'row'} alignItems={'center'} className={classes.headContainer}>
            Agent: <span className={classes.coachName}>{name}</span>
            <Link to={`/agent/${user_id}`} target={'_blank'} className={classes.linkCoach}>
              [open page]
            </Link>
          </Grid>

          <Divider />

          <CompanyAgentContacts agent={agent} />
        </Grid>
      </Grid>
    );
  }
}

const connectStore = connect(
  (state: ReduxState, { match }: Props) => {
    return {}
  },
  (dispatch: ReduxDispatch, { match }: Props) => ({}),
)
export default compose(withRouter, connectStore, withStyles(styles))(CompanyCoachSubPage)
