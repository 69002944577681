import Immutable from 'seamless-immutable'

import types from './actionTypes'

const initialState = {
  name: '',
  description: '',
  milestones: [],
}
export default function reducer(state = Immutable({}), action = {}) {
  switch (action.type) {
    case types.INIT_CREATE_PROGRAM: {
      return state.merge(initialState)
    }
    case types.CHANGE_NAME: {
      return state.merge({
        name: action.payload,
      })
    }
    case types.CHANGE_DESCR: {
      return state.merge({
        description: action.payload,
      })
    }
    case types.ADD_MILESTONE: {
      let { milestones } = state
      const addItem = (milestones, value) => milestones.concat(value)
      return state.merge({
        milestones: addItem(milestones, action.payload),
      })
    }
    case types.ADD_TASK: {
      let { tasks } = state.milestones[action.payload.milestone]
      const addItem = (tasks, value) => tasks.concat(value)
      let milestones = state.milestones.map((milestone, index) => {
        if (action.payload.milestone === index) {
          return milestone.merge({ tasks: addItem(tasks, action.payload.task) })
        }
        return milestone
      })
      return state.merge({
        milestones,
      })
    }
    default:
      return state
  }
}
