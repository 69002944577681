import { Autocomplete } from '@mui/material'
import TextField from '@mui/material/TextField'
import { ChallengeProvider } from '@reducers/challengeAppplications/api'
import ChallengeApplicationDispatcher from '@reducers/challengeAppplications/dispatcher'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'

interface Props {
  disabled?: boolean
  value: { label: string; id: string }
  onChange: ({ label, id }: { label: string; id: string; name: string }) => void
}
function ChallengeProviderSelect(props: Props) {
  const { disabled = false, value, onChange } = props
  const dispatch = useDispatch<ReduxDispatch>()
  const [challengeProviders, setChallengeProviders] = useState<ChallengeProvider[]>([])

  useEffect(() => {
    getChallengeProviders()
  }, [])

  const currentValue = useMemo(
    () => challengeProviders?.find((i) => i.label === value?.label) || null,
    [value, challengeProviders],
  )

  const getChallengeProviders = async () => {
    const response = await dispatch(ChallengeApplicationDispatcher.getChallengeProviders())
    setChallengeProviders(response)
  }

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const label = e?.target.value
    const providerObject = challengeProviders.find((i) => i.label === label)
    if (providerObject) {
      onChange({ label, id: providerObject?.id, name: providerObject.name })
    }
  }

  return (
    <Autocomplete
      disablePortal
      options={challengeProviders}
      value={currentValue}
      onSelect={handleSelect}
      getOptionLabel={(i) => i.label}
      disabled={disabled}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} required label="Challenge Provider" variant="outlined" margin="dense" />
      )}
    />
  )
}

export default ChallengeProviderSelect
