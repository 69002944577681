import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import withStyles from '@mui/styles/withStyles';
import { WithStyles, Styles } from 'react-jss'
import { compose } from 'redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { Avatar, Divider, Grid, ListItem, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import InstanceListItem from './components/InstanceListItem'
import ButtonOrange from '../../tasks/components/ButtonOrange'
import InstanceDispatcher from '../../../reducers/playbookInstances/dispatcher'

const styles: Styles = {
  container: {
    padding: '10px 20px',
  },
  paper: {
    marginTop: 30,
    width: '100%',
    overflow: 'hidden',
  },

  title: {
    backgroundColor: '#F9B22D',
    padding: '7px 30px',
    // color: '#'
  },

  coachesListContainer: {
    width: 250,
    borderRight: '1px solid #e8d2d2',
    height: 300,
    overflowY: 'auto',
  },

  coachName: {
    fontWeight: 'bold',
    marginLeft: 10,
  },

  linkCoach: {
    marginLeft: 10,
    fontSize: 12,
  },

  permissionContainer: {
    margin: '10px 0 15px',
  },
  headContainer: {
    marginBottom: 20,
  },

  instanceBody: {
    marginLeft: 10,
    flexGrow: 1,
  },
  employeeCount: {
    marginLeft: 10,
    flexGrow: 1,
  },
  selectNewGroupItem: {
    paddingLeft: 15,
    paddingRight: 5,
    position: 'relative',
  },
}

interface Props extends RouteComponentProps<{ id: string; employee_id: string }> {}

class CompanyEmployeeSubPage extends React.PureComponent<
  Props & WithStyles<typeof styles> & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> {
  state = {
    selectNewGroupActive: false,
  }

  render() {
    const { company, classes, match } = this.props
    const { selectNewGroupActive } = this.state
    const employee = _.find(company.employees, { user_id: match.params.employee_id })
    if (!employee) return null

    const { user_id, name } = employee

    return (
      <Grid container justifyContent={'space-between'} className={classes.container}>
        <Grid item lg={12}>
          <Grid container direction={'row'} alignItems={'center'} className={classes.headContainer}>
            Employee: <span className={classes.coachName}>{name}</span>
            <Link to={`/employee/${user_id}`} target={'_blank'} className={classes.linkCoach}>
              [open page]
            </Link>
          </Grid>

          <Divider />
          {selectNewGroupActive ? this.renderSelectNewGroup(employee) : this.renderInstanceBlock(employee)}
        </Grid>
      </Grid>
    );
  }

  renderInstanceBlock = (employee: Employee) => {
    const { company, classes } = this.props
    const instance = _.find(company.instances, { id: employee.company_playbook_id })
    return (
      <Grid container direction={'row'} alignItems={'center'} className={classes.headContainer}>
        <Grid container justifyContent={'space-between'}>
          <Typography variant={'h2'}>Group:</Typography>
          <ButtonOrange size={'small'} onClick={() => this.setState({ selectNewGroupActive: true })}>
            Change group
          </ButtonOrange>
        </Grid>
        <Grid container>{!_.isEmpty(instance) && <InstanceListItem instance={instance} />}</Grid>
      </Grid>
    );
  }

  renderSelectNewGroup = (employee: Employee) => {
    const { company, classes } = this.props
    const availableInstances = _.filter(company.instances, instance => instance.id !== employee.company_playbook_id)

    return (
      <Grid container direction={'row'} alignItems={'center'} className={classes.headContainer}>
        <Grid container justifyContent={'space-between'}>
          <Typography variant={'h2'}>Available groups:</Typography>
          <Button color={'primary'} onClick={() => this.setState({ selectNewGroupActive: false })}>
            Decline
          </Button>
        </Grid>
        {availableInstances.map(instance => (
          <ListItem
            button
            component="div"
            disableGutters
            className={classes.selectNewGroupItem}
            key={`instance-id-${instance.id}`}
            onClick={this.handleChangeGroupClick(employee, instance)}
          >
            <Grid container alignItems={'center'} direction={'row'}>
              <Avatar src={instance.asset ? instance.asset.url : ''} variant={'circular'} />
              <div className={classes.instanceBody}>
                <Typography variant={'body1'}>{instance.name}</Typography>
              </div>

              {<span className={classes.employeeCount}>{instance.employee_count} emp.</span>}
            </Grid>
          </ListItem>
        ))}
      </Grid>
    );
  }

  handleChangeGroupClick = (employee: Employee, instance: PlaybookInstance) => async () => {
    if (!window.confirm(`Are you sure you want to change group for ${employee.name} to ${instance.name}`)) return
    await this.props.changeEmployeeInstance({ employee_id: employee.user_id, instance_id: instance.id, company_id: this.props.company.id })
    this.setState({ selectNewGroupActive: false })
  }
}

const mapStateToProps = (state: ReduxState, { match }: Props) => {
  const company = state.companies[match.params.id]

  return {
    company,
  }
}

const mapDispatchToProps = dispatch => ({
  changeEmployeeInstance: (payload: { employee_id: string; instance_id: string; company_id: string }) =>
    dispatch(InstanceDispatcher.updateEmployeeInstance(payload)),
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(CompanyEmployeeSubPage)
