import { makeStyles } from '@mui/styles'
import { Agent } from '@reaction-club-types/core'
import { ReduxStateAgent } from '@reducers/agent/reducer'
import React from 'react'
import { useHistory } from 'react-router-dom'
import CompanyPreview from '../../../components/items/CompanyPreview'

const useStyles = makeStyles(
  {
    label: {
      fontWeight: 'bold',
      color: '#414142',
      marginLeft: 10,
    },

    noCompanyLabel: {
      color: '#7f7f7f',
    },
  },
  {
    classNamePrefix: 'AgentCompany',
  },
)

interface Props {
  agent: ReduxStateAgent
}

const AgentCompany = (props: Props) => {
  const { agent } = props
  const { company_id, company } = agent
  const classes = useStyles()

  const history = useHistory()
  const handlePress = () => history.push(`/company/${company_id}`)

  return (
    <div>
      <span className={classes.label}>Company:</span>
      <div>
        {company_id ? (
          company && <CompanyPreview company={company} onPress={handlePress} />
        ) : (
          <span className={classes.noCompanyLabel}>Agent has no company</span>
        )}
      </div>
    </div>
  )
}

export default AgentCompany
