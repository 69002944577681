import { makeStyles } from '@mui/styles'
import { Company } from '@reaction-club-types/core'
import React from 'react'
import defaultLogo from '../../assets/bird.png'

const useStyles = makeStyles(
  {
    image: {
      display: 'block',
      objectFit: 'contain',
      objectPosition: 'center',
      width: '100%',
      height: 80,
    },
  },
  {
    classNamePrefix: 'CompanyLogo',
  },
)

interface Props {
  company: Company
  width?: number | string
  height?: number | string
}
function CompanyLogo(props: Props) {
  const { company, width, height } = props
  const { name, logo } = company

  const classes = useStyles()
  const style: {
    width?: string | number
    height?: string | number
  } = {}
  if (width) style.width = width
  if (height) style.height = height

  if (!logo) return <img src={defaultLogo} className={classes.image} style={style} alt={name} />

  return <img src={logo} className={classes.image} style={style} alt={name} />
}

export default CompanyLogo
