import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createTheme } from '@mui/material'
import React from 'react'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import 'react-sweet-progress/lib/style.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

const theme = createTheme({
  // props: {
  //   MuiTypography: {
  //     color: 'textPrimary',
  //   },
  //   MuiGrid: {
  //     // component: 'div',
  //   },
  //   MuiCheckbox: {
  //     icon: <span />,
  //     checkedIcon: <FontAwesomeIcon icon={faCheck} style={{ fontSize: '0.75rem' }} />,
  //   },
  // },
  spacing: 5,
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          outline: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: '1.3rem',
          fontWeight: 'bold',
          lineHeight: '1.4rem',
          marginBottom: '1.2rem',
        },

        h2: {
          fontSize: '1rem',
          fontWeight: 'bold',
          lineHeight: '1.5rem',
        },

        h4: {
          fontSize: '0.8rem',
          fontWeight: 'bold',
          lineHeight: 1,
        },

        subtitle1: {
          fontSize: '0.9rem',
        },

        body1: {
          fontSize: '1rem',
          lineHeight: '1.3rem',
        },

        body2: {
          fontSize: '0.8rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#808080',
        },
      },
    },
    MuiInput: {
      // fontSize: '1rem', // console errors
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#323232',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#8e8d8a',
          fontWeight: 'bold',

          '&.Mui-selected': {
            color: '#323232',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: '#F9B22D',
          borderRadius: 15,
          color: '#323232',
          fontWeight: 'bold',
          paddingLeft: 30,
          paddingRight: 30,
          textTransform: 'none',

          '&:hover': {
            backgroundColor: '#f9c956',
          },
        },
        containedSecondary: {
          backgroundColor: '#CDCDCD',
          borderRadius: 15,
          color: '#323232',
          fontWeight: 'bold',
          paddingLeft: 30,
          paddingRight: 30,
          textTransform: 'none',

          '&:hover': {
            backgroundColor: '#c3bbbb',
          },
        },
        textPrimary: {
          color: '#000',
        },
        textSecondary: {
          color: '#fff',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        primary: {
          backgroundColor: '#F9B22D',
          boxShadow: '0 1px 3px 0 rgba(0,0,0,0.3)',
          color: '#323232',

          '&:hover': {
            backgroundColor: '#ffc036',
          },
        },
        secondary: {
          backgroundColor: '#979797',
          boxShadow: '0 1px 3px 0 rgba(0,0,0,0.3)',
          color: '#fff',

          '&:hover': {
            backgroundColor: '#bcbcbc',
          },
        },
        sizeSmall: {
          width: '1.6rem',
          height: '1.6rem',
          minHeight: '1.6rem',
        },
        sizeMedium: {
          width: '2rem',
          height: '2rem',
          minHeight: '2rem',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          border: '1px solid #CCCCCC',
          padding: '5px !important',
          width: '1.3rem',
          height: '1.3rem',
          marginRight: 5,
        },
        colorSecondary: {
          borderRadius: '3px !important', // fucking MuiIconButton overrides this style
          color: '#48545F !important',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginBottom: '0.3rem',
          marginLeft: 0,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '2.5rem',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          // it is not working...
          minHeight: '2rem',
          paddingTop: '0.1rem',
          paddingBottom: '0.1rem',
        },
      },
    },
    // MuiCircularProgress: {
    //   styleOverrides: {
    //     colorPrimary: {
    //       color: '#F9B22D',
    //     },
    //   },
    // },
  },
  typography: {
    fontFamily: `'Open Sans', sans-serif`,
  },
  palette: {
    text: {
      primary: '#323232',
      secondary: '#F9B22D',
    },
    secondary: {
      light: '#fff64f',
      main: '#ffa726',
      dark: '#c79400',
      contrastText: '#000000',
    },
    primary: {
      light: '#bebdba',
      main: '#8e8d8a',
      dark: '#61605d',
      contrastText: '#ffffff',
    },
    // secondary: '#FC6E74',
  },
})

export default theme
