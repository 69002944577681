import { ZoomHost } from '@reaction-club-types/core'
import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
  list: ZoomHost[]
  available: ZoomHost[]
}

const initialState: State = {
  list: [],
  available: [],
}

const slice = createSlice({
  name: 'ZoomHosts',
  initialState,
  extraReducers: {
    [appTypes.RESET]: (state, action) => {
      return initialState
    },
  },
  reducers: {
    setList(state, action: PayloadAction<State['list']>) {
      const list = action.payload
      state.list = list
    },

    setAvailableList(state, action: PayloadAction<State['available']>) {
      const list = action.payload
      state.available = list
    },
  },
})

export const { actions, reducer } = slice
export default reducer
