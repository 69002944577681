import { Checkbox, Divider, Tooltip } from '@mui/material'
import Button from '@mui/material/Button/Button'
import Grid from '@mui/material/Grid/Grid'
import TextField from '@mui/material/TextField/TextField'
import Typography from '@mui/material/Typography/Typography'
import { withStyles } from '@mui/styles'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import _ from 'lodash'
import { MuiColorInput } from 'mui-color-input'
import React, { useMemo, useRef, useState } from 'react'
import AssetEditable from '../../PlayBooks/playbookCreate/AssetEditable'
import ChallengeAppCompanyConfig from './ChallengeAppCompanyConfig'
import ChallengeAppGoalInput from './ChallengeAppGoalInput'
import ChallengeAppPageActions from './ChallengeAppPageActions'
import ChallengeProviderSelect from './ChallengeProviderSelect'
import ChallengeResultTypeSelect from './ChallengeResultTypeSelect'
import ChallengeUserLeaderboardDisplaySelect from './ChallengeUserLeaderboardDisplaySelect'
import ChallengeTypeSelect from './ChallengeTypeSelect'
import ConfigEditor from './ConfigEditor'

export const FORM_TYPES = {
  CREATE: 'create',
  UPDATE: 'update',
}

const PROVIDERS = {
  FITTY_AI: 'fitty_ai',
  REACTION: 'reaction',
}

const configPresets = {
  [PROVIDERS.FITTY_AI]: {
    company_name: '',
    workout_id: '',
    uri: '',
  },
  [PROVIDERS.REACTION]: {},
}

const connectStyles = withStyles({
  buttonsContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  imageContainer: {
    maxHeight: 200,
    minHeight: 80,
    objectFit: 'contain',
    width: '100%',
    backgroundColor: '#cacaca',
    borderRadius: 6,
    boxShadow: '0 2px 5px 0px rgba(0,0,0,0.3)',
    overflow: 'hidden',
  },
  container: {
    position: 'relative',
    padding: '1rem 2rem',
  },
})

const ChallengeApplicationForm = (props) => {
  const { classes, onSubmit, initialData = null, formType = FORM_TYPES.CREATE } = props
  const [isEditMode, setIsEditMode] = useState(false)
  const formikRef = useRef<FormikProps<any>>(null)
  const isDisabled = useMemo(() => {
    return formType === FORM_TYPES.UPDATE ? !isEditMode : false
  }, [isEditMode])

  const initialValues = useMemo(() => {
    let basicData = {
      name: initialData?.name || '',
      config: initialData?.config || {},
      test_config: initialData?.test_config || {},
      type: { name: initialData?.type || '', id: null },
      provider: { label: initialData?.provider || '', id: null },
      result_type: { label: initialData?.result_type || '', id: null },
      initial_goal: initialData?.initial_goal || 20,
      title: initialData?.title || '',
      description: initialData?.description || '',
      background_color: initialData?.background_color || '#F9B22D',
      video_url: initialData?.video_url || '',
      is_premium: initialData?.is_premium || false,
      is_default: initialData?.is_default || false,
      type_label: initialData?.type_label || '',
      type_label_color: initialData?.type_label_color || '#FFFFFF',
      cta_label: initialData?.cta_label || 'Go',
      cta_color: initialData?.cta_color || '#44EA29',
      icon_asset: initialData?.icon_url ? { url: initialData?.icon_url } : null,
      type_icon_asset: initialData?.type_icon_url ? { url: initialData?.type_icon_url } : null,
      image_asset: initialData?.image_url ? { url: initialData?.image_url } : null,
      background_image_asset: initialData?.background_image_url ? { url: initialData?.background_image_url } : null,
      is_verified: undefined,
      is_enabled: undefined,
      is_published: undefined,
      user_leaderboard_display_option: initialData?.user_leaderboard_display_option || 'total'
    }
    if (formType === FORM_TYPES.UPDATE) {
      basicData = {
        ...basicData,
        is_verified: initialData?.is_verified || false,
        is_enabled: initialData?.is_enabled || false,
        is_published: initialData?.is_published || false,
      }
    }
    return basicData
  }, [initialData, formType])

  const handleSubmit = (values) => {
    const payload = {
      name: values.name,
      icon_asset_id: undefined,
      image_asset_id: undefined,
      background_image_asset_id: undefined,
      type_icon_asset_id: undefined,
      config: values.config,
      test_config: values.test_config,
      type_id: undefined,
      provider_id: undefined,
      initial_goal: values.initial_goal,
      video_url: values.video_url,
      title: values.title,
      description: values.description,
      background_color: values.background_color,
      is_premium: values.is_premium,
      is_enabled: values.is_enabled,
      is_verified: values.is_verified,
      is_published: values.is_published,
      is_default: values.is_default,
      type_label: values.type_label,
      type_label_color: values.type_label_color,
      cta_label: values.cta_label,
      cta_color: values.cta_color,
      user_leaderboard_display_option: values.user_leaderboard_display_option,
    }

    if (values.icon_asset === null || values.icon_asset?.id) {
      payload.icon_asset_id = values.icon_asset?.id || null
    }

    if (values.image_asset === null || values.image_asset?.id) {
      payload.image_asset_id = values.image_asset?.id || null
    }

    if (values.background_image_asset === null || values.background_image_asset?.id) {
      payload.background_image_asset_id = values.background_image_asset?.id || null
    }

    if (values.type_icon_asset === null || values.type_icon_asset?.id) {
      payload.type_icon_asset_id = values.type_icon_asset?.id || null
    }

    if (values.type.id) {
      payload.type_id = values.type.id
    }

    if (values.provider.id) {
      payload.provider_id = values.provider.id
    }

    onSubmit(payload)

    if (formType === FORM_TYPES.UPDATE) {
      setIsEditMode(false)
    }
  }

  const handleSelectProvider = async (field, form, value) => {
    form.setFieldValue(field.name, value)
    await form.setFieldValue('config', _.merge({ ...configPresets[value.name] }, form.values.config))
    await form.setFieldValue('test_config', _.merge({ ...configPresets[value.name] }, form.values.test_config))
  }

  const renderCompanyConfig = () => {
    if (formType === FORM_TYPES.UPDATE) {
      return (
        <Grid item xs={12} gap={2}>
          <ChallengeAppCompanyConfig application_id={initialData.id} />
        </Grid>
      )
    }
    return null
  }

  const onChangeDefault = async ({ field, form }: FieldProps) => {
    const value = !field.value
    await form.setFieldValue(field.name, value)
    if (value) {
      await form.setFieldValue('is_premium', false)
    }
  }

  const onChangeChallengeType = async (field, form, value) => {
    await form.setFieldValue(field.name, value)
    if (!form.touched?.type_label || !form.values?.type_label) {
      await form.setFieldValue('type_label', value.label)
    }
  }

  const onChangeUserLeaderboardDisplayType = async (field, form, value) => {
    await form.setFieldValue(field.name, value)
    if (!form.touched?.user_leaderboard_display_option || !form.values?.user_leaderboard_display_option) {
      await form.setFieldValue('user_leaderboard_display_option', value)
    }
  }

  const renderBottomBar = () => {
    if (isEditMode) {
      return (
        <Grid container justifyContent={'flex-end'} className={classes.buttonsContainer} gap={2}>
          <Button variant="contained" type="submit" color="primary">
            Submit
          </Button>
        </Grid>
      )
    }

    if (formType === 'create') {
      return (
        <Grid container justifyContent={'flex-end'} className={classes.buttonsContainer}>
          <Button variant="contained" type="submit" color="primary">
            Submit
          </Button>
        </Grid>
      )
    }

    return null
  }

  return (
    <Grid item xs={12}>
      <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={handleSubmit} validateOnChange>
        <Form className={classes.container}>
          {formType === FORM_TYPES.UPDATE ? (
            <ChallengeAppPageActions
              setEditMode={setIsEditMode}
              isEditMode={isEditMode}
              challengeApplication={initialData}
            />
          ) : null}
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={4}>
              <Field name={'name'}>
                {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    disabled={isDisabled}
                    label="Application name"
                    fullWidth
                    required
                    variant="outlined"
                    margin="dense"
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={8}>
              <Field name={'type'}>
                {({ field, form }: FieldProps) => (
                  <ChallengeTypeSelect
                    {...field}
                    onChange={(value) => onChangeChallengeType(field, form, value)}
                    disabled={isDisabled}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field name={'title'}>
                {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    disabled={isDisabled}
                    label="Title"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={8} alignSelf={'center'}>
              <Tooltip
                title="There is only one Default Challenge with same Type can be selected.
                  Previous Default Challenge will be marked as not Default"
                placement={'bottom-start'}
              >
                <Grid container flexDirection={'row'}>
                  <Typography>Default for all companies</Typography>
                  <Field name={'is_default'}>
                    {({ field, form }: FieldProps) => (
                      <Checkbox
                        checked={field.value}
                        disabled={isDisabled}
                        style={{
                          marginLeft: 10,
                        }}
                        onChange={() => onChangeDefault({ field, form })}
                        value={field.value}
                        color={'secondary'}
                      />
                    )}
                  </Field>
                </Grid>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Field name={'description'}>
                {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    disabled={isDisabled}
                    label="Description"
                    multiline
                    variant="outlined"
                    fullWidth
                    margin="dense"
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={8} alignSelf={'center'}>
              <Grid container flexDirection={'row'}>
                <Typography>Only for Prime users</Typography>
                <Field name={'is_premium'}>
                  {({ field, form }: FieldProps) => (
                    <Checkbox
                      checked={field.value}
                      disabled={isDisabled}
                      style={{
                        marginLeft: 10,
                      }}
                      onChange={() => form.setFieldValue(field.name, !field.value)}
                      value={field.value}
                      color={'secondary'}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <Grid item xs={4} alignSelf={'end'}>
              <Field name={'initial_goal'}>
                {({ field, form }: FieldProps) => (
                  <ChallengeAppGoalInput
                    {...field}
                    resultType={form.values.type?.result_type || form.values.result_type?.label}
                    challengeType={form.values.type?.name}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                    disabled={isDisabled}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={8}>
              <Field name={'user_leaderboard_display_option'}>
                {({ field, form }: FieldProps) => (
                  <ChallengeUserLeaderboardDisplaySelect
                    {...field}
                    onChange={(value) => onChangeUserLeaderboardDisplayType(field, form, value)}
                    disabled={isDisabled}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={10}>
              <Typography variant={'h1'}>Design</Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name={'icon_asset'}>
                {({ field, form }: FieldProps) => (
                  <AssetEditable
                    disabled={isDisabled}
                    deleteActive
                    asset={field.value}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                    className={classes.imageContainer}
                  />
                )}
              </Field>
              <Typography>Banner Image</Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name={'type_icon_asset'}>
                {({ field, form }: FieldProps) => (
                  <AssetEditable
                    asset={field.value}
                    deleteActive
                    disabled={isDisabled}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                    className={classes.imageContainer}
                  />
                )}
              </Field>
              <Typography>Workout Icon</Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name={'image_asset'}>
                {({ field, form }: FieldProps) => (
                  <AssetEditable
                    asset={field.value}
                    deleteActive
                    disabled={isDisabled}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                    className={classes.imageContainer}
                  />
                )}
              </Field>
              <Typography>HR Image</Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name={'background_image_asset'}>
                {({ field, form }: FieldProps) => (
                  <AssetEditable
                    asset={field.value}
                    deleteActive
                    disabled={isDisabled}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                    className={classes.imageContainer}
                  />
                )}
              </Field>
              <Typography>Background Image</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>Choose Background color</Typography>
              <Field name={'background_color'}>
                {({ field, form }: FieldProps) => (
                  <MuiColorInput
                    format="hex"
                    disabled={isDisabled || form.values?.background_image_asset?.url}
                    title={'Background Color'}
                    variant="outlined"
                    value={field.value}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Typography>Choose Action Button color</Typography>
              <Field name={'cta_color'}>
                {({ field, form }: FieldProps) => (
                  <MuiColorInput
                    format="hex"
                    disabled={isDisabled}
                    required
                    title={'Action Button Color'}
                    variant="outlined"
                    value={field.value}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={3} alignContent={'flex-end'}>
              <Field name={'cta_label'}>
                {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    disabled={isDisabled}
                    required
                    label="Action Button Label"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Typography>Choose Workout Label color</Typography>
              <Field name={'type_label_color'}>
                {({ field, form }: FieldProps) => (
                  <MuiColorInput
                    format="hex"
                    disabled={isDisabled}
                    required
                    title={'Action Button Color'}
                    variant="outlined"
                    value={field.value}
                    onChange={(value) => form.setFieldValue(field.name, value)}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={3} alignContent={'flex-end'}>
              <Field name={'type_label'}>
                {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    disabled={isDisabled}
                    required
                    label="Workout Label"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name={'video_url'}>
                {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    disabled={isDisabled}
                    label="Video Url"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'h1'}>Config</Typography>
            </Grid>
            <Grid item xs={10}>
              <Field name={'provider'}>
                {({ field, form }: FieldProps) => (
                  <ChallengeProviderSelect
                    {...field}
                    onChange={(value) => handleSelectProvider(field, form, value)}
                    disabled={isDisabled}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'h1'}>Production Config</Typography>
            </Grid>
            <Grid item xs={10}>
              <Field name={'config'}>
                {({ field, form }: FieldProps) => (
                  <ConfigEditor
                    disabled={isDisabled}
                    setData={(value) => form.setFieldValue(field.name, value)}
                    data={field.value}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'h1'}>Test Config</Typography>
            </Grid>
            <Grid item xs={10}>
              <Field name={'test_config'}>
                {({ field, form }: FieldProps) => (
                  <ConfigEditor
                    disabled={isDisabled}
                    setData={(value) => form.setFieldValue(field.name, value)}
                    data={field.value}
                  />
                )}
              </Field>
            </Grid>
            {renderCompanyConfig()}
            {renderBottomBar()}
          </Grid>
        </Form>
      </Formik>
    </Grid>
  )
}

export default connectStyles(ChallengeApplicationForm)
