import Immutable from 'seamless-immutable'

import types from './actionTypes'

export default function reducer(
  state = Immutable({
    modalType: null,
    modalProps: {},
  }),
  action = {},
) {
  switch (action.type) {
    case types.SHOW_MODAL: {
      return state.merge({
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
      })
    }
    case types.CLOSE_MODAL: {
      return state.merge({
        modalType: null,
        modalProps: {},
      })
    }
    default:
      return state
  }
}
