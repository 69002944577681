import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import JSONEditorReact from '../../../components/JsonEditor'

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '700px',
    '& .react-json-view': {
      fontSize: 16,
      filter: 'grayscale(100%)',
      background: 'none !important',
      overflow: 'auto',
    },
  },
})

const ConfigEditor = ({ setData, data = {}, disabled = false }) => {
  const classes = useStyles()

  return (
    <Box className={classes.box}>
      <JSONEditorReact json={data} disabled={disabled} onChangeText={(json) => setData(JSON.parse(json))} />
    </Box>
  )
}

export default ConfigEditor
