// COMPANY REQUESTS
import apiClient from '../../lib/apiClientOld'
import { prefix_web, prefix_assets } from './apiOld'

export default {
  async getCompanies(options: ApiWebRequestCompniesQuery = {}): Promise<ApiWebResponseCompnies> {
    const { offset = 0, name } = options

    const query: ApiWebRequestCompniesQuery = {
      offset,
      name,
    }
    const result = await apiClient.get(`${prefix_web}companies`, {
      params: query,
    })
    return result.data
  },

  async getCompanyById(companyId: string): Promise<Company> {
    const result = await this.apiClient.get(`${prefix_web}company/${companyId}`)
    return result.data
  },

  /**
   * Associate coaches with company
   * @param companyId - id company
   * @param coaches - array id coaches [0, 1, 2,...]
   * @returns {Promise<*>} return status {success: true } or error
   */
  async assignCoachesToCompany(companyId, coaches) {
    const result = await this.apiClient.post(`${prefix_web}company/${companyId}/coach`, { coach_id: coaches })
    return result.data
  },
  async unassigneCoachWithCompany(companyId, coachId) {
    const result = await this.apiClient.delete(`${prefix_web}company/${companyId}/coach`, {
      data: { coach_id: coachId },
    })
    return result.data
  },
  async deleteCompany(companyId) {
    const result = await this.apiClient.delete(`${prefix_web}company/${companyId}`)
    return result.data
  },
  async getCompanyCoaches(companyId: string): Promise<Coach[]> {
    const result = await this.apiClient.get(`${prefix_web}company/${companyId}/coaches`)
    return result.data
  },
  async getCompanyEmployees(companyId: string): Promise<Employee[]> {
    const result = await this.apiClient.get(`${prefix_web}company/${companyId}/employees`)
    return result.data
  },
  async getCompanyPlaybook(companyId) {
    const result = await this.apiClient.get(`${prefix_web}company/${companyId}/playbooks`)
    return result.data
  },
  async getCompanyInstances(companyId) {
    const result = await this.apiClient.get(`${prefix_web}company/${companyId}/playbook-instances`)
    return result.data
  },
  async deleteCompanyPlaybook(playbooksIds, companyId) {
    await this.apiClient.delete(`${prefix_web}company/${companyId}/playbook-instance/${playbooksIds}`)
    const result = await this.getCompanyPlaybook(companyId)
    return result.data
  },
  async addCompanyPlaybook(playbooksIds, companyId) {
    await this.apiClient.post(`${prefix_web}company/${companyId}/playbook`, { playbook_id: playbooksIds })
    const result = await this.apiClient.get(`${prefix_web}company/${companyId}/playbooks`)
    return result.data
  },

  async updateCompany(companyId: string, payload: { asset_id?: string; name: string }) {
    await this.apiClient.post(`${prefix_web}company/${companyId}`, payload)
    const result = await this.apiClient.get(`${prefix_web}company/${companyId}`)
    return result.data
  },
  async updateCompanyWorkspace(companyId: string, payload: { workspace: string }) {
    await this.apiClient.post(`${prefix_web}company/${companyId}/workspace`, payload)
    const result = await this.apiClient.get(`${prefix_web}company/${companyId}`)
    return result.data
  },
  async updateSessionLink(companyId, link) {
    await this.apiClient.post(`${prefix_web}company/${companyId}/session_link`, { session_link: link })
    const result = await this.apiClient.get(`${prefix_web}company/${companyId}`)
    return result.data
  },
  async getStickers(companyId: string): Promise<CompanyStickers[]> {
    const result = await this.apiClient.get(`${prefix_web}company/${companyId}/stickers`)
    return result.data
  },
  async createSticker({ company_id, asset_id }: { company_id: string; asset_id: string }) {
    const result = await this.apiClient.post(`${prefix_web}company/${company_id}/sticker`, { asset_id })
    return result.data
  },
  async deleteSticker({ company_id, sticker_id }: { sticker_id: string; company_id: string }) {
    const result = await this.apiClient.delete(`${prefix_web}company/${company_id}/sticker/${sticker_id}`)
    return result.data
  },
  async verifyCompany(companyId) {
    const result = await this.apiClient.post(`${prefix_web}company/${companyId}/verify`)
    return result.data
  },
  async unverifyCompany(companyId) {
    const result = await this.apiClient.post(`${prefix_web}company/${companyId}/unverify`)
    return result.data
  },
  async createHRToken(company_id: string): Promise<{
    user_id: string
    token: string
    session: Record<string, any>
  }> {
    const result = await this.apiClient.post(`${prefix_web}company/${company_id}/session`)
    return result.data
  },

  async saveCoachPermissions({
    company_id,
    coach_id,
    permissions,
  }: {
    company_id: string
    coach_id: string
    permissions: string[]
  }): Promise<void> {
    const result = await this.apiClient.post(`${prefix_web}company/${company_id}/coach/${coach_id}/permissions`, {
      permissions,
    })
    return result.data
  },
}
