import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles';

const styles = {
  image: {
    height: 150,
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    backgroundColor: '#fff',
  },
}

interface Props {
  classes: any
  asset: Asset
}

function AssetPicture(props: Props) {
  const { classes, asset } = props

  if (_.isEmpty(asset)) return null

  return <img src={asset.url} alt={'task'} className={classes.image} />
}

AssetPicture.propTypes = {
  asset: PropTypes.object.isRequired,
} as any

export default withStyles(styles as any)(AssetPicture)
