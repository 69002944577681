import _ from 'lodash'
import actions from './actions'
import api from '../../libs/api/apiOld'

export default dispatch =>
  bounded({
    async getCompany(companyId) {
      const company = await api.getCompanyById(companyId)
      dispatch(actions.set(company))
    },

    async uploadImg(file, options) {
      return await api.uploadImg(file, options)
    },
    async getCompanyCoaches(companyId) {
      const coaches = await api.getCompanyCoaches(companyId)
      dispatch(actions.setCompanyCoaches(coaches))
    },
    async assignCoachesToCompany(companyId, coaches) {
      await api.assignCoachesToCompany(companyId, coaches)
      const updateCoaches = await api.getCompanyCoaches(companyId)
      dispatch(actions.setCompanyCoaches(updateCoaches))
    },
    async unassigneCoachWithCompany(companyId, coachId) {
      await api.unassigneCoachWithCompany(companyId, coachId)
      const updateCoaches = await api.getCompanyCoaches(companyId)
      dispatch(actions.setCompanyCoaches(updateCoaches))
    },
    async getCompanyEmployees(companyId) {
      const employees = await api.getCompanyEmployees(companyId)
      dispatch(actions.setCompanyEmployees(employees))
    },
    async getCompanyPlaybook(companyId) {
      const playbook = await api.getCompanyPlaybook(companyId)
      dispatch(actions.setCompanyPlaybook(playbook))
    },
    async deleteCompany(companyId) {
      await api.deleteCompany(companyId)
    },
    async setCoachFilter(str) {
      dispatch(actions.setCoachFilter(str))
    },
    async setCoachFilterEmpty() {
      dispatch(actions.setCoachFilter(''))
    },
    async removePlaybook(playbook) {
      dispatch(actions.removePlaybook(playbook))
    },
    async addPlaybook(playbook) {
      dispatch(actions.addOnePlaybook(playbook))
    },
    async deleteCompanyPlaybook(playbooks, companyId) {
      const updatedPlaybook = await api.deleteCompanyPlaybook(playbooks, companyId)
      dispatch(actions.setCompanyPlaybook(updatedPlaybook))
    },
    async addCompanyPlaybook(playbooks, companyId) {
      const updatedPlaybook = await api.addCompanyPlaybook(playbooks, companyId)
      dispatch(actions.setCompanyPlaybook(updatedPlaybook))
    },
    async updateCompany(companyId, company) {
      const updatedCompany = await api.updateCompany(companyId, company)
      dispatch(actions.set(updatedCompany))
    },
    async updateSessionLink(companyId, link) {
      const updatedCompany = await api.updateSessionLink(companyId, link)
      dispatch(actions.set(updatedCompany))
    },
    async getStickers(companyId) {
      const stickers = await api.getStickers(companyId)
      dispatch(actions.setCompanyStickers(stickers))
    },
    async setCompanySticker(companyId, stickerAssetId) {
      await api.setCompanySticker(companyId, stickerAssetId)
      const stickers = await api.getStickers(companyId)
      dispatch(actions.setCompanyStickers(stickers))
    },
    async deleteSticker(stickerId, companyId) {
      await api.deleteSticker(stickerId, companyId)
      const stickers = await api.getStickers(companyId)
      dispatch(actions.setCompanyStickers(stickers))
    },
    async toggleVerifyCompany(company_id, verify) {
      if (verify) await api.verifyCompany(company_id)
      else await api.unverifyCompany(company_id)
      this.getCompany(company_id)
    },
    async getTokenForHR(company_id) {
      return api.getTokenForHR(company_id)
    },
  })

function bounded(object) {
  _.keys(object).forEach(key => {
    object[key] = object[key].bind(object)
  })
  return object
}

function timer() {
  return new Promise(resolve => setTimeout(resolve, 1000))
}
