/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Button, CircularProgress } from '@mui/material'
import { Company } from '@reaction-club-types/core'
import invariant from 'invariant'
import React from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../../../typings/ReduxDispatch'
import config from '../../../../config'
import CompanyDispatcher from '../../../../reducers/companies/dispatcher'

interface Props {
  company: Company
}

function CompanyHRLoginButton(props: Props) {
  const { company } = props
  const dispatch = useDispatch<ReduxDispatch>()

  const actionCreate = useAsyncHandler(async () => {
    return dispatch(CompanyDispatcher.createHRToken(company.id))
  })

  const handleLoginWithHR = async () => {
    const token = await actionCreate.execute()
    invariant(token, 'token is not received')
    window.open(`${config.HRURL}/login?token=${token}`)
  }

  // const handleLoginWithHRNEXT = async () => {
  //   try {
  //     this.setState({ isLoading: true })
  //     const token = await this.props.createHRToken()
  //     window.open(`${config.HRNEXTURL}/login?token=${token}`)
  //   } finally {
  //     this.setState({ isLoading: false })
  //   }
  // }

  return (
    <div>
      <Button
        color="primary"
        variant={'contained'}
        onClick={handleLoginWithHR}
        disabled={actionCreate.isLoading}
        css={css`
          width: 150px;
        `}
      >
        {actionCreate.isLoading ? <CircularProgress color="primary" size={20} /> : 'Login to HR'}
      </Button>
      {/* <Button */}
      {/*  color="primary" */}
      {/*  variant={'contained'} */}
      {/*  onClick={handleLoginWithHRNEXT} */}
      {/*  disabled={isLoading} */}
      {/*  className={classes.buttonNext} */}
      {/* > */}
      {/*  {isLoading ? <CircularProgress color="primary" size={20} /> : 'Login to HR-next'} */}
      {/* </Button> */}
    </div>
  )
}

export default CompanyHRLoginButton
