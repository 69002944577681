import keyManager from 'constant-manager'

export default keyManager(
  {
    SET: '',
    SET_EMPLOYEES: '',
    SET_COACHES: '',
    SET_STICKERS: '',
    SET_INSTANCES: '',
    SET_INSTANCE_COACHES: '',
    SET_AGENTS: '',
    SET_PLAN: '',
    RESET_POINTS: '',
    DELETE: '',
  },
  { prefix: 'Companies__' },
)

export enum PlanType {
  FreeTrial = 'FREE_TRIAL',
  Base = 'BASE',
}

export enum PlanStatus {
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED',
  Blocked = 'BLOCKED',
}

export interface IPlan {
  companyId: string
  type: PlanType
  expireAt: Date
  status: PlanStatus
  manual_status: PlanStatus
  is_manual: boolean
  updatedAt: Date
  createdAt: Date
  free_plan_exceed_at: Date
  blocked_at: Date
}
