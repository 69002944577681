import SeamlessImmutable from 'seamless-immutable'

import types from './types'

interface ReduxStatePlaybookInstances {
  [key: string]: PlaybookInstance
}
const initialState: ReduxStatePlaybookInstances = {}

export default function reducer(state = SeamlessImmutable(initialState), action: ReduxAction) {
  switch (action.type) {
    case types.SET: {
      const instance: PlaybookInstance = action.payload
      const existingInstance = state[instance.id]
      if (existingInstance) {
        state = state.setIn([instance.id], existingInstance.merge(instance))
      } else {
        state = state.merge({
          [instance.id]: {
            ...instance,
          },
        })
      }
      return state
    }
    default:
      return state
  }
}
